@import "../../../../styles/variables.scss";

.widgetBox {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-width: 300px;
  background-clip: border-box;
  background-color: $white;
  border-radius: 15px;
  color: $primaryColor;
  font-family: $circularBold;
  overflow: hidden;

  .content {
    width: 100%;
    padding: 50px 45px 40px;
    min-height: 250px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    form {
      width: 100%;
    }
    .text {
      color: $primaryColor;
      font-size: 27px;
      letter-spacing: 1.35px;
      .titleDark {
        color: darken($color: purple, $amount: 18.8%);
      }
    }
    .subtitle {
      font-family: $circularStd;
      color: $black;
      .boldText {
        font-family: $circularBold;
      }
    }
    .tooSmall {
      font-family: $circularStd;
      color: $black;
      a {
        color: $black;
        text-decoration: underline;
        font-family: $circularBold;
      }
      ol {
        display: flex;
        flex-direction: column;
        text-align: left;
        row-gap: 5px;
        padding-inline-start: 18px;
        margin: 30px 0 0;
      }
    }
    .footerText {
      color: $primaryColor;
    }
    .btn {
      min-width: 100%;
      margin: 0;
      button {
        min-width: 100%;
        padding: 0px 35px;
      }
      .serviceLinkBtn {
        padding: 0;
        margin: 0;
        min-width: 100%;
      }
    }
    .icon {
      svg {
        width: 64px;
        height: 64px;
        path {
          fill: $primaryColor;
        }
      }
    }
    @media screen and (max-width: 768px) {
      padding: 60px 25px 25px;
    }
  }
  .holdTightWidget {
    justify-content: center;
    gap: 25px;
    .icon svg {
      width: 120px;
      height: 120px;
      path {
        fill: $white;
      }
      @media screen and (max-width: 768px) {
        width: 100px;
      }
    }
    .icon .barsLoader {
      max-height: 85px;
    }
    .ccIcon svg {
      width: 120px;
      height: 120px;
      path {
        fill: $white;
      }
      circle {
        fill: $primaryColor;
        &:first-child {
          fill: rgba($primaryColorRgb, 0.5);
        }
      }
      @media screen and (max-width: 768px) {
        width: 100px;
      }
    }
    .countDownList {
      text-align: initial;
      font-size: 16px;
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
    .blueText {
      min-height: 100px;
      font-weight: 700;
      color: $primaryColor;
      text-align: center;
      animation-duration: 4s;
      animation-fill-mode: forwards;
    }
    @media screen and (max-width: 768px) {
      padding: 50px 30px;
    }
  }

  .incomeVerifiedDiv {
    .uploadDiv {
      font-family: $circularBold;
    }
    .reportedIncome {
      color: $primaryColor;
      span {
        font-family: $circularBold;
      }
    }
    .ltesubtitle {
      font-size: 16px;
    }
    .btnContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 10px;
      button {
        border: none;
        padding: 12px 20px;
        border-radius: 25px;
        font-size: 14px;
        background-color: transparent;
        text-transform: uppercase;
        letter-spacing: 0.76px;
        font-family: $circularBold;
        width: 100%;
        @media screen and (max-width: 767px) {
          margin-top: 10px;
          margin-left: 0;
        }
      }
      a {
        border: none;
        padding: 12px 20px;
        border-radius: 25px;
        font-size: 14px;
        background-color: transparent;
        text-transform: uppercase;
        letter-spacing: 0.76px;
        font-family: $circularBold;
        width: 100%;
        @media screen and (max-width: 767px) {
          margin-top: 10px;
          margin-left: 0;
        }
      }
      .primaryBtn {
        border: 2px solid $ctaColor;
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
      }
      .secondaryBtn {
        background-color: transparent;
        color: $ctaColor;
        border: 2px solid $ctaColor;
      }
    }
    .formContainer {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;
      max-width: 511px;
      padding: 0;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      :global {
        .form-group .mb-4 {
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          margin-bottom: 0 !important;
          .invalid-feedback {
            bottom: 0;
            transform: translateY(100%);
          }
          .react-tel-input {
            order: 2;
            .flag-dropdown {
              background-color: transparent;
              border-width: 0;
              border-bottom-width: 1px;
            }
          }
          label {
            order: 1;
            position: relative;
            transform: translate(0);
            color: $black;
            font-size: 14px;
            margin: 0;
            pointer-events: initial;
          }
          input {
            order: 2;
            padding: 2px 0;
            &::placeholder {
              color: rgba($color: $primaryColorRgb, $alpha: 0.4);
            }
          }
          & > div:first-child {
            order: 0;
          }
        }
        :global(.form-group) {
          span > div {
            position: absolute;
            background-color: transparent;
            right: 4px;
            bottom: 4px;
            top: unset;
            left: unset;
            i {
              border-color: $primaryColor;
              border-right-color: transparent;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .formField {
        margin-bottom: 0px;
        > div {
          margin-bottom: 15px !important;
          padding-top: 25px;
          padding-bottom: 0px !important;
        }
        input {
          font-size: 14px;
        }
      }
      @media screen and (max-width: 767px) {
        gap: 15px;
      }
    }
    .radioContainer {
      &:global(.mb-4) {
        & > div,
        & > div:first-child {
          order: 1;
          label {
            width: 100%;
          }
        }
        & > label {
          order: 2;
          text-transform: unset;
        }
        input {
          display: none;
          &:checked + label .radioLabel {
            background-color: rgba($color: $primaryColorRgb, $alpha: 0.1);
            border: 2px solid $primaryColor;
            opacity: 1;
          }
        }
        .radioLabel {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          border: 2px solid rgba($color: $primaryColorRgb, $alpha: 0.6);
          border-radius: 8px;
          padding: 15px;
          cursor: pointer;
          span {
            font-size: 12px;
            text-align: center;
            &:first-child {
              font-size: 14px;
              font-family: $circularBold;
              color: $primaryColor;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }
}
.containerCC {
  padding: 0;
  font-family: $circular;
  max-width: unset;
  min-height: 200px;
  :global(div.mb-4) {
    padding-top: 0;
  }
  label {
    color: $black;
    font-size: 14px;
  }
}
