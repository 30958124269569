@import "../../../../styles/variables.scss";

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .pageContainer {
      display: flex;
      flex: 1;
      width: 100%;
      background-color: $dark;
      .mainContainer {
        background: $veryLight;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        margin-top: 0px;
        position: relative;
        .scrollContainer {
          height: 100%;
          max-height: calc(100vh - 100px);
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          .container {
            position: relative;
            display: flex;
            max-width: 1145px;
            flex-grow: 1;
            margin: 0 auto;
            width: 100%;
            text-align: center;
            @media screen and (max-width: 767px) {
              margin-top: 0;
              margin-bottom: 120px;
            }
          }
          @media screen and (max-width: 1440px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 1366px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 767px) {
            padding: 20px;
            max-height: unset;
          }
        }
        @media screen and (max-width: 767px) {
          flex-grow: 1;
          padding: 0px 10px 10px 10px;
          max-height: unset;
        }
        @media screen and (max-width: 529px) {
          padding: 0;
          min-height: calc(100vh - 167px);
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        max-width: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.getEvenMoreContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $white;
  box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  overflow: hidden;
  .detailsContainer {
    display: flex;
    flex-grow: 1;
    .offerContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 40px;
      width: 45%;
      & > * {
        max-width: 230px;
        width: 100%;
      }
      .amountContainer {
        display: flex;
        flex-direction: column;
        background-color: $primaryColor;
        border-radius: 13px;
        padding: 15px;
        min-width: 187px;
        max-width: 250px;
        margin-bottom: 12px;
        color: $white;
        .dollarAmount {
          font-family: $circularBold;
          font-size: 28px;
          margin-bottom: 2px;
          background-color: $white;
          color: $primaryColor;
          box-shadow: 0px 10px 20px #00000014;
          border-radius: 25px;
          padding: 5px 0px;
          @media screen and (max-width: 1024px) {
            font-size: 22px;
          }
        }
        .offerDetail {
          margin-bottom: 0px;
          font-size: 10px;
          font-family: $circularBold;
          margin-top: 7px;
        }
        .separator {
          font-size: 10px;
        }
        .desktopContentContainer {
          display: flex;
          ul {
            width: 100%;
            padding-inline-start: 0px;
            list-style: none;
            margin-bottom: 0;
            @media screen and (max-width: 767px) {
              margin-bottom: 0;
            }
          }
          li {
            cursor: text;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            span {
              font-size: 9px;
              font-family: $circularStd;
            }
            .value {
              font-size: 9px;
              font-family: $circularBold;
              margin-left: auto;
            }
          }
        }
        .linkBtn {
          font-size: 8px;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .moreAbout {
        display: flex;
        padding: 9px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: rgba($color: $primaryColorRgb, $alpha: 0.2);
        cursor: pointer;
        span {
          height: 12px;
          width: 12px;
          flex-shrink: 0;
          border-radius: 6px;
          background-color: $primaryColor;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1px;
          margin-right: 5px;
          svg {
            width: 100%;
            color: $white;
          }
        }
        p {
          margin-bottom: 0;
          font-size: 11px;
        }
      }
      @media screen and (max-width: 1024px) {
        padding: 20px;
        width: 40%;
      }
      @media screen and (max-width: 767px) {
        padding: 30px 20px;
        width: 100%;
      }
    }
    .videoSectionContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-left: 1px solid $veryLight;
      padding: 40px;
      width: 55%;
      background: linear-gradient(
        234deg,
        rgba($color: $primaryColorRgb, $alpha: 0.15) 0%,
        $white 50%
      );
      .videoThumbnail {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80%;
        max-height: 275px;
        height: 100%;
        cursor: pointer;
        iframe {
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
          max-width: 510px;
          max-height: 275px;
          object-fit: cover;
        }
        .playContainer {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $white;
          height: 70px;
          width: 70px;
          border-radius: 35px;
          padding: 10px;
          box-shadow: 0 12px 20px rgba($color: $black, $alpha: 0.2);
          svg {
            width: 100%;
            height: 100%;
            path {
              fill: $primaryColor;
            }
          }
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          min-height: 275px;
        }
      }
      .cardContainer {
        width: 100%;
        max-width: 510px;
        display: flex;
        margin-top: 20px;
        .card {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: space-between;
          padding: 20px 22px;
          background: linear-gradient(
            246deg,
            $white 0%,
            rgba($color: $black, $alpha: 0.1) 200%
          );
          border-radius: 12px;
          border: 1px solid rgba($color: $black, $alpha: 0.2);
          box-shadow: 0 6px 18px rgba($color: $black, $alpha: 0.2);
          font-size: 11px;
          text-align: left;
          min-height: 130px;
          max-width: 250px;
          width: 100%;
          &.lastCard {
            @media screen and (max-width: 767px) {
              margin-bottom: 20px;
            }
          }
          .logoContainer {
            flex-shrink: 0;
            svg {
              width: 30px;
              path {
                fill: $primaryColor;
              }
            }
            @media screen and (max-width: 767px) {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
          p {
            padding-top: 10px;
            margin-bottom: 0;
            margin-top: auto;
            span {
              font-family: $circularBold;
            }
          }
          &:first-child {
            margin-right: 10px;
            @media screen and (max-width: 1024px) {
              margin-right: 0;
              margin-bottom: 10px;
            }
            @media screen and (max-width: 767px) {
              margin-bottom: 5px;
            }
          }
          &:last-child {
            margin-left: 10px;
            @media screen and (max-width: 1024px) {
              margin-left: 0;
              margin-top: 10px;
            }
            @media screen and (max-width: 767px) {
              margin-top: 5px;
            }
          }
          @media screen and (max-width: 767px) {
            text-align: center;
          }
        }
        @media screen and (max-width: 1024px) {
          flex-direction: column;
          align-items: center;
        }
        @media screen and (max-width: 767px) {
          margin-top: 10px;
        }
      }
      @media screen and (max-width: 1024px) {
        padding: 20px;
        width: 60%;
      }
      @media screen and (max-width: 767px) {
        padding: 30px 20px;
        width: 100%;
        border-left: none;
        border-top: 2px solid $veryLight;
        background: none;
      }
    }
    .title {
      font-size: 19px;
      font-family: $circularBold;
      color: rgba($color: $black, $alpha: 0.7);
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      background: linear-gradient(
        234deg,
        rgba($color: $primaryColorRgb, $alpha: 0.15) 0%,
        $white 50%
      );
    }
  }
  .btnContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: $greyColor;
    border-radius: 12px;
    .adjustButtonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
    }
    .linkBtnContainer {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .containerTitle {
        display: flex;
        font-size: 14px;
        font-family: $circularBold;
        color: $black;
        opacity: 0.9;
        margin-bottom: 4px;
        @media screen and (max-width: 394px) {
          margin-bottom: 0px;
        }
      }
      .contentBody {
        font-size: 12px;
        font-family: $circularStd;
        color: $black;
        opacity: 0.7;
        text-align: start;
        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      @media screen and (max-width: 767px) {
        margin-top: 10px;
        width: 100%;
      }
      @media screen and (max-width: 394px) {
        margin-top: 5px;
        width: 100%;
      }
    }
    .linkBtn {
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
      color: rgba($color: $white, $alpha: 0.7);
      @media screen and (max-width: 767px) {
        margin-top: 15px;
        width: 100%;
      }
    }
    .offerDetailBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
    }
    .requestCallBtn {
      padding: 10px 20px;
      background-color: $primaryColor;
      color: $white;
      border-radius: 25px;
      font-size: 10px;
      letter-spacing: 2.4px;
      text-transform: uppercase;
      font-family: $circularBold;
      border: none;
      &.offerBtn {
        margin-right: 15px;
        background-color: $white;
        color: $primaryColor;
        @media screen and (max-width: 1366px) {
          white-space: nowrap;
        }
        @media screen and (max-width: 1024px) {
          margin-right: 0px;
        }
      }

      &.requestBtn {
        @media screen and (max-width: 1024px) {
          margin-top: 10px;
        }
      }
      &.requestButton {
        @media screen and (max-width: 1366px) {
          white-space: nowrap;
        }
        @media screen and (max-width: 1024px) {
          white-space: unset;
          margin-top: 10px;
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 1024px) {
      padding: 30px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      position: fixed;
      padding: 10px;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 12px 12px 0 0;
      box-shadow: 0px 10px 51px rgba($color: $black, $alpha: 0.3);
    }
  }
}

.badgeContainer {
  display: flex;
  position: absolute;
  height: 145px;
  width: 145px;
  filter: drop-shadow(0px 10px 20px rgba($color: $black, $alpha: 0.08));
  svg {
    width: 43px;
    height: 43px;
    z-index: 1;
    transform: translate(50%, 50%);
  }
  &::before {
    content: " ";
    position: absolute;
    height: 145px;
    width: 145px;
    border-radius: 12px 0 0 0;
    background-color: $primaryColor;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}

.detailsPopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  width: 100%;
  outline: none;
  background-color: #fff;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    line-height: 18px;
    padding: 6px;
    cursor: pointer;
  }
  .offerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 2;
    background: $white;
    border-radius: 12px;
    .totalContent {
      display: flex;
      padding: 30px 35px;
      width: 100%;
      font-size: 14px;
      font-family: $circularStd;
      letter-spacing: 0;
      border-bottom: 2px solid $veryLight;
      .label {
        font-size: 18px;
        font-family: $circularBold;
        text-align: left;
        width: 100%;
      }
      .expireDate {
        text-align: right;
        width: 100%;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        .expireDate {
          text-align: left;
        }
      }
    }
    .estimateContainer {
      display: flex;
      padding: 0px 20px;
      border-bottom: 2px solid $veryLight;
      font-size: 14px;
      width: 100%;
      p {
        margin-bottom: 0;
      }
      label {
        margin-bottom: 0;
      }
      .totalEstimate {
        width: 100%;
        font-family: $circularBold;
        color: $primaryColor;
        border-right: 2px solid $veryLight;
        padding: 25px 15px;
        .totalNumber {
          font-size: 32px;
          @media screen and (max-width: 1366px) {
            font-size: 26px;
          }
          @media screen and (max-width: 767px) {
            font-size: 26px;
            margin-left: auto;
          }
        }
        @media screen and (max-width: 767px) {
          display: flex;
          align-items: center;
          padding: 5px 15px;
          border-right: none;
        }
      }
      .offerDetails {
        font-family: $circularStd;
        width: 100%;
        border-right: 2px solid $veryLight;
        padding: 25px 15px;
        .importantText {
          color: $primaryColor;
        }
        .recoupmentRate {
          font-size: 32px;
          @media screen and (max-width: 1366px) {
            font-size: 26px;
          }
          @media screen and (max-width: 767px) {
            font-size: 26px;
            margin-left: auto;
          }
        }
        @media screen and (max-width: 767px) {
          display: flex;
          align-items: center;
          padding: 5px 15px;
          border-right: none;
          color: $primaryColor;
          font-family: $circularBold;
        }
      }
      .termContainer {
        font-family: $circularStd;
        width: 100%;
        padding: 25px 15px;
        .importantText {
          color: $primaryColor;
          @media screen and (max-width: 767px) {
            font-family: $circularBold;
          }
        }
        @media screen and (max-width: 767px) {
          padding: 5px 15px 10px;
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        font-size: 12px;
      }
    }
    .contentContainer {
      display: flex;
      padding: 25px 35px 0;
      ul {
        width: 100%;
        padding-inline-start: 20px;
        margin-bottom: 0;
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
        }
      }
      li {
        cursor: text;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
        span {
          font-size: 14px;
          font-family: $circularStd;
        }
        .value {
          font-size: 16px;
          font-family: $circularBold;
          margin-left: auto;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 30px 35px 10px;
      }
    }
    .btnContainer {
      padding: 0 35px;
      display: flex;
      margin-bottom: 15px;
      button {
        background-color: transparent;
        padding: 12px 0px;
        border: none;
        color: $ctaColor;
        border-radius: 25px;
        text-decoration: underline;
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 90%;
  }
}

.adjustModal {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  width: 100%;
  padding: 50px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 12px;
  &:focus {
    outline: none;
  }
  .header {
    text-align: left;
    letter-spacing: 0px;
    display: flex;
    align-items: flex-start;
    .title {
      color: $black;
      font-size: 18px;
      font-family: $circularBold;
    }
    .preview {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 14px;
      font-family: $circularStd;
      margin-left: auto;
      p {
        margin-bottom: 2px;
        &.primaryText {
          color: $primaryColor;
          font-size: 18px;
        }
      }
      span {
        font-family: $circularBold;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 25px 10px 0;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
      .preview {
        align-items: flex-start;
        margin: 0;
      }
    }
  }
  .sliderContainer {
    padding: 25px 0px;
    border: none;
    @media screen and (max-width: 1366) {
      padding: 25px 0px;
    }
    @media screen and (max-width: 767px) {
      padding: 25px 10px;
      margin-bottom: 0;
    }
  }

  .modalBtnContainer {
    display: flex;
    align-items: center;
    .modalBtn {
      margin-left: auto;
      text-transform: uppercase;
      font-family: $circularBold;
      background-color: $primaryColor;
      padding: 12px 20px;
      border-radius: 25px;
      color: $white;
      min-width: 150px;
      border: none;
      letter-spacing: 2.8px;
    }
    .resetBtn {
      text-decoration: underline;
      background: transparent;
      border: none;
      color: $helpTextColor;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    a {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      flex-direction: column;
      margin-top: 0px;
      .modalBtn {
        margin-left: 0;
      }
    }
  }
}

.marketModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 570px;
  width: 100%;
  padding: 50px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 12px;
  outline: none;
  .logoContainer {
    margin-bottom: 20px;
    svg {
      width: 60px;
      height: 60px;
      path {
        stroke: $primaryColor;
        stroke-width: 15px;
      }
      @media screen and (max-width: 767px) {
        width: 50px;
        height: 50px;
      }
    }
  }
  .primaryHeader {
    font-family: $circularBold;
    text-align: center;
    color: $primaryColor;
  }
  .header {
    font-family: $circularBold;
    max-width: 270px;
    text-align: center;
    span {
      color: $primaryColor;
    }
  }
  .infoText {
    font-size: 14px;
    text-align: center;
  }
  .btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    button {
      border: none;
      background-color: $ctaColor;
      color: $fundingCtaFontColor;
      padding: 12px 20px;
      border-radius: 25px;
      font-family: $circularBold;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      font-size: 13px;
      min-width: 250px;
      &.linkBtn {
        letter-spacing: 0;
        text-transform: none;
        font-size: 12px;
        font-family: $circularStd;
        text-decoration: underline;
        color: $helpTextColor;
        background-color: transparent;
        padding: 5px;
        margin-top: 18px;
      }
      @media screen and (max-width: 767px) {
        min-width: unset;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
  }
}
