@import "../../../styles/variables.scss";

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .pageContainer {
      display: flex;
      flex: 1;
      width: 100%;
      background-color: $dark;
      .mainContainer {
        background: $veryLight;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        margin-top: 0px;
        position: relative;
        .scrollContainer {
          height: 100%;
          max-height: calc(100vh - 100px);
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          .container {
            display: flex;
            flex-direction: column;
            max-width: 1145px;
            flex-grow: 1;
            width: 100%;
            text-align: center;
            @media screen and (max-width: 767px) {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
          @media screen and (max-width: 1440px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 1366px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 767px) {
            padding: 20px;
            max-height: unset;
          }
        }
        @media screen and (max-width: 767px) {
          flex-grow: 1;
          padding: 0px 10px 10px 10px;
          max-height: unset;
        }
        @media screen and (max-width: 529px) {
          padding: 0;
          min-height: calc(100vh - 167px);
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        max-width: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.surveyTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  margin-bottom: 20px;
  .text {
    display: flex;
    flex-direction: column;
    text-align: left;
    h3 {
      margin: 0;
      font-size: 14px;
      color: $primaryColor;
      font-family: $circularBold;
    }
    p {
      margin: 0;
      font-size: 18px;
    }
  }
  button {
    display: flex;
    align-items: center;
    border: none;
    text-decoration: underline;
    color: $ctaColor;
    white-space: nowrap;
    font-size: 16px;
    column-gap: 8px;
    font-family: $circular;
    svg {
      width: 15px;
      height: 15px;
      path {
        fill: $ctaColor;
      }
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.surveySkipBtn {
  display: none;
  align-items: center;
  border: none;
  text-decoration: underline;
  color: $ctaColor;
  white-space: nowrap;
  font-size: 16px;
  column-gap: 8px;
  font-family: $circular;
  width: fit-content;
  margin: 20px auto;
  svg {
    width: 15px;
    height: 15px;
    path {
      fill: $ctaColor;
    }
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.generatingOffersContainer {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: $white;
  padding: 25px 80px;
  box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.1);
  flex-grow: 1;
  justify-content: center;
  gap: 30px;
  min-height: 500px;
  :global(#ss_survey_widget) {
    margin: auto;
  }
  @media screen and (max-width: 1240px) {
    padding: 25px 40px;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    &.surveyContainer {
      background-color: $white;
    }
  }
  .offerMainContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    @media screen and (max-width: 1092px) {
      flex-direction: column;
    }

    .leftContainer {
      text-align: left;
      width: 100%;
      padding-right: 60px;
      margin: 60px 0;
      color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
      &.fastLeftContainer {
        margin: 0;
      }
      .checkIcon {
        margin-bottom: 20px;
      }
      .learnMoreText {
        margin-top: 15px;
        font-family: $circularSemiBold;
        font-size: 14px;
        line-height: 20px;
      }
      a {
        text-decoration: underline;
        color: $primaryColor;
      }
      a.btn {
        margin-top: 10px;
        font-family: $circularSemiBold;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
        color: $primaryColor;
        display: flex;
        height: 40px;
        padding: 0 35px;
        justify-content: center;
        align-items: center;
        border-radius: 2000px;
        border: 1px solid $primaryColor;
        @media screen and (max-width: 479px) {
          font-size: 12px;
          padding: 0 20px;
        }
      }
      .fastFlowText {
        font-size: 12px;
        padding: 0px;
        color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
        font-family: $circularStd;
        line-height: 18px;
        ol {
          margin: 0;
          width: 100%;
          min-width: 65%;
          padding-inline-start: 0px;
        }
        li {
          display: flex;
          padding: 8px 0;
          counter-increment: inst;
          text-align: left;
          cursor: text;
        }
        li::before {
          font-size: 12px;
          font-family: $circularBold;
          content: counter(inst);
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $light;
          border-radius: 50%;
          width: 21px;
          height: 21px;
          color: $primaryColor;
          margin-right: 10px;
          flex-shrink: 0;
        }
      }
      @media screen and (max-width: 1366px) {
        padding-right: 30px;
      }
      @media screen and (max-width: 1092px) {
        padding-right: 0px;
        padding-bottom: 40px;
        border-right: none;
        text-align: center;
      }
      @media screen and (max-width: 767px) {
        padding: 25px;
        margin: 0;
        background-color: $white;
        border-radius: 15px;
      }
      @media screen and (max-width: 479px) {
        text-align: left;
      }
      .barsLoadingContainer {
        img {
          height: 60px;
        }
      }
      .submittingText {
        font-size: 18px;
        font-weight: 500;
        font-family: $circularStd;
        line-height: 28px;
        color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
      }
      .ourTeamText {
        margin-top: 10px;
        font-size: 14px;
        font-family: $circular;
        line-height: 20px;
        color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
      }
      .emailText {
        margin-top: 10px;
        font-size: 14px;
        font-family: $circular;
        line-height: 20px;
        color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
        span {
          font-family: $circularBold;
          color: $primaryColor;
          @media screen and (max-width: 567px) {
            word-break: break-word;
          }
        }
      }
    }
    .rightContainer {
      width: 100%;
      padding-left: 60px;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-left: 1px solid rgba($color: $grey, $alpha: 0.7);
      @media screen and (max-width: 1366px) {
        padding-left: 30px;
      }
      @media screen and (max-width: 1092px) {
        padding-left: 0px;
        text-align: center;
        border: none;
      }
      @media screen and (max-width: 767px) {
        padding: 20px 25px;
        margin-top: 25px;
        background-color: $white;
        border-radius: 15px;
        text-align: center;
      }
      @media screen and (max-width: 479px) {
        text-align: left;
      }
      .fundingText {
        color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
        font-family: $circularSemiBold;
        line-height: 20px;
        font-size: 14px;
        &.fastFundingText {
          color: $primaryColor;
          font-family: $circularSemiBold;
          font-size: 14px;
          width: 100%;
          text-align: left;
          @media screen and (max-width: 1092px) {
            text-align: center;
          }
          @media screen and (max-width: 479px) {
            text-align: left;
          }
        }
        .musicText {
          margin-top: 5px;
          color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
          font-family: $circularStd;
          font-size: 14px;
          span {
            text-transform: lowercase;
          }
        }
      }
      .videoDiv {
        margin-top: 15px;
        iframe {
          border-radius: 8px;
          min-height: 200px;
          min-width: 350px;
          width: 100%;
          max-height: 200px;
          max-width: 350px;
          @media screen and (max-width: 1240px) {
            min-width: unset;
          }
          @media screen and (max-width: 479px) {
            min-height: 150px;
          }
        }
      }
      .fastVideoDiv {
        width: 100%;
        text-align: left;
        @media screen and (max-width: 1092px) {
          text-align: center;
        }
        @media screen and (max-width: 479px) {
          text-align: left;
        }
        iframe {
          min-width: 350px;
          min-height: 195px;
          @media screen and (max-width: 767px) {
            min-height: 150px;
            min-width: unset;
          }
        }
      }
      .btnContainer {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 767px) {
          margin-top: 25px;
        }
        .secondaryBtn {
          color: $primaryColor;
          padding: 8px 25px;
          font-family: $circularStd;
          border-radius: 25px;
          background-color: transparent;
          border: 1.5px solid $primaryColor;
          @media screen and (max-width: 767px) {
            padding: 5px 25px;
            width: 100%;
          }
        }
      }
    }
  }
}
