@import "../../styles/variables.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 99;
  background-color: rgba($color: $black, $alpha: 0.5);
  color: $white;
  &.blurOverlay {
    backdrop-filter: blur(6px);
    background-color: rgba($color: $white, $alpha: 0.9);
  }
  &.themeBg {
    backdrop-filter: blur(6px);
    background-color: rgba(
      $color: $sidebarPrimaryBackgroundColorRgb,
      $alpha: 0.9
    );
  }
  &.solidBg {
    background-color: $white;
  }
  &.smallBg {
    align-items: flex-start;
    padding-top: 90px;
    position: absolute;
  }
}

.light {
  background-color: rgba($color: $white, $alpha: 0.7);
  color: $primaryColor;
}
.bgColorNone {
  background: none !important;
}
.box {
  position: absolute;
  border-radius: 30px;
  padding: 40px;
  min-width: 300px;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $primaryColor;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  background-color: $white;
  h3 {
    font-size: 32px;
    font-family: $circularBold;
    letter-spacing: 0.72;
    font-weight: 600;
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 16px;
    font-family: $circularStd;
    span {
      font-family: $circularBold;
    }
  }
  svg {
    height: 50px;
    width: auto;
    margin-bottom: 25px;
    path {
      fill: $primaryColor;
    }
  }
  .textContent {
    display: flex;
    flex-direction: column;
    font-family: $circular;
    text-align: center;
    flex-shrink: 0;
    .logoContainer {
      svg {
        height: 120px;
        width: 100%;
        path,
        circle {
          fill: $primaryColor;
        }
      }
      img {
        height: 120px;
        @media screen and (max-width: 767px) {
          height: 90px;
        }
      }
    }
    h3 {
      font-size: 32px;
      font-family: $circularBold;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    .text {
      font-size: 16px;
      font-family: $circularStd;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
}

.spinner {
  text-align: center;
}

.spinner > div {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: $primaryColor;
  margin-left: 8px;

  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  @media screen and (max-width: 767px) {
    width: 5px;
    height: 5px;
  }
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.splashContainer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: $white;
  &.hide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0.11, 0, 0.5, 0);
  }
  img {
    width: 100px;
    height: auto;
  }
}
