@import "../../../styles/variables.scss";

$spotifyCardColor: #728a92;
$saturatedSpotifyCardColor: saturate($spotifyCardColor, 40%);

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .pageContainer {
      display: flex;
      flex: 1;
      width: 100%;
      background-color: $dark;
      .mainContainer {
        background: $veryLight;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        margin-top: 0px;
        position: relative;
        .scrollContainer {
          height: 100%;
          max-height: calc(100vh - 100px);
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          .container {
            display: flex;
            max-width: 1145px;
            flex-grow: 1;
            margin: 30px auto 0;
            width: 100%;
            text-align: center;
            @media screen and (max-width: 767px) {
              margin-top: 0;
              margin-bottom: 100px;
            }
          }
          @media screen and (max-width: 1440px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 1366px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 767px) {
            padding: 20px;
            max-height: unset;
          }
        }
        @media screen and (max-width: 767px) {
          flex-grow: 1;
          padding: 0px 10px 10px 10px;
          max-height: unset;
        }
        @media screen and (max-width: 529px) {
          padding: 0;
          min-height: calc(100vh - 167px);
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        max-width: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.pageHeadContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1145px;
  .titleContainer {
    margin-right: 10px;
    .title {
      display: flex;
      align-items: center;
      text-align: center;
      h1 {
        display: flex;
        align-items: center;
        font-family: $circularBold;
        padding: 7px 0 0 0;
        color: $black;
        letter-spacing: 0;
        font-size: 36px;
        margin-bottom: 0;
        width: 100%;
        text-align: left;
        @media screen and (max-width: 1440px) {
          font-size: 32px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 22px;
        }
        @media screen and (max-width: 767px) {
          font-size: 18px;
          font-family: $circularSemiBold;
          padding: 0;
        }
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
    @media screen and (max-width: 767px) {
      margin-right: 0;
    }
  }
  .rightInfoText {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    border-radius: 13px;
    background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
    color: $helpTextColor;
    min-width: 350px;
    max-width: 450px;
    svg {
      width: 15px;
      flex-shrink: 0;
      margin-right: 10px;
      path {
        fill: $helpTextColor;
      }
    }
    @media screen and (max-width: 1024px) {
      max-width: 250px;
      min-width: 250px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    background-color: $white;
    border-radius: 13px;
    padding: 18px;
    box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.1);
    margin-bottom: 20px;
  }
}

.statusCardContainer {
  display: flex;
  flex-direction: column;
  border-radius: 13px;
  background-color: $white;
  padding: 40px;
  box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.1);
  flex-grow: 1;
  .connectionContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    .alignmentContainer {
      display: flex;
      width: 100%;
      justify-content: center;
      .spotifyCardContainer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;
        max-width: 290px;
        width: 100%;
        .spotifyCard {
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(
            170deg,
            lighten(
              $color: adjust-hue($saturatedSpotifyCardColor, -30deg),
              $amount: 20%
            ),
            darken($color: $spotifyCardColor, $amount: 15%)
          );
          padding: 15px;
          border-radius: 24px;
          box-shadow: 0px 15px 35px
            rgba(
              $color: darken($color: $saturatedSpotifyCardColor, $amount: 40%),
              $alpha: 0.2
            );
          color: $white;
          min-height: 160px;
          svg {
            height: 40px;
            @media screen and (max-width: 1024px) {
              height: 36px;
            }
            @media screen and (max-width: 767px) {
              height: 28px;
            }
          }
          span {
            margin-top: 5px;
            margin-left: 5px;
            @media screen and (max-width: 767px) {
              font-size: 12px;
              white-space: nowrap;
            }
          }
          @media screen and (max-width: 767px) {
            min-height: 140px;
          }
        }
        .spotifyInfoText {
          font-size: 14px;
          padding: 15px;
          color: $spotifyCardColor;
          text-align: left;
          margin: 0;
          @media screen and (max-width: 1024px) {
            display: none;
          }
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        @media screen and (max-width: 1110px) {
          max-width: 260px;
        }
      }
      .connectionLineContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 180px;
        height: 160px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        z-index: 1;
        p {
          margin: 0;
          padding: 0 5px;
          background-color: $white;
          z-index: 1;
          line-height: 14px;
          color: $helpTextColor;
          max-width: 115px;
          &.pending {
            color: $progressAccentColor;
          }
          &.success {
            color: $success;
          }
          &.isSmallScreen {
            display: none;
            max-width: unset;
          }
          @media screen and (max-width: 1024px) {
            position: relative;
            padding: 8px;
            font-size: 12px;
            border: 1px solid transparent;
            background-clip: padding-box;
            z-index: unset;
            border-radius: 6px;
            &::before {
              content: " ";
              position: absolute;
              background: linear-gradient(
                180deg,
                $spotifyCardColor,
                $primaryColor
              );
              margin: -1px;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: -1;
              border-radius: inherit;
            }
            &.isSmallScreen {
              display: block;
            }
            &.isLargeScreen {
              display: none;
            }
          }
        }
        .connectionLine {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 3px;
          background: linear-gradient(90deg, $spotifyCardColor, $primaryColor);
          position: absolute;
          &::before,
          &::after {
            content: " ";
            display: block;
            height: 13px;
            width: 13px;
            border-radius: 10px;
            border: 2px solid $white;
            flex-shrink: 0;
            z-index: -1;
            @media screen and (max-width: 1024px) {
              z-index: unset;
            }
          }
          &::before {
            background-color: $spotifyCardColor;
            transform: translateX(-50%);
            @media screen and (max-width: 1024px) {
              transform: translateY(-50%);
              border: 1px solid $spotifyCardColor;
              background-color: $white;
            }
          }
          &::after {
            background-color: $primaryColor;
            transform: translateX(50%);
            @media screen and (max-width: 1024px) {
              transform: translateY(50%);
              border: 1px solid $primaryColor;
              background-color: $white;
            }
          }
          @media screen and (max-width: 1024px) {
            flex-direction: column;
            height: 100%;
            width: 1px;
            background: linear-gradient(
              180deg,
              $spotifyCardColor,
              $primaryColor
            );
          }
        }
        @media screen and (max-width: 1024px) {
          height: 120px;
        }
      }
      .labelCardContainer {
        flex-grow: 1;
        flex-shrink: 0;
        max-width: 290px;
        width: 100%;
        .labelCard {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $partnerCardBgColor;
          padding: 15px;
          border-radius: 24px;
          box-shadow: 0px 15px 35px rgba($color: $primaryColorRgb, $alpha: 0.4);
          min-height: 160px;
          img {
            height: 90px;
            width: 190px;
            object-fit: contain;
            @media screen and (max-width: 1024px) {
              height: 70px;
            }
            @media screen and (max-width: 1110px) {
              width: 100%;
            }
          }
          @media screen and (max-width: 767px) {
            min-height: 140px;
          }
        }
        @media screen and (max-width: 1110px) {
          max-width: 260px;
        }
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  .btnContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center;
    padding: 40px 0 0;
    .skipBtn {
      padding: 0;
      margin-right: 20px;
    }
    button,
    a {
      border: none;
      padding: 13px 20px;
      border-radius: 25px;
      background-color: transparent;
      color: $ctaColor;
      text-decoration: underline;
      font-size: 14px;
      &.primaryBtn {
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
        margin-left: 10px;
        text-decoration: none;
        font-family: $circularBold;
        text-transform: uppercase;
        letter-spacing: 2.8px;
        min-width: 175px;
        @media screen and (max-width: 767px) {
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 12px;
        padding: 10px 20px;
      }
    }
    .instructionInfo {
      max-width: 350px;
      text-align: left;
      color: $helpTextColor;
      font-size: 14px;
      margin-right: 10px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
        margin: 5px;
      }
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      flex-direction: column-reverse;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $white;
      padding: 20px;
      box-shadow: 0px -6px 20px rgba($color: $black, $alpha: 0.2);
      z-index: 1;
      border-radius: 10px 10px 0 0;
    }
  }
}
