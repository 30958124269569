@import "../../../../styles/variables.scss";

.pageContainer {
  display: flex;
  flex: 1;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  padding: 30px 40px;
  max-width: 1145px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  background-color: $veryLight;
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
}

.mainContainer {
  background: $veryLight;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    @media screen and (max-width: 767px) {
      max-height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 221px);
    padding: 0px 15px;
    max-height: unset;
  }
  @media screen and (max-width: 529px) {
    min-height: calc(100vh - 167px);
  }
}

.detailContainer {
  display: flex;
  flex-grow: 1;
  margin-bottom: 40px;
  .profileContainer {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: $white;
    flex: 2 0;
    .artistContainer {
      padding: 35px;
      border-bottom: 1px solid $light;
      font-size: 14px;
    }
    .socialContainer {
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      .socialInfo {
        padding: 35px;
        font-size: 14px;
        font-family: $circularStd;
        border-right: 1px solid $light;
        height: 100%;
        width: 100%;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
        .socialDetails {
          display: flex;
          align-items: center;
          .importantText {
            font-family: $circularBold;
          }
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          border-right: none;
          border-bottom: 1px solid $light;
        }
      }
      .advanceContainer {
        padding: 35px;
        height: 100%;
        width: 100%;
        ul {
          width: 100%;
          padding-inline-start: 20px;
          margin-bottom: 0;
          @media screen and (max-width: 767px) {
            margin-bottom: 0;
          }
        }
        li {
          cursor: text;
          align-items: center;
          width: 100%;
          margin-bottom: 15px;
          span {
            font-size: 14px;
            font-family: $circularStd;
            @media screen and (max-width: 767px) {
              font-size: 12px;
            }
          }
        }
        li::marker {
          color: $primaryColor;
        }
        @media screen and (max-width: 1366px) {
          justify-content: center;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          border-bottom: 1px solid $light;
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
  }

  .socialTitle {
    position: relative;
    font-size: 11px;
    letter-spacing: 2.2px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: $circularBold;
    @media screen and (max-width: 1440px) {
      font-size: 11px;
      margin-bottom: 5px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 11px;
    }
  }

  .socialInfo {
    padding: 35px;
    font-size: 14px;
    font-family: $circularStd;
    border-right: 1px solid $light;
    .socialTitle {
      position: relative;
      font-size: 11px;
      letter-spacing: 2.2px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-family: $circularBold;
      @media screen and (max-width: 1440px) {
        font-size: 11px;
        margin-bottom: 5px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 11px;
      }
    }
    .socialDetails {
      .importantText {
        font-family: $circularBold;
      }
    }

    svg {
      width: 29px;
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 1rem;
    }
  }

  .projectContainer {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    flex: 1 0;
    font-size: 14px;
    .detailHelperContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 12px;
      background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
      .text {
        padding: 10px;
        color: $helpTextColor;
        margin-bottom: 0;
        letter-spacing: 0;
      }
      .link {
        text-decoration: underline;
        color: $primaryColor;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: 80px;
        font-size: 12px;
      }
    }
    .upcomingProject {
      display: flex;
      border-radius: 12px;
      padding: 30px;
      background-color: $white;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      min-height: 370px;
      flex-grow: 1;
      .header {
        text-align: center;
        font-size: 16px;
        font-family: $circularBold;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      button {
        min-width: 144px;
        max-width: 280px;
        font-size: 14px;
        padding: 12px 20px;
        border-radius: 25px;
        background-color: transparent;
        text-align: center;
        margin: 25px 0;
        border: none;
        text-transform: uppercase;
        letter-spacing: 2.8px;
        font-family: $circularStd;
        &.secondary {
          border: 1px solid $ctaColor;
          color: $ctaColor;
        }
        &.primary {
          color: $fundingCtaFontColor;
          background-color: $ctaColor;
          &:disabled {
            background-color: rgba($color: $ctaColorRgb, $alpha: 0.4);
            cursor: not-allowed;
          }
        }
        @media screen and (max-width: 767px) {
          margin: 5px;
        }
      }
      .projectSummary {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        padding: 14px 14px 35px;
        border: 1px solid;
        border-radius: 12px;
        min-width: 100%;
        min-height: 265px;
        position: relative;
        flex-grow: 1;

        .projectText {
          font-size: 12px;
          letter-spacing: 0;
          padding-bottom: 20px;
        }
        .partnerLabel {
          font-size: 12px;
          letter-spacing: 0;
          text-decoration: underline;
          opacity: 0.5;
          padding-bottom: 10px;
        }
        .partnerText {
          font-size: 11px;
          letter-spacing: 0;
          opacity: 0.5;
        }
        .iconContainer {
          position: absolute;
          bottom: 8px;
          right: 10px;

          svg {
            cursor: pointer;
            width: 15px;
            height: 15px;
            margin: 5px;
            // path {
            //   fill: $primaryColor;
            // }
          }
        }
      }
      @media screen and (max-width: 767px) {
        margin-top: 0px;
        margin-bottom: 40px;
      }
    }

    @media screen and (max-width: 1024px) {
      margin-left: 0;
      margin-top: 40px;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.footerContainer {
  margin-top: auto;
  background-color: $white;
  display: flex;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #0000000b;
  height: 111px;
  padding: 35px;
  justify-content: space-between;
  align-items: center;

  .btnLink {
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    color: $helpTextColor;
  }
  button {
    min-width: 144px;
    max-width: 280px;
    font-size: 14px;
    padding: 12px 20px;
    border-radius: 25px;
    background-color: transparent;
    text-align: center;
    border: none;
    text-transform: uppercase;
    letter-spacing: 2.8px;
    font-family: $circularStd;
    &.primary {
      color: $fundingCtaFontColor;
      background-color: $ctaColor;
      &:disabled {
        background-color: rgba($color: $ctaColorRgb, $alpha: 0.4);
        cursor: not-allowed;
      }
    }
    @media screen and (max-width: 767px) {
      margin: 5px;
    }
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px 12px 0px 0px;
    align-items: center;
    justify-content: center;
  }
}

.artistProfile {
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  .title {
    font-size: 18px;
    font-family: $circularBold;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .editProfileLink {
    text-decoration: underline;
    color: $primaryColor;
    font-size: 12px;
    margin-left: auto;
    display: flex;
    cursor: pointer;
    svg {
      width: 12px;
      height: 12px;
      flex-grow: 0;
      flex-shrink: 0;
      margin: 3px 3px 2px;
      path {
        fill: $primaryColor;
      }
    }
  }
}

.seeWhyModal {
  display: flex;
  flex-direction: column;
  max-width: 511px;
  width: 100%;
  padding: 40px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 12px;
  &:focus {
    outline: none;
  }
  .header {
    text-align: left;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    .title {
      color: $primaryColor;
      font-size: 18px;
      font-family: $circularBold;
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      padding: 25px 10px;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
    }
  }
  .seeWhyContainer {
    p {
      font-size: 14px;
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      p {
        font-size: 12px;
      }
    }
  }

  .modalBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10px;
    .modalBtn {
      text-transform: uppercase;
      font-family: $circularBold;
      background-color: $primaryColor;
      border-radius: 25px;
      color: $white;
      min-width: 150px;
      height: 50px;
      border: none;
      letter-spacing: 2.8px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}

.guaranteeOfferModal {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  width: 100%;
  padding: 50px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 12px;
  &:focus {
    outline: none;
  }

  .header {
    text-align: left;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    padding: 50px;
    .importantText {
      color: $ctaColor;
    }

    @media screen and (max-width: 767px) {
      padding: 25px 10px;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
    }
  }

  .modalBtnContainer {
    display: flex;
    align-items: center;
    .modalBtn {
      margin-left: auto;
      text-transform: uppercase;
      font-family: $circularBold;
      background-color: $primaryColor;
      border-radius: 25px;
      color: $white;
      min-width: 150px;
      height: 50px;
      border: none;
      letter-spacing: 2.8px;
    }
    .resetBtn {
      text-decoration: underline;
      background: transparent;
      border: none;
      color: $ctaColor;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 25px 20px;
    width: 90%;
    a {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      flex-direction: column;
      margin-top: 0px;
      .modalBtn {
        margin-left: 0;
      }
    }
  }
}

.projectModal {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100%;
  height: 100%;
  max-height: 500px;
  padding: 30px 50px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 12px;
  &:focus {
    outline: none;
  }
  .header {
    text-align: left;
    letter-spacing: 0px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: $circularBold;
    color: $primaryColor;
    @media screen and (max-width: 767px) {
      padding: 0;
      font-size: 14px;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
    }
  }

  .formContainer {
    display: flex;
    flex-grow: 1;
    .worksContainer {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      position: relative;
      max-height: 325px;
      height: 100%;
      .partnersContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        .partnersInputs {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          position: relative;
          &:first-child {
            margin-top: 0px;
          }
          .fields {
            margin-right: 30px;
            margin-bottom: 10px;
          }
          .fieldInputs {
            margin-bottom: 0;
            :global(.selectField__single-value) {
              color: $black;
            }
            & > div {
              padding-top: 0;
            }
          }
          .deletePartner {
            display: flex;
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 5px;
            svg {
              width: 17px;
              height: 17px;
            }
          }
        }
      }
      .plusIcon {
        display: flex;
        justify-content: center;
        cursor: pointer;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 1px solid $ctaColor;
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
        margin-right: 10px;
        font-size: 17px;
      }
      @media screen and (max-width: 767px) {
        max-height: 275px;
      }
      &.stepOne {
        max-height: 325px;
      }
    }
  }

  form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    textarea {
      width: 100%;
      min-height: 100px;
      height: 269px;
      outline: none;
      border: 2px solid $sidebarPrimaryBackgroundColor;
      border-radius: 10px;
      font-size: 14px;
      padding: 10px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
        height: 290px;
      }
    }
  }

  .modalFooter {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .stepContainer {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      .circle {
        display: flex;
        width: 14px;
        height: 14px;
        background-color: transparent;
        border-radius: 50%;
        border: 1px solid $ctaColor;
        margin-right: 6px;
        &.active {
          background-color: $ctaColor;
        }
        &.cursorPoint {
          cursor: pointer;
        }
      }
    }

    .btnWrapper {
      display: flex;
      margin-left: auto;
      align-items: center;
      font-size: 14px;
      .addNSkip {
        display: flex;
        align-items: center;
      }
      .modalBtn {
        margin-left: auto;
        text-transform: uppercase;
        font-family: $circularBold;
        background-color: $ctaColor;
        border-radius: 25px;
        color: $fundingCtaFontColor;
        min-width: 200px;
        height: 37px;
        border: none;
        letter-spacing: 2.8px;
      }
      .secondaryBtn {
        text-transform: uppercase;
        font-family: $circularBold;
        background-color: $white;
        border: 1px solid $ctaColor;
        color: $ctaColor;
        min-width: 100px;
        height: 37px;
        letter-spacing: 2.8px;
        border-radius: 25px;
        margin-right: 20px;
        @media screen and (max-width: 767px) {
          margin-bottom: 10px;
          margin-right: 0;
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        font-size: 12px;
        margin-left: 0;
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      margin-top: auto;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 25px 20px;
    width: 90%;
    a {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      flex-direction: column;
      margin-top: 0px;
      .modalBtn {
        margin-left: 0;
      }
    }
  }
}
