@import "../../../styles/variables.scss";

.otherOfferForm {
  display: flex;
  flex-direction: column;
  max-width: 516px;
  width: 90%;
  outline: none;
  background-color: #fff;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  padding: 40px 40px 15px 40px;

  .header {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 700;
    color: $primaryColor;
    font-family: $circularBold;
    text-align: left;
    letter-spacing: 1px;
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
  .subHeader {
    text-align: left;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #1c162c;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .shareOfferContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;
    .headerContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media screen and (max-width: 767px) {
        gap: 5px;
      }
    }
    .uploadOtherOfferDoc {
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 14px;
        color: $primaryColor;
        text-decoration: underline;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    :global {
      .form-group .mb-4 {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        margin-bottom: 0 !important;
        .invalid-feedback {
          order: 3;
          bottom: 0;
          position: relative !important;
          @media screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 9px;
          }
        }
        label {
          width: 100%;
          order: 1;
          position: relative;
          transform: translate(0);
          color: $primaryColor;
          font-size: 14px;
          margin: 0;
          pointer-events: initial;
          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
        input {
          order: 2;
          &::placeholder {
            color: rgba($color: $primaryColorRgb, $alpha: 0.4);
          }
        }
        & > div:first-child {
          order: 2;
        }
      }
      :global(.form-group) {
        span > div {
          position: absolute;
          background-color: transparent;
          right: 4px;
          bottom: 4px;
          top: unset;
          left: unset;
          i {
            border-color: $primaryColor;
            border-right-color: transparent;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .offerType {
      :global {
        .offerType__control {
          border: 1px solid rgba($color: $primaryColorRgb, $alpha: 0.6);
          border-radius: 8px;
          padding: 0;
          height: 40px;
          display: flex;
          align-items: center;
        }
        .offerType__value-container--has-value {
          padding: 0;
        }
        .offerType__single-value {
          font-family: $circularStd;
          padding: 0 15px;
        }
        .offerType__placeholder {
          padding: 0 15px;
        }
        .offerType__input {
          padding: 0 15px;
          color: $primaryColor;
          input {
            font-family: $circularStd;
          }
        }
        .offerType__indicator-separator {
          display: none;
        }
        .offerType__indicator {
          color: $primaryColor;
        }
      }
    }
    .mainInputContainer {
      margin: 0;
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 0;
      & > input {
        order: 2;
      }
      & > label {
        order: 3;
      }
    }
    .inputClass {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 0;
      margin-bottom: 5px;
      width: 100%;
      background-color: transparent;
      border: 1px solid rgba($color: $primaryColorRgb, $alpha: 0.6);
      padding: 11px 15px 10px 35px;
      color: $primaryColor;
      font-size: 14px;
      border-radius: 8px;
      height: 40px;
      font-family: Poppins, sans-serif;
      font-weight: 400;
      line-height: 18px;
      label {
        color: $primaryColor;
        font-size: 14px;
      }
      &.optinalField {
        padding: 11px 15px 10px 15px;
      }
      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background-color: transparent;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        padding: 0;
        margin: 0;
      }
      svg {
        path {
          fill: $primaryColor;
          stroke: none;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
    .labelContainer {
      display: flex;
      justify-content: space-between;
      gap: 200px;
      .extraFormField {
        display: flex;
        justify-content: flex-end;
        color: black;
        font-size: 12px;
        @media screen and (max-width: 767px) {
          font-size: 10px;
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        gap: 0;
      }
    }
  }
  .btnContainer {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    .btnPrimary {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: $circularBold;
      width: 100%;
      padding: 12px;
      border-radius: 30px;
      color: $white;
      background-color: $ctaColor;
      border: none;
      cursor: pointer;
      column-gap: 5px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 90%;
    margin: auto;
    padding: 20px 25px 10px 25px;
    border-radius: 20px;
  }
  .uploadSection {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .uploadContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    .uploadBox {
      border: 2px dashed $primaryColor;
      border-radius: 7px;
      width: 100%;
      height: 125px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }
    .submitBtn {
      display: flex;
      background-color: $ctaColor;
      color: $white;
      text-transform: uppercase;
      font-family: $circularBold;
      font-size: 14px;
      border-radius: 99px;
      margin-top: 25px;
      border: none;
      justify-content: center;
      padding: 10px 15px;
      &:disabled {
        background-color: rgba($color: $ctaColorRgb, $alpha: 0.6);
        cursor: not-allowed;
      }
    }
    .loader {
      position: absolute;
    }
  }
  .closeBtn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
    font-size: 30px;
    line-height: 20px;
    font-family: monospace;
    transform: rotate(45deg);
    color: $primaryColor;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    height: 25px;
    width: 25px;
    @media screen and (max-width: 767px) {
      top: 9px;
      right: 9px;
      font-size: 25px;
      height: 15px;
      width: 15px;
    }
  }
  .linkBtn {
    display: flex;
    background-color: transparent;
    border: none;
    color: $ctaColor;
    font-size: 14px;
    font-family: $circularBold;
    text-decoration: underline;
    justify-content: center;
    margin-top: 5px;
    padding: 10px 15px;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.9
  );
  z-index: 6;
  overflow: auto;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: auto;
  .title {
    margin-bottom: 32px;
    color: $medium;
  }
  .help {
    margin: 10px 0;
    display: flex;
    align-items: center;
    color: $helpTextColor;
    font-size: 18px;
    cursor: pointer;
    font-family: $circularBold;
    letter-spacing: 0.76px;
    @media screen and (max-width: 1366px) {
      font-size: 15px;
    }
    @media screen and (max-width: 1440px) {
      font-size: 14px;
      letter-spacing: 0.56px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    .fileCount {
      font-size: 12px;
      margin: 15px 0 0;
      width: 100%;
      text-transform: uppercase;
      font-family: $circularBold;
      letter-spacing: 2px;
      color: $helpTextColor;
      @media screen and (max-width: 767px) {
        font-size: 10px;
        letter-spacing: 1px;
      }
    }
    .textContent {
      margin-left: 32px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1024px) {
        margin-left: 0;
        margin-top: 20px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 14px;
        letter-spacing: 0.56px;
      }
      h3 {
        font-weight: 700;
      }
    }
    .dropContainer {
      text-align: center;
    }
    .files {
      align-items: flex-start;
      justify-items: flex-start;
      flex: 1;
      overflow-y: auto;
      margin-top: 10px;
      max-height: calc(100vh - 600px);
      min-height: 125px;
      > div {
        margin: auto;
      }
      .row {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px;
        overflow: hidden;
        box-sizing: border-box;
        border-radius: 6px;
        position: relative;
        margin-bottom: 6px;
        background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
        &.error {
          background-color: rgba($color: $error, $alpha: 0.2);
        }
        .fileNameContainer {
          font-size: 12px;
          color: $dark;
          word-break: break-all;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .fileName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .msgError {
            display: flex;
            align-items: center;
            color: $error;
            font-size: 12px;
            float: right;
            margin-left: 10px;
            white-space: nowrap;
            .helpBtn {
              color: $primaryColor;
              font-size: 18px;
              margin-left: 8px;
              cursor: pointer;
            }
          }
        }
        .progressWrapper {
          display: flex;
          flex-grow: 1;
          flex-direction: row;
          align-items: center;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
        .checkIcon {
          width: 11px;
          margin-left: 10px;
          line-height: 18px;
          cursor: pointer;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          svg {
            width: 100%;
            path {
              fill: rgba($color: $helpTextColorRgb, $alpha: 0.7);
            }
          }
        }
      }
      @media screen and (max-width: 1024px) {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
      }
      @media screen and (max-width: 767px) {
        min-height: unset;
        max-height: 200px;
      }
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: rgba($color: $black, $alpha: 0.1);
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba($color: $black, $alpha: 0.2);
      border-radius: 8px;
      max-height: 100px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: rgba($color: $black, $alpha: 0.4);
    }
  }
  .actions {
    display: flex;
    flex: 1;
    width: 100%;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }
}
