@import "../../../styles/variables.scss";

.comapreDealContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #f1f1f1;
  align-items: flex-start;
  justify-content: center;
  gap: 25px;
  max-width: 1075px;
  position: relative;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .sliderCtn {
    display: flex;
    gap: 10px;
    position: absolute;
    right:0;
    top:0;

    .disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    svg {
      cursor: pointer;
      transform: translateY(-5px) rotateX(0deg) rotateZ(90deg);
      margin-top: 10px;
      path {
        fill: $primaryColor;
      }
    }
    svg:last-child {
      transform: translateY(-5px) rotateX(0deg) rotateZ(270deg);
    }
  }
}
.titleCtn {
  h2 {
    @include font-acumin-b;
    display: flex;
    font-size: 32px;
    letter-spacing: 0;
    margin: 0;
    color: $primaryColor;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      font-size: 27px;
    }
  }
  p {
    font-family: $circular;
    font-size: 16px;
    color: $black;
    margin: 0;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}
.transitionContainer {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
  flex-grow: 1;
  .swipe {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 20px;
    position: absolute;
    width: 100%;
    max-width: 1075px;
    &:global(.compare-step-swipe-enter) {
      opacity: 0;
      left: 100%;
    }
    &:global(.compare-step-swipe-enter-active) {
      opacity: 1;
      left: 0%;
      width: 100%;
      transition:
        transform 2s cubic-bezier(0.16, 1, 0.3, 1),
        opacity 2s cubic-bezier(0.16, 1, 0.3, 1),
        max-width 2s cubic-bezier(0.16, 1, 0.3, 1),
        left 2s cubic-bezier(0.16, 1, 0.3, 1);
    }
    &:global(.compare-step-swipe-exit) {
      opacity: 1;
      left: 0%;
    }
    &:global(.compare-step-swipe-exit-active) {
      opacity: 0;
      left: -100%;
      transition:
        transform 2s cubic-bezier(0.16, 1, 0.3, 1),
        opacity 2s cubic-bezier(0.16, 1, 0.3, 1),
        max-width 2s cubic-bezier(0.16, 1, 0.3, 1),
        left 2s cubic-bezier(0.16, 1, 0.3, 1);
    }
    @media screen and (max-width: 767px) {
      padding: 0 10px;
    }
  }
  &.back :global(.compare-step-swipe-enter) {
    left: -100%;
  }
  &.back :global(.compare-step-swipe-enter-active) {
    left: 0%;
  }
  &.back :global(.compare-step-swipe-exit) {
    left: 0%;
  }
  &.back :global(.compare-step-swipe-exit-active) {
    left: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 0px;
  }
}

.tableMainDiv {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 15px;
  overflow-y: auto;
  flex-grow: 1;
  width: 100%;
  max-width: 1075px;
  .tableContainerDesktop {
    :global(.MuiTable-root) {
      @media screen and (max-width: 767px) {
        table-layout: fixed;
      }
    }
    :global(.MuiTableCell-body) {
      padding: 9px 25px;
      min-width: 250px;
      text-align: center;
      font-family: $circular;
      font-size: 18px;
      border: none;
    }
    .headerRow {
      @include font-acumin-b;
      background-color: $primaryColor;
      text-align: left;
      font-size: 27px;
      line-height: 25px;
      color: $white;
      text-transform: uppercase;
      border: none;
      :global(.MuiTableCell-body) {
        @include font-acumin-b;
        background-color: $primaryColor;
        text-align: center;
        font-size: 27px;
        line-height: 25px;
        color: $white;
        text-transform: uppercase;
        border: none;
        &.sectionTitle {
          text-align: left;
        }
      }
    }
    .titleRow {
      @include font-acumin-b;
      background-color: $primaryColor;
      text-align: left;
      font-size: 27px;
      line-height: 25px;
      color: $white;
      text-transform: uppercase;
      border: none;
      padding: 9px 25px;
      tbody tr th {
        color: $white;
      }
    }
    .partnerCell {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      margin-top: 3px;
      img {
        height: 20px;
        max-width: 35px;
        object-fit: contain;
        object-position: left;
      }
    }

    .value {
      &.bestDeal {
        background-color: #ecf9ea;
        svg {
          path {
            fill: #569a4d;
          }
        }
      }
      .dealButton {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 5px;
        .primary {
          font-family: $circularBold;
          color: $white;
          border-radius: 25px;
          text-transform: uppercase;
          width: 100%;
          height: 40px;
          background-color: $ctaColor;
          border: none;
          @media screen and (max-width: 767px) {
            color: $white;
            height: 36px;
          }
        }
        .textBtn {
          font-family: $circularBold;
          background: none;
          text-transform: uppercase;
          height: 20px;
          color: $ctaColor;
          border: none;
        }
      }
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .title {
      @include font-acumin-b;
      text-align: left;
      font-size: 27px;
      line-height: 25px;
      left: 0;
      border: none;
      background-color: $primaryColor;
      color: $white;
      padding-top: 12px;
    }
    .sticky {
      @include font-acumin-b;
      text-align: left;
      font-size: 27px;
      line-height: 25px;
      color: $black;
      border: none;
      position: sticky;
      left: 0;
      background-color: $white;
      &.title {
        background-color: $primaryColor;
        color: $white;
        padding-top: 12px;
      }
    }
    .noService {
      font-family: $circularBold;
      font-size: 14px;
      color: $ctaColor;
      text-transform: uppercase;
    }
    tbody tr:last-child td {
      padding: 20px 25px 35px;
      @media screen and (max-width: 767px) {
        padding: 9px 15px;
      }
    }
    .linkBtn {
      font-size: 12px;
      text-transform: uppercase;
      background-color: transparent;
      border: none;
      padding: 0;
      text-decoration: underline;
      color: $primaryColor;
      font-family: $circularBold;
      @media screen and (max-width: 767px) {
        font-size: 10px;
      }
    }
    .offerBtn {
      background-color: $ctaColor;
      color: $white;
      padding: 4px 20px;
      border: none;
      border-radius: 25px;
      font-size: 14px;
      font-family: $circularBold;
      text-transform: uppercase;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      @media screen and (max-width: 767px) {
        font-size: 10px;
        padding: 4px 10px;
      }
    }
    @media screen and (max-width: 767px) {
      scroll-snap-type: x mandatory;
    }
  }

  @media screen and (max-width: 1024px) {
    max-width: 900px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.mobileTableDiv {
  display: none;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
    border: 1px solid $primaryColor;
    overflow: hidden;
    .cardSection {
      display: flex;
      @include font-acumin-b;
      background-color: $primaryColor;
      color: $white;
      font-size: 24px;
      padding: 10px 13px;
      line-height: 20px;
      border-radius: 15px 15px 0 0;
      text-transform: uppercase;
      &.secondary {
        background-color: $white;
        color: $primaryColor;
      }
    }
    .title {
      display: flex;
      padding: 8px 13px;
      background-color: $primaryColor;
      font-family: $circularBold;
      font-size: 10px;
      line-height: 15px;
      color: $white;
      text-transform: uppercase;
    }
    .cardDetails {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      color: $primaryColor;
      font-family: $circular;
    }
    .rows {
      display: flex;
      justify-content: center;
      min-height: 30px;
      .fields {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-width: 150px;
        background-color: rgba($color: $primaryColorRgb, $alpha: 0.05);
        column-gap: 10px;
        padding: 8px 13px;
        img {
          height: 20px;
          max-width: 35px;
          object-fit: contain;
          object-position: left;
        }
      }
      .value {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 8px 13px;
        background-color: $white;
        &.bestDeal {
          background-color: #ecf9ea;
          svg {
            path {
              fill: #569a4d;
            }
          }
        }
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .buttonCtn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 10px;
        font-family: $circularBold;
        width: 100%;
        background-color: $white;
        .primary {
          color: $white;
          border-radius: 25px;
          text-transform: uppercase;
          height: 20px;
          background-color: $ctaColor;
          border: none;
        }
        .textBtn {
          background: none;
          text-transform: uppercase;
          height: 20px;
          color: $ctaColor;
          border: none;
        }
        .noService {
          min-width: 87px;
          text-align: center;
          color: $ctaColor;
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
  }
}

.footerContainer {
  display: flex;
  width: 100%;
  span {
    display: flex;
    gap: 5px;
    font-family: $circular;
    font-size: 14px;
    color: $primaryColor;
    text-decoration: underline;
    cursor: pointer;
  }

  svg {
    rotate: 90deg;
    path {
      fill: $primaryColor;
    }
  }
}
