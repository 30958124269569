@import "../../styles/variables.scss";

.modalOverlay {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(
      $color: $sidebarPrimaryBackgroundColorRgb,
      $alpha: 0.95
    );
    overflow: auto;
    z-index: 4;
    backdrop-filter: blur(4px);
}


  .detailsPopupContainer {
    display: flex;
    flex-direction: column;
    max-width: 516px;
    width: 90%;
    outline: none;
    padding: 20px 0px;
    background-color: #fff;
    margin: auto;
    position: relative;
    box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
    border-radius: 12px;
    .closeBtn {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      line-height: 18px;
      padding: 10px;
      cursor: pointer;
    }
    
    .firstContainer{
        padding: 20px 40px;
        color: $primaryColor;
        font-family: $circularBold;
        @media screen and (max-width:474px) {
          padding: 20px;
        }
    }
    .secondContainer{
        padding: 10px 40px;
        font-family: $circular;
        span{
            cursor: pointer;
            text-decoration: underline;
        }
        @media screen and (max-width:474px) {
          padding: 10px 20px;
        }
    }

    

    
}