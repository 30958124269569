@import "../../../../styles/variables.scss";

.select__menu-list::-webkit-scrollbar {
  width: 6px;
  height: 0px;
}
.select__menu-list::-webkit-scrollbar-track {
  background: $medium;
}
.select__menu-list::-webkit-scrollbar-thumb {
  background: rgba($color: $white, $alpha: 0.4);
  border-radius: 4px;
}
.select__menu-list::-webkit-scrollbar-thumb:hover {
  background: rgba($color: $white, $alpha: 0.3);
}
