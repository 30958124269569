@import "../../styles/variables.scss";

.headerComponent {
  display: flex;
  background-color: $white;
  padding: 0 40px;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  @media screen and (max-width: 767px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    z-index: 4;
    box-shadow: 0 0 12px 2px rgba(0,0,0,.1);
    background-color: var(--sidebar-primary-background-color);
  }
}

.container a {
  color: $grey;
  &.importantLink {
    color: $navigationTextColor;
    @media screen and (max-width: 767px) {
      color: inherit;
    }
  }
}
.container a:hover,
.container a:active {
  color: $navigationTextColor;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 110px;
  width: 100%;
  @media screen and (max-width: 1440px) {
    height: 83px;
  }
  @media screen and (max-width: 1366px) {
    height: 65px;
  }
  @media (max-width: 767px) {
    height: 90px;
  }
}
.headerTitle {
  font-size: 18px;
  font-family: $circularBold;
  color: $primaryColor;
  margin-bottom: 0px;
  @media screen and (max-width: 1366px) {
    font-size: 18px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.LogoTitle {
  font-family: $circularBold;
  font-weight: 700;
  font-size: 26px;
  float: left;
  cursor: pointer;
}
.bBLogo {
  height: 50px;
  margin-right: 20px;
  position: relative;
  display: none;
  &.adminSideLogo {
    display: flex;
  }
  @media screen and (max-width: 1440px) {
    height: 42px;
  }
  @media screen and (max-width: 1366px) {
    height: 40px;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    max-width: 160px;
    object-fit: contain;
  }
}

.menu {
  display: flex;
  flex-wrap: wrap;

  overflow: hidden;
  transition: 0.4s width;
  .title,
  .close {
    display: none;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    position: fixed;
    background-color: $white;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    z-index: 9;
    width: 80%;
    top: 0;
    right: 0;
    bottom: 0;
    box-shadow: -10px 0 20px rgba($color: $black, $alpha: 0.4);
    .title {
      display: block;
      padding: 20px 20px;
      margin: 0 0 20px;
      width: 100%;
      text-align: right;
      background-color: $light;
    }
    .close {
      display: block;
      cursor: pointer;
      color: $primaryColor;
      font-size: 52px;
      margin: auto;
      margin-bottom: 60px;
    }
    .menuBtns {
      width: 100%;
      padding: 20px 10px;
      display: block;
      text-transform: uppercase;
      font-family: $circularBold;
      .link {
        margin: 10px 0;
        border: 1px solid $navigationTextColor;
        color: $navigationTextColor;
        font-size: 14px;
        display: block;
        padding: 15px 20px;
        cursor: pointer;
        &.primary {
          background-color: $navigationTextColor;
          color: $white;
        }
      }
    }
  }
}

.menu > div {
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
  }
}

.menuIcon {
  color: $white;
  fill: $primaryColor !important;
  font-size: 36px !important;
  display: none;
  &.white {
    fill: $white !important;
  }
  @media screen and (max-width: 767px) {
    display: block;
  }
}

.menu a {
  margin-left: 48px;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.76px;
  @media screen and (max-width: 1440px) {
    margin-left: 33px;
    font-size: 13px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 1366px) {
    margin-left: 28px;
    font-size: 12px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 767px) {
    color: $black;
    font-size: 18px;
    margin: 0;
    padding: 15px 20px;
    background-color: $white;
    text-transform: inherit;
    font-weight: 500;
    opacity: 1;
    display: block;
    transition: 0.8s cubic-bezier(0.79, -0.05, 0.39, 0.9) padding,
      0.8s cubic-bezier(0.79, -0.05, 0.39, 0.9) opacity;
  }
}

.menu .primary {
  text-decoration: none;
}

.menuContainer {
  fill: $primaryColor;
}

.menuContainer .dNone {
  @media screen and (max-width: 767px) {
    width: 0%;
    a {
      opacity: 0;
    }
  }
}

.companyName {
  margin: 0 10px;
  cursor: pointer;
  font-size: 20px;
  color: $white;
  font-weight: 700;
}

@media screen and (max-width: 767px) {
  .menuOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: $white, $alpha: 0.4);
    transition: background-color 0.3s;
    width: 100%;
    z-index: 2;
    &.dNone {
      width: 0;
      background-color: rgba($color: $white, $alpha: 0);
    }
  }
}
