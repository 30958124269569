@import "../../../styles/variables.scss";

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 14px;
  background: $grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  padding: 12px;
  margin: 34px 0;
  .react-switch-button {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 45px;
    transition: 0.2s;
    background: $white;
    @media screen and (max-width: 767px) {
      width: 16px;
      height: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 10px;
  }
}

.react-switch-checkbox:checked + .react-switch-label {
  background-color: $primaryColor;
  .react-switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
}

.react-switch-checkbox:disabled + .react-switch-label {
  background-color: rgba($color: $primaryColorRgb, $alpha: 0.5) !important;
  cursor: not-allowed;
}
