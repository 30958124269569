@import "../../styles/variables.scss";

.toolgroup {
  .infoIcon {
    height: 20px;
    width: 20px;
    display: flex;
    @media screen and (max-width: 767px) {
      height: 13px;
      width: 13px;
    }
  }
  .infoIcon.light svg {
    fill: $tooltipBackgroundColor;
  }
  .infoIcon.dark svg {
    fill: $sidebarTooltipIconColor;
  }
  .tooltipInstance {
    width: 30%;
    pointer-events: auto !important;
    font-weight: normal;
    text-align: left;
    box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.3);
    border-radius: 5px !important;
    background-color: $alertBackgroundColor !important;
    color: $alertFontColor !important;
    &::after {
      border: transparent !important;
      box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.3);
      background-color: $alertBackgroundColor !important;
    }
    &.show {
      opacity: 1 !important;
    }
    @media screen and (max-width: 767px) {
      width: 50%;
    }
    a {
      color: $tooltipFontColor !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .tooltipText {
    font: 13px/1.5 $circularStd;
  }
}
