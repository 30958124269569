@import "../../../styles/variables.scss";

.container {
  display: flex;
  flex-grow: 1;
  height: 100vh;
  width: 100%;
  background-color: $white;
  .leftBox {
    display: flex;
    width: 100%;
    max-width: 230px;
    flex-grow: 1;
    background-color: $sidebarPrimaryBackgroundColor;
  }
  .rightBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $veryLight;
    .headerBox {
      width: 100%;
      height: 100px;
      background-color: $white;
    }
  }
}
svg.logo {
  height: 120px;
  width: 100%;
  path,
  circle {
    fill: $primaryColor;
  }
}
