@import "../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 111px);
  overflow-y: auto;
  text-align: center;
  width: 100%;
  background-color: $light;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  max-width: 100%;

  a,
  .artistDetails {
    color: $primaryColor;
    &:hover {
      color: rgba($color: $primaryColor, $alpha: 0.6);
      .subText {
        color: rgba($color: $primaryColor, $alpha: 0.6);
      }
    }
  }
  .subContainer {
    text-align: center;
    width: 100%;
    padding: 50px 40px;
    align-items: flex-start;
    height: calc(100vh - 111px);
    overflow-y: auto;
    @media screen and (max-width: 1366px) {
      height: calc(100vh - 66px);
    }
    @media screen and (max-width: 767px) {
      height: unset;
      padding: 10px;
      min-width: 100%;
    }
    @media screen and (max-width: 479px) {
      padding: 10px;
      margin: 0px;
      min-width: 100%;
    }
  }
  @media screen and (max-width: 1366px) {
    height: calc(100vh - 66px);
  }
  @media (max-width: 767px) {
    max-width: 100%;
    height: unset;
    min-height: calc(100vh - 66px);
    margin-top: 0;
  }
  @media screen and (max-width: 479px) {
    height: 100%;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  h1 {
    color: $primaryColor;
    font-weight: 600;
    font-size: 54px;
    line-height: 69px;
    letter-spacing: -3.5px;
    font-family: $circular;
    white-space: nowrap;
    @media screen and (max-width: 767px) {
      white-space: unset;
      font-size: 48px;
    }
    @media screen and (max-width: 479px) {
      white-space: unset;
      font-size: 40px;
    }
  }
  h2 {
    display: none;
    color: $white;
    padding: 18px;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1.1px;
  }
  h4 {
    position: absolute;
    left: 0;
    cursor: pointer;
    padding-right: 10px;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    @media screen and (max-width: 767px) {
      top: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    background-color: $dark;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      display: none;
    }
    h2 {
      display: block;
      flex-shrink: 0;
      margin-bottom: 0;
    }
    &.innerTitle {
      background-color: $light;
      justify-content: center;
      h1 {
        display: block;
        font-size: 36px;
      }
    }
  }
}

.demoArtistsList {
  display: flex;
  flex-direction: column;
  .createBtn {
    margin: 15px 0;
    display: flex;
    justify-content: flex-start;
    button {
      background-color: transparent;
      font-family: $circularBold;
      border: 1px solid $dark;
      color: $dark;
      padding: 10px 10px;
      min-width: 150px;
      @media screen and (max-width: 767px) {
        width: 100%;
        min-width: unset;
      }
    }
  }
  .tableContainer {
    background-color: $white;
    tr th {
      font-family: $circularBold;
    }
    @media screen and (max-width: 767px) {
      tr td:nth-child(2),
      tr th:nth-child(2) {
        display: none;
      }
    }
    .profileColumn {
      display: flex;
      align-items: center;
      img {
        height: 60px;
        width: 60px;
        object-fit: cover;
        border-radius: 50%;
        flex-shrink: 0;
        @media screen and (max-width: 767px) {
          height: 45px;
          width: 45px;
        }
      }
      p {
        margin: 0 0 0 20px;
        font-family: $circularBold;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      .genre {
        font-family: $circularBold;
      }
      p {
        margin: 0;
        white-space: nowrap;
        width: 100%;
        max-width: calc(100vw - 740px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .actionIcon {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      label {
        margin: 0 0 0 20px;
      }
      svg {
        color: $primaryColor;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .noData {
      text-align: center;
      font-family: $circularBold;
      color: $grey;
    }
  }
}

.summaryContainer {
  :global(div.mb-4) {
    padding-top: 0;
    margin-bottom: 40px !important;
  }
}
