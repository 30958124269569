@import "../../../styles/variables.scss";

.container {
  padding: 30px;
  width: 100%;
  max-height: calc(100vh - 0px);
  background-color: $white;
  max-width: 495px;
  @media screen and (max-width: 1440px) {
    max-height: calc(100vh - 0px);
  }
  @media screen and (max-width: 1366px) {
    max-height: calc(100vh - 0px);
  }

  .logoContainer {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    img {
      width: auto;
      max-height: 60px;
      object-fit: contain;
      flex-shrink: 0;
      height: 30px;
    }

    .closeIcon {
      justify-content: flex-end;
      display: none;
      span {
        font-size: 16px;
        font-family: $circularBold;
        color: $primaryColor;

        @media screen and (max-width: 767px) {
          padding-right: 10px;
        }
      }
      @media screen and (max-width: 767px) {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px;
    max-width: 100%;
  }
}

.detailsContainer {
  max-width: 650px;
  width: 100%;
  margin: auto;
  form {
    & > div {
      position: relative;
      @media screen and (max-width: 767px) {
        margin-bottom: 0 !important;
      }
    }

    .btnContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;

      align-items: center;
      padding-bottom: 10px;

      @media screen and (max-width: 767px) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    button {
      &.delete {
        background-color: $white;
        color: $red;
        padding-left: 0px;
        font-size: 14px;
        border: none;
        text-decoration: underline;
        &:disabled {
          background-color: $white;
          color: $red;
        }
        @media screen and (max-width: 1366px) {
          font-size: 12px;
        }
      }
      &.save {
        padding: 13px 45px;
        background-color: $primaryColor;
        color: $white;
        font-family: $circularBold;
        font-size: 14px;
        border: none;
        border-radius: 25px;
        @media screen and (max-width: 1440px) {
          padding: 13px 57px;
        }
        @media screen and (max-width: 767px) {
          padding: 8px 35px;
          font-size: 10px;
        }
      }
    }
  }
  .passwordContainer {
    margin-bottom: 5px;
  }
}

.detailsHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  & > div:last-child {
    text-align: right;
    margin-bottom: 12px;
    p {
      font-size: 15px;
      margin: 0;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: unset;
  }
}

.detailsBody {
  display: flex;
  justify-content: space-between;

  & > .profileEdit {
    flex: 0.2 1 auto;
  }
  svg {
    color: $primaryColor;
  }
  .formContainer {
    flex-grow: 1;
    .profileContainer {
      display: flex;
      margin-top: 15px;
      .nameContainer {
        flex-grow: 1;
      }
      @media screen and (max-width: 767px) {
        display: unset;
      }
    }
    .formField {
      margin-bottom: 0px;
      > div {
        margin-bottom: 15px !important;
        padding-top: 25px;
        padding-bottom: 0px !important;
      }
      input {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    & > .formContainer {
      margin-right: 0;
    }
  }
  :global(.text-dark) {
    color: $primaryColor !important;
  }
}

.imageModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: fit-content;
  padding: 50px;
  background-color: $white;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 20px;
  button {
    padding: 13px 30px;
    min-width: 185px;
    font-family: $circularBold;
    color: $primaryColor;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 15px;
    border-radius: 30px;
    &.primary {
      background-color: $ctaColor;
      border: none;
      color: $fundingCtaFontColor;
    }
    &.secondary {
      border: 1px solid $ctaColor;
      color: $ctaColor;
    }
    @media screen and (max-width: 767px) {
      padding: 10px 35px;
    }
  }
  h3 {
    text-align: center;
    font-family: $circularBold;
    color: $sidebarPrimaryBackgroundColor;
    letter-spacing: 0.76px;
    font-size: 32px;
    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }
  .modalBtnContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    & > button:first-child {
      margin-right: 25px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px 30px;
    width: 90%;
    button {
      min-width: 90px;
    }
    .modalBtnContainer {
      margin-top: 40px;
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}
.titleContainer {
  .title {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 6px;
    margin-top: 25px;
    span {
      color: $sidebarPrimaryBackgroundColor;
    }
    @media screen and (max-width: 1440px) {
      margin-top: 20px;
    }
  }

  .title h1 {
    color: $primaryColor;
    font-family: $circular;
    font-size: 40px;
    font-weight: 900;
    letter-spacing: -2.25px;
    margin-right: 15px;
    margin-bottom: 0;
    @media screen and (max-width: 1440px) {
      font-size: 32px;
    }
    @media screen and (max-width: 767px) {
      font-size: 26px;
      letter-spacing: unset;
    }
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $white;
    color: $black;
    margin-right: 15px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
}

.subtitle {
  font-size: 20px;
  letter-spacing: -0.38px;
  width: 100%;
  font-family: $circular;
  line-height: 18px;
  display: flex;
  margin-bottom: 0px;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    padding-bottom: 10px;
  }
}

.customFileUpload {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  max-width: 144px;
  text-align: center;
  input[type="file"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    cursor: pointer;
    &::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
  .profileIconContainer {
    height: 100%;
    width: 100%;
    svg {
      height: auto;
      width: 100%;
      color: $darkGreyColor;
    }
  }

  .cameraSvg {
    top: 30px;
    position: relative;
    overflow: hidden;
    right: 25px;
    height: 33px;
    width: 33px;
    flex-shrink: 0;
    background: $primaryColor;
    border-radius: 25px;
    border: 3px solid $white;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 15px;
      width: 15px;
      path {
        fill: $white;
      }
    }
    &.profileCamera {
      right: 35px;
      height: 30px;
      width: 30px;
      svg {
        height: 10px;
      }
    }
  }
  button {
    position: relative;
    height: 144px;
    width: 144px;
    background-color: $light;
    border: 1px solid $grey;
    color: $dark;
    font-size: 24px;
    font-weight: 900;
    border-radius: 72px;
  }
  img {
    position: relative;
    height: 115px;
    width: 115px;
    background-color: #e6e6e6;
    border-radius: 72px;
    @media screen and (max-width: 767px) {
      height: 100px;
      width: 100px;
    }
  }
  .errorText {
    cursor: default;
    position: absolute;
    color: $error;
    font-size: 80%;
    bottom: 0;
    transform: translateY(30px);
    @media screen and (max-width: 767px) {
      width: max-content;
      transform: translateY(10px);
    }
    @media screen and (max-width: 474px) {
      transform: translateY(5px);
    }
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    position: relative;
    transform: unset;
    right: unset;
    button {
      height: 105px;
      width: 105px;
    }
  }
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.textLnk {
  font-size: 14px;
  text-decoration: underline;
  color: $primaryColor;
  cursor: pointer;
  &:hover {
    color: $primaryColor;
    text-decoration: underline;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
  }
}

.formContainer {
  .infoIcon {
    display: flex;
    position: absolute;
    transform: translate(150%, 160%);
    color: $white;
    right: 0;
    margin-left: 10px;
    z-index: 1;
    @media screen and (max-width: 767px) {
      transform: translate(0, 0);
    }
  }

  button {
    background: $ctaColor;
    border-radius: 30px;
    color: $white;
    min-width: 300px;
    border: none;
    font-family: $circularBold;
    @media screen and (max-width: 767px) {
      min-width: 90px;
    }
  }

  :global(.css-1okebmr-indicatorSeparator) {
    display: none;
  }
}

.role {
  :global(.control) {
    height: 20px;
  }
}
