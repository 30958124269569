/* General Colours Variables */
$circular: "poppins";
$circularStd: "poppins-medium";
$circularBold: "poppins-bold";
$circularSemiBold: "poppins-semi-bold";
$circularLight: "circular-light";
$font-acumin: "acumin-variable";

@font-face {
  font-family: $circularStd;
  src: url("../assets/fonts/Poppins-Medium.woff2");
}

@font-face {
  font-family: $circularBold;
  src: url("../assets/fonts/Poppins-Bold.woff2");
}
@font-face {
  font-family: $circularSemiBold;
  src: url("../assets/fonts/Poppins-SemiBold.woff2");
}
@font-face {
  font-family: $circularLight;
  src: url("../assets/fonts/CircularStd-Book.ttf");
}

@font-face {
  font-family: $circular;
  src: url("../assets/fonts/Poppins-Medium.woff2");
}

@font-face {
  font-family: $font-acumin;
  src: url("../assets/fonts/AcuminVariableConcept.otf");
}

@mixin font-acumin-r {
  font-family: $font-acumin;
  font-variation-settings:
    "wght" 500,
    "wdth" 50,
    "slnt" 0;
}

@mixin font-acumin-sb {
  font-family: $font-acumin;
  font-variation-settings:
    "wght" 600,
    "wdth" 50,
    "slnt" 0;
}

@mixin font-acumin-b {
  font-family: $font-acumin;
  font-variation-settings:
    "wght" 700,
    "wdth" 50,
    "slnt" 0;
}

//Common colors
$black: #000000;
$white: #ffffff;
$dark: #1b1b1b;
$red: #fc3256;
$error: #ff4d56;
$bbError: #e41164;
$success: #6fd361;
$spotify: #1db954;
$purple: #8469ff;
$darkPurple: #26223c;
$darkYellow: #d2aa4f;
$updateMsg: #eb33fd;

$primary: #06ffb3;
$primaryDisabled: #b4ffe8;
$secondary: #310ae3;
$medium: #383838;
$grey: #999999;
$light: var(--light);
$veryLight: #f1f1f1;

// Marketplace offer range colors
$marketplaceGreen: #569a4d;
$marketplaceYellow: #ffe624;
$marketplaceYellowOrange: #ffab24;
$marketplaceOrange: #fa8510;
$marketplaceRed: #e63b3b;
$marketplaceDarkRed: #771e1e;

$primaryColor: var(--primary-color);
$greyColor: #e3e3e3;
$darkGreyColor: var(--dark-grey-color);
$btnDeactivated: #b5b5b5;
$btnDeactivatedText: #5d5d5d;
$accentColor: #ffba66;
$successColor: #0bdb1e;
$blueColor: #322343;
$darkBlueColor: #291d2e;

// Theme component variables
$appPrimaryBackgroundColor: var(--app-primary-background-color);
$appHeaderBackgroundColor: var(--app-header-background-color);
$appHeaderFontColor: var(--app-header-font-color);
$appAccentFontColor: var(--accent-font-color);
$ctaColor: var(--cta-color);
$fundingCtaFontColor: var(--cta-font-color);

$sidebarPrimaryBackgroundColor: var(--sidebar-primary-background-color);
$sidebarHeaderFooterColor: var(--sidebar-header-footer-color);
$sidebarFontColor1: var(--sidebar-font-color-1);
$sidebarFontColor2: var(--sidebar-font-color-2);
$sidebarTooltipIconColor: var(--sidebar-tooltip-icon-color);
$sidebarTextIconColor: var(--sidebar-text-icon-color);
$sidebarCardButtonColor: var(--sidebar-card-button-color);

$progressAccentColor: var(--progress-accent-color);
$progressUpdateColor: var(--progress-update-color);

$tooltipBackgroundColor: var(--tooltip-background-color);
$tooltipFontColor: var(--tooltip-font-color);
$alertBackgroundColor: var(--alert-background-color);
$alertFontColor: var(--alert-font-color);

$sliderColor: var(--slider-color);
$helpTextColor: var(--help-text-color);
$fundCtaFontColor: var(--funding-cta-font-color);
$fundCtaBgColor: var(--funding-cta-bg-color);

$partnerCardBgColor: var(--spotify-card-background-color);

$navigationBgColor: var(--login-nav-background-color);
$navigationTextColor: var(--login-nav-font-color);
$loginBgColor: var(--login-bg-color);

// Theme colors for RGB
$primaryColorRgb: var(--primary-color-rgb);

$appPrimaryBackgroundColorRgb: var(--app-primary-background-color-rgb);
$appHeaderBackgroundColorRgb: var(--app-header-background-color-rgb);
$appHeaderFontColorRgb: var(--app-header-font-color-rgb);
$appAccentFontColorRgb: var(--accent-font-color-rgb);
$ctaColorRgb: var(--cta-color-rgb);
$fundingCtaFontColorRgb: var(--cta-font-color-rgb);

$sidebarPrimaryBackgroundColorRgb: var(--sidebar-primary-background-color-rgb);
$sidebarHeaderFooterColorRgb: var(--sidebar-header-footer-color-rgb);
$sidebarFontColor1Rgb: var(--sidebar-font-color-1-rgb);
$sidebarFontColor2Rgb: var(--sidebar-font-color-2-rgb);
$sidebarTooltipIconColorRgb: var(--sidebar-tooltip-icon-color-rgb);
$sidebarTextIconColorRgb: var(--sidebar-text-icon-color-rgb);
$sidebarCardButtonColorRgb: var(--sidebar-card-button-color-rgb);

$progressAccentColorRgb: var(--progress-accent-color-rgb);
$progressUpdateColorRgb: var(--progress-update-color-rgb);

$tooltipBackgroundColorRgb: var(--tooltip-background-color-rgb);
$tooltipFontColorRgb: var(--tooltip-font-color-rgb);
$alertBackgroundColorRgb: var(--alert-background-color-rgb);
$alertFontColorRgb: var(--alert-font-color-rgb);

$sliderColorRgb: var(--slider-color-rgb);
$helpTextColorRgb: var(--help-text-color-rgb);
$fundCtaFontColorRgb: var(--funding-cta-font-color-rgb);
$fundCtaBgColorRgb: var(--funding-cta-bg-color-rgb);

$partnerCardBgColorRgb: var(--spotify-card-background-color-rgb);

$navigationBgColorRgb: var(--login-nav-background-color-rgb);
$navigationTextColorRgb: var(--login-nav-font-color-rgb);
$loginBgColorRgb: var(--login-bg-color-rgb);

:export {
  black: $black;
  grey: $grey;
  circularBold: $circularBold;
  primaryColor: $primaryColor;
  darkGreyColor: $darkGreyColor;
  success: $success;
}
