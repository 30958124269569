@import "../../../styles/variables.scss";

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .pageContainer {
      display: flex;
      flex: 1;
      width: 100%;
      background-color: $dark;
      .mainContainer {
        background: $veryLight;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        margin-top: 0px;
        position: relative;
        .scrollContainer {
          height: 100%;
          max-height: calc(100vh - 100px);
          max-width: calc(100vw - 240px);
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          .container {
            display: flex;
            flex-direction: column;
            max-width: 1145px;
            flex-grow: 1;
            width: 100%;
            text-align: center;
            margin-bottom: 110px;
            @media screen and (max-width: 767px) {
              margin-top: 0;
            }
          }
          @media screen and (max-width: 1440px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 1366px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 767px) {
            padding: 20px;
            max-height: unset;
            max-width: 100vw;
          }
        }
        @media screen and (max-width: 767px) {
          flex-grow: 1;
          padding: 0px 10px 10px 10px;
          max-height: unset;
        }
        @media screen and (max-width: 529px) {
          padding: 0;
          min-height: calc(100vh - 167px);
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        max-width: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  h3 {
    font-size: 30px;
    margin: 0;
    font-family: $circularBold;
    color: $sidebarPrimaryBackgroundColor;
  }
  p {
    font-size: 14px;
    margin: 0;
    color: $helpTextColor;
    line-height: 20px;
  }
}

.tableContainer {
  display: flex;
  flex-direction: column;
  background-color: $white;
  margin-top: 20px;
  border-radius: 15px;
  overflow-y: auto;
  flex-grow: 1;
  .table {
    &.mobTable {
      display: none;
      th {
        padding: 12px;
        font-size: 12px;
        &:last-child {
          min-width: 112px;
          text-align: right;
        }
        &.mobileCheckboxCell {
          text-align: left;
        }
      }
      td:not([colspan]) {
        border-bottom: none;
        padding: 12px;
      }
      .collapseContainer .include {
        justify-content: unset;
      }
      td[colspan] {
        padding: 0;
      }
      tr.collapsedRow {
        height: unset;
      }
      .collapseContainer {
        tr {
          height: unset;
        }
        td {
          padding: 7px 15px;
          font-size: 10px;
          color: $primaryColor;
          label {
            text-transform: uppercase;
            font-family: $circularBold;
            margin-bottom: 0;
          }
        }
      }
      @media screen and (max-width: 767px) {
        display: table;
      }
    }
    :global(.mb-4) {
      margin-bottom: 0 !important;
      padding-top: 0;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    :global(.MuiTableBody-root .MuiTableRow-root) {
      height: 70px;
    }
    :global(.MuiTableRow-head) {
      background-color: $primaryColor;
      :global(.MuiTableCell-head) {
        color: $white;
        font-family: $circularBold;
        :global(.MuiCheckbox-root) {
          color: $white;
        }
      }
      .trackTitle {
        margin-left: 42px;
      }
      .trackHeaderCtn {
        display: inline-flex;
        flex-direction: column;
        .editCtn {
          display: inline-flex;
          gap: 10px;
        }
      }
      .checkTitle {
        display: inline-flex;
        align-items: center;
        font-family: $circular;
        gap: 5px;
        text-decoration: underline;
        svg {
          width: 15px;
          height: 15px;
          cursor: pointer;
          path {
            fill: $white;
          }
        }
      }
    }
    :global(.MuiTouchRipple-root) {
      display: none;
    }
    :global(.MuiCheckbox-root) {
      padding: 4px;
      color: $primaryColor;
      &:hover {
        background-color: transparent;
      }
    }
    :global(.form-group input),
    :global(.dist__control) {
      background-color: transparent;
    }
    :global(.dist__placeholder) {
      font-family: $circularBold;
      color: rgba($color: $primaryColorRgb, $alpha: 0.3);
    }
    :global(.dist__menu) {
      border-radius: 6px;
      :global(.dist__option) {
        color: $primaryColor;
        font-family: $circularBold;
      }
      :global(.dist__option--is-selected) {
        background-color: rgba($color: $primaryColorRgb, $alpha: 0.1);
      }
      ::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }
      ::-webkit-scrollbar-track {
        background-color: rgba($color: $black, $alpha: 0.1);
      }
      ::-webkit-scrollbar-thumb {
        background-color: rgba($color: $black, $alpha: 0.2);
        border-radius: 8px;
        max-height: 100px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: rgba($color: $black, $alpha: 0.4);
      }
    }
    :global(.dist__control),
    :global(.dist__menu) {
      .option {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
        span {
          color: $primaryColor;
          font-family: $circularBold;
        }
      }
    }
    .activeRow {
      background-color: rgba($color: $primaryColorRgb, $alpha: 0.07);
    }
    .trackBasicContainer {
      display: flex;
      align-items: center;
      gap: 15px;
      :global(.MuiAvatar-circular) {
        width: 30px;
        height: 30px;
        background-color: $light;
        color: $primaryColor;
        svg {
          padding: 5px;
          path {
            fill: $primaryColor;
          }
          @media screen and (max-width: 767px) {
            padding: 3px;
          }
        }
        @media screen and (max-width: 767px) {
          width: 25px;
          height: 25px;
        }
      }
      .nameContainer {
        display: flex;
        flex-direction: column;
        gap: 3px;
        h4 {
          margin: 0;
          font-size: 12px;
          font-family: $circularSemiBold;
          @media screen and (max-width: 767px) {
            font-size: 10px;
          }
        }
        p {
          margin: 0;
          font-size: 12px;
          @media screen and (max-width: 767px) {
            font-size: 10px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        gap: 10px;
      }
    }
    .share {
      display: flex;
      align-items: center;
      span {
        right: 0;
        left: unset;
        top: 6px;
      }
      input::placeholder {
        color: rgba($color: $primaryColorRgb, $alpha: 0.3);
      }
    }
    .distributor {
      display: flex;
      align-items: center;
      width: 100%;
      min-width: 180px;
      > div {
        width: 100%;
      }
    }
    .include {
      display: flex;
      align-items: center;
      gap: 15px;
      :global(.react-switch-checkbox) {
        display: none;
      }
      .iconBtn {
        svg {
          transform: rotateZ(-90deg);
          transition: transform 0.3s;
          &.open {
            transform: rotateZ(-180deg);
          }
          path {
            fill: $primaryColor;
          }
        }
      }
      svg {
        width: 20px;
        path {
          fill: #c92c2c;
        }
        @media screen and (max-width: 767px) {
          margin-left: auto;
        }
      }
      :global(.react-switch-label) {
        margin: 0;
        scale: 0.8;
      }
      :global(.react-switch-checkbox:checked:disabled + .react-switch-label) {
        background-color: rgba(
          $color: $primaryColorRgb,
          $alpha: 0.8
        ) !important;
      }
      :global(.react-switch-checkbox:disabled + .react-switch-label) {
        background-color: rgba($color: $grey, $alpha: 0.7) !important;
      }
      @media screen and (max-width: 767px) {
        justify-content: flex-end;
      }
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.9
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}

.editModal {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 550px;
  text-align: center;
  padding: 40px;
  background-color: $white;
  margin: 20px;
  border: none;
  border-radius: 20px;
  height: fit-content;
  outline: none;
  width: 90%;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  h2 {
    font-size: 24px;
    font-family: $circularBold;
    color: $primaryColor;
    letter-spacing: 0;
    margin-bottom: 20px;
    text-align: left;
  }
  .formContainer {
    display: flex;
    flex-direction: column;
    :global(.form-group) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin-bottom: 8px;
      & > label {
        flex-grow: 1;
        width: 40%;
        margin-bottom: 0;
        text-transform: uppercase;
        font-size: 12px;
        font-family: $circularBold;
        @media screen and (max-width: 767px) {
          font-size: 10px;
        }
      }
      :global(.mb-4) {
        margin-bottom: 0 !important;
        padding: 0;
        flex-grow: 1;
        width: 60%;
        & > span {
          right: 0;
          top: 5px;
          left: unset;
        }
        input {
          font-size: 14px;
          &::placeholder {
            color: rgba($color: $primaryColorRgb, $alpha: 0.3);
          }
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
      :global(.dist__control) {
        background-color: transparent;
        font-size: 14px;
        :global(.dist__placeholder) {
          font-family: $circularBold;
          color: rgba($color: $primaryColorRgb, $alpha: 0.3);
        }
      }
      :global(.dist__menu) {
        border-radius: 6px;
        :global(.dist__option) {
          color: $primaryColor;
          font-family: $circularBold;
        }
        :global(.dist__option--is-selected) {
          background-color: rgba($color: $primaryColorRgb, $alpha: 0.1);
        }
        ::-webkit-scrollbar {
          width: 5px;
          height: 10px;
        }
        ::-webkit-scrollbar-track {
          background-color: rgba($color: $black, $alpha: 0.1);
        }
        ::-webkit-scrollbar-thumb {
          background-color: rgba($color: $black, $alpha: 0.2);
          border-radius: 8px;
          max-height: 100px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background-color: rgba($color: $black, $alpha: 0.4);
        }
      }
      :global(.dist__control),
      :global(.dist__menu) {
        .option {
          display: flex;
          align-items: center;
          gap: 5px;
          img {
            width: 30px;
            height: 30px;
            object-fit: contain;
          }
          span {
            color: $primaryColor;
            font-family: $circularBold;
            font-size: 12px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
        margin-bottom: 12px;
      }
    }
  }
  .btnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    button {
      border: 1px solid $ctaColor;
      background-color: transparent;
      color: $ctaColor;
      padding: 12px 20px;
      border-radius: 25px;
      font-family: $circularBold;
      font-size: 14px;
      letter-spacing: 0;
      text-transform: uppercase;
      margin-top: 20px;
      min-width: 190px;
      &.primary {
        width: 100%;
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
      }
      @media screen and (max-width: 767px) {
        padding: 10px 12px;
        font-size: 12px;
        margin-top: 15px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 25px;
  }
}

.ackContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  svg {
    width: 64px;
    path {
      fill: $primaryColor;
    }
  }
  h2 {
    margin-bottom: 10px;
    font-size: 30px;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 16px;
    margin-bottom: 0;
    &:last-child {
      font-family: $circularBold;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: transparent;
  font-size: 30px;
  cursor: pointer;
  line-height: 18px;
  overflow: hidden;
  padding: 4px;
}

.footerContainer {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 4;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 25px 40px;
  background-color: $white;
  box-shadow: 0 10px 51px rgba($color: $black, $alpha: 0.3);
  gap: 15px;
  justify-content: space-between;
  button {
    border: 1px solid $ctaColor;
    padding: 12px 20px;
    border-radius: 25px;
    font-family: $circularBold;
    text-transform: uppercase;
    min-width: 190px;
    font-size: 14px;
    letter-spacing: 0;
    background-color: $ctaColor;
    color: $fundingCtaFontColor;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 10px;
    }
  }
  .contactText {
    display: flex;
    flex-direction: column;
    gap: 2px;
    text-align: left;
    color: $helpTextColor;
    font-size: 12px;
    p {
      margin: 0;
    }
    span.btnLink {
      font-family: $circularSemiBold;
      cursor: pointer;
      text-decoration: underline;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px 15px;
    flex-direction: column-reverse;
    position: fixed;
    border-radius: 15px 15px 0 0;
  }
}
