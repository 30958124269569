@import "../../../styles/variables.scss";

.leftPanel {
  background-color: $medium;
  padding: 40px 30px 30px;
  color: $white;
  flex: 0 0 20%;
  height: calc(100vh - 111px);
  text-align: left;
  h1 {
    font-size: 32px;
    color: $grey;
  }
  .menuItems {
    padding: 20px 0;
    p {
      white-space: nowrap;
      margin: 15px 0;
      font-weight: 600;
      font-size: 20px;
      cursor: pointer;
    }
    .active {
      color: $accentColor;
    }
  }
  @media screen and (max-width: 1440px) {
    height: calc(100vh - 84px);
  }
  @media screen and (max-width: 1366px) {
    height: calc(100vh - 66px);
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
