@import "../../../styles/variables.scss";

.main {
  display: flex;

  .leftpanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
    background-color: $sidebarPrimaryBackgroundColor;
    color: $sidebarFontColor1;
    max-width: 240px;
    width: 100%;
    padding: 20px 30px 30px 30px;

    .logoContainer {
      .logo {
        width: 100%;
        max-height: 60px;
        object-fit: contain;
        flex-shrink: 0;
      }

      @media screen and (max-width: 767px) {
        .logo {
          max-width: 165px;
        }
      }

      @media screen and (max-width: 319px) {
        .logo {
          max-width: 160px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      max-width: none;
      padding: 20px 30px;
    }

    .textlogin {
      display: flex;
      align-items: center;
      margin: 0px;

      @media screen and (max-width: 319px) {
        display: flex;
        flex-direction: column;
        margin-top: 0px;
      }

      .lefttext {
        display: none;
        margin-right: 10px;

        @media screen and (max-width: 767px) {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          border-radius: 20px;
          height: 20px;
          width: 20px;
          background-color: $helpTextColor;
          color: $veryLight;
          margin-bottom: 0px;
          cursor: pointer;

          &::after {
            content: "?";
            font-family: $circularBold;
            font-size: 14px;
          }
          svg,
          p {
            display: none;
          }
        }

        @media screen and (max-width: 319px) {
          margin-right: 0px;
        }
      }

      .loginleft {
        display: none;

        @media screen and (max-width: 767px) {
          display: inline-block;

          .loginBtn {
            border: 2px solid $ctaColor;
            border-radius: 25px;
            background-color: transparent;
            color: $ctaColor;
            font-family: $circularBold;
            padding: 5px 25px;
          }
        }

        @media screen and (max-width: 360px) {
          .loginBtn {
            padding: 3px 20px;
          }
        }

        @media screen and (max-width: 319px) {
          margin-top: 10px;
        }
      }
    }
  }

  .rightpanel {
    display: flex;
    flex-direction: column;
    background-color: white;
    flex: 1;

    .headerpanel {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 12px;
      margin: 10px;

      .text {
        margin-right: 30px;
        align-items: center;
        text-align: center;
        display: flex;
        font-family: $circularStd;

        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
          padding: 10px 31px;
          color: $helpTextColor;
          background-color: $veryLight;
          border-radius: 20px;
          margin-bottom: 0px;
          cursor: pointer;
          p {
            margin-bottom: 0;
          }
          svg {
            flex-shrink: 0;
            margin-right: 5px;
            path {
              fill: $helpTextColor;
              opacity: 0.8;
            }
          }

          @media screen and (max-width: 767px) {
            padding: 0;
            height: 20px;
            width: 20px;
            background-color: $white;
            color: $black;
            &::after {
              content: "?";
              font-family: $circularBold;
              font-size: 14px;
            }
            svg,
            p {
              display: none;
            }
          }
        }
      }

      .loginBtn {
        border: 2px solid $ctaColor;
        border-radius: 25px;
        background-color: $white;
        color: $ctaColor;
        font-family: $circularStd;
        padding: 5px 25px;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.pageContainer {
  background-color: $light;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 83px);
  overflow-y: auto;
  padding: 20px;

  .getFundedFastDetails {
    font-size: 16px;
    padding: 10px 0px;

    ol {
      width: 100%;
      min-width: 65%;
      padding-inline-start: 0px;
    }
    li {
      display: flex;
      padding: 8px 0;
      counter-increment: inst;
      text-align: left;
    }
    li::before {
      content: counter(inst);
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primaryColor;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      color: #fff;
      margin-right: 5px;
      flex-shrink: 0;
    }
  }

  .containerbig {
    background: white;
    max-width: 650px;
    width: 100%;
    padding: 40px;
    border-radius: 12px;

    @media screen and (max-width: 767px) {
      max-width: 550px;
      margin: 20px;
      width: 80%;
    }
    h3 {
      font-size: 25px;
      font-family: $circularBold;
      color: $primaryColor;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    h4 {
      font-size: 18px;
      padding-top: 5px;
      font-family: $circularStd;
      line-height: 1.3;
      @media screen and (max-width: 767px) {
        font-size: 15px;
      }
    }
    h5 {
      font-size: 17px;
      font-family: $circularBold;
      @media screen and (max-width: 767px) {
        font-size: 15px;
      }
    }
  }

  .containersm {
    background: white;
    max-width: 550px;
    width: 100%;
    padding: 40px;
    border-radius: 12px;

    @media screen and (max-width: 767px) {
      max-width: 450px;
      margin: 20px;
      width: 80%;
    }
    h3 {
      font-size: 25px;
      font-family: $circularBold;
      color: $primaryColor;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    h4 {
      font-size: 18px;
      padding-top: 5px;
      font-family: $circularStd;
      line-height: 1.3;
      @media screen and (max-width: 767px) {
        font-size: 15px;
      }
    }
    h5 {
      font-size: 17px;
      font-family: $circularBold;
      @media screen and (max-width: 767px) {
        font-size: 15px;
      }
    }
  }

  .container {
    background: white;
    max-width: 600px;
    width: 100%;
    border-radius: 12px;

    .firstContainer {
      padding: 40px 50px 30px 50px;
      border-bottom: 1px solid $greyColor;

      @media screen and (max-width: 767px) {
        padding: 40px 50px 30px 50px;
      }

      @media screen and (max-width: 319px) {
        padding: 40px 30px 30px 30px;
      }
    }

    .secondContainer {
      padding: 30px 70px 10px 50px;

      @media screen and (max-width: 767px) {
        padding: 30px 50px 10px 50px;
      }

      @media screen and (max-width: 319px) {
        padding: 40px 30px 30px 30px;
      }
    }

    @media screen and (max-width: 767px) {
      margin: 20px;
      width: 80%;
    }
    h3 {
      font-size: 25px;
      font-family: $circularBold;
      color: $primaryColor;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    h4 {
      font-size: 18px;
      padding-top: 5px;
      font-family: $circularStd;
      line-height: 1.3;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    h5 {
      font-size: 17px;
      font-family: $circularBold;
      @media screen and (max-width: 767px) {
        font-size: 15px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    height: calc(100vh - 91px);
    padding: 0px;
  }

  @media screen and (max-width: 340px) {
    height: calc(100vh - 84px);
  }

  @media screen and (max-width: 319px) {
    height: calc(100vh - 94px);
  }
}
