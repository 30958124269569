@import "../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  text-align: center;
  width: 100%;
  background-color: $white;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - 111px);
  @media (max-width: 1440px) {
    min-height: calc(100vh - 84px);
  }
  @media (max-width: 1366px) {
    min-height: calc(100vh - 66px);
  }
  @media (max-width: 767px) {
    min-height: 100vh;
  }
  a,
  .artistDetails {
    color: $primaryColor;
    &:hover {
      color: rgba($color: $primaryColor, $alpha: 0.6);
      .subText {
        color: rgba($color: $dark, $alpha: 0.6);
      }
    }
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    padding: 70px 25px 20px;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    .tabContainer {
      width: 100%;
      .tab {
        background-image: linear-gradient(
          to bottom,
          $primaryColor calc((0.12 - 1) * 10000%),
          transparent calc(0.12 * 10000%)
        );
        color: $primaryColor;
        font-family: $circular;
        opacity: 1;
        border-radius: 8px 8px 0 0;
        margin: 0 10px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        font-size: 18px;
        padding: 15px 20px;
        @media screen and (max-width: 1366px) {
          font-size: 14px;
          padding: 6px 12px;
        }
        @media screen and (max-width: 767px) {
          font-size: 13px;
          padding: 5px 12px;
          min-width: unset;
        }
      }
      :global {
        .Mui-selected {
          border: 3px solid $primaryColor;
          border-bottom: 0;
          background-image: none;
          background-color: transparent;
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            background-color: $white;
            height: 4px;
            flex-shrink: 0;
            bottom: -1px;
            z-index: 1;
          }
        }
        .MuiTabs-indicator {
          display: none;
        }
      }
    }
    .btnContainer {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      button {
        background-color: $ctaColor;
        font-family: $circularBold;
        font-size: 18px;
        color: $fundingCtaFontColor;
        border: none;
        border-radius: 25px;
        padding: 12px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 40px;
          margin-right: -18px;
          stroke: $white;
          @media screen and (max-width: 767px) {
            width: 35px;
            margin-right: -10px;
          }
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          font-size: 16px;
          padding: 10px 15px;
        }
      }
      @media screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }
    @media screen and (max-width: 1440px) {
      max-width: 1366px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 29px 15px;
    }
    @media screen and (max-width: 479px) {
      padding: 15px;
    }
  }
}

.title {
  h1 {
    color: $primaryColor;
    font-family: $circular;
    font-weight: 600;
    font-size: 64px;
    line-height: 69px;
    letter-spacing: -3.5px;
    white-space: nowrap;
    @media screen and (max-width: 767px) {
      white-space: unset;
      font-size: 48px;
    }
    @media screen and (max-width: 479px) {
      white-space: unset;
      font-size: 40px;
    }
  }
  h2 {
    display: none;
    color: $white;
    padding: 18px;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1.1px;
  }
  @media screen and (max-width: 767px) {
    background-color: $dark;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      display: none;
    }
    h2 {
      display: block;
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }
}

.artistDetailsContainer {
  padding: 50px 100px;
  border: 3px solid $primaryColor;
  border-radius: 0 0 8px 8px;
  margin-top: -3px;
  display: flex;
  flex-direction: column;
  .profile {
    display: flex;
    align-items: flex-end;
    width: 50%;
    margin-bottom: 30px;
    .imageContainer {
      height: 140px;
      width: 140px;
      object-fit: cover;
      margin-right: 30px;
      @media screen and (max-width: 767px) {
        height: 90px;
        width: 90px;
        margin-right: 20px;
      }
    }
    .name {
      text-align: left;
      p {
        margin-bottom: 6px;
        font-weight: 600;
      }
      h2 {
        margin-bottom: 0;
        font-family: $circularBold;
        letter-spacing: 0px;
        font-weight: 400;
        @media screen and (max-width: 767px) {
          font-size: 22px;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  .summary {
    display: flex;
    text-align: left;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    h4.heading {
      border-bottom: 2px solid $primaryColor;
      font-family: $circularBold;
      font-weight: 400;
      font-size: 24px;
      padding: 10px 0;
      @media screen and (max-width: 767px) {
        font-size: 20px;
        padding: 5px 0;
      }
    }
    .artistProfile {
      flex-shrink: 0;
      flex-grow: 0;
      width: 48%;
      @media screen and (max-width: 1024px) {
        width: 100%;
        margin-bottom: 30px;
      }
    }
    .musicDetails {
      flex-shrink: 0;
      width: 48%;
      flex-grow: 0;
      .musicAnalytics {
        display: flex;
        flex-wrap: wrap;
        .subSections {
          padding-right: 12px;
          width: 33.33%;
          &:last-child {
            padding-right: 0;
          }
          p.heading {
            text-transform: uppercase;
            font-size: 14px;
            @media screen and (max-width: 767px) {
              margin-bottom: 2px;
            }
          }
          p.content {
            font-family: $circularBold;
            font-size: 18px;
            span {
              color: $primaryColor;
            }
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px 20px;
  }
}

.artistCollapse {
  position: fixed;
  left: 0;
  bottom: 80px;
  padding: 20px;
  background-color: $primaryColor;
  text-align: left;
  color: $white;
  border-radius: 0 30px 30px 0;
  transition: padding 0.3s;
  margin-right: 20px;
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 40px;
    background-color: $white;
    border: 2px solid $primaryColor;
    border-radius: 50%;
    cursor: pointer;
    transform: translate(40%, -40%);
    &::after {
      content: "";
      display: block;
      height: 12px;
      width: 12px;
      border-right: 2px solid $black;
      border-bottom: 2px solid $black;
      transform: translateY(-2px) rotate(45deg);
      transition: transform 0.3s;
      @media screen and (max-width: 767px) {
        height: 10px;
        width: 10px;
      }
    }
    @media screen and (max-width: 767px) {
      height: 30px;
      width: 30px;
      transform: translate(30%, -30%);
    }
  }
  .collapseTitle {
    margin-bottom: 0;
    font-family: $circularBold;
    transition: font-size 0.3s, margin 0.3s;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  &.open {
    padding-left: 50px;
    .arrow::after {
      transform: translateY(2px) rotate(225deg);
    }
    .collapseTitle {
      font-size: 24px;
      margin-bottom: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 10px 20px 10px 10px;
  }
}
