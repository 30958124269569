@import "../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  @media (max-width: 100%) {
    max-width: 765px;
    margin-top: 30px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
    margin-top: 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    height: calc(100vh - 92px);
    margin-top: 0;
    background-color: $white;
  }
  a,
  .artistDetails {
    color: $primaryColor;
    &:hover {
      color: rgba($color: $primaryColor, $alpha: 0.6);
      .subText {
        color: rgba($color: $dark, $alpha: 0.6);
      }
    }
  }

  .subContainer {
    text-align: center;
    max-width: 636px;
    width: 100%;
    max-height: 725px;
    height: 100%;
    padding: 30px 50px;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 29px 0;
    }
    @media screen and (max-width: 479px) {
      padding: 0;
    }
  }

  .logoContainer {
    display: flex;
    width: 100%;
    padding: 30px;
    img {
      width: auto;
      max-height: 60px;
      object-fit: contain;
      flex-shrink: 0;
      height: 30px;
    }

    .closeIcon {
      justify-content: flex-end;
      display: none;
      span {
        font-size: 16px;
        font-family: $circularBold;
        color: $primaryColor;

        @media screen and (max-width: 767px) {
          padding-right: 10px;
        }
      }
      @media screen and (max-width: 767px) {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 10px 0px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    max-width: 100%;
  }
}

.title {
  span {
    color: $sidebarPrimaryBackgroundColor;
    margin-right: 2px;
  }
  h1 {
    color: $primaryColor;
    font-family: $circular;
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 0px;
    white-space: nowrap;
    text-align: left;
    @media screen and (max-width: 767px) {
      white-space: unset;
      font-size: 32px;
    }
    @media screen and (max-width: 479px) {
      white-space: unset;
      font-size: 32px;
    }
  }
  h2 {
    display: none;
    color: $white;
    padding: 18px;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1.1px;
  }
  @media screen and (max-width: 424px) {
    font-size: 12px;
  }
}

.artistContainer {
  margin-top: 22px;
  color: $primaryColor;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  .artistDetails {
    max-width: 538px;
    width: 48%;
    min-height: 106px;
    height: 100%;
    border-radius: 12px;
    color: $black;
    background-color: $white;
    display: flex;
    align-items: center;
    margin: 9px 0;
    padding: 10px;
    box-shadow: 0px 0px 12px 2px rgba($color: $dark, $alpha: 0.1);
    cursor: pointer;
    &.cursorDefault {
      cursor: not-allowed;
    }
    p.name {
      font-family: $circularBold;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -1.2px;
      margin: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      div {
        font-size: 18px;
        text-align: left;
        svg {
          height: 26px;
          margin-right: 5px;
          path {
            fill: $dark;
          }
        }
      }
      span {
        align-self: flex-start;
        font-size: 12px;
        letter-spacing: 0px;
        font-weight: 100;
        display: none;
      }
    }
    .moreInfoText {
      font-size: 10px;
      color: $primaryColor;
      cursor: pointer;
      white-space: nowrap;
    }
    .failedSection {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-size: 12px;
        padding: 0px;
        overflow: hidden;
      }
    }
    .failedText {
      font-size: 12px;
      letter-spacing: -0.8px;
      margin: 0;
      line-height: 16px;
      color: $error;
      text-align: left;
      align-items: center;
      width: 60%;
      padding: 15px 10px 15px 60px;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        font-size: 12px;
        width: 100%;
        padding: 0px;
      }
      svg {
        height: 26px;
        margin-right: 5px;
        path {
          fill: $dark;
        }
      }
      img,
      .altImage {
        opacity: 0.5;
      }
    }
    p.subText {
      width: 60%;
      align-items: center;
      max-width: 180px;
      font-size: 12px;
      letter-spacing: -0.8px;
      margin: 0;
      line-height: 16px;
      color: $dark;
      text-align: left;
    }
    .profile {
      box-shadow: 0px 10px 9px $greyColor;
      height: 70px;
      width: 70px;
      margin: 0px 14px;
      border-radius: 51px;
      overflow: hidden;
      flex-shrink: 0;
      img {
        width: 100%;
      }
      .altImage {
        width: 100%;
        height: 100%;
        path {
          fill: $dark;
        }
      }
    }
  }
  .active {
    color: $primaryColor;
    border: 2px solid $primaryColor;
    p.name {
      span {
        display: inline;
        font-weight: 100;
      }
    }
  }

  .pending {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    background-color: $white;
    .artistDetails {
      min-width: unset;
      width: 100%;
      margin: 10px 0;
      padding: 14px;
      overflow: hidden;
      max-width: unset;
      .profile {
        height: 61px;
        width: 61px;
        margin-left: 0px;
      }
      p.name {
        font-size: 18px;
        overflow: hidden;
        width: 100%;
        text-align: left;
        div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          svg {
            height: 18px;
          }
        }
      }
      p.subText {
        font-size: 12px;
        width: 100%;
        padding: 0px;
      }
    }
    .pending {
      svg {
        height: 18px;
      }
    }
  }
}

.disabledParent {
  padding: 25px 50px;
  margin-top: auto;
  @media screen and (max-width: 767px) {
    padding: 30px 25px;
    font-size: 18px;
  }
  @media screen and (max-width: 474px) {
    padding: 20px;
    font-size: 12px;
  }
}

.disabledDiv {
  color: $sidebarPrimaryBackgroundColor;
}

.subTextDis {
  height: 100%;
}
.contactUsDis {
  height: 100%;
  text-decoration: underline;
  color: $primaryColor;
  cursor: pointer;
}

.linkArtist {
  display: flex;
  text-align: left;
  color: $primaryColor;
  align-items: center;
  justify-content: flex-start;

  max-width: 1004px;
  max-height: 100px;
  height: 100%;
  padding: 35px 50px;
  margin-top: auto;
  cursor: pointer;
  .contactUs {
    text-decoration: underline;
    cursor: pointer !important;
    font-size: 14px;
    margin: 0;
  }

  > div:first-child {
    height: 32px;
    width: 32px;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: $primaryColor;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: 100;
    margin-right: 8px;
    box-shadow: 0px 10px 9px $greyColor;
  }

  p.name {
    font-size: 16px;
    font-weight: 100;
    margin: 0;
  }
  p.subText {
    font-size: 18px;
    margin: 0;
    line-height: 18px;
    color: $white;
  }
  a {
    color: $white;
    text-decoration: underline;
  }
  @media (max-width: 1025px) {
    max-width: 765px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
  }
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 30px 5%;
    margin-bottom: 0;
    a {
      color: $white;
    }
    > div:first-child {
      height: 32px;
      width: 32px;
      font-size: 28px;
      color: $white;
    }
    p.name {
      font-size: 18px;
    }
    p.subText {
      font-size: 15px;
      color: $white;
    }
  }
}

.disabledPoint {
  cursor: not-allowed;
}
.infoPopupContent {
  padding: 30px;
  a {
    color: $dark;
    text-decoration: underline;
  }
}
