@import "../../../styles/variables.scss";

.estimateContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  bottom: 0;
  flex: 1.25;
  min-width: min-content;
  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
    flex: 0;
  }
}

.fundingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  @media screen and (max-width: 767px) {
    flex-direction: row;
  }
  .andText {
    margin: 5px 0;
    font-size: 16px;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .dashLine {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      margin: 0 10px;
    }
  }
}

.fastMainContainer {
  background-color: $sidebarPrimaryBackgroundColor;
  min-width: min-content;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  bottom: 0;
  color: $sidebarFontColor1;
  border-radius: 20px;
  flex: 1.25;
  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
    padding: 20px 30px;
  }
  @media screen and (max-width: 480px) {
    padding: 20px 10px;
  }
  .menuBarContainerBox {
    width: 100%;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
    @media screen and (max-width: 767px) {
      border: none;
      &.active {
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
        overflow: unset;
        height: fit-content;
      }
    }
  }
  .seeMoreLnk {
    display: none;
    background-color: $sidebarPrimaryBackgroundColor;
    padding: 10px;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0;
      font-size: 14px;
      font-family: $circularBold;
    }
    span {
      display: flex;
      width: 60px;
      height: 60px;
      background-color: $sidebarPrimaryBackgroundColor;
      position: absolute;
      transform: translateY(50%);
      bottom: 0;
      z-index: -1;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      &::before {
        content: " ";
        width: 115%;
        background: $sidebarPrimaryBackgroundColor;
        position: absolute;
        height: 6px;
        transform: translateY(47%);
        clip-path: polygon(
          100% 0,
          0 0,
          1% 5%,
          4% 15%,
          7% 50%,
          93% 50%,
          96% 15%,
          99% 5%
        );
      }
      &::after {
        content: " ";
        width: 10px;
        height: 10px;
        border-bottom: 2px solid $sidebarFontColor1;
        display: block;
        border-right: 2px solid $sidebarFontColor1;
        transition: 0.5s cubic-bezier(0.64, 0, 0.78, 0);
        transform: translateY(90%) rotate(45deg);
      }
    }
    &.showMore span::after {
      transform: translateY(90%) rotate(-135deg);
    }
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
  div.collapseMenu {
    @media screen and (max-width: 767px) {
      max-height: 0;
      overflow: hidden;
      padding: 0;
      transition:
        max-height 0.3s,
        padding 0.3s;
      &.active {
        max-height: 900px;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($color: $white, $alpha: 0.3);
    max-height: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $white, $alpha: 0.5);
  }
}

.helperText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-color: $light;
  border-radius: 13px;
  opacity: 0.1px;
  padding: 14px 18px;
  font-size: 14px;
  .text {
    padding: 0 10px;
    color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.7);
    display: flex;
    text-align: start;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.svgIcon {
  width: 17px;
  height: 17px;
  flex-grow: 0;
  flex-shrink: 0;
  path {
    fill: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.7);
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.digitDetails {
  padding: 28px 30px 15px 30px;

  transition: max-height 0.3s cubic-bezier(0.5, 1, 0.89, 1);
  @media screen and (max-width: 1024px) {
    padding: 24px 40px 15px;
  }
  @media screen and (max-width: 767px) {
    padding: 22px 36px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: hidden;
    &.showMore {
      max-height: 1150px;
    }
  }
  .dataReportContainer {
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .menuItems {
    margin-top: 40px;
    width: 100%;
    text-align: left;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
  .validThrough {
    font-size: 14px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
}

.menuToolTip {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: $white;
  letter-spacing: 0px;
  p {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: $circularBold;
    @media screen and (max-width: 1366px) {
      font-size: 16px;
    }
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  i {
    margin-left: 5px;
    transform: translate(0px, -1px);
  }
  a {
    color: $sidebarFontColor1;
    text-decoration: underline;
    &:hover {
      color: $sidebarFontColor1;
    }
  }
  @media screen and (max-width: 767px) {
    position: relative;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $sidebarPrimaryBackgroundColor;
  border-radius: 50%;
  cursor: pointer;
  background-color: $sidebarTooltipIconColor;
  @media screen and (max-width: 1366px) {
    height: 13px;
    width: 13px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "?";
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
  @media screen and (max-width: 767px) {
    font-size: 11px;
    line-height: 11px;
  }
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  font-weight: normal;
  text-align: left;
  color: $tooltipBackgroundColor !important;
  background-color: $tooltipFontColor !important;
  box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.3);
  border-radius: 5px !important;
  white-space: break-spaces;
  text-transform: initial;
  font-family: $circularStd;
  &::after {
    border: transparent !important;
  }
  &:global(.show) {
    opacity: 1 !important;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.accountCount {
  font-family: $circularStd;
  font-size: 48px;
  width: 100%;
  display: inline-block;
  margin: auto 0;
  * {
    font-family: $circularStd;
    letter-spacing: 1.92px;
  }
  .totalEstimateContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 18px;
    align-items: center;
    white-space: nowrap;
    padding: 25px 30px;
    letter-spacing: 1.2px;
    .fundingValues {
      font-family: $circularBold;
      letter-spacing: -0.72px;
      overflow: hidden;
      font-size: 36px;
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
      @media screen and (max-width: 480px) {
        font-size: 20px;
      }
      * {
        font-family: $circularBold;
      }
    }
    @media screen and (max-width: 1366px) {
      padding: 12px 30px 12px;
    }
    @media screen and (max-width: 767px) {
      border: none;
      &.active {
        border-top: 1px solid rgba($color: $white, $alpha: 0.2);
      }
    }
    @media screen and (max-width: 480px) {
      padding: 0;
    }
  }
  .countText {
    display: flex;
    text-align: center;
    font-family: $circularStd;
    white-space: nowrap;
    letter-spacing: 0px;
    color: $white;
    font-size: 14px;
    @media screen and (max-width: 1366px) {
      margin-right: 15px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  :global {
    .odometer-value {
      overflow: visible;
    }
    .odometer-digit-inner {
      overflow: visible !important;
    }
  }
  .mobFundingText {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      font-size: 14px;
      text-align: center;
      font-family: $circularStd;
    }
  }
  .publishBtnContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    align-items: center;
    padding: 25px 30px;
    letter-spacing: normal;
    p {
      margin-bottom: 15px;
      letter-spacing: normal;
      text-align: center;
    }
    button {
      display: flex;
      align-items: center;
      background-color: $sidebarFontColor2;
      color: $sidebarPrimaryBackgroundColor;
      border: none;
      column-gap: 8px;
      border-radius: 30px;
      padding: 4px 20px;
      text-transform: uppercase;
      span {
        white-space: nowrap;
        font-family: $circularBold;
        letter-spacing: normal;
      }
      svg {
        width: 15px;
        path {
          fill: $sidebarPrimaryBackgroundColor;
        }
      }
    }
    @media screen and (max-width: 1366px) {
      padding: 12px 30px;
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 14px;
    * {
      letter-spacing: -0.4px;
    }
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
    * {
      letter-spacing: -0.4px;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 30px;
  font-size: 13px;
  border-top: 1px solid rgba($color: $white, $alpha: 0.2);
  .accurateTextContainer {
    width: 100%;
    p {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
    i {
      margin-left: 5px;
    }
    .helpIcon {
      svg {
        margin-left: 5px;
        transform: translateY(-1px);
        cursor: pointer;
        path {
          color: $sidebarPrimaryBackgroundColor;
          fill: $sidebarTooltipIconColor;
        }
        @media screen and (max-width: 1366px) {
          width: 13px;
          height: 13px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      display: none;
      &.active {
        display: inline;
        width: 100%;
      }
    }
  }
  .primary {
    margin-top: 15px;
    font-family: $circularBold;
    color: $fundCtaFontColor;
    letter-spacing: 2.6px;
    border-radius: 25px;
    opacity: 1;
    width: 100%;
    height: 50px;
    background-color: $fundCtaBgColor;
    box-shadow: 0px 7px 15px rgba($color: $dark, $alpha: 0.4);
    border: none;
    @media screen and (max-width: 767px) {
      margin-top: 0;
      &.active {
        margin-top: 15px;
      }
    }
  }
  .finePrintText {
    color: $sidebarFontColor1;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 0;
  }
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.collapseArrow {
  max-height: 0;
  &.arrowDown {
    @media screen and (max-width: 767px) {
      max-height: 900px;
      transform: translateY(-5px) rotateX(110deg) rotateZ(45deg);
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.detailsPopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 770px;
  width: 90%;
  outline: none;
  padding: 40px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  .offerContainer {
    display: flex;
    flex-direction: column;
    .closeBtn {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      line-height: 18px;
      padding: 10px;
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
    .titleContainer {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba($color: $primaryColorRgb, $alpha: 0.2);
      h2 {
        font-size: 30px;
        font-family: $circularBold;
        color: $primaryColor;
        margin-bottom: 0;
        letter-spacing: 0;
      }
      p {
        font-size: 14px;
        font-family: $circular;
        margin-bottom: 0;
      }
    }
    .firstContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 50%;
      }

      .firstImage {
        width: 62px;
        height: 62px;
        margin-right: -20px;
      }
      .secondImage {
        width: 90px;
        height: 90px;
        z-index: 5;
      }
      .thirdImage {
        width: 62px;
        height: 62px;
        margin-left: -20px;
      }
    }
    .secondContainer {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      font-size: 18px;
      padding: 5px 10px;
      text-align: center;
      .firstHead {
        color: $primaryColor;
        font-family: $circularBold;
      }
      .secondHead {
        color: $black;
      }
    }
    .thirdContainer {
      display: flex;
      font-size: 14px;
      padding: 15px 0px;

      .first {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          color: $white;
          background-color: $primaryColor;
          border-radius: 50%;
          padding: 2px 10px;
          margin-bottom: 0px;
        }
        span {
          width: 81%;
          text-align: center;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        .first {
          flex-direction: row;
          padding: 5px 0px;
          span {
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }

    .fourthContainer {
      color: $primaryColor;
      letter-spacing: 2.8px;
      font-family: $circularBold;
      display: flex;
      justify-content: flex-end;
      padding-right: 30px;
      cursor: pointer;
    }
    .publishingForm {
      display: flex;
      flex-direction: column;
      padding: 30px 0 0;
      :global(.form-group .mb-4) {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        margin-bottom: 0 !important;
        & > div:first-child {
          order: 2;
        }
        input {
          order: 2;
          padding: 2px 0;
          &::placeholder {
            color: rgba($color: $primaryColorRgb, $alpha: 0.4);
          }
        }
        :global(.invalid-feedback) {
          bottom: 0;
          transform: translateY(100%);
        }
        label {
          order: 1;
          position: relative;
          transform: translate(0);
          color: #000;
          font-size: 14px;
          margin: 0;
          pointer-events: auto;
          text-transform: unset;
        }
      }
      button {
        background-color: $ctaColor;
        color: $white;
        border: none;
        padding: 10px 15px;
        text-transform: uppercase;
        border-radius: 30px;
        font-family: $circularBold;
        margin-top: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 25px;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}
