@import "../../../styles/variables.scss";

.layoutContainer {
  display: flex;
  min-height: 100vh;

  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 20px 30px 150px 30px;
      background-color: #d2d7dd;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.container {
  max-width: 2000px;
  width: 100%;
  background-color: $white;
  text-align: center;
  border-radius: 12px;
  flex-grow: 1;
  display: flex;
  padding: 40px 50px;
  flex-direction: column;
  gap: 25px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  position: relative;

  @media screen and (max-width: 767px) {
    padding: 0;
    background: $white;
    margin-right: 0;
    gap: 25px;
    border-radius: 12px;
    background-color: transparent;
  }
}

.badgeContainer {
  display: flex;
  position: absolute;
  height: 145px;
  width: 145px;
  left: 0;
  top: 0;
  filter: drop-shadow(0px 10px 20px rgba($color: $black, $alpha: 0.08));

  svg {
    width: 43px;
    height: 43px;
    z-index: 1;
    transform: translate(50%, 50%);
  }

  &::before {
    content: " ";
    position: absolute;
    height: 145px;
    width: 145px;
    border-radius: 12px 0 0 0;
    background-color: $primaryColor;
    clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.titleContainer {
  display: flex;
  height: 61px;
  align-items: flex-start;
  align-self: stretch;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  padding-left: 75px;
  margin-bottom: 25px;
  margin-top: 10px;

  .title {
    font-size: 30px;
    font-family: $circularBold;
    color: $primaryColor;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      background: $white;
      gap: 25px;
      border-radius: 15px;
      padding: 10px 10px 10px 0px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid $veryLight;
    }
  }

  .mobileBadgeCtn {
    display: none;

    @media screen and (max-width: 767px) {
      display: flex;
      width: 100%;
      max-width: 54px;
      align-items: center;
      justify-content: center;
      background-color: $primaryColor;
      border-radius: 15px 0 0 15px;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: row;
    padding: 0px;
    background: $white;
    margin-right: 0;
    gap: 25px;
    height: 100%;
    margin-bottom: 0;
    margin-top: 0;
    border-radius: 15px;
    align-items: stretch;

    svg {
      height: 25px;
      width: 25px;

      path {
        fill: $white;
      }
    }
  }
}

.bodyContainer {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
  text-align: left;
  gap: 12px;
  border: 1px solid $greyColor;
  padding: 15px 25px;
  border-radius: 15px;

  .header {
    font-size: 14px;
    font-family: $circularBold;
    color: $primaryColor;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      height: 20px;
      width: 20px;

      path {
        fill: $primaryColor;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-family: $circular;
    color: $black;

    p {
      margin-bottom: 0px;
    }

    a {
      cursor: pointer;
      color: $primaryColor;
      text-decoration: underline;
      text-transform: lowercase;
      overflow-wrap: anywhere;
    }

    .boldLine {
      font-family: $circularBold;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 767px) {
    background-color: $white;
  }
}

.pageContainer {
  display: flex;
  flex: 1;
  width: 100%;
  background-color: $sidebarPrimaryBackgroundColor;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
    background-color: transparent;
    border-radius: 12px;
  }
}

.viewOfferButtonContainer {
  max-width: 2000px;
  width: 100%;
  background-color: $white;
  text-align: center;
  border-radius: 12px;
  display: flex;
  padding: 20px 30px;
  justify-content: flex-end;

  .viewOfferBtn {
    font-size: 14px;
    color: $white;
    background: $primaryColor;
    text-align: center;
    display: flex;
    height: 50px;
    padding: 0px 50px;
    gap: 10px;
    border-radius: 25px;
    text-transform: uppercase;
    font-family: $circularBold;
    align-items: center;
    border: none;

    @media screen and (max-width: 767px) {
      height: 40px;
      padding: 0px 35px;
      gap: 10px;
      justify-content: center;
      align-self: stretch;
      width: 2000px;
    }
  }

  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    padding: 25px 30px;
    gap: 23px;
    align-items: center;
    border-radius: 12px 12px 0px 0px;
  }
}

.renderNewOfferContainer {
  max-width: 2000px;
  width: 100%;
  background-color: $white;
  text-align: center;
  border-radius: 15px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  position: relative;
  height: 100%;
  border: 2px solid rgba($color: $helpTextColorRgb, $alpha: 0.5);

  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    background: $white;
    margin-right: 0;
    gap: 20px;
    border-radius: 15px;
  }
}

.maxOfferAdvContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #1a1632;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transform: scale(1.003);
  max-width: 400px;
  height: 100%;
  white-space: nowrap;
  p {
    display: flex;
    font-size: 20px;
    font-family: $circular;
    color: $sidebarFontColor1;
    margin: 0;
    @media screen and (max-width: 767px) {
      font-size: 18px;
      max-width: unset;
    }
  }

  h2 {
    @include font-acumin-b;
    display: flex;
    flex-direction: column;
    font-size: 90px;
    color: $progressAccentColor;
    line-height: 90px;
    margin: 0;
    white-space: nowrap;
    text-transform: lowercase;
    align-items: center;
    :global(.odometer) {
      @include font-acumin-b;
    }

    @media screen and (max-width: 767px) {
      font-size: 75px;
      line-height: 75px;
    }
  }
}

.newOfferBodyContainer {
  display: flex;
  flex-direction: column;
  padding: 40px 50px;
  gap: 25px;
  width: 100%;

  .videoTitle {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: $circular;
    font-size: 18px;
    color: #1a1632;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      font-size: 15px;
    }
  }

  .videoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    iframe {
      border-radius: 8px;
      min-height: 263px;
      min-width: 470px;
      width: 100%;
      max-height: 263px;
      max-width: 470px;

      @media screen and (max-width: 1240px) {
        min-width: unset;
      }

      @media screen and (max-width: 479px) {
        min-height: 150px;
      }
    }
  }

  .nextBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $circular;
    font-size: 18px;
    color: $ctaColor;
    border: none;
    background: none;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 30px;
    gap: 15px;
  }
}

.mainContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;

  @media screen and (max-width: 767px) {
    display: flex;
    gap: 25px;
    border-radius: 12px;
    background-color: transparent;
  }

  .scrollContainer {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

    &.maxHeight {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;

      @media screen and (max-width: 767px) {
        max-height: unset;
      }
    }

    @media screen and (max-width: 767px) {
      max-height: unset;
    }
  }

  .footerContainer {
    background-color: $veryLight;
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  .welcomeSubContainer {
    width: 100%;
    display: flex;
    padding: 30px 40px;
    background-color: $veryLight;
    flex-grow: 1;
    justify-content: center;
    gap: 20px;
    flex-direction: column;

    @media screen and (max-width: 767px) {
      padding: 0;
      gap: 25px;
      background-color: transparent;
      border-radius: 8px;
    }
  }

  .approvalMessageContainer {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    text-align: left;
    width: 100%;
    border-radius: 15px;
    flex-direction: column;
    .iconContainer {
      display: flex;
      gap: 10px;
      background-color: rgba($color: $helpTextColorRgb, $alpha: 0.8);
      width: 70px;
      justify-content: center;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      flex-shrink: 0;
      svg {
        height: 20px;
        width: 20px;
        margin-top: 15px;
        path {
          fill: $white;
        }
      }
      p {
        font-size: 14px;
        font-family: $circularBold;
        color: $primaryColor;
        margin: 0;
      }
    }
  }

  .messageContainer {
    display: flex;
    width: 100%;
  }

  .approvalContainer {
    display: flex;
    flex-direction: column;
    font-family: $circular;
    padding: 15px 25px;
    border: 2px solid rgba($color: $helpTextColorRgb, $alpha: 0.8);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    p {
      font-size: 14px;
      color: $black;
      margin-top: 10px;
      margin-bottom: 0;
    }
    span {
      font-size: 14px;
      color: $primaryColor;
      border-bottom: 1px solid rgba($color: $helpTextColorRgb, $alpha: 0.2);
      padding-bottom: 10px;
    }
  }
}
