@import "../../../../styles/variables.scss";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: $appPrimaryBackgroundColor;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  .headerContainer {
    padding: 20px;
    width: 100%;
    img {
      max-height: 60px;
      object-fit: contain;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
    }
  }
  .subContainer {
    display: flex;
    margin: 0 auto auto auto;
    flex-direction: column;
    max-width: 1000px;
    width: 100%;
    padding: 30px;
    &.maxWidth {
      max-width: 500px;
    }
    header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      padding: 30px 20px;
      color: $white;
      p {
        margin-bottom: 0;
        margin-left: 10px;
      }
      svg {
        width: 30px;
        height: auto;
        path {
          fill: $white;
        }
      }
    }
    .details {
      display: flex;
      background-color: $white;
      text-align: center;
      color: $black;
      letter-spacing: 0px;
      border-radius: 20px;
      h2 {
        font-family: $circularBold;
        font-weight: normal;
        margin: 20px 0;
        font-size: 36px;
        letter-spacing: -0.69px;
      }
      .profile {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin: 20px 0;
        img {
          width: 100%;
        }
      }
      .text {
        margin: 0 0 20px;
        span.bold {
          font-family: $circularBold;
        }
        a {
          color: $primaryColor;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .btn {
        margin-top: auto;
        margin-bottom: 0;
        font-size: 14px;
        cursor: pointer;
        width: 100%;
        background-color: transparent;
        color: $ctaColor;
        border: 1px solid $ctaColor;
        font-family: $circularBold;
        text-transform: uppercase;
        padding: 18px;
        border-radius: 50px;
        letter-spacing: 2.8px;
      }
      .poweredBy {
        margin-top: 30px;
        img {
          height: 60px;
          width: auto;
        }
        p {
          margin-top: 10px;
        }
      }
      .ackInfo {
        max-width: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }
    @media screen and (max-width: 1024px) {
      max-width: fit-content;
    }
    @media screen and (max-width: 767px) {
      max-width: unset;
      padding-bottom: 60px;
      padding-top: 0px;
    }
  }
}

.backgroundImage {
  background: url("../../../../img/ackBG.png");
}

.registerContainer {
  padding-left: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  &.borderTop {
    @media screen and (max-width: 1024px) {
      &::before {
        border-top: none;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    &::before {
      content: " ";
      display: block;
      width: 15%;
      margin: auto;
      border-top: 1px solid $grey;
      flex-grow: 0;
      margin-top: 20px;
    }
    padding-left: 0;
  }
  @media screen and (max-width: 767px) {
    &::before {
      border-top: none;
      margin-top: 0px;
    }
  }
}

.registerBox {
  border-right: 1px solid $greyColor;
  padding: 60px;
  width: 100%;
  &.borderRight {
    border-right: none;
    @media screen and (max-width: 767px) {
      border-right: none;
      border-bottom: none;
    }
  }
  @media screen and (max-width: 767px) {
    border-right: none;
    border-bottom: 1px solid $greyColor;
    padding: 30px;
  }
}

.getFundedFastContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 30px;
  letter-spacing: 0px;
  width: 100%;
  &.displayNone {
    display: none;
  }
  .getFundedBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;
  }
  .getFundedFooter {
    margin-top: auto;
  }
  .getFundedFastHeader {
    padding: 10px 0px;
    text-align: left;
    span {
      font-size: 18px;
      text-align: center;
      font-family: $circularBold;
    }
    p {
      font-size: 14px;
    }
  }

  .getFundedFastDetails {
    font-size: 14px;
    padding: 10px 0px;

    ol {
      width: 100%;
      min-width: 65%;
      padding-inline-start: 0px;
    }
    li {
      display: flex;
      padding: 8px 0;
      counter-increment: inst;
      text-align: left;
      cursor: text;
    }
    li::before {
      content: counter(inst);
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primaryColor;
      border-radius: 50%;
      width: 21px;
      height: 21px;
      color: #fff;
      margin-right: 5px;
      flex-shrink: 0;
    }
  }
  &.partnerFundContainer {
    display: none;
  }
}
