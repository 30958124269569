@import "../../styles/variables.scss";

.drawer {
  z-index: 4 !important;

  :global(.MuiPaper-root) {
    width: 100%;
    max-width: 525px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $white;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $sidebarPrimaryBackgroundColor;
    opacity: 0.9;
  }
}
