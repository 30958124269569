@import "../../../styles/variables.scss";

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.pageContainer {
  display: flex;
  flex: 1;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  max-width: 1000px;
  width: 100%;
  background-color: $white;
  text-align: center;
  border-radius: 12px;
  margin-right: 40px;
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 45px;
  @media screen and (max-width: 767px) {
    padding: 0px;
    margin-right: 0;
    background-color: $white;
  }
  &.fastContainer {
    background-color: $white;
    padding: 45px;
    border-radius: 20px;
    justify-content: flex-start;
    gap: 20px;
    @media screen and (max-width: 767px) {
      padding: 25px;
    }
  }
}
.mainContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  &.fastMainContainer {
    @media screen and (max-width: 767px) {
      min-height: calc(100vh - 133px);
    }
  }
  .scrollContainer {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    &.maxHeight {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      @media screen and (max-width: 767px) {
        max-height: unset;
      }
    }
    @media screen and (max-width: 767px) {
      max-height: unset;
    }
  }
  .footerContainer {
    background-color: $veryLight;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .footerBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    max-width: 100%;
    margin: 0 auto;
    background-color: $white;

    p {
      margin-bottom: 0px;
      font-size: 14px;
      font-family: $circularStd;
      padding-left: 25px;
    }
    .btnContainer {
      display: flex;
    }
    button {
      font-weight: bold;
      width: 250px;
      max-width: 280px;
      border-radius: 25px;
      height: 50px;
      background-color: transparent;
      text-align: center;
      color: $ctaColor;
      letter-spacing: 2.6px;
      font-size: 13px;
      @media screen and (max-width: 1024px) {
        height: 35px;
        padding: 3px 10px;
        width: unset;
      }
    }

    .secondaryBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid $ctaColor;
    }
    .primaryBtn {
      border: 1px solid $ctaColor;
      background-color: $ctaColor;
      color: $fundingCtaFontColor;
      margin-left: 15px;
      @media screen and (max-width: 1024px) {
        margin-left: 10px;
      }
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .customizeFundingContainer {
    width: 100%;
    display: flex;
    padding: 30px 40px;
    background-color: $veryLight;
    flex-grow: 1;
    justify-content: center;
    &.fastCustomizeFundingContainer {
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
      }
    }
    .networkTitle {
      display: none;
      flex-direction: column;
      background-color: $white;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 20px;
      font-size: 14px;
      @media screen and (max-width: 767px) {
        display: flex;
      }
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
    }
    @media screen and (max-width: 767px) {
      padding: 20px;
    }
  }
  .partnerFlowContainer {
    .titleContainer {
      border-radius: 0;
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        border-radius: 12px 12px 0 0;
        margin-bottom: 0;
        border-bottom: none;
        padding: 0 0 20px 0;
        .title {
          display: none;
        }
      }
    }
    .sliderContainer {
      @media screen and (max-width: 767px) {
        border-radius: 0 0 12px 12px;
        margin-bottom: 140px;
        padding: 0;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 221px);
    padding: 0px;
    max-height: unset;
    flex-grow: 1;
  }
  @media screen and (max-width: 529px) {
    min-height: calc(100vh - 167px);
  }
}

.title {
  display: flex;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 4px;
  }
}
.description {
  text-align: center;
  letter-spacing: -1.1px;
  padding: 0 0 21px;
  margin-top: 20px;
  p:first-child {
    font-size: 20px;
  }
}
.hLine {
  margin-top: -2px;
  border-bottom: 1px solid #e6e6e6;
}
.sliderContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0 15px;
  border-top: 1px solid $darkGreyColor;
  &.fastSliderContainer {
    padding: 25px 0 0 0;
    gap: 20px;
    @media screen and (max-width: 767px) {
      padding: 20px 0 0 0 !important;
      margin-bottom: 0 !important;
      gap: 20px;
    }
  }
  .mobPublishingToggle {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      min-height: 25px;
    }
  }
  .sliderData {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    p {
      @media screen and (max-width: 767px) {
        width: calc(100% - 13px);
        font-size: 14px;
      }
    }
    i {
      margin-left: 5px;
      @media screen and (max-width: 767px) {
        margin-left: auto;
      }
    }
    span {
      @media screen and (max-width: 767px) {
        font-size: 13px;
        padding: 3px 0px;
      }
    }
    .cashflowTerm {
      color: $black;
    }
  }
  .publisherRadios {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .radioContainer {
      display: flex;
      column-gap: 28px;
      font-size: 14px;
      padding: 0 !important;
      margin: 0 !important;
      & > div {
        flex-grow: 1;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 767px) {
      gap: 5px;
    }
  }
  @media screen and (max-width: 1366px) {
    padding: 10px 0 15px;
  }
  @media screen and (max-width: 767px) {
    padding: 25px 20px;
    margin-bottom: 240px;
  }
}
.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0 30px;
  &.fastTitleContainer {
    flex-grow: unset;
    padding: 0;
    margin: 0;
  }
  .fastSubTitle {
    margin: 20px 0 15px;
    color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.7);
    font-size: 14px;
    text-align: left;
    @media screen and (max-width: 767px) {
      margin: 10px 0 0;
      font-size: 12px;
    }
  }
  .mainHeader {
    display: flex;
    align-items: center;
    .headerText {
      display: flex;
      align-items: center;
      p {
        margin: 0 0 0 10px;
        font-family: $circularBold;
        font-size: 24px;
        text-align: left;
        @media screen and (max-width: 1366px) {
          font-size: 22px;
        }
        @media screen and (max-width: 1180px) {
          font-size: 20px;
        }
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      span {
        color: $primaryColor;
      }
    }
    .artistImage {
      width: 36px;
      height: 36px;
      z-index: 1;
      flex-shrink: 0;
      border-radius: 25px;
      @media screen and (max-width: 1366px) {
        width: 32px;
        height: 32px;
      }
      @media screen and (max-width: 767px) {
        width: 26px;
        height: 26px;
      }
    }
    .headerImages {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      padding: 8px;
      background-color: $primaryColor;
      border-radius: 25px;
      margin-left: -12px;
      z-index: 1;
      flex-shrink: 0;
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: $white;
        }
      }
      @media screen and (max-width: 1366px) {
        width: 32px;
        height: 32px;
      }
      @media screen and (max-width: 767px) {
        width: 26px;
        height: 26px;
        padding: 6px;
        margin-left: -7px;
      }
    }
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px 25px;
    margin-bottom: 20px;
    .title {
      display: none;
    }
  }
  .importantText {
    @media screen and (max-width: 767px) {
      color: $primaryColor;
    }
  }
  @media screen and (max-width: 767px) {
    border-radius: 0 0 12px 12px;
    border-bottom: none;
    .title {
      display: none;
    }
  }
}
.artistIncomeContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  font-size: 18px;
  font-family: $circularStd;
  &.fastArtistIncomeContainer {
    button.mobNextBtn {
      @media screen and (max-width: 1024px) {
        display: block;
      }
    }
    .finePrintContainer {
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
      padding: 0;
      border-top: none;
    }
  }
  .referenceText {
    font-size: 10px;
    text-align: left;
    color: rgba($helpTextColorRgb, 0.9);
  }
  .incomeTitle {
    display: flex;
    letter-spacing: 2.2px;
    font-size: 16px;
    .fastIncomeTitle {
      font-size: 12px;
      color: $primaryColor;
      letter-spacing: 1px;
      font-family: $circularSemiBold;
    }
    @media screen and (max-width: 1366px) {
      font-size: 11px;
    }
  }
  .incomeBoxContainer {
    display: flex;
    .incomeBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-right: 1px solid $darkGreyColor;
      justify-content: flex-start;
      padding: 0 3px;
      .incomePercentage {
        margin: 0px;
        font-size: 18px;
        font-family: $circularBold;
        color: $primaryColor;
      }
      .incomeLabel {
        margin: 0px;
        &.fastIncomeLabel {
          font-size: 14px;
        }
        @media screen and (max-width: 1366px) {
          font-size: 14px;
        }
      }
    }
    .lastBox {
      border: none;
    }
  }
  .finePrintContainer {
    display: flex;
    font-size: 12px;
    margin-bottom: 0px;
    justify-content: flex-end;
    gap: 10px;
    .mobNextBtn {
      display: none;
      padding: 10px 35px;
      background-color: $primaryColor;
      color: $white;
      font-size: 14px;
      font-family: $circularBold;
      border: none;
      width: 100%;
      border-radius: 2000px;
      text-transform: uppercase;
      @media screen and (max-width: 767px) {
        display: block;
      }
    }
    p & > span {
      text-decoration: underline;
      cursor: pointer;
    }
    .fastReadText {
      color: $primaryColor;
      margin: 0;
      text-decoration: underline;
      padding: 4px 0;
      display: none;
      margin-top: 10px;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        display: flex;
      }
      @media screen and (max-width: 767px) {
        display: flex;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding: 0;
    }
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.distributionPartners {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin: 0;
  text-align: left;
  .distributionTitle {
    display: flex;
    align-items: center;
    column-gap: 10px;
    svg {
      height: 20px;
      width: 20px;
      margin-left: -4px;
      path {
        fill: $primaryColor;
      }
    }
    h3 {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  p {
    margin: 0;
    font: 400 12px $circular;
    @media screen and (max-width: 767px) {
      font: 400 10px $circular;
    }
    button {
      background-color: transparent;
      border: none;
      text-decoration: underline;
      color: $primaryColor;
      font-size: 12px;
      padding: 0;
      text-transform: capitalize;
      cursor: pointer;
    }
  }
  .switchContainer {
    display: flex;
    align-items: center;
    gap: 15px;
    label {
      margin: 0;
      font-size: 14px;
      text-align: left;
    }
    input {
      display: none;
    }
  }
  .otherOfferContainer {
    display: flex;
    margin-top: 20px;
    p {
      margin: 0;
      font-size: 14px;
    }
    & .otherOfferLinkBtn {
      font-size: 14px;
    }
  }
}
.rangeThumb {
  background-color: $primaryColor;
}
.sliderBox {
  margin: 0;
}
.lastSliderBox {
  margin: 14px 0 30px;
}
.description p {
  text-align: center;
  margin-bottom: 10px;
  letter-spacing: -1px;
  line-height: 18px;
  color: $grey;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    padding: 0px;
  }
}
.contentname {
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.34px;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
    line-height: 16px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 16px;
  }
  & > * {
    white-space: nowrap;
    @media screen and (max-width: 1440px) {
      white-space: unset;
      text-align: left;
    }
    @media screen and (max-width: 1366px) {
      white-space: unset;
      text-align: left;
    }
    @media screen and (max-width: 767px) {
      white-space: unset;
      text-align: left;
    }
  }
  &.fastContentName {
    font-size: 14px;
    display: flex;
    .linkBtn {
      display: flex;
      align-items: center;
      background-color: transparent;
      color: $primaryColor;
      border: none;
      column-gap: 8px;
      padding: 4px 20px;
      text-transform: uppercase;
      text-decoration: underline;
      span {
        white-space: nowrap;
        font-family: $circularBold;
        letter-spacing: normal;
      }
      svg {
        width: 14px;
        height: 14px;
        padding: 3px;
        border-radius: 14px;
        background-color: $primaryColor;
        path {
          fill: $white;
        }
      }
    }
  }
  p {
    margin: 0;
  }
  span {
    color: $primaryColor;
    font-family: $circularBold;
    margin-left: auto;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
}
.title h1 {
  display: flex;
  align-items: center;
  font-family: $circularBold;
  color: $black;
  letter-spacing: 1.44px;
  font-size: 30px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  &.fastEstimateTitle {
    color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
    font-family: $circularSemiBold;
    letter-spacing: normal;
    font-size: 18px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 22px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 18px;
    letter-spacing: 0px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
    padding: 0;
  }
}

.youraccountCount {
  font-family: $circularStd;
  font-size: 34px;
  text-align: center;
  display: inline-block;
  margin-top: -4px;
  margin-left: -3px;
  letter-spacing: 0px;
  font-weight: lighter;
  sup {
    top: -0.3em;
    font-size: 23px;
  }
}

.totalStreams {
  position: absolute;
  left: 0;
  top: 0;
  padding: 19px 14px;
  background: #f1f1f1;
  text-align: center;
  @media screen and (max-width: 768px) {
    position: relative;
  }
  p {
    letter-spacing: -0.1px;
    line-height: 17px;
    font-weight: bold;
    margin: 0 0 11px;
  }
  button {
    display: block;
    padding: 7px 60px;
    width: 100%;
    line-height: 16px;
    background: $black;
    color: $white;
    border: none;
    letter-spacing: -0.9px;
    @media screen and (max-width: 768px) {
      margin: auto;
      max-width: 300px;
    }
  }
}
.subtitle {
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin: 15px 0;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1366px) {
    margin: 7px 0;
    font-size: 14px;
  }
}

.statsContainer {
  margin-top: 28px;
  .header {
    background-color: $light;
    color: $medium;
    font-weight: 500;
    letter-spacing: 0px;
    margin: 0;
    padding: 10px 20px;
    text-transform: uppercase;
  }
  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

.statsBox {
  padding: 9px;
  position: relative;
  border-top: 3px solid;
  width: 40.7%;
  margin-right: 7.7%;
  margin-top: 6px;
  float: left;
  margin-left: 1.5%;
  img {
    height: 55px;
    width: 55px;
    margin-right: 15px;
  }
  .details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.2px;
    margin: 0;
    padding: 5px 0;
    width: calc(100% - 90px);
    @media screen and (max-width: 767px) {
      letter-spacing: -0.4px;
      padding: 5px 0 0;
    }
  }
  p {
    margin: 0;
    line-height: 16px;
    font-size: 15px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0;
    border-top: 1px solid;
    padding: 9px 9px 17px;
  }
}

.statsBox:nth-child(2n + 3) {
  clear: both;
}
.nextContact {
  font-size: 19px;
  padding: 20px 0px 3px 0px;
  letter-spacing: 0px;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.fixedBtn {
  position: fixed;
  bottom: 70px;
  left: 0px;
  z-index: 4;
  width: 100%;
  background: #e7e7e7;
  padding: 0 0 30px 0;
  @media screen and (max-width: 767px) {
    position: static;
    padding: 30px 0;
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  .btnyouradvance:first-child {
    border: 1px solid $primaryColor;
    color: $primaryColor;
    background-color: $white;
    margin-right: 20px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .btnyouradvance {
    min-width: 220px;
    padding: 15px 30px;
    color: $white;
    background-color: $primaryColor;
    border: none;
    font-weight: 600;
    font-size: 22px;
    border-radius: 2px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.statsTitle {
  letter-spacing: -2px;
  margin-bottom: 2px;
}
.statsCount {
  font-size: 34px;
  letter-spacing: initial;
  margin-bottom: 0;
  padding: 0 0 42px;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    padding: 0 0 5px;
  }
}
.statsSource {
  position: absolute;
  right: 7px;
  top: 12px;
  padding: 4px 8px;
  border: 1px solid #f3f3f3;
  color: #000;
  border-radius: 3px;
  letter-spacing: -0.7px;
  @media screen and (max-width: 767px) {
    right: 9px;
    top: 10px;
  }
}
.statsSource img {
  width: 15px;
  height: 15px;
  margin: 0 6px 0 0;
}
.mainFooter {
  position: relative;
  display: block;
  clear: both;
  margin: 0;
  width: 95%;
  align-self: center;
  padding-bottom: 240px;
  @media screen and (max-width: 767px) {
    margin: 0;
    width: 100%;
    padding-bottom: 10px;
  }

  .topFooter {
    background-color: #f1f1f1;
    padding: 30px;
  }
  .footerContent {
    padding-top: 22px;
    letter-spacing: 1px;
    line-height: 18px;
    margin: auto;
  }

  .income {
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    p {
      font-size: 16px;
      margin: 10px 3px;
      color: black;
      text-align: center;
    }
  }
  .complete {
    text-align: center;
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
    .FLine {
      text-transform: uppercase;
      color: $btnDeactivatedText;
      font-weight: 600;
      letter-spacing: 2px;
      @media screen and (max-width: 767px) {
        margin: auto;
        width: 100%;
        min-width: fit-content;
      }
    }
  }
  .term {
    text-align: center;
    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
    .FLine {
      letter-spacing: 2px;
      color: $btnDeactivatedText;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  .termEnd {
    text-align: center;
    @media screen and (max-width: 767px) {
      width: 100%;
      text-align: center;
    }
    .FLine {
      letter-spacing: 2px;
      color: $btnDeactivatedText;
      text-transform: uppercase;
      margin-left: auto;
      font-weight: bold;
      @media screen and (max-width: 767px) {
        margin: auto;
        width: 100%;
        min-width: fit-content;
      }
    }
  }
  h1 {
    font-size: 31px;
    display: inline-block;
    font-weight: 900;
    width: 100%;
    text-align: center;
    letter-spacing: -0.8px;
  }

  .Box {
    width: 50%;
    vertical-align: top;
    display: inline-block;
    text-align: left;
  }
  .footerContentTerm {
    display: inline-block;
    max-width: 30%;
  }
  .footerContentEnd {
    display: inline-block;
    max-width: 30%;
    text-align: right;
  }
  h4 {
    padding: 2px 14px 9px;
  }
  .footerBoxContent {
    padding: 0 0 0 45px;
    line-height: 22px;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .footerBox ul {
    list-style: none;
    padding: 0;
  }
  .footerBox > ul {
    padding: 0 10px 0 0px;
  }
  .footerBox > ul > li > label {
    background: #000;
  }
  .footerBox li {
    padding: 8px 0px;

    label {
      padding: 7px 21px 7px 15px;
      margin: 0;
      width: 100%;
      span {
        letter-spacing: 1.2px;
      }
    }
    .leftItem {
      float: left;
    }
    .rightItem {
      float: right;
    }
  }
  .footerBox > ul > li {
    padding: 8px 0 21px;
  }
  .footerBox > ul > li > ul {
    padding-top: 2px;
  }
  .footerBox > ul > li > ul > li {
    padding: 0 20px;
  }
  .footerBox > ul > li > ul > li > label {
    padding: 0;
  }
  .footerBox > ul > li > ul > li > label span {
    letter-spacing: 0;
  }
  .footerBox > ul > li > ul > li > ul {
    padding: 0px 0 11px 10px;
  }
  .footerBox > ul > li > ul > li > ul > li {
    padding: 0;
    line-height: 22px;
  }
  .footerBox > ul > li > ul > li > ul > li > label {
    padding: 0;
  }
  .footerBox > ul > li > label > span.leftItem {
    letter-spacing: 1.4px;
  }
  .footerBox > ul > li > label > span.rightItem {
    letter-spacing: 0.5px;
  }
}

.modalTopBorder {
  border-top: 5px solid $black;
}

.dataReviewContainer {
  .title {
    align-self: center;
    margin: 10px auto 0 auto;
  }
}
.welcomeModal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: $white;
  margin: 0px auto;
  align-items: center;
  .contactSubText {
    display: block;
    color: $helpTextColor;
    font-size: 80%;
    text-align: center;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }
  .dataReviewSubHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    p {
      width: 100%;
      text-align: left;
      padding: 10px 0 20px;
      font-size: 18px;
      letter-spacing: 0.96px;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
        letter-spacing: 0.76px;
        line-height: 1.4;
        padding: 10px 0 10px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 14px;
        letter-spacing: 0.76px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    .buttonContainer {
      display: flex;
      button {
        margin: 10px;
        font-weight: bold;
        flex-shrink: 0;
        flex-grow: 1;
        max-width: 280px;
        border-radius: 2px;
        border: 1px solid $ctaColor;
        height: 50px;
        text-align: center;
        background-color: transparent;
        color: $ctaColor;
        &.secondary {
          border: none;
          &:hover {
            text-decoration: underline;
          }
        }
        &.primary {
          background-color: transparent;
        }
        &.demoBtn {
          background-color: $primaryColor;
          font-family: $circularBold;
          font-size: 18px;
          max-width: fit-content;
          color: $white;
          border: none;
          border-radius: 4px;
          padding: 12px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 40px;
            margin-right: -18px;
            stroke: $white;
            @media screen and (max-width: 767px) {
              width: 35px;
              margin-right: -10px;
            }
          }
          @media screen and (max-width: 1024px) {
            font-size: 17px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            font-size: 16px;
            padding: 10px 15px;
          }
        }
        @media screen and (max-width: 1440px) {
          height: 40px;
          max-width: 200px;
        }
      }
    }
  }
  .dataTuneButton {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 10px 0;
    background: $white;
    border-top: 1px solid $light;
    @media screen and (max-width: 1440px) {
      padding: 0;
    }
    .buttonContainer {
      justify-content: center;
      align-items: center;
      width: 100%;
      button {
        margin-top: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: 1440px) {
          margin-top: 12px;
          margin-bottom: 12px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      border-top: 1px solid $darkGreyColor;
    }
  }
  .dataReviewSecondHeading {
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $white;
    table {
      text-align: center !important;
      border-top: 1px solid $light;
      padding: 30px 43px;
      overflow: hidden;
      width: 100%;
      font-size: 18px;
      tr {
        > td:first-child {
          border-right: 1px solid $darkGreyColor;
          display: flex;
          flex-direction: column;
          align-items: center;
          @media screen and (max-width: 767px) {
            flex-direction: row;
            border-right: none;
            border-bottom: 1px solid $darkGreyColor;
            padding-bottom: 5px;
          }
        }
        > td.notLookRight {
          max-width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: 767px) {
            max-width: unset;
            justify-content: flex-start;
            padding-bottom: 12px;
            margin-top: -5px;
          }
        }
        height: 85px;
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: 1440px) {
          height: 80px;
        }
        @media screen and (max-width: 1366px) {
          height: 80px;
        }
        @media screen and (max-width: 1024px) {
          height: 96px;
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
          height: unset;
        }
      }
      @media screen and (max-width: 767px) {
        border-top: 1px solid $darkGreyColor;
        background-color: $veryLight;
      }
    }
    @media screen and (max-width: 1440px) {
      bottom: 62px;
    }
    @media screen and (max-width: 1366px) {
      bottom: 62px;
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      bottom: 62px;
    }
  }
  .modalHead {
    padding: 30px 43px;
  }
  .modalBody {
    display: flex;
    flex-direction: column;
    width: 100%;

    .artistImage {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 30px;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      img {
        width: 100%;
        height: auto;
      }
      svg {
        height: 100%;
        width: 100%;
      }
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-height: unset;
      min-height: unset;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 0px;
    }
    &::-webkit-scrollbar-track {
      background: $white;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($color: $dark, $alpha: 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($color: $dark, $alpha: 0.4);
    }
  }
  .lastModalRow td {
    margin: 14px 10px;
    flex: 1;
    &.dataDisplay {
      p p {
        color: $primaryColor !important;
        font-family: $circularBold;
      }
    }
    > p:first-child,
    label {
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.48px;
      .contactSubText {
        display: block;
        position: absolute;
        font-size: 80%;
        font-weight: normal;
        text-decoration: underline;
        cursor: pointer;
      }
      @media screen and (max-width: 1024px) {
        font-size: 11px;
      }
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    > p:nth-child(2),
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      margin: auto;
      max-width: 400px;
      margin-top: 2px;
      color: $primaryColor;
      font-family: $circularBold;
      & > div {
        font-size: 20px;
        @media screen and (max-width: 1440px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1366px) {
          font-size: 16px;
        }
        @media screen and (max-width: 767px) {
          margin-top: -15px;
        }
      }
      p {
        font-size: 16px;
        color: $black;
        @media screen and (max-width: 1440px) {
          font-size: 14px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      @media screen and (max-width: 1440px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 18px;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
        max-width: unset;
        margin: 0 10px;
      }
    }
    @media screen and (max-width: 1440px) {
      margin: 10px 6px;
    }
    @media screen and (max-width: 1024px) {
      margin: 10px 6px;
    }
    @media screen and (max-width: 767px) {
      margin: 8px 15px;
      &.dataDisplay {
        display: flex;
        align-items: center;
        p {
          text-align: left;
          p {
            display: block;
          }
        }
      }
    }
  }
  .soundExchange {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0px;
      font-family: $circularStd;
    }
  }
  td .soundExchange {
    p {
      font-size: 18px;
    }
  }
  .soundExchangeInputContainer {
    display: flex;
    justify-content: center;
    p {
      font-weight: normal;
      margin-right: 5px;
    }
  }

  .dataReviewSecondTable {
    margin-top: 10px;
    width: 100%;
    align-self: center;
    margin-bottom: 185px;
  }
  .tuneDataSecondTable {
    margin-top: 10px;
    width: 100%;
    align-self: center;
    margin-bottom: 175px;
    @media screen and (max-width: 767px) {
      margin-bottom: 250px;
    }
  }
  h4,
  h3 {
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.86px;
    color: $primaryColor;
    line-height: 26px;
  }
  h4 {
    font-size: 26px;
  }
  h3 {
    font-size: 31px;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }
  p {
    margin: 0;
  }
}

.tuneDataModal {
  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }

  .modalBtnContainer {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    button.primary {
      margin: 0;
      color: $white;
    }
    button {
      color: $dark;
    }
  }
}

.tuneDataModal .lastTable {
  @media screen and (max-width: 1024px) {
    tr:first-child {
      display: none;
    }
    tr:last-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      td {
        max-width: 500px;
        width: 100%;
        padding: 20px 10px;
        border-bottom: 1px solid $light;
        > * {
          text-align: center;
          align-items: center;
        }
      }
    }
  }
}

.tuneDataModal table tr {
  &.lastRow td {
    font-weight: 600;
    vertical-align: top;
    padding-bottom: 20px;
  }
  &:last-child > * {
    text-align: center;
    font-weight: normal;
    vertical-align: middle;
    padding-bottom: 5px;
    &:first-child {
      text-align: center;
    }
  }
  .linkText {
    letter-spacing: -0.34px;
    text-decoration: underline;
    color: $red;
    cursor: pointer;
    font-weight: normal;
  }
  @media screen and (max-width: 1024px) {
    td {
      padding: 20px 10px;
    }
  }
}

.fundingSave {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 44px;
  z-index: 4;
  left: 0;
  right: 0;
  padding: 10px 0;
  background: $white;
  border-top: 1px solid $darkGreyColor;
  &.atBottom {
    bottom: 0;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    button {
      font-weight: bold;
      width: 280px;
      max-width: 280px;
      border-radius: 2px;
      height: 50px;
      background-color: transparent;
      text-align: center;
      margin: 20px 5px;
      color: $ctaColor;
      &.secondary {
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &.primary {
        border: 1px solid $ctaColor;
      }
      &.longBtn {
        width: unset;
        max-width: unset;
      }
      &.demoBtn {
        background-color: $primaryColor;
        font-family: $circularBold;
        font-size: 18px;
        max-width: fit-content;
        width: unset;
        color: $white;
        border: none;
        border-radius: 4px;
        padding: 12px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 40px;
          margin-right: -18px;
          stroke: $white;
          @media screen and (max-width: 767px) {
            width: 35px;
            margin-right: -10px;
          }
        }
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          font-size: 14px;
          padding: 10px 15px;
        }
      }
      @media screen and (max-width: 1440px) {
        margin: 15px 5px;
      }
      @media screen and (max-width: 767px) {
        margin: 10px 5px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    padding: 0;
    box-shadow: unset;
  }
}

.readBtn {
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  right: 0;
  padding: 10px 0;
  color: $alertFontColor;
  background: $alertBackgroundColor;
  margin: 0;
  @media screen and (max-width: 767px) {
    position: fixed;
    margin-bottom: 0;
    box-shadow: unset;
  }
}

.footerText {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  color: $primaryColor;
  z-index: 4;
  height: 350px;
  overflow: hidden;
  transition: height 0.3s ease;
  box-shadow: 0px -5px 20px 0px #00000033;
  border-radius: 30px 30px 0px 0px;

  @media screen and (max-width: 1024px) {
    height: 450px;
  }
  .close {
    align-self: flex-end;
    margin-right: 30px;
    font-size: 26px;
    cursor: pointer;
    padding: 10px;
    line-height: 20px;
    @media screen and (max-width: 767px) {
      margin-right: 20px;
    }
  }
  & > p {
    text-align: center;
    letter-spacing: -0.79px;
    line-height: 21px;
    font-weight: 400;
    font-size: 18px;
    width: 100%;
    max-width: 800px;
    padding: 20px;
    margin: 0;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 14px;
    }
  }
  .whitelabelFinePrint {
    height: 200px;
    overflow: auto;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    box-shadow: unset;
  }
}

.footerHide {
  height: 0;
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 6;
}

.modalPublishingOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}

.moreFundingmodalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.9
  );
  z-index: 6;
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  font-weight: normal;
  text-align: left;
  background-color: $tooltipBackgroundColor !important;
  color: $tooltipFontColor !important;
  box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.3);
  border-radius: 5px !important;
  white-space: break-spaces;
  &::after {
    border: transparent !important;
    background-color: $tooltipBackgroundColor !important;
    box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.3);
  }
  &:global(.show) {
    opacity: 1 !important;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
  }
  a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}

.smallModalContainer {
  display: flex;
  flex: auto;
  flex-direction: column;
}

.smallModalBody {
  background-color: $white;
  border: 3px solid $primaryColor;
  padding: 50px;
  max-width: 700px;
  text-align: left;
  width: 90%;
  margin: auto;
  margin-bottom: 70px;
  p {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .link {
    cursor: pointer;
    color: $primaryColor;
    text-decoration: underline;
  }
  a {
    text-decoration: underline;
  }
  .btn {
    background-color: $primaryColor;
    color: $white;
    padding: 10px 20px;
    border: none;
    margin: auto;
    display: block;
  }
  @media screen and (max-width: 767px) {
    margin: 20px auto;
    padding: 20px;
    text-align: left;
    width: auto;
  }
}

// contact beatbread to get funded page style start
.contactMainContainer {
  background-color: $white;
  display: flex;
  width: 100%;
  margin-top: 0px;
  position: relative;
}
.contactContainer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  overflow-y: auto;
  text-align: center;
  padding-bottom: 16%;
  @media (min-width: 320px) {
    width: 90%;
    padding-top: 20px;
  }
  @media (min-width: 639px) {
    max-width: 350px;
  }
  @media (min-width: 768px) {
    padding-top: 56px;
    max-width: 490px;
  }
  @media (min-width: 992px) {
    max-width: 570px;
    padding-top: 56px;
  }
  @media (min-width: 1024px) {
    max-width: 685px;
    padding-top: 56px;
  }
  @media (min-width: 1200px) {
    max-width: 980px;
    padding-top: 56px;
  }
}
.contactHeader {
  font-weight: 600;
  line-height: 48px;
  font-size: 48px;
  font-family: $circular;
  letter-spacing: -2.5px;
  padding: 20px 0 0 0 !important;
  color: $primaryColor;
  @media (min-width: 768px) {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 992px) {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    max-width: 485px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    max-width: 677px;
    margin-left: auto;
    margin-right: auto;
  }
}

.contactText {
  line-height: 31.5px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.875px;
  color: $black;
  margin-top: 12px;
  margin-bottom: 15px;
  @media (min-width: 768px) {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 992px) {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    max-width: 485px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    max-width: 677px;
    margin-left: auto;
    margin-right: auto;
  }
}
.buttonLine {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  @media (min-width: 768px) {
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 992px) {
    max-width: 470px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1024px) {
    max-width: 485px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    max-width: 677px;
    margin-left: auto;
    margin-right: auto;
  }
}
.noteItem {
  @media (max-width: 1198px) {
    margin-top: 40px;
  }
  line-height: 31.5px;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.875px;
  color: $black;
  padding: 0 8px;
}
.scheduleBtn {
  height: 61px;
  border-radius: 2px;
  background-color: $primaryColor;
  font-weight: 600;
  font-size: 18px;
  color: $white;
  line-height: 60px;
  letter-spacing: -0.34px;
  border: none;
  padding-left: 6px;
  padding-right: 7px;
  width: 265px;
  @media (max-width: 992px) {
    font-size: 14px;
  }
}
.goBackBtn {
  height: 61px;
  width: 265px;
  border-radius: 2px;
  background-color: #7b7b7b;
  font-weight: 600;
  font-size: 18px;
  color: $white;
  line-height: 60px;
  letter-spacing: -0.34px;
  border: none;
  padding-left: 6px;
  padding-right: 7px;
  @media (max-width: 992px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
}
.stepRow {
  margin-top: 64px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  justify-content: space-around;
  @media screen and (max-width: 511px) {
    flex-direction: column;
  }
  svg {
    width: 65px;
    height: 65px;
    display: block;
    margin: 0px auto;
    position: relative;
    top: -10px;
    path {
      fill: $primaryColor;
    }
  }

  svg g {
    fill: $primaryColor;
  }
}

.stepsSection {
  margin-top: 32px;
  margin-bottom: 12px;
  @media (min-width: 1200px) {
    padding: 0 88px;
  }
}

.scheduleBtn:hover {
  color: $white;
}
.main_success {
  display: table;
  width: 100%;
}
.success {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  background-color: $white;
  visibility: visible !important;
  height: 100vh;
  text-align: center;
  h1 {
    font-size: 65px;
    font-family: $circular;
    font-weight: bold;
    color: $primaryColor;
  }
  p {
    clear: both;
    font-weight: bold;
  }
}
.goBackLink {
  color: $primaryColor;
  font-weight: 600;
  text-decoration: underline;
}
.goBackLink:hover {
  text-decoration: underline;
}

.reactRangeLastMonth {
  width: 100%;
  min-width: 120px;
  padding: 0 15px;
  height: 28px;
  div {
    font-size: 16px;
    & > div > div {
      transform: translate(0, 22px);
    }
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}
.valueContainer {
  display: none;
}

.flex {
  display: flex;
  align-items: center;
  .infoIcon {
    align-items: center;
    background-color: $primaryColor;
    border: none;
    border-radius: 50%;
    color: $white;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    font-style: normal;
    height: 17px;
    justify-content: center;
    margin-left: 15px;
    width: 17px;
    &::after {
      content: "?";
      font-family: Poppins, sans-serif;
      font-size: 12px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 12px;
      position: relative;
      text-transform: lowercase;
      top: 1px;
    }
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $tooltipFontColor;
  border-radius: 50%;
  cursor: pointer;
  background-color: $tooltipBackgroundColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $alertFontColor;
    color: $alertBackgroundColor;
    margin-right: 15px;
  }
  &.disabled {
    opacity: 0.4;
  }
  &.customizedFunding {
    margin-left: 5px;
  }
  @media screen and (max-width: 1366px) {
    height: 13px;
    width: 13px;
  }
}

.fastInfoIcon {
  background-color: $sidebarTooltipIconColor;
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "?";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

.artistCollapse {
  position: fixed;
  right: 0;
  top: 130px;
  padding: 20px 20px 20px 30px;
  background-color: $primaryColor;
  text-align: left;
  color: $white;
  border-radius: 30px 0 0 30px;
  transition: padding 0.3s;
  margin-left: 20px;
  z-index: 8;
  box-shadow: 0 2px 12px -7px $black;
  .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    width: 40px;
    background-color: $white;
    border: 2px solid $primaryColor;
    border-radius: 50%;
    cursor: pointer;
    transform: translate(-40%, -40%);
    &::after {
      content: "";
      display: block;
      height: 12px;
      width: 12px;
      border-right: 2px solid $black;
      border-bottom: 2px solid $black;
      transform: translateY(-2px) rotate(45deg);
      transition: transform 0.3s;
      @media screen and (max-width: 767px) {
        height: 10px;
        width: 10px;
      }
    }
    @media screen and (max-width: 767px) {
      height: 30px;
      width: 30px;
      transform: translate(-30%, -30%);
    }
  }
  .scrollContainer {
    max-height: calc(100vh - 170px);
    overflow-y: auto;
    @media screen and (max-width: 1440px) {
      max-height: calc(100vh - 110px);
    }
  }
  .collapseTitle {
    opacity: 0.6;
    margin-bottom: 0;
    font-family: $circularBold;
    transition:
      font-size 0.3s,
      margin 0.3s;
    &.activeGuide {
      opacity: 1;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  &.open {
    padding-right: 50px;
    .arrow::after {
      transform: translateY(2px) rotate(225deg);
    }
    .collapseTitle {
      font-size: 24px;
      margin-bottom: 12px;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    @media screen and (max-width: 1366px) {
      padding-right: 30px;
    }
    @media screen and (max-width: 767px) {
      padding-right: 25px;
    }
  }
  .collapseContent {
    margin-bottom: 20px;
    &.inactiveGuide {
      opacity: 0.6;
    }
    :global {
      .getFundedContainer {
        min-width: 440px;
        max-height: calc(100vh - 240px);
        overflow-y: auto;
        & > div {
          background-color: transparent;
          & > div {
            margin: 0;
            border-radius: 20px;
            min-height: unset;
            @media screen and (max-width: 767px) {
              min-width: unset;
              width: 100%;
            }
            & > div {
              padding: 0;
            }
          }
        }
        button[type="submit"] {
          color: $white;
          border: none;
        }
        iframe {
          border: none;
          width: 100%;
          height: 549px;
          border-radius: 20px;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          min-height: unset;
          min-width: unset;
          overflow-x: hidden;
          padding: 5px;
        }
      }
    }
  }
  &.maxW {
    max-width: 500px;
    padding-right: 0px;
    @media screen and (max-width: 1750px) {
      max-width: 400px;
    }
  }
  @media screen and (max-width: 1440px) {
    top: 80px;
    padding: 15px 10px 15px 25px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 10px 10px 20px;
    max-width: 96%;
    top: 145px;
  }
}

.AdjustModal {
  display: flex;
  white-space: break-spaces;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 12px;
  &:focus {
    outline: none;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 8px;
    font-size: 26px;
    cursor: pointer;
    line-height: 26px;
  }
  .buttonContainer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    button {
      font-weight: bold;
      width: 200px;
      max-width: 250px;
      border-radius: 25px;
      height: 45px;
      background-color: $ctaColor;
      text-align: center;
      color: $fundingCtaFontColor;
      letter-spacing: 2.6px;
      font-size: 16px;
      border: 0;
      outline: none;
      @media screen and (max-width: 1024px) {
        height: 35px;
        padding: 3px 10px;
        width: unset;
      }
    }
  }
}

.artistModalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}

.cashflowDiv {
  .radioGroup {
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 5px;
    .radio {
      margin: 0;
      border: 1px solid $grey;
      border-radius: 18px;
      padding: 10px;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      :global {
        .PrivateSwitchBase-root-1 {
          padding: 0;
        }
        .MuiRadio-colorSecondary.Mui-checked {
          color: $primaryColor;
        }
      }
      &.active {
        background-color: rgba($primaryColorRgb, $alpha: 0.1);
        border-color: $primaryColor;
      }
    }

    @media screen and (max-width: 1450px) {
      flex-direction: column;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: row;
    }
    @media screen and (max-width: 970px) {
      flex-direction: column;
    }
    @media screen and (max-width: 768px) {
      flex-direction: row;
    }
    @media screen and (max-width: 560px) {
      flex-direction: column;
    }
  }
}

.mainDiv {
  font-family: $circularStd;
  text-align: left;
  p {
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    span {
      font-family: $circularBold;
    }
    i {
      margin-left: 5px;
    }
    @media screen and (max-width: 1366px) {
      align-items: center;
    }
  }
  .option {
    font-size: 14px;
  }
}

.cashflow {
  margin: 60px 0 30px;
}

.publishingPopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 770px;
  width: 90%;
  outline: none;
  padding: 40px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  .offerContainer {
    display: flex;
    flex-direction: column;
    .closeBtn {
      position: absolute;
      top: 32px;
      right: 30px;
      font-size: 28px;
      line-height: 18px;
      padding: 10px;
      cursor: pointer;
      border: none;
      background-color: transparent;
      color: $primaryColor;
    }
    .titleContainer {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba($color: $primaryColorRgb, $alpha: 0.2);
      h2 {
        font-size: 30px;
        font-family: $circularBold;
        color: $primaryColor;
        margin-bottom: 0;
        letter-spacing: 0;
      }
      p {
        font-size: 14px;
        font-family: $circular;
        margin-bottom: 0;
      }
    }
    .firstContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 50%;
      }

      .firstImage {
        width: 62px;
        height: 62px;
        margin-right: -20px;
      }
      .secondImage {
        width: 90px;
        height: 90px;
        z-index: 5;
      }
      .thirdImage {
        width: 62px;
        height: 62px;
        margin-left: -20px;
      }
    }
    .secondContainer {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      font-size: 18px;
      padding: 5px 10px;
      text-align: center;
      .firstHead {
        color: $primaryColor;
        font-family: $circularBold;
      }
      .secondHead {
        color: $black;
      }
    }
    .thirdContainer {
      display: flex;
      font-size: 14px;
      padding: 15px 0px;

      .first {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          color: $white;
          background-color: $primaryColor;
          border-radius: 50%;
          padding: 2px 10px;
          margin-bottom: 0px;
        }
        span {
          width: 81%;
          text-align: center;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        .first {
          flex-direction: row;
          padding: 5px 0px;
          span {
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }

    .fourthContainer {
      color: $primaryColor;
      letter-spacing: 2.8px;
      font-family: $circularBold;
      display: flex;
      justify-content: flex-end;
      padding-right: 30px;
      cursor: pointer;
    }
    .publishingForm {
      display: flex;
      flex-direction: column;
      padding: 30px 0 0;
      :global(.form-group .mb-4) {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        margin-bottom: 0 !important;
        & > div:first-child {
          order: 2;
        }
        input {
          order: 2;
          padding: 2px 0;
          &::placeholder {
            color: rgba($color: $primaryColorRgb, $alpha: 0.4);
          }
        }
        :global(.invalid-feedback) {
          bottom: 0;
          transform: translateY(100%);
        }
        label {
          order: 1;
          position: relative;
          transform: translate(0);
          color: #000;
          font-size: 14px;
          margin: 0;
          pointer-events: auto;
          text-transform: unset;
        }
      }
      button {
        background-color: $ctaColor;
        color: $white;
        border: none;
        padding: 10px 15px;
        text-transform: uppercase;
        border-radius: 30px;
        font-family: $circularBold;
        margin-top: 20px;
      }
      .helperLabel {
        color: $primaryColor;
        font-size: 10px;
        margin: 5px 0;
        order: 2;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 25px;
  }
}

.detailsPopupContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 155px 0 90px;
  margin: auto;
  max-width: 770px;
  width: 90%;
  outline: none;
  * {
    box-sizing: border-box;
  }
  .offerContainer {
    display: flex;
    flex-direction: column;
    outline: none;
    padding: 35px;
    background-color: $white;
    position: relative;
    box-shadow: 0px 10px 51px 0px rgba($color: $black, $alpha: 0.33);
    border-radius: 15px;
    .closeBtn {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      line-height: 18px;
      padding: 10px;
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
    .secondContainer {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      row-gap: 20px;
      .firstHead {
        color: $primaryColor;
        font-weight: 700;
      }
      .secondHead {
        color: $black;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .thirdContainer {
      display: flex;
      justify-content: space-evenly;
      font-size: 14px;
      padding: 20px 0 0;
      gap: 25px;
      flex-wrap: wrap;
      .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 25px 30px;
        max-width: 185px;
        min-height: 160px;
        border: 1px solid $primaryColor;
        border-radius: 6px;
        row-gap: 15px;
        box-shadow: 0 2px 6px -2px rgba($color: $black, $alpha: 0.5);
        svg {
          width: 100%;
          @media screen and (max-width: 767px) {
            max-width: 140px;
            margin: auto;
          }
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          background-color: $primaryColor;
          padding: 5px 10px;
          border-radius: 20px;
          font-weight: 700;
          font-size: 10px;
          text-decoration: none;
          text-transform: uppercase;
          column-gap: 3px;
          white-space: nowrap;
          svg {
            height: 12px;
            width: 12px;
            transform: rotateZ(-90deg);
            @media screen and (max-width: 767px) {
              margin: 0;
            }
          }
        }
        @media screen and (max-width: 767px) {
          max-width: unset;
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    .fourthContainer {
      margin-right: 30px;
      color: $tooltipFontColor;
      letter-spacing: 2.8px;
      font-weight: 700;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
    }
    .finePrintText {
      font-size: 14px;
      text-align: center;
    }
  }
  @media screen and (max-width: 989px) {
    padding: 90px 0;
  }
}

.rejectPopupContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 155px 0 90px;
  margin: auto;
  max-width: 770px;
  width: 90%;
  outline: none;
  :global(#ss_survey_widget) {
    margin: auto;
  }
  * {
    box-sizing: border-box;
  }
  .mainContainer {
    display: flex;
    flex-direction: column;
    outline: none;
    padding: 35px;
    background-color: $white;
    position: relative;
    box-shadow: 0px 10px 51px 0px rgba($color: $black, $alpha: 0.33);
    border-radius: 15px;
    min-height: 500px;
    .headerContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px;
    }
    .titleCtn {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .heading {
        font-family: $circularBold;
        color: $primaryColor;
        margin-bottom: 0;
        font-size: 14px;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      .subHeading {
        font-family: $circularStd;
        margin-bottom: 0;
        font-size: 16px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
    .closeBtn {
      font-size: 24px;
      line-height: 18px;
      padding: 10px;
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
    .surveyContainer {
      display: flex;
      flex-direction: column;
      font-size: 20px;
      row-gap: 20px;
    }
    @media screen and (max-width: 767px) {
      max-height: 350px;
      padding: 25px;
    }
  }
  @media screen and (max-width: 989px) {
    padding: 90px 0;
  }
}

.networkTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  p {
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 18px;
    gap: 15px;
    &.AdvanceEstimate {
      color: #5849a6;
      display: inline;
      font-family: $circular;
      font-weight: 600;
      .funding {
        color: black;
        font-weight: 500;
      }
    }
    &:last-child {
      flex-shrink: 0;
      @media screen and (max-width: 989px) {
        flex-shrink: 1;
      }
    }
    svg {
      max-width: 100px;
      max-height: 50px;
      width: 100%;
      @media screen and (max-width: 1200px) {
        max-width: 80px;
      }
    }
    @media screen and (max-width: 1200px) {
      font-size: 16px;
    }
  }
}
