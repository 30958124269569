@import "../../../../styles/variables.scss";

@mixin box-with-border {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid $helpTextColor;
  min-height: 140px;
}

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .pageContainer {
      display: flex;
      flex: 1;
      width: 100%;
      background-color: $dark;
      .mainContainer {
        background: $veryLight;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        margin-top: 0px;
        position: relative;
        .scrollContainer {
          height: 100%;
          max-height: calc(100vh - 100px);
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          @media screen and (max-width: 1440px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 1366px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 767px) {
            padding: 20px;
            max-height: unset;
          }
        }
        @media screen and (max-width: 767px) {
          flex-grow: 1;
          padding: 0px 10px 10px 10px;
          max-height: unset;
        }
        @media screen and (max-width: 529px) {
          padding: 0;
          min-height: calc(100vh - 167px);
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        max-width: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1145px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background-color: $white;
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.1);
  .titleHeader {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 20px;
    .titleContainer {
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        h3 {
          font-size: 18px;
          font-family: $circularBold;
          margin-bottom: 0;
        }
      }
      p {
        font-size: 14px;
        margin: 0;
      }
      @media screen and (max-width: 767px) {
        align-items: center;
        text-align: center;
      }
    }
    .helpText {
      display: block;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    @media screen and (max-width: 767px) {
      background-color: $white;
      box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.1);
      padding: 20px;
      border-radius: 12px;
    }
  }
  .helpText {
    display: none;
    padding: 14px;
    border-radius: 12px;
    background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
    color: $helpTextColor;
    font-size: 14px;
    margin-left: 10px;
    max-width: 360px;
    p {
      margin: 0;
    }
    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-bottom: 20px;
      max-width: unset;
      width: 100%;
      text-align: center;
      display: block;
    }
  }
  .sliderOfferContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .targetOfferContainer {
      display: flex;
      .targetOffer {
        @include box-with-border;
        flex-grow: 2;
        border-radius: 12px 0 0 12px;
        .amountHeadContainer {
          display: flex;
          flex-direction: column;
          .amountTitle {
            display: flex;
            align-items: center;
            justify-content: center;
            p {
              margin: 0;
              font-family: $circularBold;
              color: $sidebarPrimaryBackgroundColor;
              font-size: 22px;
              line-height: 20px;
            }
          }
          .marketPlaceAmount {
            font-size: 34px;
            font-family: $circularBold;
            color: $sidebarPrimaryBackgroundColor;
            div {
              font-family: $circularBold;
            }
          }
        }
        .sliderContainer {
          margin-bottom: 65px;
          .reactRange {
            align-items: center;
            & > span {
              font-size: 14px;
              margin-right: 10px;
              &:last-child {
                margin-left: 10px;
                margin-right: 0;
              }
            }
            :global(.range-thumb) {
              background-color: $white;
              border: 3px solid $helpTextColor;
            }
          }
          .sliderBackground {
            background: linear-gradient(
              90deg,
              rgba($marketplaceGreen, 0.6),
              rgba($marketplaceYellow, 0.6),
              rgba($marketplaceOrange, 0.6),
              rgba($marketplaceRed, 0.6),
              rgba($marketplaceDarkRed, 0.6)
            );
            background-color: rgba($color: $black, $alpha: 0.2);
          }
          .customThumb {
            position: absolute;
            text-align: center;
            max-width: 162px;
            width: max-content;
            font-size: 14px;
            transform: translate(0, 25px);
            padding: 5px;
            border: 2px solid;
            border-radius: 5px;
            &.guaranteed,
            &.pointOne {
              border-color: $marketplaceGreen;
              span {
                color: $marketplaceGreen;
                font-family: $circularBold;
                font-size: 16px;
                @media screen and (max-width: 767px) {
                  font-size: 13px;
                }
              }
            }
            &.pointTwo {
              border-color: $marketplaceYellowOrange;
              span {
                color: $marketplaceYellowOrange;
                font-family: $circularBold;
                font-size: 16px;
                @media screen and (max-width: 767px) {
                  font-size: 13px;
                }
              }
            }
            &.pointThree {
              border-color: $marketplaceOrange;
              span {
                color: $marketplaceOrange;
                font-family: $circularBold;
                font-size: 16px;
                @media screen and (max-width: 767px) {
                  font-size: 13px;
                }
              }
            }
            &.pointFour {
              border-color: $marketplaceRed;
              span {
                color: $marketplaceRed;
                font-family: $circularBold;
                font-size: 16px;
                @media screen and (max-width: 767px) {
                  font-size: 13px;
                }
              }
            }
            @media screen and (max-width: 1024px) {
              font-size: 12px;
              transform: translate(0, 20px);
            }
            @media screen and (max-width: 767px) {
              font-size: 12px;
            }
          }
        }
        @media screen and (max-width: 1024px) {
          border-radius: 12px;
        }
        @media screen and (max-width: 767px) {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid $helpTextColor;
        }
      }
      .scopeDetails {
        .countText {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          font-size: 14px;
          &.bold {
            font-family: $circularBold;
          }
        }
        @include box-with-border;
        border-left: none;
        flex-grow: 0;
        border-radius: 0 12px 12px 0;
        justify-content: center;
        max-width: 30%;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          margin-bottom: 5px;
          &:last-child {
            font-size: 12px;
          }
        }
        .title {
          font-family: $circularBold;
        }
        .scope {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          &::after {
            content: " ";
            display: block;
            position: absolute;
            width: 20%;
            border-bottom: 1px solid
              rgba($color: $helpTextColorRgb, $alpha: 0.7);
            bottom: 0;
            transform: translateY(10px);
          }
        }
        @media screen and (max-width: 1024px) {
          max-width: unset;
          width: 100%;
          border: 1px solid $helpTextColor;
          border-top: none;
          border-radius: 12px;
        }
        @media screen and (max-width: 767px) {
          border: none;
        }
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        background-color: $white;
        border-radius: 12px;
        box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.1);
      }
    }
    .calculationContainer {
      display: flex;
      .offerStructureContainer {
        @include box-with-border;
        border-top: none;
        flex-grow: 1.2;
        border-radius: 12px 0 0 12px;
        justify-content: center;
        .linkContainer {
          margin-top: 15px;
          font-size: 12px;
          color: $primaryColor;
          text-decoration: underline;
          span {
            cursor: pointer;
          }
        }
        @media screen and (max-width: 1024px) {
          width: 100%;
          border-radius: 12px;
        }
        @media screen and (max-width: 767px) {
          margin-top: 20px;
          border: none;
          background-color: $white;
          box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.1);
        }
      }
      .artistShareContainer {
        @include box-with-border;
        border-top: none;
        border-left: none;
        flex-grow: 1;
        border-radius: 0 12px 12px 0;
        justify-content: center;
        @media screen and (max-width: 1024px) {
          width: 100%;
          border-radius: 12px;
          border: 1px solid $helpTextColor;
          border-top: none;
        }
        @media screen and (max-width: 767px) {
          justify-content: flex-start;
          margin-top: 20px;
          border: none;
          background-color: $white;
          box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.1);
        }
      }
      .offerStructureValues {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        max-width: 600px;
        width: 100%;
        margin: 0 auto;
        & > div {
          position: relative;
          padding: 0 10px;
          max-width: 107px;
          margin: 0 auto;
          &::after {
            content: " ";
            display: block;
            position: absolute;
            height: 100%;
            border-right: 1px solid rgba($color: $helpTextColorRgb, $alpha: 0.7);
            top: 0;
            right: -1vw;
            @media screen and (max-width: 1366px) {
              right: -2%;
            }
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
          &:last-child::after {
            border: none;
          }
          p {
            font-size: 12px;
            margin-bottom: 0;
            text-align: left;
            &:first-child,
            div {
              font-family: $circularBold;
            }
          }
          @media screen and (max-width: 767px) {
            max-width: unset;
            width: 100%;
            padding: 0;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
          }
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column;
      }
    }
    .floatingLabel {
      position: absolute;
      text-transform: uppercase;
      letter-spacing: 2.2px;
      color: $helpTextColor;
      font-size: 11px;
      font-family: $circularBold;
      background-color: $white;
      padding: 10px 15px;
      top: 0;
      transform: translateY(-50%);
      @media screen and (max-width: 767px) {
        position: static;
        transform: unset;
        text-align: left;
        padding: 0;
        margin-bottom: 20px;
      }
    }
  }
  .btnContainer {
    display: flex;
    margin-top: auto;
    align-items: center;
    justify-content: space-between;
    .btnLink {
      cursor: pointer;
      font-size: 12px;
      color: $ctaColor;
      text-decoration: underline;
    }
    .btnPrimary {
      color: $fundingCtaFontColor;
      text-transform: uppercase;
      font-family: $circularBold;
      font-size: 14px;
      letter-spacing: 2.8px;
      padding: 12px 20px;
      border: none;
      border-radius: 25px;
      background-color: $ctaColor;
      @media screen and (max-width: 767px) {
        margin-bottom: 15px;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 12px 12px 0 0;
      padding: 20px;
      background-color: $white;
      box-shadow: 0px 10px 51px rgba($color: $black, $alpha: 0.3);
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
    padding: 0;
    margin-bottom: 120px;
    background-color: transparent;
    box-shadow: none;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}

.adjustModal {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  width: 100%;
  padding: 50px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 12px;
  &:focus {
    outline: none;
  }
  .header {
    text-align: left;
    letter-spacing: 0px;
    display: flex;
    align-items: flex-start;
    .title {
      color: $black;
      font-size: 18px;
      font-family: $circularBold;
    }
    .preview {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 14px;
      font-family: $circularStd;
      margin-left: auto;
      p {
        margin-bottom: 2px;
        &.primaryText {
          color: $primaryColor;
          font-size: 18px;
        }
      }
      span {
        font-family: $circularBold;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 25px 10px 0;
      flex-direction: column;
      justify-content: left;
      align-items: flex-start;
      .preview {
        align-items: flex-start;
        margin: 0;
      }
    }
  }
  .sliderContainer {
    padding: 25px 0px;
    border: none;
    @media screen and (max-width: 1366) {
      padding: 25px 0px;
    }
    @media screen and (max-width: 767px) {
      padding: 25px 10px;
      margin-bottom: 0;
    }
  }

  .modalBtnContainer {
    display: flex;
    align-items: center;
    .modalBtn {
      margin-left: auto;
      text-transform: uppercase;
      font-family: $circularBold;
      background-color: $primaryColor;
      padding: 12px 20px;
      border-radius: 25px;
      color: $white;
      min-width: 150px;
      border: none;
      letter-spacing: 2.8px;
    }
    .resetBtn {
      text-decoration: underline;
      background: transparent;
      border: none;
      color: $helpTextColor;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    a {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      flex-direction: column;
      margin-top: 0px;
      .modalBtn {
        margin-left: 0;
      }
    }
  }
}

.detailsPopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 750px;
  width: 100%;
  outline: none;
  background-color: #fff;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    line-height: 18px;
    padding: 6px;
    cursor: pointer;
  }
  .offerContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 2;
    background: $white;
    border-radius: 12px;
    .totalContent {
      display: flex;
      padding: 30px 35px;
      width: 100%;
      font-size: 14px;
      font-family: $circularStd;
      letter-spacing: 0;
      border-bottom: 2px solid $veryLight;
      .label {
        font-size: 18px;
        font-family: $circularBold;
        text-align: left;
        @media screen and (max-width: 767px) {
          text-align: center;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        border-bottom: none;
        padding: 20px 20px 10px;
      }
    }
    .estimateContainer {
      display: flex;
      padding: 0px 20px;
      border-bottom: 2px solid $veryLight;
      font-size: 14px;
      width: 100%;
      p {
        margin-bottom: 0;
      }
      label {
        margin-bottom: 0;
      }
      .totalEstimate {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-family: $circularBold;
        color: $primaryColor;
        border-right: 2px solid $veryLight;
        padding: 25px 15px;
        .totalNumber {
          font-size: 32px;
          @media screen and (max-width: 1366px) {
            font-size: 26px;
          }
          @media screen and (max-width: 767px) {
            font-size: 26px;
          }
        }
        span {
          font-family: $circularStd;
          margin-top: -5px;
        }
        @media screen and (max-width: 767px) {
          padding: 5px 20px;
          border-right: none;
        }
      }
      .offerDetails {
        display: flex;
        flex-direction: column;
        font-family: $circularStd;
        width: 100%;
        border-right: 2px solid $veryLight;
        padding: 25px 15px;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        & > div {
          display: flex;
          justify-content: center;
          width: 100%;
        }
        .importantText {
          color: $primaryColor;
          font-family: $circularBold;
          @media screen and (max-width: 767px) {
            margin: 10px 0;
            text-align: center;
          }
        }
        .recoupmentRate {
          margin-left: 10px;
          @media screen and (max-width: 767px) {
            margin-left: auto;
          }
        }
        @media screen and (max-width: 767px) {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          border-right: none;
        }
      }
      .termContainer {
        display: flex;
        font-family: $circularStd;
        width: 100%;
        padding: 25px 15px;
        align-items: center;

        .importantText {
          color: $primaryColor;
          @media screen and (max-width: 767px) {
            color: inherit;
          }
        }
        @media screen and (max-width: 767px) {
          padding: 15px 20px;
          background-color: $veryLight;
          color: rgba($color: $black, $alpha: 0.6);
          justify-content: center;
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        font-size: 12px;
        padding: 0;
      }
    }
    .contentContainer {
      display: flex;
      padding: 25px 35px 0;
      ul {
        width: 100%;
        padding-inline-start: 20px;
        margin-bottom: 0;
        @media screen and (max-width: 767px) {
          margin-bottom: 0;
          padding-inline-start: 0;
        }
      }
      li {
        cursor: text;
        align-items: center;
        width: 100%;
        margin-bottom: 15px;
        span {
          font-size: 14px;
          font-family: $circularStd;
        }
        .value {
          font-size: 16px;
          font-family: $circularBold;
          margin-left: auto;
        }
        @media screen and (max-width: 767px) {
          display: block;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 30px 20px 10px;
      }
    }
    .btnContainer {
      padding: 0 35px;
      display: flex;
      margin-bottom: 15px;
      margin-top: 10px;
      justify-content: space-between;
      button {
        background-color: transparent;
        border: none;
        color: $ctaColor;
        border-radius: 25px;
        text-decoration: underline;
        font-size: 12px;
        &.btnPrimary {
          padding: 12px 20px;
          background-color: $ctaColor;
          color: $fundingCtaFontColor;
          font-family: $circularBold;
          text-transform: uppercase;
          letter-spacing: 2.8px;
          text-decoration: none;
          font-size: 14px;
          @media screen and (max-width: 767px) {
            margin-bottom: 15px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        padding: 0 20px;
        flex-direction: column-reverse;
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 90%;
  }
}

.marketModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 570px;
  width: 100%;
  padding: 50px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 12px;
  outline: none;
  .primaryHeader {
    font-family: $circularBold;
    text-align: center;
    color: $primaryColor;
    font-size: 18px;
  }
  .header {
    font-family: $circularBold;
    max-width: 270px;
    text-align: center;
    span {
      color: $primaryColor;
    }
  }
  .infoText {
    font-size: 14px;
    text-align: center;
  }
  .btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    button {
      border: none;
      background-color: $ctaColor;
      color: $fundingCtaFontColor;
      padding: 12px 20px;
      border-radius: 25px;
      font-family: $circularBold;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      font-size: 13px;
      min-width: 250px;
      &.linkBtn {
        letter-spacing: 0;
        text-transform: none;
        font-size: 12px;
        font-family: $circularStd;
        text-decoration: underline;
        color: $helpTextColor;
        background-color: transparent;
        padding: 5px;
        margin-top: 18px;
      }
      @media screen and (max-width: 767px) {
        min-width: unset;
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $tooltipFontColor;
  border-radius: 50%;
  cursor: pointer;
  background-color: $tooltipBackgroundColor;
  margin-left: 15px;
  &::after {
    font-size: 12px;
    text-transform: lowercase;
    content: "?";
    letter-spacing: 0px;
    line-height: 12px;
    position: relative;
    top: 1px;
    font-family: $circularBold;
    @media screen and (max-width: 1366px) {
      font-size: 11px;
    }
  }
  @media screen and (max-width: 1366px) {
    height: 15px;
    width: 15px;
  }
}

.tooltip {
  width: 30%;
  white-space: normal;
  text-transform: initial;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.emptyScreen {
  width: 100%;
  height: 100vh;
  background-color: $white;
}

.chancesContainer {
  display: flex;
  margin-left: auto;
  padding: 10px 15px;
  border-radius: 12px;
  background-color: rgba($marketplaceGreen, 0.1);
  align-items: center;
  p {
    margin-bottom: 0;
    margin-left: 5px;
    font-family: $circularBold;
    color: $marketplaceGreen;
  }
  svg {
    flex-shrink: 0;
    height: 19px;
    width: 19px;
    path {
      fill: $marketplaceGreen;
    }
    @media screen and (max-width: 767px) {
      width: 15px;
      height: 15px;
    }
  }
  &.pointTwo {
    background-color: rgba($marketplaceYellowOrange, 0.1);
    p,
    svg path {
      color: $marketplaceYellowOrange;
      fill: $marketplaceYellowOrange;
    }
  }
  &.pointThree {
    background-color: rgba($marketplaceOrange, 0.1);
    p,
    svg path {
      color: $marketplaceOrange;
      fill: $marketplaceOrange;
    }
  }
  &.pointFour {
    background-color: rgba($marketplaceRed, 0.1);
    p,
    svg path {
      color: $marketplaceRed;
      fill: $marketplaceRed;
    }
  }
  @media screen and (max-width: 767px) {
    width: fit-content;
    margin: 10px auto 0;
  }
}
