@import "../../../../styles/variables.scss";
.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.9
  );
  overflow: auto;
  z-index: 4;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  &.mobileOverLay {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }
    .saveChanges {
      display: flex;
      padding-top: 15px;
      width: 100%;
      button {
        border: none;
        display: flex;
        justify-content: center;
        width: 100%;
        background: $ctaColor;
        padding: 15px 20px;
        border-radius: 25px;
        font-size: 14px;
        font-family: $circularBold;
        color: $white;
        letter-spacing: 2.8px;
      }
    }
    .emailInput {
      input {
        padding: 15px;
      }
    }
    :global(.form-group) {
      margin-bottom: 0px;
    }
    :global(.mb-4) {
      padding-top: 0px;
      padding-bottom: 8px;
    }
  }
}
.biddersModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 50px;
  background-color: #fff;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 20px;
  @media screen and (max-width: 767px) {
    width: 90%;
    padding: 30px;
  }
  form {
    input {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .mobileSubTitle {
    display: none;
    p {
      font-size: 14px;
      font-family: $circularStd;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      width: 100%;
    }
  }
}
.modalContainer {
  h2 {
    font-size: 18px;
    letter-spacing: 0;
    color: $primaryColor;
    font-family: $circularBold;
    text-align: center;
  }
  p {
    font-size: 14px;
    font-family: $circularStd;
    text-align: center;
  }

  .btnContainer {
    display: flex;
    justify-content: center;
    button {
      border: 1px solid $ctaColor;
      background-color: transparent;
      color: $ctaColor;
      padding: 12px 20px;
      border-radius: 25px;
      font-family: $circularBold;
      letter-spacing: 2.8px;
      text-transform: uppercase;
      margin-top: 20px;
      min-width: 190px;
      &.primary {
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
      }
    }
  }
}
.pageContainer {
  display: flex;
  flex: 1;
  width: 100%;
  background: $light;
  .mainContainer {
    background: $veryLight;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    margin-top: 0px;
    position: relative;
    .scrollContainer {
      height: 100%;
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      @media screen and (max-width: 1440px) {
        max-height: calc(100vh - 100px);
      }
      @media screen and (max-width: 1366px) {
        max-height: calc(100vh - 100px);
      }
      @media screen and (max-width: 767px) {
        padding: 0px;
        max-height: unset;
        height: unset;
        padding-bottom: 0px;
      }
    }
    @media screen and (max-width: 767px) {
      flex-grow: 1;
      max-height: unset;
      padding: 0px;
    }
    @media screen and (max-width: 529px) {
      padding: 0;
      min-height: calc(100vh - 167px);
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    max-width: 1366px;
    margin: 40px auto;
    text-align: left;
    justify-content: center;
    background: $white;
    border-radius: 10px;

    .addInvestor {
      &.mobileAddInvestor {
        display: none;
        cursor: pointer;
        color: $primaryColor;
        .addInvitation {
          border: none;
          background-color: transparent;
        }
        .add {
          border: none;
          height: 32px;
          width: 32px;
          flex-shrink: 0;
          border-radius: 50%;
          background-color: $primaryColor;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          font-family: $circularStd;
          margin-right: 8px;
        }

        @media screen and (max-width: 767px) {
          display: flex;
          padding-top: 20px;
          justify-content: center;
          align-items: center;
          border: none;
          width: 100%;
          background: transparent;
        }
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .mobileTitle {
      display: none;
      background: $sidebarTooltipIconColor;
      border-radius: 8px;
      padding: 20px;
      margin: 20px;
      margin-bottom: 0px;
      align-items: center;
      justify-content: center;
      color: $white;
      font-family: $circularStd;
      p {
        margin-bottom: 0px;
      }
      @media screen and (max-width: 767px) {
        display: flex;
        margin: 20px;
      }
    }
    .addInvestorContainer {
      border-top: 2px solid $light;
      padding: 25px;
      display: flex;
      justify-content: space-between;
      .submitBtnContainer {
        display: flex;
        .noThanks {
          font-size: 14px;
          border: none;
          background-color: transparent;
          text-decoration: underline;
          font-family: $circularStd;
          color: $primaryColor;
        }
        .previewBtn {
          border: none;
          background: $ctaColor;
          padding: 10px 20px;
          border-radius: 25px;
          font-size: 14px;
          font-family: $circularBold;
          color: $fundingCtaFontColor;
          letter-spacing: 1.5px;
          margin-left: 20px;
          @media screen and (max-width: 767px) {
            letter-spacing: 2.8px;
            padding: 15px 20px;
            margin-left: 0;
          }
        }
        @media screen and (max-width: 767px) {
          flex-direction: column-reverse;
          width: 100%;
          justify-content: space-around;
        }
      }

      .addInvestor {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        color: $primaryColor;

        .addInvitation {
          border: none;
          background-color: transparent;
        }
        .add {
          border: none;
          height: 32px;
          width: 32px;
          flex-shrink: 0;
          border-radius: 50%;
          background-color: $primaryColor;
          color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          font-family: $circularStd;
          margin-right: 8px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      @media screen and (max-width: 767px) {
        border-top: none;
        background-color: $white;
        height: 152px;
        box-shadow: 0px 10px 51px #00000055;
        border-radius: 10px 10px 0px 0px;
        margin-top: 50px;
      }
    }

    .formContainer {
      .errorField {
        > div {
          padding-top: 2px;
          @media screen and (max-width: 767px) {
            display: flex;
            padding-top: 10px;
          }
        }
        padding-left: 30px;
      }

      .scroll {
        .mobileScroll {
          @media screen and (max-width: 767px) {
            max-height: 200px;
            overflow-y: auto;
          }
        }
        max-height: 260px;
        overflow-y: auto;
        @media screen and (max-width: 767px) {
          background-color: $white;
          max-height: unset;
          overflow-y: unset;
          padding: 20px;
          box-shadow: 0px 3px 6px #0000000b;
          border-radius: 12px;
          margin: 20px;
        }

        .mobileView {
          display: none;
          max-height: 300px;
          overflow-y: auto;

          .subContainer {
            svg {
              height: 20px;
            }
            display: flex;
            justify-content: space-between;
            width: 100%;
            border: 2px solid $primaryColor;
            align-items: center;
            border-radius: 7px;
            margin-bottom: 20px;
            height: 53px;
            .nameContainer {
              display: flex;
              align-items: center;
              img {
                height: 40px;
              }
              .userSvg {
                svg {
                  height: 35px;
                  path {
                    fill: $primaryColor;
                  }
                }
              }
            }
            .iconContainer {
              display: flex;

              .editIcon {
                svg {
                  path {
                    fill: $primaryColor;
                  }
                }
              }
              svg {
                height: 17px;
                padding-right: 10px;
                cursor: pointer;
              }
            }
          }
          @media screen and (max-width: 767px) {
            display: flex;
          }
        }
        @media screen and (max-width: 767px) {
          margin: 20px;
          margin-top: 20px;
        }
      }
      .gridContainer {
        .investorInputEamil {
          input {
            padding: 15px;
            color: $primaryColor;
          }
        }
        padding-left: 25px;
        padding-right: 25px;
        :global(.mb-4) {
          padding-top: 0px;
          margin-bottom: 10px !important;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .biddersInput {
        margin: 0px;
        display: flex;
        flex-wrap: unset;
        width: 100%;
        .firstInvestorInput {
          padding-left: 0px;
          input {
            color: $primaryColor;
          }
        }
        justify-content: space-around;
        :global(.css-tlfecz-indicatorContainer) {
          svg {
            path {
              fill: $primaryColor;
            }
          }
        }

        .dropDown {
          display: flex;
          justify-content: space-between;
          :global(.w-auto) {
            width: 100% !important;
          }
          :first-child {
            width: 100%;
          }
        }

        .deleteIcon {
          display: flex;
          align-items: center;
          position: absolute;
          top: 50%;
          right: 0;
          height: 18px;
          width: 20px;
          transform: translate(20px, -50%);
          svg {
            display: flex;
            font-size: 14px;
            cursor: pointer;
            width: 100%;
            height: 100%;
            path {
              fill: $error;
            }
          }
        }
      }
    }
    .investorDeal {
      display: flex;
      background-color: $sidebarTooltipIconColor;
      padding: 10px;
      margin: 25px;
      align-items: center;
      border-radius: 8px;
      max-width: 93%;
      font-size: 14px;
      font-family: $circularStd;
      color: $white;
      svg {
        flex-shrink: 0;
      }
      p {
        font-size: 14px;
        margin-left: 20px;
        margin-bottom: 0;
        font-family: $circularStd;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .topTitle {
      h3 {
        font-size: 20px;
        font-family: $circularBold;
      }
      padding: 25px;
      border-bottom: 1px solid $light;
      p {
        font-size: 14px;
        font-family: $circularStd;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .infoTitle {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-bottom: 0px;
    }

    .infoIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      font-style: normal;
      height: 17px;
      width: 17px;
      border: none;
      color: $tooltipFontColor;
      border-radius: 50%;
      cursor: pointer;
      background-color: $sidebarTooltipIconColor;
      margin-left: 8px;
      &.infoWhite {
        background-color: $alertFontColor;
        color: $alertBackgroundColor;
        margin-right: 15px;
      }
      @media screen and (max-width: 1366px) {
        height: 15px;
        width: 15px;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .infoIcon::after {
      font-size: 12px;
      text-transform: lowercase;
      content: "?";
      letter-spacing: 0px;
      line-height: 12px;
      position: relative;
      top: 1px;
      font-family: $circularBold;
      @media screen and (max-width: 1366px) {
        font-size: 11px;
      }
    }
    @media screen and (max-width: 767px) {
      background-color: unset;
      width: 100%;
      margin: unset;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0px;
    height: 100%;
  }
}
.errorFormField {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.errormobileField {
  display: none;
  @media screen and (max-width: 767px) {
    display: unset;
  }
}
.errorField {
  :global(.invalid-feedback) {
    margin-right: 15px;
  }
  > div {
    padding-top: 2px;

    @media screen and (max-width: 767px) {
      display: flex;
      padding-top: 10px;
    }
  }
}
