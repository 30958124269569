@import "../../../styles/variables.scss";

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.pageContainer {
  display: flex;
  flex: 1;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  padding: 30px;
  max-width: 1000px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 1440px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 1024px) {
    padding: 10px 20px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
}
.mainContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
      max-height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 221px);
    padding: 0px 10px 10px 10px;
    max-height: unset;
  }
  @media screen and (max-width: 529px) {
    min-height: calc(100vh - 167px);
  }
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  .RightText {
    background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
    padding: 15px;
    color: $helpTextColor;
    display: flex;
    border-radius: 10px;
    font-size: 14px;
    margin-left: 20px;
    align-items: center;
    .svgIcon {
      margin-right: 10px;
      flex-shrink: 0;
      path {
        fill: $helpTextColor;
      }
    }
    img {
      height: 15px;
      margin-right: 8px;
    }
    @media screen and (max-width: 1024px) {
      max-width: 250px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: flex-start;
    flex: 1;
    p {
      font-size: 14px;
      font-family: $circularStd;
      text-align: left;
      br {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      span {
        @media screen and (max-width: 767px) {
          color: $primaryColor;
        }
      }
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 4px;
      font-size: 20px;
      padding: 15px 0 10px 0px;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    background: $white;
    border-radius: 10px;
    margin-bottom: 16px;
    margin-top: 10px;
    padding: 20px;
  }
}

.contactModal {
  border-radius: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 90%;
  top: 8%;
  left: 4%;
  background-color: $white;
  margin: 170px auto 78px;
  align-items: center;
  border: none;
  height: fit-content;
  outline: none;
  box-shadow: 0px -5px 17px #0000001c;

  .modal {
    margin: 40px;
    .modalText {
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
      > p {
        font-family: $circularBold;
        font-size: 16px;
      }
      .secondDesc {
        font-family: $circularStd;
        font-size: 14px;
        color: $appAccentFontColor;
      }
      .lastDesc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        .leftInfoText {
          display: flex;
          align-items: center;
          padding: 10px;
          font-size: 14px;
          border-radius: 13px;
          background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
          color: $helpTextColor;
          max-width: 450px;
          svg {
            width: 15px;
            flex-shrink: 0;
            margin-right: 10px;
            path {
              fill: $helpTextColor;
            }
          }
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          padding: 10px 0px;
        }
      }
    }

    .close {
      display: flex;
      justify-content: flex-end;
      img {
        cursor: pointer;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  .modalRpw {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    .specialChar {
      font-family: $circularStd;
      margin-bottom: 0px;
      color: $sliderColor;
      @media screen and (max-width: 767px) {
        margin-bottom: -30px;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    position: relative;
    top: 23%;
    left: 4%;
  }
  @media screen and (max-width: 767px) {
    position: relative;
    top: 8%;
    left: 0%;
  }
}

.title h1 {
  display: flex;
  align-items: center;
  font-family: $circularBold;
  padding: 7px 0 0 0;
  color: $black;
  letter-spacing: 0px;
  font-size: 32px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;

  @media screen and (max-width: 1366px) {
    font-size: 30px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    font-size: 22px;
    padding: 15px 0 20px 0;
  }
  p {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
  }
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  .btnyouradvance:first-child {
    border: 1px solid $primaryColor;
    color: $primaryColor;
    background-color: $white;
    margin-right: 20px;
    @media screen and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.dataReviewContainer {
  .title {
    align-self: center;
    margin: 10px auto 0 auto;
    @media screen and (max-width: 1024px) {
      margin: unset;
    }
    @media screen and (max-width: 820px) {
      align-self: center;
    }
  }
}
.welcomeModal {
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  background-color: $white;
  margin: 0px auto;
  align-items: center;

  .contactSubText {
    display: block;
    color: $helpTextColor;
    font-size: 80%;
    text-align: center;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }
  .dataReviewSubHeading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    p {
      width: 100%;
      text-align: left;
      padding: 10px 0 20px;
      font-size: 18px;
      letter-spacing: 0.96px;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
        letter-spacing: 0.76px;
        line-height: 1.4;
        padding: 10px 0 10px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 14px;
        letter-spacing: 0.76px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    .buttonContainer {
      display: flex;
      button {
        margin: 10px;
        font-weight: bold;
        flex-shrink: 0;
        flex-grow: 1;
        max-width: 280px;
        border-radius: 2px;
        border: 1px solid $ctaColor;
        height: 50px;
        text-align: center;
        background-color: transparent;
        color: $ctaColor;
        &.secondary {
          border: none;
          &:hover {
            text-decoration: underline;
          }
        }
        &.primary {
          background-color: transparent;
        }
        &.demoBtn {
          background-color: $primaryColor;
          font-family: $circularBold;
          font-size: 18px;
          max-width: fit-content;
          color: $white;
          border: none;
          border-radius: 4px;
          padding: 12px 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 40px;
            margin-right: -18px;
            stroke: $white;
            @media screen and (max-width: 767px) {
              width: 35px;
              margin-right: -10px;
            }
          }
          @media screen and (max-width: 1024px) {
            font-size: 17px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            font-size: 16px;
            padding: 10px 15px;
          }
        }
        @media screen and (max-width: 1440px) {
          height: 40px;
          max-width: 200px;
        }
      }
    }
  }
  .dataTuneButton {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 10px 0;
    background: $white;
    @media screen and (max-width: 1440px) {
      padding: 0;
    }
    .buttonContainer {
      justify-content: center;
      align-items: center;
      width: 100%;
      button {
        margin-top: 20px;
        margin-bottom: 20px;
        @media screen and (max-width: 1440px) {
          margin-top: 12px;
          margin-bottom: 12px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      border-top: 1px solid $darkGreyColor;
    }
  }
  .dataReviewSecondHeading {
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    bottom: 0px;
    width: 100%;
    background-color: $white;
    box-shadow: 0px -5px 17px #0000001c;
    .tableWrapper {
      max-width: 93%;
      margin: 0 auto;
      padding: 30px;
      .resetButton {
        background-color: $white;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 23px 50px;
        height: 25px;
        font-family: $circularBold;
        color: $ctaColor;
        border-radius: 40px;
        border: 2px solid $ctaColor;
        @media screen and (max-width: 1366px) {
          padding: 23px 68px;
        }
        @media screen and (max-width: 1024px) {
          padding: 19px 42px;
          height: 17px;
        }

        @media screen and (max-width: 767px) {
          display: flex;
          padding: 20px 50px;
          align-items: center;
          justify-content: center;
        }
      }

      @media screen and (max-width: 1366px) {
        max-width: 100%;
        padding: 26px 20px;
      }
      @media screen and (max-width: 767px) {
        padding: unset;
      }
    }
    table {
      text-align: center !important;
      padding: 30px 43px;
      overflow: hidden;
      width: 100%;
      font-size: 18px;
      tr {
        > td:first-child {
          display: flex;
          font-size: 12px;
          flex-direction: column;
          align-items: flex-start;
          @media screen and (max-width: 767px) {
            flex-direction: row;
            border-right: none;
            border-bottom: 1px solid $darkGreyColor;
            padding-bottom: 5px;
            display: none;
          }
        }
        > td.notLookRight {
          max-width: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media screen and (max-width: 767px) {
            max-width: unset;
            justify-content: flex-start;
            padding-bottom: 12px;
            margin-top: -5px;
          }
        }
        height: 85px;
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 1440px) {
          height: 80px;
        }
        @media screen and (max-width: 1366px) {
          height: 80px;
        }
        @media screen and (max-width: 1024px) {
          height: 96px;
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
          background: $white;
          height: unset;
        }
      }
      @media screen and (max-width: 767px) {
        background-color: $veryLight;
      }
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    @media screen and (max-width: 767px) {
      position: fixed;
      bottom: 2px;
      border-radius: 10px;
    }
  }
  .modalHead {
    padding: 30px 43px;
  }
  .modalBody {
    display: flex;
    flex-direction: column;
    width: 100%;

    .artistImage {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 30px;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      img {
        width: 100%;
        height: auto;
      }
      svg {
        height: 100%;
        width: 100%;
      }
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      max-height: unset;
      min-height: unset;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 0px;
    }
    &::-webkit-scrollbar-track {
      background: $white;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($color: $dark, $alpha: 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($color: $dark, $alpha: 0.4);
    }
  }

  .lastModalRow td {
    margin: 14px 10px;
    justify-content: space-around;
    flex: 1;
    .specialChar {
      text-transform: uppercase;
    }
    .contactLink {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start !important;
      font-size: 14px;
      color: $helpTextColor;
      text-decoration: underline;
      cursor: pointer;
      font-family: $circularStd !important;
    }

    &.dataDisplay {
      p p {
        color: $primaryColor !important;
        font-family: $circularBold;
      }
    }
    a {
      color: $helpTextColor;
      text-decoration: underline;
      font-size: 14px;
    }
    span {
      color: $helpTextColor;
      font-size: 14px;
      font-family: $circularStd;
    }
    > p:first-child,
    label {
      margin: auto;
      font-weight: bold;
      color: $sidebarTooltipIconColor;
      text-transform: uppercase;
      letter-spacing: 0.48px;
      .contactSubText {
        display: block;
        position: absolute;
        font-size: 80%;
        font-weight: normal;
        text-decoration: underline;
        cursor: pointer;
      }
      @media screen and (max-width: 1366px) {
        font-size: 20px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 11px;
      }
    }
    > p:nth-child(2),
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      max-width: 400px;
      margin-top: 2px;
      font-family: $circularBold;
      & > div {
        font-size: 20px;
        @media screen and (max-width: 1440px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1366px) {
          font-size: 16px;
        }
        @media screen and (max-width: 767px) {
          margin-top: -15px;
        }
      }

      p {
        font-size: 16px;
        color: $black;
        @media screen and (max-width: 1440px) {
          font-size: 14px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      @media screen and (max-width: 1440px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 12px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        opacity: 0.8;
        max-width: unset;
        margin: 0 10px;
      }
    }
    @media screen and (max-width: 1440px) {
      margin: 10px 6px;
    }
    @media screen and (max-width: 1024px) {
      margin: 10px 6px;
    }
    @media screen and (max-width: 767px) {
      margin: 8px 16px;
      display: flex;
      flex-direction: column;
      &.dataDisplay {
        display: flex;
        align-items: center;
        p {
          text-align: left;
          p {
            display: block;
          }
        }
      }
    }
  }
  .soundExchange {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    p {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0px;
      font-family: $circularStd;
    }
  }
  td .soundExchange {
    p {
      font-size: 18px;
    }
  }
  .soundExchangeInputContainer {
    display: flex;
    justify-content: center;
    p {
      font-weight: normal;
      margin-right: 5px;
    }
  }

  .dataReviewSecondTable {
    margin-top: 10px;
    width: 100%;
    align-self: center;
    margin-bottom: 185px;
  }
  .tuneDataSecondTable {
    margin-top: 10px;
    width: 100%;
    align-self: center;
    margin-bottom: 125px;

    tbody tr td:first-child {
      padding-left: 30px;
      @media screen and (max-width: 767px) {
        padding-left: 15px;
      }
    }
    tbody tr td:last-child {
      padding-right: 30px;
    }
    @media screen and (max-width: 1024px) {
      margin-bottom: 175px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 250px;
    }
  }
  h4,
  h3 {
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.86px;
    color: $primaryColor;
    line-height: 26px;
  }
  h4 {
    font-size: 26px;
  }
  h3 {
    font-size: 31px;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }
  p {
    margin: 0;
  }
}

.modalBody table {
  padding: 30px 43px;
  overflow: hidden;
  width: 100%;
  tbody {
    display: block;

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      @media screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 0px;
    }
    &::-webkit-scrollbar-track {
      background: $white;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($color: $dark, $alpha: 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($color: $dark, $alpha: 0.4);
    }
  }
  thead {
    display: table;
    table-layout: fixed;
    width: calc(100% - 8px);

    :first-child {
      border-bottom: unset;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  p {
    font-size: 18px;
    @media screen and (max-width: 1440px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }

  tr {
    border-bottom: 1px solid $light;
    & > th:first-child,
    & > td:first-child {
      padding: 10px 0 10px 0;
      text-align: center;
      @media screen and (max-width: 1440px) {
        padding: 7px 0;
      }
      @media screen and (max-width: 767px) {
        padding: 20px 0 10px 0;
      }
    }
    & > th:last-child,
    & > td:last-child {
      padding-right: 84px;
      text-align: center;
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: unset;
        position: relative;
      }
      .mobShareIncome {
        display: none;
        @media screen and (max-width: 767px) {
          display: inline;
        }
      }
      .mobSliderIncome {
        text-align: left;
        font-size: 14px;
        > span {
          display: none;
          @media screen and (max-width: 767px) {
            display: none;
            text-transform: uppercase;
            font-family: $circularBold;
            color: $grey;
            letter-spacing: 0.46px;
            margin-bottom: -18px;
          }
        }
        @media screen and (max-width: 767px) {
          div span {
            color: $primaryColor;
            font-family: $circularBold;
          }
        }
      }
      @media screen and (max-width: 1024px) {
        padding-right: 20px;
        p {
          width: auto;
          position: static;
          right: 0px;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 15px 25px;
        p {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          font-family: $circularBold;
          color: $grey;
          margin-top: -20px;
          .mobShare {
            color: $primaryColor;
            margin-left: 15px;
          }
        }
      }
    }
    &:last-child > * {
      text-align: center;
      vertical-align: middle;
      &:first-child {
        text-align: left;
      }
    }

    &.lastModalRow {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
  th,
  .th {
    color: $grey;
    text-transform: uppercase;
    letter-spacing: 0.48px;
    font-family: $circularBold;
    p {
      font-size: 11px;
      display: flex;
      white-space: nowrap;
      color: $helpTextColor;
      justify-content: center;
      align-items: center;
    }
    @media screen and (max-width: 1024px) {
      letter-spacing: 0.5px;

      p {
        white-space: unset;
      }
    }
    @media screen and (max-width: 340px) {
      &:nth-child(2) p {
        display: none;
      }
    }
  }
  td,
  th {
    text-align: center;
    padding: 5px 10px;
  }
  .trackProfile {
    display: flex;
    align-items: center;
    width: 100%;
    .musicSvg {
      path {
        fill: $sliderColor;
        stroke: none;
      }
      margin-right: 15px;
      font-family: $circularStd;
    }

    img {
      height: 60px;
      width: 60px;
      margin-right: 20px;
      flex-shrink: 0;
      border-radius: 50%;
    }

    svg {
      width: 60px;
      height: auto;
      margin-right: 10px;
      flex-shrink: 0;
    }
    p {
      font-weight: 600;
      text-align: left;

      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }

    .mobLastMonth {
      color: $grey;
      display: none;
      margin-left: -50px;
      letter-spacing: 0.76px;
      padding-top: 42px;
      margin-bottom: -62px;
      span {
        color: $grey;
        margin-right: 10px;
      }
      &.otherTrack {
        display: flex;
        margin-top: -24px;
        margin-left: 12px;

        & .otherTrackTitle {
          margin-left: 0px;
        }
      }
      .title {
        margin-left: 30px;

        @media screen and (max-width: 767px) {
          display: block;
          margin-top: 0px;
          margin-bottom: 23px;
        }
      }
      @media screen and (max-width: 767px) {
        display: block;
        padding-top: 21px;
        margin-bottom: -46px;
      }
    }
    @media screen and (max-width: 1440px) {
      img {
        height: 40px;
        width: 40px;
        margin-right: 10px;
        flex-shrink: 0;
        border-radius: 50%;
      }
      svg {
        width: 40px;
      }
      p {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 1366px) {
      img {
        height: 40px;
        width: 40px;
        margin-right: 10px;
        flex-shrink: 0;
        border-radius: 50%;
      }
      svg {
        width: 40px;
      }
      p {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 1024px) {
      img {
        height: 40px;
        width: 40px;
      }
      svg {
        width: 40px;
      }
      p {
        font-size: 14px;
        @media screen and (max-width: 1024px) {
          opacity: 0.8;
        }
      }
    }
    @media screen and (max-width: 767px) {
      img {
        height: 50px;
        width: 50px;
      }
      svg {
        width: 50px;
      }
    }
  }
  .chartData {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $sidebarTooltipIconColor;
    justify-content: center;
    p {
      margin-right: 15px;
      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 1440px) {
      & > div {
        top: 5px;
      }
    }
    @media screen and (max-width: 1024px) {
      p {
        margin: 0;
      }
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  div.th {
    margin: 20px 0;
    p {
      justify-content: center;
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .partitionTitle {
    border-bottom: unset;
    td {
      padding: 12px 10px;
      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }

    p {
      text-align: left;
      flex: 1;
    }
  }
}

.confirmModalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 6;
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  font-weight: normal;
  text-align: left;
  background-color: $tooltipBackgroundColor !important;
  color: $tooltipFontColor !important;
  box-shadow: 0 0 6px rgba($color: $dark, $alpha: 0.3);
  border-radius: 5px !important;
  &::after {
    border: transparent !important;
  }
  &:global(.show) {
    opacity: 1 !important;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
  }
  a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}

.success {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  background-color: $white;
  visibility: visible !important;
  height: 100vh;
  text-align: center;
  h1 {
    font-size: 65px;
    font-family: $circular;
    font-weight: bold;
    color: $primaryColor;
  }
  p {
    clear: both;
    font-weight: bold;
  }
}

.reactRangeLastMonth {
  width: 100%;
  min-width: 120px;
  padding: 0 15px;
  height: 28px;
  div {
    font-size: 16px;
  }
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $tooltipFontColor;
  border-radius: 50%;
  cursor: pointer;
  background-color: $tooltipBackgroundColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $alertFontColor;
    color: $alertBackgroundColor;
    margin-right: 15px;
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "?";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

.submitButton {
  background-color: $ctaColor;
  display: flex;
  align-items: center;
  float: right;
  font-size: 14px;
  font-family: $circularBold;
  color: $fundingCtaFontColor;
  padding: 25px 50px;
  margin-left: 25px;
  height: 25px;
  border-radius: 40px;
  border: none;
  @media screen and (max-width: 1366px) {
    padding: 24px 68px;
    height: 25px;
  }
  @media screen and (max-width: 1024px) {
    padding: 19px 42px;
    height: 17px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 10px;
    display: flex;
    margin-left: unset;
    padding: 24px 50px;
    align-items: center;
    justify-content: center;
  }
}
.continueButton {
  background-color: $ctaColor;

  float: right;
  font-size: 14px;
  font-family: $circularBold;
  color: $fundingCtaFontColor;
  padding: 8px 35px;
  border-radius: 25px;
  border: none;
  @media screen and (max-width: 767px) {
    margin-left: auto;
  }
}

.streamingDataMainContainer {
  .streamingDataScrollContainer {
    background-color: $veryLight;
    .streamingDataContainer {
      max-width: 1145px;
      @media screen and (max-width: 1366px) {
        max-width: 1070px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    background-color: $veryLight;
  }
}
