@import "../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  .subTitle {
    text-align: left;
    font-size: 12px;
    margin-bottom: 0px;
  }
  .title {
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;
    font-family: $circularBold;
    span {
      font-family: $circularBold;
      color: $primaryColor;
    }
  }
  :global(.distributor__control--menu-is-open) {
    border-radius: 7px 7px 0 0;
    border-bottom: none;
    &:hover {
      border-bottom: none;
    }
  }
  :global(.distributor__option) {
    padding: 5px;
  }
  :global(.distributor__single-value) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    .option img,
    .option .amount {
      display: none;
    }
  }
  .option {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 14px;
    img,
    svg {
      height: 20px;
      width: 40px;
      object-fit: contain;
      object-position: center;
      border: none;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .amount {
      margin-left: auto;
      font-size: 12px;
      text-align: right;
      span {
        font-family: $circularBold;
        font-size: 14px;
      }
    }
  }
  .linkBtn {
    font-size: 12px;
    margin-top: 10px;
    background-color: transparent;
    border: none;
    color: $primaryColor;
    padding: 0;
    text-decoration: underline;
    text-align: left;
    width: fit-content;
  }
}
