@import "../../../../styles/variables.scss";

@mixin mobileView {
  padding: 20px;
  border-radius: 12px;
  background-color: $white;
  box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.2);
  margin-bottom: 30px;
}

@mixin inviteHeaderMobile {
  padding: 5px 0px 20px 0px;
  font-size: 18px;
  letter-spacing: 0px;
  font-family: $circularSemiBold;
  color: $black;
}

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    .pageContainer {
      display: flex;
      flex: 1;
      width: 100%;
      background-color: $dark;
      .mainContainer {
        background: $veryLight;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: auto;
        margin-top: 0px;
        position: relative;
        .scrollContainer {
          height: 100%;
          max-height: calc(100vh - 100px);
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          .container {
            position: relative;
            display: flex;
            max-width: 1545px;
            flex-grow: 1;
            margin: 0 auto;
            width: 100%;
            text-align: center;
            @media screen and (max-width: 767px) {
              margin-top: 0;
              margin-bottom: 120px;
            }
          }
          @media screen and (max-width: 1440px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 1366px) {
            max-height: calc(100vh - 100px);
          }
          @media screen and (max-width: 767px) {
            padding: 20px;
            max-height: unset;
          }
        }
        @media screen and (max-width: 767px) {
          flex-grow: 1;
          padding: 0px 10px 10px 10px;
          max-height: unset;
        }
        @media screen and (max-width: 529px) {
          padding: 0;
          min-height: calc(100vh - 167px);
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        max-width: none;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.inviteBidderContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $white;
  box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    background-color: transparent;
    box-shadow: none;
  }

  .firstContainer {
    font-size: 18px;
    padding: 45px;
    text-align: left;
    font-family: $circularSemiBold;
    border-bottom: 1px solid $greyColor;

    @media screen and (max-width: 767px) {
      @include mobileView;
    }
  }
  .secondContainer {
    display: flex;
    border-bottom: 1px solid $greyColor;
    text-align: left;
    flex-grow: 1;

    .emailInvite {
      display: flex;
      flex-direction: column;
      padding: 10px 20px 10px 45px;
      flex: 2 1;
      border-right: 1px solid $greyColor;
      @media screen and (max-width: 767px) {
        @include mobileView;
      }

      .emailInviteContent {
        padding-right: 30px;
        overflow-y: auto;
        max-height: 350px;
        color: $black;
        .span {
          text-decoration: underline;
        }
        .image {
          img {
            height: 25px;
            width: 25px;
            border-radius: 50%;
          }
        }
        .name {
          font-family: $circularBold;
          padding: 5px 0px;
        }

        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
          @media screen and (max-width: 474px) {
            width: 5px;
          }
        }
        &::-webkit-scrollbar-track {
          background: $greyColor;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $grey;
          border-radius: 5px;
          max-height: 100px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: rgba($color: $grey, $alpha: 0.5);
        }
      }
      @media screen and (max-width: 1024px) {
        border-right: 0px;
        border-bottom: 1px solid $greyColor;
      }
      @media screen and (max-width: 767px) {
        border: 0px;
      }
    }
    .emailSent {
      display: flex;
      flex-direction: column;
      padding: 10px 45px 10px 25px;
      flex: 1 1;
      text-align: left;

      .emailContent {
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        flex-grow: 1;
        overflow-y: auto;
        max-height: 350px;
        color: $black;
        .inviteContent {
          display: flex;
          flex-direction: column;
          margin-bottom: 30px;
          .name {
            font-family: $circularBold;
            margin-bottom: 5px;
          }
        }

        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
          @media screen and (max-width: 474px) {
            width: 5px;
          }
        }
        &::-webkit-scrollbar-track {
          background: $greyColor;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
          background: $grey;
          border-radius: 5px;
          max-height: 100px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: rgba($color: $grey, $alpha: 0.5);
        }
      }

      @media screen and (max-width: 767px) {
        @include mobileView;
        .emailContent {
          max-height: 250px;
          .inviteContent {
            margin-bottom: 20px;
            .email {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {
      border: 0px;
    }
  }

  .inviteHeader {
    padding: 25px 0px;
    letter-spacing: 2.2px;
    color: $helpTextColor;
    font-size: 12px;
    font-family: $circularBold;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      @include inviteHeaderMobile;
      text-transform: capitalize;
    }
  }

  .message {
    color: $primaryColor;
    padding: 35px 0px 15px 0px;
    margin-top: auto;
    cursor: pointer;
    svg {
      path {
        fill: $primaryColor;
      }
    }
    span {
      margin-left: 5px;
      text-decoration: underline;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px;
      padding-top: 25px;
      span {
        margin-left: 0px;
      }
    }
  }
  .thirdContainer {
    padding: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      cursor: pointer;
      text-decoration: underline;
      color: $primaryColor;
    }
    .simpleButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      background-color: $primaryColor;
      border: 1px solid $primaryColor;
      border-radius: 25px;
      color: $white;
      letter-spacing: 2.8px;
      font-family: $circularBold;
    }

    @media screen and (max-width: 767px) {
      border-top: none;
      margin-left: 0;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 30px;
      border-radius: 12px 12px 0 0;
      box-shadow: 0px 10px 51px rgba($color: $black, $alpha: 0.3);
      background-color: $white;
      span {
        display: none;
      }
      .simpleButton {
        width: 100%;
        justify-content: center;
      }
    }
    @media screen and (max-width: 476px) {
      padding: 18px;
    }
  }
}

.customModal {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 90%;
  padding: 5px 50px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 20px;
  &:focus {
    outline: none;
  }
  .header {
    text-align: left;
    font-family: $circularBold;
    color: $primaryColor;
    letter-spacing: 0px;
    font-size: 18px;
    padding: 20px 0px;
  }
  .bioDetails {
    font-family: $circularStd;
    font-size: 14px;
    margin: 20px 0;
  }
  .textarea {
    height: 250px;
    font-family: $circularStd;
    font-size: 14px;
    color: $black;
    padding: 20px;
    border: 1.5px solid;
    border-radius: 7px;
  }
  .modalBtnContainer {
    display: flex;
    justify-content: right;
    padding-bottom: 20px;
    .modalBtn {
      text-transform: uppercase;
      font-family: $circularBold;
      background-color: $primaryColor;
      border-radius: 25px;
      color: $white;
      min-width: 125px;
      height: 50px;
      border: none;
      &:disabled {
        background-color: $grey;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    a {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      margin: 0px;
      padding: 0px;
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}
