@import "../../styles/variables.scss";

.container a {
  @media screen and (max-width: 767px) {
    color: $white;
  }
  color: $grey;
}
.container a:hover {
  @media screen and (max-width: 767px) {
    color: $accentColor;
  }
  color: $primaryColor;
}
.dropSetting {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  width: 100%;
  margin-bottom: -15px;
  font-family: $circular;
  :active {
    background-color: unset;
  }
  :hover {
    background-color: unset;
  }
  p {
    font-size: 14px;
    padding-left: 10px;
    opacity: 0.7;

    @media screen and (max-width: 1366px) {
      font-size: 12px;
      padding-left: unset;
    }
  }
}

.container {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  .headerTitle {
    font-size: 24px;
    font-family: $circularBold;
    color: $primaryColor;
    margin-bottom: 0px;
    flex-grow: 1;
    &.accuminTitle{
      text-transform: uppercase;
      font-size: 27px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    height: 80px;
    width: 100%;
  }
}
.menuText {
  margin-left: 35px;
  display: flex;
  font: 500 normal 14px/142% $circular;
  text-decoration: none;
  text-transform: capitalize;
  letter-spacing: 0.76px;
  @media screen and (max-width: 1440px) {
    margin-left: 0;
  }
}
.rightNav .menuText {
  padding: 15px 5px;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  font-family: $circularStd;
  color: $black;
  letter-spacing: 0.72px;
  line-height: 0px;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }
  @media screen and (max-width: 1440px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px;
    padding: 12px 5px;
  }
}
.leftNav {
  display: flex;
  align-items: center;
  flex-grow: 1;
  .demoIndicator {
    font-family: $circularBold;
    text-transform: uppercase;
    font-size: 18px;
    @media screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
    @media screen and (max-width: 767px) {
      color: $grey;
    }
  }
}
.rightNav {
  display: flex;
  align-items: center;
  text-align: right;
  color: $grey;
  height: 100%;
  :global(.select__single-value) .optional {
    display: none;
  }
  .modalBtnContainer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    border-radius: 15px;
    background-color: rgba($grey, 0.2);
    font-family: $circularSemiBold;
    font-size: 14px;
    color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.6);
    @media screen and (max-width: 767px) {
      display: none;
    }
    svg {
      fill: $primaryColor;
      margin-right: 15px;
    }
  }

  .profileContainer {
    height: 100%;
    display: flex;
    align-items: center;
    font-family: $circular;
    position: relative;
    margin-left: 48px;
    .dropDown {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      width: 255px;
      right: 0;
      top: 20px;
      padding: 0 15px;
      background-color: $white;
      box-shadow: 0 2px 7px $light;
      z-index: 5;
      max-height: 0;
      overflow-y: hidden;
      transition:
        padding 0.2s ease-in-out,
        max-height 0.2s ease-in-out;
      :global(.MuiListItem-gutters) {
        padding-left: 0px;
        padding-right: 25px;
      }

      @media screen and (max-width: 1440px) {
        top: 69px;
        width: 210px;
      }
      @media screen and (max-width: 1366px) {
        top: 22px;
        padding: 10px 15px;
      }
    }
  }
  .rightContainerText {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    @media screen and (max-width: 767px) {
      display: none;
    }
    .fundingEstimatesText {
      color: #1b1b1b;
    }
    .fundingEstimatedValue {
      color: black;
      text-align: center;
      font-size: 24px;
      font-family: $circularBold;
    }
    span {
      margin-left: 5px;
      svg {
        cursor: pointer;
        transform: translate(0px, -1px);
        path {
          fill: $tooltipBackgroundColor;
        }
      }
    }
  }
  .rightContainer {
    display: flex;
    align-items: center;
    .loginBtn {
      border-radius: 25px;
      border: 2px solid $ctaColor;
      padding: 5px 25px;
      background: $white;
      color: $ctaColor;
      font-family: $circularStd;
    }
    .text {
      align-items: center;
      text-align: center;
      display: flex;
      font-family: $circularStd;

      h3 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        padding: 10px 31px;
        color: $helpTextColor;
        background-color: $veryLight;
        border-radius: 20px;
        margin-bottom: 0px;
        cursor: pointer;
        p {
          margin-bottom: 0;
        }
        svg {
          flex-shrink: 0;
          margin-right: 5px;
          path {
            fill: $helpTextColor;
            opacity: 0.8;
          }
        }
        @media screen and (max-width: 1366px) {
          font-size: 14px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 12px;
          padding: 10px 15px;
        }
        @media screen and (max-width: 767px) {
          padding: 0;
          height: 20px;
          width: 20px;
          background-color: $white;
          color: $black;
          &::after {
            content: "?";
            font-family: $circularBold;
            font-size: 14px;
          }
          svg,
          p {
            display: none;
          }
        }
      }
    }
    :global(.artist-select__option--is-selected) {
      .dropLabel {
        color: $primaryColor;
      }
    }
  }
  .highlightedBtn {
    background-color: $accentColor;
    font-size: 18px;
    padding: 12px 25px;
    border-radius: 30px;
    color: $white;
    font-family: $circularBold;
    cursor: pointer;
    &:hover {
      color: $white;
    }
    @media screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media screen and (max-width: 1366px) {
      padding: 6px 15px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    color: $white;
  }
}
.accountImg {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  margin: 0;
}

.title {
  font-family: $circularBold;
  font-weight: 700;
  font-size: 26px;
  float: left;
  cursor: pointer;
}
.bBLogo {
  height: 50px;
  margin-right: 20px;
  position: relative;
  display: none;
  &.adminSideLogo {
    display: flex;
  }
  @media screen and (max-width: 1440px) {
    height: 42px;
  }
  @media screen and (max-width: 1366px) {
    height: 40px;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    max-width: 160px;
    object-fit: contain;
  }
}
.menu a {
  margin: 0 20px;
  font-weight: 500;
  font-size: 18px;
  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 20px 40px;
    background-color: $dark;
    border-top: 1px solid $medium;
  }
}
.settingMenu {
  .mobileMenuItem {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
  :global(.MuiListItem-button:hover) {
    background-color: $sidebarCardButtonColor;
    color: $sidebarFontColor1;
  }
  :global(.MuiPaper-root) {
    background-color: $sidebarPrimaryBackgroundColor;
    color: $sidebarFontColor2;
    width: 100%;
    max-width: unset;
    left:0px !important;
    top: 80px !important;
    border-radius: 0px 0px 15px 15px;
  }
  :global(.MuiMenuItem-root) {
    background-color: unset;
    min-height: auto;
    padding: 10px 20px;
  }
}
.container .primary {
  color: $primaryColor !important;
}
.accImage {
  width: 35px !important;
  height: auto !important;
  margin-right: 5px;
}
.adminLink {
  margin-right: 8px !important;
}
.imgIcon {
  margin-top: -3px;
  font-size: 15px;
  margin-right: 10px;
  max-width: 29px;
  border-radius: 29px;
}
.headermain {
  background-color: $white;
  height: 100px;
  @media only screen and (max-width: 767px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    z-index: 4;
    box-shadow: 0 0 12px 2px rgba($color: $black, $alpha: 0.1);
    background-color: $sidebarPrimaryBackgroundColor;
  }
}

.spanLink {
  color: $grey;
  cursor: pointer;
  &:hover {
    color: $primaryColor;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.adminSearch {
  display: inline-block;
  width: 350px;
  position: relative;
  height: fit-content;
  i {
    display: inline-block;
    position: absolute;
    left: 13px;
    top: 14px;
    z-index: 1;
    @media only screen and (max-width: 1366px) {
      top: 9px;
      left: 10px;
    }
  }
  @media only screen and (max-width: 1366px) {
    width: 300px;
    margin-top: 0;
  }
  @media only screen and (max-width: 962px) {
    width: 200px;
  }
  @media only screen and (max-width: 767px) {
    display: none;
  }
}
.adminSearch .selectSearch > div:first-child,
.adminSearch .selectSearch > span + div {
  padding: 5px 15px 5px 35px !important;
  min-height: auto;
  @media screen and (max-width: 1366px) {
    padding: 0 15px 0 30px !important;
  }
}
.adminSearch .selectSearch > span + div + div > div:not(:empty) {
  background: #fff !important;
}
.adminSearch .selectSearch > span + div + div > div:not(:empty) * {
  background: #fff !important;
  text-align: center;
}
.adminSearch .selectSearch > span + div + div > div:not(:empty) > div * {
  text-align: left;
}
.adminSearch .selectSearch > span + div + div > div > div p {
  font-size: 16px;
  text-align: left;
}
.selectOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  .profile {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100%;
    }
    svg {
      height: auto;
      width: auto;
    }
  }
  p {
    letter-spacing: -0.79px;
    font-size: 18px;
    margin: 0;
    color: $black;
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }
  p.optional {
    font-size: 14px !important;
    color: $grey;
    line-height: 13px !important;
  }
  button {
    background-color: $dark !important;
    padding: 8px 20px !important;
    width: auto !important;
    color: $white;
    flex: 0 0 auto;
    border-radius: 2px;
  }
  button:hover {
    color: $grey;
  }
}

:global(.artist-select__menu) {
  .selectOption {
    .profile {
      height: 20px;
      width: 20px;
    }
    p {
      font-size: 16px;
      @media screen and (max-width: 1366px) {
        font-size: 12px;
      }
    }
  }
}

.selectArtistDrop {
  width: 250px;
  position: relative;

  :global(.artist-select__control) {
    padding-top: 0px;
    .labelContainer {
      width: 150px;
      @media screen and (max-width: 1366px) {
        width: 110px;
      }
    }
  }
  :global(.artist-select__value-container) {
    overflow: unset;
  }
  .dropLabel {
    color: $black;
    font-family: $circularStd;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 18px;
    overflow: hidden;
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }
  .dropBtnContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-right: 10px;
    .artistSvg {
      height: 27px;
    }
    .artistSvg path {
      fill: $black;
    }
    div {
      padding: 3px 65px 5px 0px;
      font-size: 15px;
      font-family: $circularStd;
      color: $black;
      cursor: default;

      @media screen and (max-width: 1366px) {
        padding-right: 20px;
        font-size: 12px;
      }
    }
    @media screen and (max-width: 1366px) {
      padding-right: 28px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 1366px) {
    width: 200px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.profile {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 10px;
  img {
    width: 100%;
  }
  svg {
    height: auto;
    width: auto;
  }
  @media screen and (max-width: 1366px) {
    width: 40px;
    height: 40px;
  }
}

.searchUsers {
  text-align: center;
  pointer-events: none;
}

.midContainer {
  display: flex;
}

.divider {
  display: inline-block;
  width: 2px;
  background-color: gray;
  margin: 0 10px;
  height: 2em;
}

.role {
  background-color: white;
  flex-shrink: 0;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 30px;
  border-color: $primaryColor;
}

.userProfileContainer {
  display: flex;
  align-items: center;
  font-family: $circular;
  gap: 5px;
  @media screen and (min-width: 768px) {
    display: none !important;
  }
  div {
    padding: 8px;
    @media screen and (min-width: 767px) {
      display: none;
    }
    svg {
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (max-width: 767px) {
    svg path {
      fill: $white;
    }
  }
}

.eventPopup {
  display: flex;
  flex-direction: column;
  .mainText {
    color: $black;
    span {
      color: $primaryColor;
    }
  }
  img {
    height: 83px;
  }
  button {
    border: none;
    padding: 12px 20px;
    background-color: transparent;
    border-radius: 25px;
    min-width: 150px;
    &.primaryBtn {
      color: $fundingCtaFontColor;
      background-color: $ctaColor;
      font-family: $circularBold;
      text-transform: uppercase;
      letter-spacing: 2.8px;
    }
    &.linkBtn {
      margin-top: 10px;
      color: $ctaColor;
      text-decoration: underline;
      padding: 0 20px;
    }
  }
  .promaryBtn {
    border: none;
  }
}

.separator {
  align-self: stretch;
  background-color: $veryLight;
  width: 2px;
  margin: 0px 15px 8px 25px;
  box-sizing: border-box;
  @media screen and (max-width: 1024px) {
    margin: 0 5px 0 10px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.dashboard {
  div:global(.MuiPaper-root) {
    max-width: 636px;
    background-color: $veryLight;
  }
}

.hideIcon {
  display: none;
}

.detailsPopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 90%;
  outline: none;
  background-color: #fff;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  @media screen and (max-width: 767px) {
    max-width: 400px;
  }

  .offerContainer {
    .dealContainer {
      border-bottom: 1px solid rgba($grey, 0.7);
      display: flex;
      flex-direction: column;
      padding: 20px 30px;
      .dealTextContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .dealText {
          font-size: 18px;
          font-family: $circularSemiBold;
          color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
        }
        .closeBtn {
          font-size: 40px;
          line-height: 20px;
          padding: 10px;
          height: fit-content;
          cursor: pointer;
          color: $primaryColor;
        }
      }
      .informationText {
        margin-right: 50px;
        font-family: $circularSemiBold;
        font-size: 14px;
        line-height: 20px;
        color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.6);
      }
    }
    .estimatesContainer {
      border-bottom: 1px solid rgba($grey, 0.7);
      padding: 20px 30px;
      background-color: rgba(
        $color: $sidebarPrimaryBackgroundColorRgb,
        $alpha: 0.1
      );
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .estimatesText {
        color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.7);
        font-family: $circularSemiBold;
        font-size: 14px;
        line-height: 20px;
      }
      .estimatesValue {
        color: $primaryColor;
        font-family: $circularBold;
        font-size: 24px;
        line-height: 32px;
        margin-top: 5px;
      }
    }
    .catalogContainer {
      border-bottom: 1px solid rgba($grey, 0.7);
      display: flex;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      .catalogText {
        display: flex;
        padding: 15px 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        font-family: $circularSemiBold;
        font-size: 15px;
        color: $primaryColor;
        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 15px 30px 0 30px;
        }
      }
      .catalogLine {
        margin: 15px 0px;
        border-right: 1px solid rgba($grey, 0.7);
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .termContainer {
        display: flex;
        width: 50%;
        padding: 15px 30px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media screen and (max-width: 767px) {
          flex-direction: row;
          width: 100%;
          padding: 5px 30px 20px 30px;
        }
        .termText {
          color: $primaryColor;
          font-size: 14px;
          font-family: $circularSemiBold;
        }
        .incomeText {
          color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.9);
          font-size: 14px;
          font-family: $circularStd;
          line-height: 20px;
          @media screen and (min-width: 768px) {
            display: flex;
          }
        }
      }
    }
    .btnContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      padding: 15px 30px;
      button,
      a {
        border: none;
        padding: 13px 20px;
        border-radius: 25px;
        background-color: transparent;
        color: $primaryColor;
        text-decoration: underline;
        font-size: 14px;
        &.primaryBtn {
          background-color: $primaryColor;
          color: $fundingCtaFontColor;
          margin-left: auto;
          text-decoration: none;
          font-family: $circularBold;
          text-transform: uppercase;
          min-width: 175px;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
        &.returnBtn {
          padding: 0;
          @media screen and (max-width: 767px) {
            margin-top: 15px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
      }
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}

.helperText {
  width: 100%;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
  border-radius: 13px;
  padding: 14px 18px;
  font-size: 12px;
  .text {
    padding: 0 10px;
    color: $helpTextColor;
    display: flex;
    text-align: start;
  }
  .svgIcon {
    width: 17px;
    height: 17px;
    flex-grow: 0;
    flex-shrink: 0;
    path {
      fill: $helpTextColor;
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
