@import "../../../styles/variables.scss";

.articleImg {
  img {
    height: 50px;
    width: 50px;
    border-radius: 8px;
  }
}
.actionIcon {
  display: flex;
  align-items: center;
  .edit {
    cursor: pointer;
    color: $primaryColor;
    font-family: $circular;
    text-decoration: underline;
  }
  svg {
    height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }
}
.width {
  width: unset;
}
.disableContent {
  color: $grey !important;
  cursor: default !important;
}
.disableDelete {
  opacity: 0.6;
  cursor: default !important;
}

.iconContainer {
  svg {
    cursor: pointer;
  }
}
