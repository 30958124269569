@import "./variables.scss";

/* Common Global styles */

:root {
  --primary-color: #310ae3;
  --cta-font-color: #ffffff;

  --app-primary-background-color: #310ae3;
  --app-header-background-color: #5a3be9;
  --app-header-font-color: #ffffff;
  --accent-font-color: #310ae3;
  --cta-color: #000000;

  --sidebar-primary-background-color: #310ae3;
  --sidebar-header-footer-color: #5a3be9;
  --sidebar-font-color-1: #ffffff;
  --sidebar-font-color-2: #5a3be9;
  --sidebar-tooltip-icon-color: #ffffff;
  --sidebar-text-icon-color: #ffffff;
  --sidebar-card-button-color: #5a3be9;

  --progress-accent-color: #310ae3;
  --progress-update-color: #eb33fd;

  --tooltip-background-color: #ffffff;
  --tooltip-font-color: #000000;
  --alert-background-color: #000000;
  --alert-font-color: #ffffff;

  --slider-color: #310ae3;
  --help-text-color: #310ae3;

  --funding-cta-font-color: #ffffff;
  --funding-cta-bg-color: #000000;

  --spotify-card-background-color: #310ae3;

  --login-nav-background-color: #ffffff;
  --login-nav-font-color: #310ae3;
  --login-bg-color: #310ae3;

  --light: #e6e6e6;
  --dark-grey-color: #cacaca;
}

:export {
  primaryColor: var(--primary-color);
  fundingCtaFontColor: var(--cta-font-color);

  appPrimaryBackgroundColor: var(--app-primary-background-color);
  appHeaderBackgroundColor: var(--app-header-background-color);
  appHeaderFontColor: var(--app-header-font-color);
  appAccentFontColor: var(--accent-font-color);
  ctaColor: var(--cta-color);

  sidebarPrimaryBackgroundColor: var(--sidebar-primary-background-color);
  sidebarHeaderFooterColor: var(--sidebar-header-footer-color);
  sidebarFontColor1: var(--sidebar-font-color-1);
  sidebarFontColor2: var(--sidebar-font-color-2);
  sidebarTooltipIconColor: var(--sidebar-tooltip-icon-color);
  sidebarTextIconColor: var(--sidebar-text-icon-color);
  sidebarCardButtonColor: var(--sidebar-card-button-color);

  progressAccentColor: var(--progress-accent-color);
  progressUpdateColor: var(--progress-update-color);

  tooltipBackgroundColor: var(--tooltip-background-color);
  tooltipFontColor: var(--tooltip-font-color);
  alertBackgroundColor: var(--alert-background-color);
  alertFontColor: var(--alert-font-color);

  sliderColor: var(--slider-color);
  helpTextColor: var(--help-text-color);
  fundCtaFontColor: var(--funding-cta-font-color);
  fundCtaBgColor: var(--funding-cta-bg-color);

  partnerCardBgColor: var(--spotify-card-background-color);

  navigationBgColor: var(--login-nav-background-color);
  navigationTextColor: var(--login-nav-font-color);
  loginBgColor: var(--login-bg-color);

  light: var(--light);
  darkGreyColor: var(--dark-grey-color);
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
}

body {
  font-family: $circularStd;
  background-color: $white;
  color: $black;
  letter-spacing: 0;
  @media (min-width: 639px) {
    background-color: $appPrimaryBackgroundColor;
  }
}
/* Common Global styles */

/* ==== Utility Styles ==== */

.theme-bg-dark {
  background-color: $appPrimaryBackgroundColor;
}

.textPrimary {
  color: $primaryColor;
}

.textMuted {
  color: $grey;
}

.textSmall {
  font-size: 80%;
}

.textSize14 {
  font-size: 14px;
  @media screen and (max-width: 1366px) {
    font-size: 12px;
  }
}

.textSize16 {
  font-size: 16px;
}

.textBold {
  font-weight: bold;
}

/* ==== Utility Styles ==== */

/* ===== Header ==== */
.header {
  background-color: black;
  padding: 20px 20px 20px 20px;
}
.img-circle {
  height: 100px;
  width: 100px;
  border-radius: 100px;
  margin-left: 15px;
}

.header-text {
  font-size: 25px;
  font-weight: bold;
  color: white;
  margin-left: 15px;
}

/* =====Footer==== */
.footer-circle {
  height: 60px;
  width: 60px;
  border-radius: 100px;
  padding: 3px;
  border: 2px solid #c0c1c1;
  margin-left: 10px;
}

.footer-text {
  color: white;
  padding: 10px;
  font-size: larger;
  display: inline-block;
}

.footerhline {
  border-top: 2px solid;
  margin-left: -10px;
  margin-right: -10px;
}
.footer {
  background-color: black;
  padding: 25px 10px 10px 10px;
}
.btnadmin {
  background: #01bc82;
  width: 15%;
  padding: 7px;
  display: block;
  text-align: center;
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
  font-size: large;
  height: 41px;
  font-weight: bold;
  float: right;
}

.footer-button {
  padding: 10px 5px 40px 5px;
  background-color: #c0c1c1;
}

.content {
  margin: 0px 5px 0px 5px;
  background-color: white;
  padding: 40px 10px 0px 10px;
}
.final-step .content {
  margin: 5px 5px 0px 5px;
  padding: 20px 10px 0px 10px;
}
.hline {
  margin-left: -10px;
  margin-right: -10px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.mainheading {
  color: cornflowerblue;
  font-weight: bold;
  font-size: 30px;
}
.contentborder {
  background-color: #c0c1c1;
  height: 100%;
  display: table;
  width: 100%;
}
.sequence {
  height: 50px;
  width: 50px;
  background-color: blue;
  border-radius: 100px;
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: bold;
  padding-top: 4px;
}
.advanceterm {
  display: table;
  width: 100%;
  padding: 5px;
  padding-bottom: 20px;
}
.advance-content {
  background-color: white;
  padding: 15px 10px;
  line-height: 35px;
}
.bg-grbutton {
  padding: 10px 5px 15px 5px;
}
.advance-note {
  padding: 10px;
  font-size: large;
}
.text-header {
  margin: 5px 5px 5px 5px;
  display: table-cell;
  vertical-align: middle;
  background-color: white;
  height: 100px;
  color: cornflowerblue;
  font-weight: bold;
  font-size: 30px;
  padding: 10px;
}

.final-step .text-header {
  height: auto;
}
.advance-share {
  padding-left: 15px;
  display: block;
}
.advance-summary {
  display: block;
  width: 100%;
  padding: 5px;
  padding-bottom: 10px;
}
.advance-text {
  letter-spacing: 3px;
  padding: 0px 10px 0px;
}
.advance-total {
  float: right;
  padding-right: 10px;
  font-weight: bold;
}
.advance-price {
  float: right;
  padding-right: 10px;
  font-weight: bold;
}
.content-header {
  width: 80px;
  display: inline-block;
  vertical-align: top;
}

.trueSign {
  height: 50px;
  width: 50px;
  border-radius: 100px;
  text-align: center;
  color: blue;
  font-size: 50px;
  font-weight: bold;
  padding-top: 4px;
}

.content-body {
  width: calc(100% - 80px);
  display: inline-block;
  padding: 0 80px 8px 0;
}
.content-body .text {
  padding: 12px 0 0px;
  font-size: larger;
  font-weight: bold;
}
.button {
  background: #000;
  width: 100%;
  padding: 18px;
  display: block;
  text-align: center;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: large;
  font-weight: bold;
}

.text-main {
  font-size: larger;
}
.bg-gray {
  background-color: #c0c1c1 !important;
  color: black;
}
.font-gray {
  color: #c0c1c1;
  padding: 10px 20px;
}
.font-gray a {
  color: #c0c1c1;
}
.font-gray a.home-link {
  font-weight: bold;
}
.ul-header {
  display: flex;
  float: right;
  list-style: none;
}
.li-header {
  padding: 0px 15px;
}
.bg-black {
  background-color: black;
}
.btnpadd {
  padding: 30px 18px;
  font-size: larger;
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $white;
    color: $black;
    margin-right: 15px;
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

/* ==== Form Styles ==== */

.form-group {
  position: relative;
  width: 100%;
  text-align: left;
  color: $white;
  & > div.mb-4 {
    padding-top: 20px;
    position: relative;
  }
}

.form-group label {
  color: $primaryColor;
  font-family: $circularStd;
}

.form-group-m0 div:last-child {
  margin-bottom: 0;
}

.form-group label.floating {
  width: fit-content;
  padding: 0 0 5px;
  font-size: 16px;
  transform: translate(0px, 26px);
  left: 0;
  top: 0;
  pointer-events: none;
  position: absolute;
  transition:
    transform 0.3s,
    font-size 0.3s,
    color 0.3s,
    font-family 0.6s;
  color: $grey;
  font-family: $circularStd;
  font-weight: normal;
  text-transform: capitalize;
  &.icon {
    transform: translate(50px, 26px);
  }
  &.active {
    font-size: 13px;
    transform: translateY(0);
    color: $black;
    font-family: $circularStd;
  }
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 5px 0px;
  background-color: $white;
  border: none;
  border-bottom: 1px solid $darkGreyColor;
  color: $primaryColor;
  border-radius: 2px;
  outline: none;
  font-family: $circularBold;
  &::placeholder {
    padding-left: 0px;
    color: transparent;
    transition: color 0.3s;
  }
  &:focus::placeholder {
    color: $darkGreyColor;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.form-group input:focus + label.floating,
.form-group textarea:focus + label.floating,
.form-group select:focus + label.floating,
.form-group input:not(:placeholder-shown) + label.floating,
.form-group textarea:not(:placeholder-shown) + label.floating,
.form-group select:not(:placeholder-shown) + label.floating {
  font-size: 13px;
  transform: translateY(0);
  color: $black;
  font-family: $circularStd;
}

.form-group input:-webkit-autofill {
  background-color: $white !important;
  color: $primaryColor !important;
  -webkit-text-fill-color: $primaryColor !important;
  -webkit-box-shadow: 0 0 0 40px $white inset;
}

.form-group button {
  width: 100%;
  padding: 20px;
  background-color: transparent;
  color: $ctaColor;
  border: 1px solid $ctaColor;
  border-radius: 2px;
  font-family: $circularBold;
  font-size: 18px;
  margin-top: 10px;
}

.form-group button:disabled {
  cursor: not-allowed;
  border: 1px solid $grey;
  color: $grey;
}

/* ==== Form Styles ==== */

/* ==== Tostify style ==== */

.Toast {
  position: fixed !important;
  z-index: 100000;
  width: 100%;
  max-width: 400px;
  margin: 0;
  padding: 0;
  cursor: default;
  top: 1em;
  border-radius: 3px 3px 0 3px;
  overflow: hidden;
}

.Toastify > div {
  margin-top: 30px;
  @media screen and (max-width: 480px) {
    margin-top: 20px;
    width: 80%;
    left: unset;
  }
}

.Toastify__toast {
  min-height: 0px !important;
}

.Toast > div {
  padding: 15px 50px 15px 40px !important;
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.05);
  font-size: 14px;
  color: $dark;
  background: $white;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 3px;

  .Toast > div::before {
    position: absolute;
    width: 10px;
    height: 100%;
    top: 0;
    left: 0;
    content: "";
    background: $primary;
  }

  .Toastify__toast-body {
    font-family: $circularStd;
    display: inline-block;
    text-align: left;
  }

  .Toast a,
  .Toast a:hover {
    color: $primary;
    text-decoration: underline;
    font-weight: 600;
  }

  .Toastify__close-button {
    text-indent: -9999px;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &::before,
    &::after {
      content: "+";
      position: absolute;
      right: 15px;
      top: 10px;
      background: $black;
      font-size: 16px;
      width: 10px;
      height: 2px;
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(135deg);
    }
  }
}

.Toastify__toast--error {
  border-left: 7px solid $error !important;
}

.Toastify__toast--success {
  border-left: 7px solid $success !important;
}
.Toastify__toast--error {
  border-left: 7px solid $error !important;
}

.Toastify__toast--info {
  border-left: 7px solid $darkYellow !important;
}

.toast_hidden {
  visibility: hidden;
}
/* ==== Tostify style ==== */

/* ==== Rich text editor ==== */

.tox-notifications-container {
  display: none !important;
}

/* ==== Rich text editor ==== */

.react-tel-input .country-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.react-tel-input .country-list::-webkit-scrollbar {
  width: 6px;
}

.react-tel-input .country-list::-webkit-scrollbar-thumb {
  background-color: $light;
  border-radius: 5px;
  &:hover {
    background-color: $darkGreyColor;
  }
}
