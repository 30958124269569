@import "../../styles/variables.scss";

.progressBar {
  width: 100%;
  height: 4px;
  background-color: $light;
}

.err {
  background-color: $bbError !important;
}

.pending {
  background-color: $helpTextColor;
}

.success {
  background-color: $helpTextColor;
}

.progress {
  height: 100%;
  margin: 0;
}
