@import "../../../styles/variables.scss";

.pageContainer {
  display: flex;
  flex: 1;
  width: 100%;
  background: $light;
  .mainContainer {
    background: $veryLight;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    margin-top: 0px;
    position: relative;
    .scrollContainer {
      height: 100%;
      max-height: calc(100vh - 100px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;

      @media screen and (max-width: 1440px) {
        max-height: calc(100vh - 100px);
      }
      @media screen and (max-width: 1366px) {
        max-height: calc(100vh - 100px);
      }
      @media screen and (max-width: 767px) {
        padding: 0px;
        max-height: unset;
        height: unset;
        padding-bottom: 0px;
      }
      .container {
        display: flex;
        flex-direction: column;
        max-width: 1366px;
        margin: 40px auto;
        text-align: left;
        justify-content: center;
        border-radius: 10px;
        h2 {
          color: $black;
        }
        button {
          border: none;
          text-decoration: underline;
          background: $white;
        }
        @media screen and (max-width: 1024px) {
          margin: unset;
        }
      }
    }
    @media screen and (max-width: 767px) {
      flex-grow: 1;
      max-height: unset;
      padding: 0px;
    }
    @media screen and (max-width: 529px) {
      padding: 0;
      min-height: calc(100vh - 167px);
    }
  }

  @media screen and (max-width: 767px) {
    background-color: unset;
    width: 100%;
    margin: unset;
  }

  @media screen and (max-width: 767px) {
    padding: 0px;
    height: 100%;
  }
}

.container {
  .separator {
    align-self: stretch;
    background-color: hsl(0, 0%, 80%);
    width: 2px;
    opacity: 0.7;
    margin: 60px 0px 15px 0px;
    box-sizing: border-box;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 0px;
  }
  .overViewContainers {
    padding: 80px 80px;
    padding-right: 20px;
    display: flex;
    max-width: 1200px;
    margin: 40px auto;
    text-align: left;
    justify-content: center;
    background: $white;
    border-radius: 10px;

    .subOverView {
      .contactUsLink {
        text-decoration: underline;
        color: $primaryColor;
        cursor: pointer;
      }
      &.headingBig {
        h2 {
          font-size: 22px;
          font-family: $circularStd;
          letter-spacing: 0px;
          @media screen and (max-width: 767px) {
            font-size: 20px;
          }
        }
      }
      .pendingButtonContainer {
        display: flex;
        flex-direction: column;
        .updateLinkBtn {
          font-size: 14px;
          font-family: $circularBold;
          text-decoration: none;
          border: none;
          background: $ctaColor;
          padding: 12px 25px;
          border-radius: 25px;
          margin-top: 10px;
          margin-bottom: 10px;
          letter-spacing: 2.8px;
          color: $fundingCtaFontColor;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          &.noThanksBtn {
            background: $white;
            border: 2px solid $ctaColor;
            color: $ctaColor;
          }
          @media screen and (max-width: 767px) {
            font-size: 10px;
          }
        }
      }
      &.secondOverView {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;

        h2 {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .pendingSubOverView {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
      .pendingBtnContainer {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;
        button {
          font-size: 14px;
          text-decoration: underline;
          color: $primaryColor;
        }
      }
      .pendingPara {
        padding-top: 18px;
      }
      .artistName {
        color: $primaryColor;
      }
      h2 {
        font-size: 18px;
        letter-spacing: 0px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      .loaderIcon {
        img {
          height: 50px;
        }
      }
      .stepsListing {
        font-size: 14px;
        padding: 10px 0px;

        ol {
          width: 100%;
          min-width: 65%;
          padding-inline-start: 0px;
        }
        li {
          display: flex;
          padding: 8px 0;
          counter-increment: inst;
          text-align: left;
        }
        li::before {
          content: counter(inst);
          display: flex;
          align-items: center;
          justify-content: center;
          background: $primaryColor;
          border-radius: 50%;
          width: 21px;
          height: 21px;
          color: #fff;
          margin-right: 5px;
          flex-shrink: 0;
        }
      }
      @media screen and (max-width: 1024px) {
        background: $white;
        padding: 25px;
        border-radius: 12px;
        box-shadow: 0px 3px 6px #0000000b;
      }
    }
    @media screen and (max-width: 1366px) {
      max-width: 1215px;
      padding: 80px 30px;
    }
    @media screen and (max-width: 1024px) {
      display: flex;
      padding: 20px;
      margin: 0px;
      background-color: $veryLight;
      flex-direction: column-reverse;
      max-width: 500px;
    }
  }
  h2 {
    color: $black;
  }

  .overViewContainer {
    width: 60%;
    display: flex;
    padding: 40px;
    justify-content: center;
    flex-direction: column;
    &.smallOverview {
      .smallTitle {
        font-size: 15px;
        font-family: $circularStd;
      }
      ul {
        padding-top: 10px;
        font-size: 13px;
        font-family: $circularStd;
      }
      @media screen and (max-width: 767px) {
        padding: unset;
      }
    }
    &.overView {
      width: 38%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }
    &.publicProfile {
      width: 51%;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
    }

    p {
      font-size: 14px;
      margin: 0;
      font-family: $circularStd;
      button {
        text-decoration: none;
        color: $primaryColor;
      }
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
    h2 {
      font-size: 18px;
      letter-spacing: 0px;
      font-family: $circularStd;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    .sorryIcon {
      padding-bottom: 30px;
      &.smileIcon {
        svg {
          path {
            fill: $primaryColor;
          }
        }
      }
      svg {
        path:global(#primary-color-svg) {
          fill: $primaryColor;
        }
        @media screen and (max-width: 1024px) {
          height: 50px;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 0px;
      margin-top: 20px;
    }
  }
  .inputContainer {
    display: flex;
    width: 50%;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    p {
      font-size: 13px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
    .formContainer {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: unset;
      }
    }
    .formField {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      max-width: 275px;
      width: 250px;
      .span {
        margin-right: 20px;
      }

      padding: 10px;
      align-items: center;
      border: 1px solid #707070;
      border-radius: 6px;

      @media screen and (max-width: 1440px) {
        max-width: 300px;
        width: 270px;
      }

      span {
        font-size: 12px;
        opacity: 0.8;
        font-family: $circularStd;
      }
      .email {
        color: $primaryColor;
      }

      :global(.mb-4) {
        margin-bottom: 0px !important;
        height: 2rem;
      }

      @media screen and (max-width: 1024px) {
        max-width: unset;
        width: 100%;
        display: flex;
      }
    }
    .btnContainer {
      display: flex;
      justify-content: flex-end;
      padding: 0px;
      background: $white;
      .btnColor {
        color: $primaryColor;
        font-family: $circularStd;
        font-size: 15px;
        @media screen and (max-width: 767px) {
          font-size: 10px;
        }
      }
      @media screen and (max-width: 1024px) {
        padding-top: 25px;
      }
    }
    @media screen and (max-width: 1366px) {
      padding-right: 0px;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 0px;
    }
  }
  button {
    border: none;
    text-decoration: underline;
    background: $white;
  }
}

.popover {
  border-radius: 0;
  .titlePopover {
    font-weight: 600;
    font-size: 15px;
    color: $dark;
    text-align: center;
  }
  .btnPopover {
    display: flex;
    button {
      padding: 8px 30px;
      font-weight: bold;
      color: $white;
      border: none;
      margin: auto;
      &:first-child {
        background-color: $primaryColor;
      }
      &:last-child {
        background-color: $grey;
      }
    }
  }
}
