@import "../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 30px 40px;
  width: 100%;
  flex-grow: 1;
  background-color: $veryLight;
  .subContainer {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 15px;
    .titleContainer {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
      padding: 20px 30px;
      h2 {
        font-size: 30px;
        font-family: $circularBold;
        margin: 0;
        letter-spacing: 0;
        @media screen and (max-width: 767px) {
          font-size: 24px;
        }
      }
      p {
        font-size: 14px;
        margin: 0;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 15px;
      }
    }
    .comparisonContent {
      display: grid;
      .columnHeader {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        color: $sidebarPrimaryBackgroundColor;
        span {
          text-transform: uppercase;
          font-size: 14px;
          font-family: $circularBold;
          @media screen and (max-width: 767px) {
            font-size: 10px;
          }
        }
      }
    }
    .firstRow {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .logoContainer {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          height: 20px;
          object-fit: contain;
        }
        span {
          font-size: 14px;
          font-family: $circularSemiBold;
          white-space: nowrap;
          @media screen and (max-width: 767px) {
            font-size: 12px;
            white-space: unset;
          }
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
      }
      .total {
        font-size: 20px;
        font-family: $circularBold;
        color: $black;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      .offerBtn {
        display: none;
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        @media screen and (max-width: 767px) {
          display: flex;
        }
      }
      @media screen and (max-width: 767px) {
        gap: 5px;
      }
    }
    .tableContainerDesktop {
      :global(.MuiTable-root) {
        @media screen and (max-width: 767px) {
          table-layout: fixed;
        }
      }
      :global(.MuiTableCell-body) {
        padding: 9px 25px;
        min-width: 250px;
        font-size: 18px;
        text-align: center;
        font-family: $circular;
        border-bottom-color: transparent;
        border-right: 1px solid
          rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.2);
        @media screen and (max-width: 767px) {
          min-width: 33.33%;
          width: 33.33%;
          padding: 9px 15px;
          font-size: 14px;
          border-bottom: none;
          display: table-cell;
          z-index: 0;
          scroll-snap-align: end;
        }
      }
      tbody tr:first-child td {
        border-bottom: 1px solid
          rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.2);
        border-right: 1px solid transparent;
        @media screen and (max-width: 767px) {
          font-size: 12px;
          border-bottom: 1px solid $greyColor;
        }
      }
      tbody tr:last-child td {
        padding: 20px 25px 35px;
        @media screen and (max-width: 767px) {
          padding: 9px 15px;
        }
      }
      tbody tr:first-child td:nth-child(2) {
        border-bottom-color: rgba($color: $white, $alpha: 0.2);
        .total {
          color: $sidebarTooltipIconColor;
        }
        @media screen and (max-width: 767px) {
          border-bottom: 1px solid $greyColor;
        }
      }
      tbody tr td:nth-child(2) {
        background-color: $sidebarPrimaryBackgroundColor;
        color: $white;
        img {
          filter: grayscale(1) brightness(999) contrast(1);
        }
        .offerBtn {
          background-color: $sidebarTooltipIconColor;
          color: $sidebarPrimaryBackgroundColor;
        }
        @media screen and (max-width: 767px) {
          z-index: 1;
          position: sticky;
          left: 33.33%;
        }
      }
      tbody tr td:first-child {
        background-color: rgba($color: $primaryColorRgb, $alpha: 0.05);
        text-align: right;
        font-size: 14px;
        color: $black;
        font-family: $circularBold;
        @media screen and (max-width: 767px) {
          font-size: 12px;
          text-align: left;
          background-color: $sidebarFontColor2;
          z-index: 1;
          position: sticky;
          left: 0;
        }
      }
      .linkBtn {
        font-size: 12px;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        padding: 0;
        text-decoration: underline;
        color: $primaryColor;
        font-family: $circularBold;
        @media screen and (max-width: 767px) {
          font-size: 10px;
        }
      }
      .offerBtn {
        background-color: $ctaColor;
        color: $white;
        padding: 4px 20px;
        border: none;
        border-radius: 25px;
        font-size: 14px;
        font-family: $circularBold;
        text-transform: uppercase;
        cursor: pointer;
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        @media screen and (max-width: 767px) {
          font-size: 10px;
          padding: 4px 10px;
        }
      }
      @media screen and (max-width: 767px) {
        scroll-snap-type: x mandatory;
      }
    }
    .btnContainer {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      button {
        font-size: 14px;
        background-color: transparent;
        border: none;
        padding: 0;
        color: $primaryColor;
        text-decoration: underline;
        cursor: pointer;
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      @media screen and (max-width: 767px) {
        padding: 15px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.featureDataModal {
  display: flex;
  flex-direction: column;
  max-width: 630px;
  width: 90%;
  outline: none;
  padding: 40px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    line-height: 18px;
    padding: 10px;
    cursor: pointer;
  }
  .modalContent {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    @media screen and (max-width: 767px) {
      row-gap: 20px;
    }
  }
  .firstContainer {
    color: $primaryColor;
    font-size: 18px;
    font-family: $circularBold;
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  .secondContainer {
    min-height: 300px;
    max-height: 377px;
    overflow-y: auto;
    font-size: 14px;
    li::before {
      box-sizing: content-box;
    }
    @media screen and (max-width: 767px) {
      font-size: 12px;
      max-height: calc(100vh - 200px);
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}
