@import "../../styles/variables.scss";

.modalOverlay {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(
      $color: $sidebarPrimaryBackgroundColorRgb,
      $alpha: 0.95
    );
    overflow: auto;
    z-index: 4;
    backdrop-filter: blur(4px);
}


  .detailsPopupContainer {
    display: flex;
    flex-direction: column;
    max-width: 516px;
    width: 90%;
    outline: none;
    padding: 20px 0px;
    background-color: #fff;
    margin: auto;
    position: relative;
    box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
    border-radius: 12px;
    .closeBtn {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      line-height: 18px;
      padding: 10px;
      cursor: pointer;
    }
    .firstContainer{
        padding: 20px 40px 10px 40px;
        border-bottom: 1px solid $grey;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        p.name{
            font-size: 18px;
            font-weight: bold;
            color: $primaryColor;
            text-align: left;
            width: 100%;
        }
        span{
            font-size: 14px;
            font-family: $circular;
            color: $black;
            text-align: left;
        }

        .videoThumbnail{
            padding: 5px 0px;
            width: 100%;
            img{
                max-height: 240px;
                height: 1005;
                max-width: 440px;
                width: 100%;
                border-radius: 12px;
                object-fit: cover;
                cursor: pointer;
            }
            iframe {
                min-width: 440px;
                min-height:240px;
                width: 100%;
                height: 100%;
              }
            .playContainer {
                position: absolute;
                top:48%;
                right: 43%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $white;
                height: 70px;
                width: 70px;
                border-radius: 35px;
                padding: 10px;
                box-shadow: 0 12px 20px rgba($color: $black, $alpha: 0.2);
                svg {
                  width: 100%;
                  height: 100%;
                  cursor: pointer;
                  path {
                    fill: $primaryColor;
                  }
                }
               
              }

              @media screen and (max-width:767px) {
                padding: 5px 15px;
              }

              @media screen and (max-width:479px) {
                iframe{
                  min-width: 100px;
                  min-height: 100%;
                }
                .playContainer{
                  top: 52%;
                  right: 40%;
                }
              }
        }

        .orElem{
            display: inline-block;
            color:$grey;
            border: 1px solid $grey;
            border-radius: 50%;
            padding: 0px 5px;
            position: relative;
            transform: translateY(90%);
            background-color: $white;
        }

        @media screen and (max-width:767px){
          padding: 20px 20px 10px 20px;

        }
    }

    

    .secondContainer{
        display: flex;
        padding: 0px 40px;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
            
        span{
            font-size: 14px;
            font-family: $circular;
            text-align: left;
            margin-right: 40px;
        }
        .requestCallBtn {
            padding: 15px 25px;
            color: $white;
            background-color: $primaryColor;
            border-radius: 25px;
            font-size: 12px;
            letter-spacing: 2.1px;
            text-transform: uppercase;
            font-family: $circularBold;
            border: none;
            white-space: nowrap;
            @media screen and (max-width: 479px) {
            padding: 10px;
            white-space: nowrap;
            }
          }

          @media screen and (max-width:767px) {
            padding: 0px 20px;
          }

          @media screen and (max-width:479px) {
            flex-direction: column;
            align-items: flex-start;
            span{
              margin-right: 0px;
              margin-bottom: 5px;
            }
            .requestCallBtn{
              width: 100%;
              text-align: center;
            }
          }
      }

    
}