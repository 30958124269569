@import "../../styles/variables.scss";

.modalOverlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 6;
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    justify-content: unset;
    align-items: unset;
  }
  @media screen and (max-height: 700px) {
    align-items: unset;
  }
}
.contactModal {
  display: flex;
  flex-direction: column;
  max-width: 830px;
  width: 90%;
  background-color: $white;
  margin: 20px auto 78px;
  align-items: center;
  border: none;
  height: fit-content;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  padding: 0 40px;
  .title {
    border-bottom: 2px solid $light;
    margin-bottom: 10px;
    width: 100%;
    font-family: $circular;
    letter-spacing: -2.5px;
    h3 {
      text-align: center;
      font-weight: bold;
      letter-spacing: -0.86px;
      color: $primaryColor;
      line-height: 26px;
      margin: 30px auto;
    }
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  text-align: start;
  padding: 30px;
  @media screen and (max-width: 767px) {
    padding: 30px;
    width: 100%;
  }
}
.modalBody {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .emailField {
    input {
      font-size: 14px;
      border-bottom: 2px solid $grey;
    }
  }
  form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    textarea {
      width: 100%;
      min-height: 100px;
      height: 188px;
      outline: none;
      border: 2px solid $grey;
      border-radius: 10px;
      font-size: 14px;
      padding: 10px;
    }
    textarea + div {
      margin-top: -3px;
    }
    label {
      font-size: 14px;
      font-family: $circularStd;
    }
  }
}
.issueBox {
  label {
    color: $black;
  }
}
.logoContainer {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  img {
    width: auto;
    max-height: 60px;
    object-fit: contain;
    flex-shrink: 0;
    height: 30px;
  }

  .closeIcon {
    justify-content: flex-end;
    display: none;
    span {
      font-size: 16px;
      font-family: $circularBold;
      color: $primaryColor;

      @media screen and (max-width: 767px) {
        padding-right: 10px;
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }
}
.title {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  font-family: $circular;
  letter-spacing: -2.5px;
  padding-top: 40px;
  h3 {
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.86px;
    color: $primaryColor;
    line-height: 26px;
    font-size: 32px;
    font-family: $circularBold;
    span {
      color: $sidebarPrimaryBackgroundColor;
    }
    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }
}
.chatForm {
  a {
    color: $ctaColor;
    text-decoration: underline;
    font-family: $circularBold;
    font-size: 12px;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 5px;
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    background-color: $ctaColor;
    color: $fundingCtaFontColor;
    padding: 15px 35px;
    min-width: 200px;
    font-family: $circularBold;
    border: none;
    border-radius: 25px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 15px;
    &:disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }
    @media screen and (max-width: 767px) {
      padding: 10px 20px;
      font-size: 12px;
      margin-top: 10px;
      text-align: center;
      margin-bottom: 5px;
    }

    form {
      width: 100%;
      padding: 20px;
      margin: auto;

      textarea {
        width: 100%;
        min-height: 100px;
        outline: none;
      }
      textarea + div {
        margin-top: -3px;
      }
      label {
        font-size: 15px;
        margin-bottom: 0;
      }
    }
  }

  .btnLink {
    border: none;
    background-color: transparent;
    padding: 8px 16px;
    display: flex;
    color: $black;
    margin-top: 10px;
    text-decoration: underline;
    text-transform: unset;
    font-family: $circularStd;
    &:hover {
      text-decoration: underline;
    }
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column-reverse;
    align-items: unset;
  }
}
