@import "../../styles/variables.scss";

.mainContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  bottom: 0;
  flex: 1.25;
  min-width: min-content;
  row-gap: 20px;
  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    overflow: visible;
    position: fixed;
    z-index: 4;
    height: unset;
    align-items: center;
    justify-content: space-between;
    min-width: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
    max-height: calc(100vh - 70px);
  }
}
.container {
  background-color: $sidebarPrimaryBackgroundColor;
  min-width: min-content;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 0;
  bottom: 0;
  color: $sidebarFontColor1;
  border-radius: 12px;
  flex: 1.25;
  @media screen and (max-width: 767px) {
    overflow: visible;
    position: fixed;
    z-index: 4;
    height: unset;
    align-items: center;
    justify-content: space-between;
    min-width: auto;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 12px 12px 0 0;
    max-height: calc(100vh - 70px);
  }
  .menuBarContainerBox {
    width: 100%;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
    @media screen and (max-width: 767px) {
      border: none;
      &.active {
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
        overflow: auto;
        height: fit-content;
      }
    }
  }
  .seeMoreLnk {
    display: none;
    background-color: $sidebarPrimaryBackgroundColor;
    padding: 10px;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    p {
      margin: 0;
      font-size: 14px;
      font-family: $circularBold;
    }
    span {
      display: flex;
      width: 60px;
      height: 60px;
      background-color: $sidebarPrimaryBackgroundColor;
      position: absolute;
      transform: translateY(50%);
      bottom: 0;
      z-index: -1;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      &::before {
        content: " ";
        width: 115%;
        background: $sidebarPrimaryBackgroundColor;
        position: absolute;
        height: 6px;
        transform: translateY(47%);
        clip-path: polygon(
          100% 0,
          0 0,
          1% 5%,
          4% 15%,
          7% 50%,
          93% 50%,
          96% 15%,
          99% 5%
        );
      }
      &::after {
        content: " ";
        width: 10px;
        height: 10px;
        border-bottom: 2px solid $sidebarFontColor1;
        display: block;
        border-right: 2px solid $sidebarFontColor1;
        transition: 0.5s cubic-bezier(0.64, 0, 0.78, 0);
        transform: translateY(90%) rotate(45deg);
      }
    }
    &.showMore span::after {
      transform: translateY(90%) rotate(-135deg);
    }
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
  > p {
    margin-top: auto !important;
    margin-bottom: 85px;
    font-size: 12.5px;
    padding: 20px 20px 0;
    a {
      text-decoration: underline;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  div.collapseMenu {
    @media screen and (max-width: 767px) {
      max-height: 0;
      overflow: hidden;
      padding: 0;
      transition:
        max-height 0.3s,
        padding 0.3s;
      &.active {
        max-height: 900px;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($color: $white, $alpha: 0.3);
    max-height: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $white, $alpha: 0.5);
  }
}
.helperText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
  border-radius: 13px;
  opacity: 0.1px;
  padding: 14px 18px;
  font-size: 14px;
  .text {
    padding: 0 10px;
    color: $helpTextColor;
    display: flex;
    text-align: start;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.svgIcon {
  width: 17px;
  height: 17px;
  flex-grow: 0;
  flex-shrink: 0;

  path {
    fill: $helpTextColor;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.summaryContainer {
  display: none;
  .digitDetails {
    display: none;
  }
  .seeMoreLnk {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.partnerFlow {
  position: relative;
  z-index: 1;
  .partnerBar {
    .collapseArrow {
      display: none;
    }
    @media screen and (max-width: 767px) {
      position: relative;
      width: 100%;
      border-radius: 12px;
      &.active {
        .totalEstimateContainer {
          padding: 12px 24px;
          .countText {
            display: flex;
          }
          .fundingValues {
            margin: 0 0 0 auto;
          }
        }
      }
      .btnContainer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $sidebarPrimaryBackgroundColor;
        border-radius: 15px 15px 0 0;
      }
      .collapseArrow {
        order: 3;
        border: none;
        transform: none;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        font-family: $circularBold;
        text-decoration: underline;
        font-size: 11px;
        color: $sidebarTooltipIconColor;
        cursor: pointer;
        &.arrowDown {
          svg {
            transform: rotateZ(180deg);
          }
          span::before {
            content: "MINIMIZE OFFER DETAILS";
          }
        }
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: $sidebarTooltipIconColor;
          }
        }
        span::before {
          content: "EXPAND OFFER DETAILS";
        }
      }
      .youraccountCount {
        order: 2;
      }
      .totalEstimateContainer {
        padding: 0 24px;
        .countText {
          display: none;
        }
        .fundingValues {
          margin: 0 auto;
        }
      }
      .menuBarContainerBox {
        order: 1;
        &.collapseMenu {
          max-height: 900px;
        }
        .digitDetails {
          align-items: center;
          padding: 15px;
          .partnerDetails span {
            font-size: 16px;
          }
          .menuToolTip {
            display: none;
          }
        }
      }
    }
  }
}

.offerSummary {
  position: relative;
  z-index: 1;
  .partnerBar {
    .collapseArrow {
      display: none;
    }
    .totalEstimateContainer {
      .countText {
        font-size: 18px;
        margin-top: 0;
        color: $sidebarFontColor1;
      }
    }
    @media screen and (max-width: 767px) {
      position: relative;
      width: 100%;
      border-radius: 12px;
      .mainWarningDiv {
        display: none;
      }
      &.active {
        .totalEstimateContainer {
          padding: 12px 24px;
          .countText {
            display: flex;
            font-size: 14px;
            color: $sidebarFontColor2;
          }
          .fundingValues {
            margin: 0 0 0 auto;
          }
        }
        .mainWarningDiv {
          display: flex;
          margin: 5px 10px 5px;
        }
      }
      .btnContainer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $white;
        border-radius: 15px 15px 0 0;
        .fineText {
          display: flex;
          color: $ctaColor;
          text-decoration: underline;
          font-size: 10px;
        }
      }
      .collapseArrow {
        order: 3;
        border: none;
        transform: none;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        font-family: $circularBold;
        text-decoration: underline;
        font-size: 11px;
        color: $sidebarTooltipIconColor;
        cursor: pointer;
        &.arrowDown {
          svg {
            transform: rotateZ(180deg);
          }
          span::before {
            content: "MINIMIZE OFFER DETAILS";
          }
        }
        svg {
          width: 16px;
          height: 16px;
          path {
            fill: $sidebarTooltipIconColor;
          }
        }
        span::before {
          content: "EXPAND OFFER DETAILS";
        }
      }
      .youraccountCount {
        order: 2;
      }
      .totalEstimateContainer {
        padding: 0 24px;
        .countText {
          display: flex;
        }
        .fundingValues {
          margin-left: auto;
        }
      }
      .menuBarContainerBox {
        order: 1;
        &.collapseMenu {
          max-height: 900px;
        }
        .digitDetails {
          align-items: center;
          padding: 15px;
          .partnerDetails span {
            font-size: 16px;
          }
          .menuToolTip {
            display: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    background-color: $white;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $sidebarPrimaryBackgroundColor;
  border-radius: 50%;
  cursor: pointer;
  background-color: $sidebarTooltipIconColor;
  @media screen and (max-width: 1366px) {
    height: 13px;
    width: 13px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "?";
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
  @media screen and (max-width: 767px) {
    font-size: 11px;
    line-height: 11px;
  }
}

.tooltip {
  width: 30%;
  white-space: normal;
  text-transform: initial;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.menuToolTip {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: $white;
  flex-shrink: 0;
  flex-wrap: nowrap;
  white-space: nowrap;
  letter-spacing: 0px;
  p {
    font-size: 22px;
    font-family: $circularBold;
    &.textSmall {
      font-size: 12px;
      font-family: $circular;
    }
    @media screen and (max-width: 1366px) {
      font-size: 18px;
    }
  }
  i {
    margin-left: 5px;
  }
  a {
    color: $sidebarFontColor1;
    text-decoration: underline;
    &:hover {
      color: $sidebarFontColor1;
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 12px;
    flex-wrap: wrap;
    white-space: unset;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px;
  }
  @media screen and (max-width: 767px) {
    position: relative;
  }
}
.profile {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  padding: 15px 40px;
  background-color: $sidebarHeaderFooterColor;
  flex-shrink: 0;
  & > div:first-child {
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    @media screen and (max-width: 1440px) {
      height: 48px;
      width: 48px;
    }
    @media screen and (max-width: 1366px) {
      height: 40px;
      width: 40px;
    }
    @media screen and (max-width: 767px) {
      height: 45px;
      width: 45px;
    }
  }
  img {
    width: 100%;
  }
  p {
    font-size: 24px;
    @media screen and (max-width: 1440px) {
      font-size: 18px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 16px;
    }
    @media screen and (max-width: 767px) {
      font-size: 24px;
    }
  }
  p:nth-child(3) {
    font-size: 20px !important;
  }
  p:first-child {
    padding: 0 0 0;
    font-family: $circularBold;
    letter-spacing: 0.72px;
    @media screen and (max-width: 767px) {
      margin-top: 0;
      line-height: 16px;
    }
  }
  .youraccountCount {
    display: none;
  }
  @media screen and (max-width: 1440px) {
    padding: 14px 30px;
  }
  @media screen and (max-width: 1366px) {
    padding: 15px 30px;
  }
  @media screen and (max-width: 767px) {
    color: $sidebarFontColor1;
    padding: 15px 20px;
    width: 100%;
    min-height: 90px;
    .youraccountCount {
      display: inline-block;
    }
  }
}

.menuContainer {
  font-size: 18px;
  display: flex;
  right: 0;
  top: 21px;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 767px) {
    background: none;
    position: absolute;
  }
  > p {
    margin-top: auto !important;
    font-size: 10px;
    padding-left: 20px;
  }
  .menuItems {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 80px;
    padding: 28px 28px 15px 40px;
    .active {
      background-color: rgba($color: $white, $alpha: 0.2);
    }
    a {
      padding: 20px 0px 0px 20px;
      font-size: 14px;
      letter-spacing: -0.5px;
      @media screen and (max-width: 767px) {
        margin: 0;
        padding: 18px 40px;
        letter-spacing: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $black;
      }
      .visited {
        color: $success !important;
        i {
          margin-right: 5px;
        }
        .menuIcon {
          font-size: 15px;
          svg {
            color: $success;
            border-radius: 50%;
            background: radial-gradient(
              $white,
              $white,
              $white,
              $white,
              $white,
              transparent,
              transparent,
              transparent,
              transparent
            );
          }
        }
      }
      .notVisited {
        color: $darkYellow !important;
      }
      .updated {
        color: $updateMsg !important;
      }
      box-shadow: 0 3px 10px rgba($color: transparent, $alpha: 0.05);
    }
    @media screen and (max-width: 1440px) {
      padding: 24px 24px 15px 30px;
    }
    @media screen and (max-width: 1366px) {
      padding: 24px 24px 15px 30px;
    }
    @media screen and (max-width: 767px) {
      display: none;
      position: absolute;
      background-color: $white;
      box-shadow: 0 3px 10px rgba($color: $dark, $alpha: 0.4);
      right: 5px;
      top: 5px;
      z-index: 2;
      padding: 0 0 0px;
    }
  }
  .ellipsis {
    margin: -5px -1px 0px 0;
    display: none;
    z-index: 3;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .ellipsisActive {
    color: #2e03e4;
  }

  .active {
    @media screen and (max-width: 767px) {
      color: $primaryColor;
      font-weight: bold;
    }
  }

  .dashboard {
    @media screen and (max-width: 767px) {
      background-color: #2e03e4;
      color: white;
      margin: 0px;
      font-weight: bold;
      padding: 10px 59px 10px 17px;
      display: block;
      img {
        margin: -1px 2px 0;
      }
    }
    display: none;
  }
  .hamburgerToggle {
    position: absolute;
    padding: 3px;
    margin: 4px 0px 0px -4px;
    top: 10px;
    right: 13px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ and Edge */
    user-select: none; /* Standard syntax */
  }
  .hamburgerToggle:hover {
    background: none;
  }
  .hamburgerToggle img {
    height: 20px;
    width: auto;
  }
}
.menuClose {
  @media screen and (min-width: 767px) {
    max-width: 72px;
    .menuItems a:not(.hamburgerToggle) {
      display: none;
    }
    .menuItems a.hamburgerToggle {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      padding: 0px 15px;
    }
    .menuItems a.hamburgerToggle img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
    .profile p {
      display: none;
    }
  }
}

.artistShare {
  font-size: 18px;
  letter-spacing: 0.72px;
  line-height: 30px;

  & > div {
    margin: 20px 0;
  }
  p {
    margin: 0;
    line-height: 28px;
    white-space: normal;
    &.offerTitle {
      color: $sidebarFontColor2;
      font-weight: 700;
    }
    @media screen and (max-width: 1440px) {
      line-height: 20px;
    }
    @media screen and (max-width: 767px) {
      line-height: 22px;
    }
  }
  li {
    position: relative;
    font-size: 10px;
    cursor: text;
    span {
      font-size: 18px;
      position: relative;
      top: 3px;
      left: -4px;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 14px;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;
  }
}

.dNone {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.hamburgerIcon {
  margin-bottom: 8px;
}

.digitDetails {
  padding: 30px 30px 25px;
  transition: max-height 0.3s cubic-bezier(0.5, 1, 0.89, 1);
  @media screen and (max-width: 1440px) {
    padding: 24px 30px;
  }
  @media screen and (max-width: 1366px) {
    padding: 24px 28px;
  }
  @media screen and (max-width: 767px) {
    padding: 22px 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    max-height: 90px;
    overflow: hidden;
    &.showMore {
      max-height: 1150px;
    }
  }
  .menuItems {
    margin-top: 40px;
    width: 100%;
    text-align: left;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }
  .validThrough {
    font-size: 14px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .partnerDetails {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-top: 5px;
    img {
      height: 24px;
      object-fit: contain;
      filter: grayscale(1) brightness(999) contrast(1);
    }
    span {
      font-family: $circularBold;
      font-size: 20px;
    }
  }
  .dealDetails {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      font-family: $circularBold;
      font-size: 20px;
    }
  }
}

.youraccountCount {
  font-family: $circularStd !important;
  font-size: 48px;
  width: 100%;
  display: inline-block;
  * {
    font-family: $circularStd;
    letter-spacing: 1.92px;
  }
  .odometerContainer {
    overflow: hidden;
    height: 40px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 18px;
    @media screen and (max-width: 767px) {
      height: 24px;
    }
  }
  .menuBarScroll {
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 425px);
  }
  .totalEstimateContainer {
    display: flex;
    width: 100%;
    font-size: 18px;
    align-items: center;
    white-space: nowrap;
    padding: 25px 30px;
    letter-spacing: 1.2px;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
    .fundingValues {
      font-family: $circularBold;
      margin-left: auto;
      overflow: hidden;
      font-size: 32px;
      @media screen and (max-width: 767px) {
        font-size: 22px;
      }
      * {
        font-family: $circularBold;
      }
    }
    @media screen and (max-width: 1366px) {
      padding: 12px 28px;
    }
    @media screen and (max-width: 767px) {
      border: none;
      &.active {
        border-bottom: 1px solid rgba($color: $white, $alpha: 0.2);
      }
    }
  }
  .customTextContainer {
    font-size: 12px;
    letter-spacing: 0px;
    padding: 18px 44px;
    @media screen and (max-width: 1366px) {
      padding: 12px 44px 12px 50px;
    }
    @media screen and (max-width: 1024px) {
      padding: 12px 44px 12px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .mobileCustomTextContainer {
    display: none;
    @media screen and (max-width: 767px) {
      &.active {
        border-top: 1px solid rgba($color: $white, $alpha: 0.2);
        display: flex;
        padding: 10px 36px;
        letter-spacing: 0;
      }
    }
  }
  .betterTermsContainer {
    font-size: 12px;
    letter-spacing: 0px;
    display: flex;
    padding: 25px 44px;
    .boxContainer {
      background: $sidebarTooltipIconColor;
      border-radius: 11px;
      display: flex;
      align-items: center;
      height: 56px;
      padding: 15px;
      width: 100%;
      .svgContainer {
        max-width: 26px;
        margin-right: 15px;
        .dollorIcon {
          width: 100%;
        }
      }
      p {
        margin-bottom: 0px;
        font-family: $circularBold;
        letter-spacing: 0px;
      }
      a {
        text-decoration: underline;
        font-family: $circularStd;
        letter-spacing: 0px;
      }
    }
    @media screen and (max-width: 1366px) {
      padding: 15px 32px 30px;
    }
    @media screen and (max-width: 1024px) {
      padding: 15px 32px 30px;
    }
    @media screen and (max-width: 767px) {
      padding: 5px 36px;
      &.active {
        padding: 5px 36px;
      }
    }
  }
  .teaserOuterContainer {
    padding: 10px 45px;
    &.padding {
      padding: 15px 32px 30px;
      @media screen and (max-width: 767px) {
        padding: 10px 45px;
      }
    }

    .teaserContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 15px;
      background-color: $sidebarTooltipIconColor;
      padding: 0px 10px;
      color: $sidebarPrimaryBackgroundColor;

      svg {
        height: 70px;
        width: 70px;
        path {
          fill: $sidebarPrimaryBackgroundColor;
        }
      }

      .teaserText {
        font-size: 12px;
        font-family: $circularBold;
        letter-spacing: 0px;
        span {
          text-decoration: underline;
          margin-left: 5px;
          cursor: pointer;
          letter-spacing: 0px;
        }
      }
    }

    @media screen and (max-width: 767px) {
      display: none;
      &.active {
        display: inline;
        width: 100%;
      }
    }
  }

  .btnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 44px;
    font-size: 13px;
    flex-direction: column;
    row-gap: 15px;
    &.hideBtn {
      display: none;
      @media screen and (max-width: 767px) {
        padding: 15px 36px 20px;
        width: 100%;
        display: flex;
      }
    }
    &.btnColumn {
      flex-direction: column;
      row-gap: 10px;
      text-align: center;
      @media screen and (max-width: 767px) {
        background-color: $white;
      }
      > span {
        font-size: 14px;
        font-family: $circularBold;
        @media screen and (max-width: 767px) {
          color: $ctaColor;
        }
      }
      .seeOther {
        font-size: 14px;
        font-family: $circularBold;
        text-decoration: underline;
        background-color: transparent;
        border: none;
        color: $sidebarFontColor1;
      }
    }
    .primary {
      font-family: $circularBold;
      color: $fundCtaFontColor;
      letter-spacing: 2.6px;
      border-radius: 25px;
      opacity: 1;
      width: 100%;
      height: 50px;
      background-color: $fundCtaBgColor;
      box-shadow: 0px 7px 15px rgba($color: $dark, $alpha: 0.4);
      border: none;
      @media screen and (max-width: 767px) {
        color: $white;
        height: 40px;
        box-shadow: none;
      }
    }
    .secondary {
      font-family: $circularBold;
      color: $sidebarFontColor2;
      letter-spacing: 2.6px;
      border-radius: 25px;
      opacity: 1;
      width: 100%;
      height: 50px;
      background-color: transparent;
      border: 2px solid $sidebarFontColor2;
      text-transform: uppercase;
      outline: none;
    }
    .link {
      font-family: $circularBold;
      color: $sidebarFontColor1;
      letter-spacing: 0;
      opacity: 1;
      width: 100%;
      background-color: transparent;
      border: none;
      text-transform: uppercase;
      text-decoration: underline;
      outline: none;
      font-size: 14px;
      padding: 5px 10px;
      margin-top: 5px;
      @media screen and (max-width: 767px) {
        border-radius: 25px;
        height: 40px;
        border: 2px solid $ctaColor;
        background-color: $white;
        color: $ctaColor;
        text-decoration: none;
        outline: none;
      }
    }
    .fineText {
      display: none;
    }
    @media screen and (max-width: 767px) {
      background-color: $white;
      box-shadow: 0px 10px 51px rgba($color: $dark, $alpha: 0.5);
    }
    @media screen and (max-width: 1366px) {
      padding: 15px 32px 30px;
    }
    @media screen and (max-width: 1024px) {
      padding: 15px 32px 30px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 15px 36px 20px;
    }
  }
  .otherValues {
    font-size: 16px;
    width: 100%;
    font-weight: normal;
    * {
      align-items: center;
      font-family: $circularBold;
      letter-spacing: 0px;
    }
    ul {
      margin: 0;
      padding-inline-start: 0px;
      padding: 10px 0;
      font-size: 17px;
      width: 100%;
      min-width: 65%;
      li {
        cursor: text;
        list-style: none;
        display: flex;
        align-items: center;
        padding: 3px 30px;
        .fundingValues {
          font-family: $circularBold;
          margin-left: auto;
        }
        .linkBtn {
          text-transform: uppercase;
          font-size: 14px;
          text-decoration: underline;
          background-color: transparent;
          border: none;
          color: inherit;
          text-align: right;
          padding: 0;
          cursor: pointer;
        }
        @media screen and (max-width: 1366px) {
          padding: 3px 28px;
          margin-bottom: 6px;
        }
        @media screen and (max-width: 767px) {
          padding: 3px 24px;
          justify-content: center;
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    li > div {
      position: relative;
      align-items: center;
      overflow: hidden;
      line-height: normal;
      bottom: -3px;
      font-size: 16px;
      white-space: nowrap;
      @media screen and (max-width: 1440px) {
        font-size: 16px;
      }
      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }
    @media screen and (max-width: 1440px) {
      margin: 0px;
    }
    @media screen and (max-width: 1366px) {
      margin: 0px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .artistIncomeContainer {
    padding: 10px 44px;
    font-size: 14px;
    width: 100%;
    font-family: $circularStd;
    border-top: 1px solid rgba($color: $white, $alpha: 0.2);
    display: none;
    .artistIncomeBox {
      p {
        letter-spacing: 0px;
        color: $sidebarFontColor2;
      }
    }
    .artistIncome {
      display: flex;
      p {
        font-family: $circularBold;
        letter-spacing: 0px;
      }
      span {
        letter-spacing: 0px;
        margin-left: auto;
        color: $sidebarFontColor2;
      }
    }
    @media screen and (max-width: 767px) {
      display: block;
      border: none;
      &.active {
        border-top: 1px solid rgba($color: $white, $alpha: 0.2);
        padding: 10px 36px;
      }
    }
  }
  .countText {
    display: flex;
    margin-top: 10px;
    font-family: $circularBold;
    white-space: nowrap;
    letter-spacing: 0px;
    color: $sidebarFontColor2;
    font-size: 14px;
    &.bold {
      font-family: $circularBold;
    }
    .royaltyTooltip {
      font-size: 14px;
      font-family: $circularStd;
      letter-spacing: normal;
    }
    @media screen and (max-width: 1366px) {
      margin-right: 15px;
    }
  }
  .textBig {
    font-family: $circularBold;
    margin: 0 0 0 15px;
    text-transform: capitalize;
    @media screen and (max-width: 1440px) {
      font-size: 24px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 24px;
    }
  }
  .mainWarningDiv {
    text-align: left;
    padding: 12px;
    margin: 10px;
    border: 0;
    background-color: rgba($helpTextColorRgb, 0.3);
    border-radius: 12px;
    letter-spacing: normal;
    line-height: normal;
    .text {
      color: $white;
      font-size: 14px;
      margin-bottom: 0;
      letter-spacing: normal;
    }
    @media screen and (max-width: 767px) {
      margin: 0px 10px 5px;
      .text {
        font-size: 12px;
      }
    }
    @media screen and (max-width: 479px) {
      padding: 10px;
      .text {
        font-size: 10px;
      }
    }
  }
  :global {
    .odometer-value {
      overflow: visible;
    }
    .odometer-digit-inner {
      overflow: visible !important;
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 14px;
    * {
      letter-spacing: -0.4px;
    }
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
    * {
      letter-spacing: -0.4px;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
  }
}

.moreOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  transition: 0.4s height;
  overflow: hidden;
  background-color: rgba($color: $white, $alpha: 0.5);
  display: flex;
  flex-direction: column;
  .seeMoreContainer {
    background-color: $sidebarPrimaryBackgroundColor;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    .profile {
      justify-content: center;
      & > div {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        margin: 15px 0;
      }
    }
    .youraccountCount {
      padding: 15px 0;
      display: flex;
      font-size: 40px;
      flex-direction: column;
      align-items: center;
      .odometerContainer {
        height: 40px;
      }
      .otherValues {
        display: block;
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        @media screen and (max-width: 1366) {
          padding: 15px 30px;
          justify-content: center;
        }
      }
      .countText {
        display: inline-block;
      }
      .seeMoreLnk {
        display: none;
      }
    }
    .bottomInfoText {
      max-width: 300px;
      text-align: center;
      font-size: 14px;
      a {
        display: inline-block;
        text-decoration: underline;
        color: $sidebarFontColor1;
        margin: 10px 0;
        padding: 5px;
        font-size: 15px;
        &:hover {
          color: $sidebarFontColor1;
        }
      }
    }
    .closeBtn {
      content: " ";
      display: inline-block;
      transform: rotate(-135deg);
      flex-shrink: 0;
      height: 10px;
      width: 10px;
      border-bottom: 3px solid $white;
      border-right: 3px solid $white;
    }
  }
  @media screen and (min-width: 767px) {
    display: none;
  }
}
.closeOverlay {
  height: 0%;
}

.collapseArrow {
  max-height: 0;
  &.arrowDown {
    @media screen and (max-width: 767px) {
      max-height: 900px;
      transform: translateY(-5px) rotateX(110deg) rotateZ(45deg);
    }
  }
  @media screen and (max-width: 767px) {
    padding: 15px;
    border-left: 4px solid white;
    border-top: 4px solid white;
    transform: rotateX(70deg) rotateZ(45deg);
    transition: transform 0.3s;
  }
}

.sidebarTextContainer {
  background-color: $sidebarHeaderFooterColor;
  margin: 30px 30px 28px -20px;
  padding: 20px;
  width: 100%;
  overflow: auto;
  position: relative;
  font-size: 14px;
  * {
    box-sizing: initial;
    &:after,
    &:before {
      font-size: initial;
      box-sizing: initial;
    }
  }
  a {
    text-decoration: underline;
    color: -webkit-link;
  }
  @media screen and (max-width: 1440px) {
    margin: 28px 0 0 -10px;
  }
  @media screen and (max-width: 1366px) {
    margin: 28px 0 0 -10px;
  }
  @media screen and (max-width: 767px) {
    margin: 0px;
  }
}

.detailsPopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 770px;
  width: 90%;
  outline: none;
  padding: 20px 0px;
  background-color: #fff;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;

  .offerContainer {
    padding: 5px 10px;
    .closeBtn {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 24px;
      line-height: 18px;
      padding: 10px;
      cursor: pointer;
    }

    .firstContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 50%;
      }

      .firstImage {
        width: 62px;
        height: 62px;
        margin-right: -20px;
      }
      .secondImage {
        width: 90px;
        height: 90px;
        z-index: 5;
      }
      .thirdImage {
        width: 62px;
        height: 62px;
        margin-left: -20px;
      }
    }
    .secondContainer {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      font-size: 18px;
      padding: 5px 10px;
      text-align: center;
      .firstHead {
        color: $primaryColor;
        font-family: $circularBold;
      }
      .secondHead {
        color: $black;
      }
    }
    .thirdContainer {
      display: flex;
      font-size: 14px;
      padding: 15px 0px;

      .first {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          color: $white;
          background-color: $primaryColor;
          border-radius: 50%;
          padding: 2px 10px;
          margin-bottom: 0px;
        }
        span {
          width: 81%;
          text-align: center;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        .first {
          flex-direction: row;
          padding: 5px 0px;
          span {
            text-align: left;
            margin-left: 10px;
          }
        }
      }
    }

    .fourthContainer {
      color: $primaryColor;
      letter-spacing: 2.8px;
      font-family: $circularBold;
      display: flex;
      justify-content: flex-end;
      padding-right: 30px;
      cursor: pointer;
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}
