@import "../../styles/variables.scss";

.container {
  background-color: $black;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  color: $white;
  font-weight: 400;
  font-size: 16px;
  z-index: 5;

  .copyright {
    color: $greyColor;
  }
  .contactUs {
    padding-right: 20px;
    color: $white !important;
    cursor: pointer;
  }
  .privacyPolicy {
    cursor: pointer;
    color: $white !important;
  }
  @media screen and (max-width: 767px) {
    position: relative;
    margin: 0;
  }
}
