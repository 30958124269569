@import "../../styles/variables.scss";

.container {
  position: relative;
}

.fieldContainer {
  .boxInput {
    border-radius: 7px;
    border: 2px solid $sidebarPrimaryBackgroundColor;
    font-size: 14px;
    color: $black;
    font-family: $circularStd;
    height: 50px;
    &::placeholder {
      color: $darkGreyColor;
    }
    &.paddingValidation {
      padding-right: 35px;
    }
  }
}
.container input {
  padding-right: 60px;
}

.icon {
  position: absolute;
  cursor: pointer;
  font-size: 22px;
  transform: translateY(25%);
  right: 25px;
  color: $darkGreyColor;
}

.iconBg {
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 24px;
  background-position: 12px 50%;
  cursor: auto;
  padding-left: 50px !important;
}

.buttonClass {
  background-color: $greyColor;
}

.containerClass {
  box-shadow: none;
  outline: none;
  &.inputWithIcon input {
    padding-left: 35px;
  }
  .dropdownClass {
    color: $black;
    box-shadow: 0px 5px 8px -1px rgba($color: $black, $alpha: 0.2);
    width: 353px;
    border: 1px solid $light;
    background-color: #f9f9f9;
    margin: 2px 0 0 0;
    li {
      margin: 0 10px;
      &:hover {
        background-color: $light !important;
      }
    }
    @media screen and (max-width: 767px) {
      width: 530px;
    }
    @media screen and (max-width: 567px) {
      width: calc(100vw - 36px);
    }
  }
  > div:last-child {
    background-color: transparent !important;
    border: none;
    &:hover {
      background-color: transparent !important;
    }
    > div {
      background-color: transparent !important;
      width: 70px;
      padding-left: 20px;
      & > div > div {
        left: 30px !important;
      }
      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.inputClass {
  width: 100%;
  height: auto;
  outline: none;
  font-size: 16px;
  padding-left: 95px !important;
  border: 1px solid $darkGreyColor;
  background-color: $greyColor;
}

.maxLengthClass {
  color: $grey;
  width: 100%;
  text-align: right;
}

.iconComponent {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(15px, -12px);
  svg {
    path {
      fill: $primaryColor;
    }
  }
}

.validationIcon {
  position: absolute;
  right: 20px;
  transform: translate(10px, 13px);
  path {
    stroke-width: 2px;
    stroke: $success;
  }
}
.errorIcon {
  path {
    stroke-width: 2px;
    stroke: $error;
  }
}
.radioInput {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .label {
    margin-left: 10px;
    font-size: 14px;
    font-family: $circularStd;
    margin-bottom: 0px;
  }
  input[type="radio"] {
    height: 16px;
    width: 16px;
    accent-color: $primaryColor;
    &:disabled + .label {
      color: rgba($color: $primaryColorRgb, $alpha: 0.6);
      cursor: not-allowed;
    }
  }
}

.componentIcon {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  top: 42px;
  @media screen and (max-width: 767px) {
    width: 17px;
    height: 18px;
    top: 40px;
  }
}
