@import "../../../../styles/variables.scss";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: $appPrimaryBackgroundColor;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  & > * {
    z-index: 1;
  }
  .headerContainer {
    padding: 20px;
    width: 100%;
    .logoContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
      width: fit-content;
      svg {
        height: 15px;
      }
    }
    img {
      max-height: 50px;
      object-fit: contain;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
    }
  }
  .subContainer {
    display: flex;
    margin: 0 auto auto auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 30px;
    & > * {
      max-width: 600px;
      width: 100%;
    }
    .subHeading {
      text-align: center;
      font-size: 18px;
      color: $appHeaderFontColor;
      margin-bottom: 40px;
    }
    .formContainer {
      display: flex;
      flex-direction: column;
      padding: 40px 50px;
      background-color: $white;
      border-radius: 15px;
      position: relative;
      @media screen and (max-width: 767px) {
        padding: 25px;
      }
    }
    .stepIndicator {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      gap: 10px;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14px;
        height: 14px;
        border: 2px solid $primaryColor;
        border-radius: 20px;
        flex-shrink: 0;
        &.active::after {
          content: " ";
          display: block;
          flex-shrink: 0;
          height: 8px;
          width: 8px;
          background-color: $primaryColor;
          border-radius: 10px;
        }
      }
    }
    a.returnLink,
    button.returnLink {
      display: flex;
      align-items: center;
      color: $appHeaderFontColor;
      font-size: 16px;
      text-decoration: underline;
      column-gap: 10px;
      width: fit-content;
      margin-top: 20px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
    .firstDiv {
      display: flex;
      flex-direction: column;
      padding: 40px 0;
    }
    .estimatesWidget {
      :global {
        .form-group .mb-4 {
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          margin-bottom: 0 !important;
          .invalid-feedback {
            bottom: 0;
            transform: translateY(100%);
          }
          .react-tel-input {
            order: 2;
            .flag-dropdown {
              background-color: transparent;
              border-width: 0;
              border-bottom-width: 1px;
            }
          }
          label {
            order: 1;
            position: relative;
            transform: translate(0);
            color: $black;
            font-size: 14px;
            margin: 0;
            pointer-events: initial;
          }
          input {
            order: 2;
            padding: 2px 0;
            &::placeholder {
              color: rgba($color: $primaryColorRgb, $alpha: 0.4);
            }
          }
          & > div:first-child {
            order: 2;
          }
        }
        :global(.form-group) {
          span > div {
            position: absolute;
            background-color: transparent;
            right: 4px;
            bottom: 4px;
            top: unset;
            left: unset;
            i {
              border-color: $primaryColor;
              border-right-color: transparent;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      .radioContainer {
        &:global(.mb-4) {
          & > div,
          & > div:first-child {
            order: 1;
            label {
              width: 100%;
            }
          }
          & > label {
            order: 0;
            text-transform: unset;
          }
          input {
            display: none;
            &:checked + label .radioLabel {
              background-color: rgba($color: $primaryColorRgb, $alpha: 0.1);
              border: 2px solid $primaryColor;
              opacity: 1;
            }
          }
          .radioLabel {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            border: 2px solid rgba($color: $primaryColorRgb, $alpha: 0.6);
            border-radius: 8px;
            padding: 15px;
            cursor: pointer;
            span {
              font-size: 12px;
              text-align: center;
              &:first-child {
                font-size: 14px;
                font-family: $circularBold;
                color: $primaryColor;
                text-transform: uppercase;
              }
            }
          }
        }
      }
      .flex {
        display: flex;
      }
      .tooltip {
        width: 100%;
      }
      .infoIcon {
        align-items: center;
        background-color: $primaryColor;
        border: none;
        border-radius: 50%;
        color: $white;
        cursor: pointer;
        display: flex;
        flex-shrink: 0;
        font-style: normal;
        height: 17px;
        justify-content: center;
        margin-left: 15px;
        width: 17px;
        &::after {
          content: "?";
          font-family: Poppins, sans-serif;
          font-size: 12px;
          font-weight: 800;
          letter-spacing: 0;
          line-height: 12px;
          position: relative;
          text-transform: lowercase;
          top: 1px;
        }
      }
      .checkboxClass {
        display: flex;
        font-size: 12px;
        column-gap: 5px;
        align-items: flex-start;
        margin-top: 40px;
        a {
          display: inline;
          font-size: 12px;
          color: $primaryColor;
        }
      }
      .helperLabel {
        font-size: 10px;
        color: $primaryColor;
        margin-bottom: 5px;
        margin-top: 5px;
        order: 2;
      }
    }
    .btnLinkContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background-color: transparent;
        border: none;
        text-decoration: underline;
        color: $primaryColor;
        font-family: $circularStd;
      }
    }
    .btnContainer {
      display: flex;
      flex-direction: column;
      .btnPrimary {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 14px;
        font-family: $circularBold;
        width: 100%;
        padding: 12px;
        border-radius: 30px;
        color: $white;
        background-color: $ctaColor;
        border: none;
        cursor: pointer;
        column-gap: 5px;
        &:disabled {
          opacity: 0.4;
          cursor: not-allowed;
        }
        div {
          position: relative;
          background-color: transparent;
          i {
            border-color: $white;
            border-right-color: transparent;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .recaptchaMainDiv {
      position: absolute;
      height: 70px;
      right: 0;
      left: 0;
      z-index: 0;
      overflow: hidden;
      transform: translateY(-100px);
      :global(.grecaptcha-badge) {
        position: absolute !important;
        bottom: 5px !important;
      }
      @media screen and (max-width: 767px) {
        transform: translateY(-70px);
      }
    }
  }
}

.backgroundImage {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  mix-blend-mode: luminosity;
  background-image: url("../../../../img/cC-bg.png");
  background-position: center;
  background-size: cover;
  &.bB {
    background-image: url("../../../../img/beatBreas-signup-bg.webp");
    mix-blend-mode: normal;
    background-size: cover;
    opacity: 1;
  }
}

.formHeading {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
  color: $black;
  p {
    font-family: $circularSemiBold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .artistInfo {
    display: flex;
    align-items: center;
    column-gap: 10px;
    img {
      height: 40px;
      width: 40px;
      flex-shrink: 0;
      object-fit: cover;
      border-radius: 20px;
      @media screen and (max-width: 767px) {
        height: 30px;
        width: 30px;
      }
    }
    h3 {
      font-size: 24px;
      font-family: $circularBold;
      color: $primaryColor;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 21px;
      }
    }
  }
}

.videoModal {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px 0 20px;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 20px;
  .closeBtn {
    background-color: transparent;
    color: rgba($color: $white, $alpha: 0.4);
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    line-height: 18px;
    padding: 5px;
    cursor: pointer;
  }
  iframe {
    width: 445px;
    height: 250px;
    border-radius: 12px;
    background-color: $white;
    @media screen and (max-width: 767px) {
      max-width: 95vw;
      width: 400px;
      height: 200px;
    }
  }
}
.modalOverlay {
  display: flex;
  position: fixed;
  padding: 20px 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.9
  );
  overflow: auto;
  z-index: 4;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}
