@import "../../../styles//variables.scss";

.CompareDealChartContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
  justify-content: center;
  gap: 25px;
  max-width: 1075px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .title {
    h2 {
      @include font-acumin-b;
      display: flex;
      font-size: 32px;
      letter-spacing: 0;
      margin: 0;
      color: $primaryColor;
      text-transform: uppercase;
      @media screen and (max-width: 767px) {
        font-size: 27px;
      }
    }
    p {
      font-family: $circular;
      font-size: 16px;
      color: $black;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}

.mainDealContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}

.mainBoxContainer {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  border: 2px solid rgba($color: $helpTextColorRgb, $alpha: 0.5);
  width: 100%;
  padding: 20px 30px;
  justify-content: space-between;
  gap: 25px;

  .filterContainer {
    display: flex;
    gap: 20px;
    justify-content: center;
    .titleContainer {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;
      max-width: 290px;
      .typeContainer {
        border-radius: 8px;
        border: 2px solid $primaryColor;
        height: 40px;
        padding: 5px;
      }
      .newReleasePerformContainer {
        display: flex;
        flex-direction: column;
        :global(.distributor__control) {
          background-color: unset;
          width: 100%;
          max-width: 290px;
          height: 40px;
          border: 2px solid $primaryColor;
          padding: unset;
          color: $primaryColor;
        }

        :global(.distributor__single-value) {
          color: $primaryColor;
        }

        :global(.distributor__control--menu-is-open) {
          border-radius: 7px 7px 0 0;
          border-bottom: none;
        }

        :global(.distributor__control--is-disabled) {
          opacity: 0.6;
        }

        :global(.distributor__menu) {
          animation: fadeInDistributorMenu 0.2s ease-in-out;
        }

        :global(.distributor__option) {
          font-size: 14px;
          padding: 6px 12px;
        }

        :global(.distributor__menu-list) {
          &::-webkit-scrollbar {
            width: 6px;
            height: 0px;
          }

          &::-webkit-scrollbar-track {
            background: $veryLight;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba($color: $grey, $alpha: 0.4);
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: rgba($color: $grey, $alpha: 0.3);
          }
        }

        :global(.distributor__indicator) {
          svg {
            fill: $primaryColor;
            width: 20px;
            height: 20px;
          }
        }

        @keyframes fadeInDistributorMenu {
          0% {
            max-height: 0;
            opacity: 0;
          }

          50% {
            opacity: 0.2;
          }

          100% {
            max-height: 200px;
            opacity: 1;
          }
        }
        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }
        @media screen and (max-width: 1024px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        :global(.form-group) {
          margin-bottom: 0px;
          > div {
            margin-bottom: 0px !important;
            padding-top: 0px !important;
          }
        }
      }
      .newReleaseNextYearContainer {
        border-radius: 8px;
        border: 2px solid $primaryColor;
        height: 40px;
        padding: 5px;
      }
      .buttonContainer {
        display: flex;
        font-family: $circularBold;
        font-size: 14px;
        text-transform: uppercase;
        border: 2px solid $primaryColor;
        padding: 4px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        min-height: 40px;
        width: 100%;
        .submitButton {
          width: 100%;
          height: 100%;
          border-radius: 3px;
          text-transform: uppercase;
          color: $primaryColor;
          border: none;
          cursor: pointer;
        }
        .submitButton.activeButton {
          background-color: $primaryColor;
          color: $sidebarFontColor1;
        }
      }
    }
    h2 {
      font-family: $circular;
      font-size: 14px;
      color: $black;
      letter-spacing: 0;
      margin: 0;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .chartContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .yAxisLabel {
      font-size: 14px;
      font-family: $circular;
      color: $primaryColor;
      text-anchor: middle;
      @media screen and (max-width: 767px) {
        font-size: 10px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 10px 10px;
  }
}

.mainTableDiv {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 15px;
  border: 2px solid rgba($color: $helpTextColorRgb, $alpha: 0.5);
  flex-grow: 1;
  width: 100%;
  max-width: 1075px;
  overflow: hidden;
  .tableDesktopContainer {
    :global(.MuiTable-root) {
      @media screen and (max-width: 767px) {
        table-layout: fixed;
      }
    }
    :global(.MuiTableCell-body) {
      padding: 9px 25px;
      min-width: 250px;
      text-align: center;
      font-family: $circular;
      font-size: 18px;
      border: none;
      position: relative;
      @media screen and (max-width: 767px) {
        min-width: 33.33%;
        width: 33.33%;
        padding: 9px 15px;
        font-size: 14px;
        border-bottom: none;
        display: table-cell;
        z-index: 0;
        scroll-snap-align: end;
      }
    }
    .headerRow {
      @include font-acumin-b;
      background-color: rgba($color: $primaryColorRgb, $alpha: 0.9);
      text-align: left;
      font-size: 27px;
      line-height: 25px;
      color: $white;
      text-transform: uppercase;
      border: none;
      :global(.MuiTableCell-body) {
        @include font-acumin-b;
        background-color: rgba($color: $primaryColorRgb, $alpha: 0.9);
        text-align: center;
        font-size: 27px;
        line-height: 25px;
        color: $white;
        text-transform: uppercase;
        border: none;
        &.sectionTitle {
          text-align: left;
        }
      }
    }
    .partnerCell {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      margin-top: 3px;
    }
    .dealIndex {
      background-color: rgba($color: $primaryColorRgb, $alpha: 0.1);
    }
  }
}
