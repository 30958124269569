@import "../../../../styles/variables.scss";

.container {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.logoContainer {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  img {
    width: auto;
    max-height: 60px;
    object-fit: contain;
    flex-shrink: 0;
    height: 30px;
  }

  .closeIcon {
    justify-content: flex-end;
    display: none;
    span {
      font-size: 16px;
      font-family: $circularBold;
      color: $primaryColor;

      @media screen and (max-width: 767px) {
        padding-right: 10px;
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0px;
  }
}
.mainContainer {
  text-align: center;
  width: 100%;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  height: 100%;
  padding-right: 40px;

  margin-top: 27px;
  max-width: 1076px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .subContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 0px;
    form {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      padding: 0px;
    }
  }

  @media (max-width: 1025px) {
    max-width: 765px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 100%;
    padding: 25px;
    justify-content: unset;
  }
  @media screen and (max-width: 529px) {
    min-height: 100%;
  }
}
.mainContainer h1 {
  color: $primaryColor;
  font-weight: 600;
  font-size: 64px;
  line-height: 69px;
  letter-spacing: -3.01875px;
  white-space: nowrap;
  @media screen and (max-width: 767px) {
    white-space: unset;
    font-size: 48px;
    text-align: left;
    padding: 0 20px;
  }
  @media screen and (max-width: 479px) {
    white-space: unset;
    font-size: 40px;
  }
}

.formContainer {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 511px;
  padding: 79px 0;
  align-items: center;
  flex-direction: column;
  .mainInputContainer {
    margin: 0;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0;
    & > input {
      order: 2;
    }
    & > label {
      order: 3;
    }
  }
  .formField {
    margin-bottom: 0px;
    > div {
      margin-bottom: 15px !important;
      padding-top: 25px;
      padding-bottom: 0px !important;
    }
    input {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 60px 0;
  }
}
.btnContainer {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 511px;
  align-items: center;
  padding-top: 150px;
  .btn {
    background-color: $ctaColor;
    padding: 15px 45px;
    border-radius: 25px;
    border: none;
    width: unset;
    color: $fundingCtaFontColor;
    font-size: 14px;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    &:disabled {
      background-color: rgba($color: $ctaColorRgb, $alpha: 0.4);
      cursor: not-allowed;
      border: none;
      color: $fundingCtaFontColor;
    }
    @media screen and (max-width: 767px) {
      padding: 7px 25px;
    }
  }
  .cancelBtn {
    padding: 20px;
    width: unset;
    border: none;
    color: $black;
    background-color: transparent;
    font-size: 14px;
    border-radius: 2px;
    font-family: $circularStd;
    margin-top: 10px;
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
    padding-top: 0px;
  }
}
.loginContent .emailorphone {
  margin: -4px 3px;
  text-align: right;
  float: right;
  font-size: 14px;
  font-family: $circularStd;
  text-decoration: underline;
  color: $grey;

  @media screen and (max-width: 767px) {
    margin: 0;
  }
}
.sociallabelText {
  margin: 36px 0px 0px -1px;
  letter-spacing: -0.41px;
  padding: 0px !important;
  line-height: initial;
}
.socialMediaLink {
  display: flex;
  align-items: center;
  padding: 22px 16px;
  text-align: left;
  color: $white;
  background-color: $medium;
  margin: 6px -1px;
  float: left;
  width: 100%;
  vertical-align: middle;
}

.next {
  background-color: #b6b6b6 !important;
}
.socialYoutube {
  padding-top: 10px;
}
.selectOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 99%;
  .profile {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    img {
      width: 100%;
    }
    svg {
      height: auto;
      width: auto;
    }
  }
  p {
    letter-spacing: -0.79px;
    font-size: 18px;
    margin: 0;
  }
  button {
    background-color: $dark;
    padding: 8px 20px;
    width: auto;
    color: $white;
    flex: 0 0 auto;
    border-radius: 2px;
    border: none;
  }
  button:hover {
    color: $grey;
  }
}

.noOption {
  text-align: center;
  color: $grey;
}

.formMargin0 div {
  margin-bottom: 0 !important;
}

.spotifySearch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 0 6px;
}

.spotifySearch > * {
  flex-shrink: 0;
}

.spotifySearch > div {
  width: 100%;
}

.spotifySearch > i {
  color: $spotify;
  position: absolute;
  background: radial-gradient(
    $white,
    $white,
    $white,
    $white,
    $white,
    transparent,
    transparent,
    transparent,
    transparent
  );
  border-radius: 50px;
  font-size: 30px;
  transform: translateX(-19px);
}
.emailorphone {
  text-align: right;
  float: right;
  font-size: 14px;
  text-decoration: underline;
  color: $primaryColor;
  &:hover {
    color: $primaryColor;
    text-decoration: underline;
  }
}

.subtitle {
  color: $medium;
}
.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  h3 {
    font-size: 32px;
    font-family: $circularBold;

    @media screen and (max-width: 767px) {
      font-size: 23px;
    }
  }
  h4 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: $circularStd;
    color: $primaryColor;
    cursor: pointer;
    svg {
      @media screen and (max-width: 767px) {
        padding-right: 5px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    h1 {
      display: none;
    }
    h2 {
      display: block;
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }
}
.hideContent {
  display: none;
}
.tooltip {
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
  a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}

.flex {
  display: flex;
}

.labelText {
  letter-spacing: -0.41px;
  text-align: justify;
  color: $primaryColor;
  margin-bottom: 0px;
}

.errorClass {
  position: relative !important;
  order: 1;
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $sidebarPrimaryBackgroundColor;
  border-radius: 50%;
  cursor: pointer;
  background-color: $sidebarTooltipIconColor;
  margin-left: 15px;
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "?";
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
  @media screen and (max-width: 767px) {
    font-size: 11px;
    line-height: 11px;
  }
}
