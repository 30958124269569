@import "../../../styles/variables.scss";

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.pageContainer {
  display: flex;
  flex: 1;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 647px;
  width: 100%;
  background-color: $white;
  text-align: center;
  border-radius: 12px;
  flex: 2;
  border: 2px solid rgba($color: $helpTextColorRgb, $alpha: 0.5);
  i[data-tip] {
    width: 15px;
    height: 15px;
    svg {
      width: 15px;
      height: 15px;
      path {
        fill: $primaryColor;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0px;
    background-color: $white;
    margin-top: unset;
  }
}

.mainContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  &.fastMainContainer {
    @media screen and (max-width: 767px) {
      min-height: calc(100vh - 133px);
    }
  }
  .scrollContainer {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    &.maxHeight {
      max-height: calc(100vh - 200px);
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      @media screen and (max-width: 767px) {
        max-height: unset;
      }
    }
    @media screen and (max-width: 767px) {
      max-height: unset;
    }
  }
  .customizeFundingContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 30px 40px;
    background-color: $veryLight;
    flex-grow: 1;
    justify-content: center;
    gap: 20px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 221px);
    padding: 0px;
    max-height: unset;
    flex-grow: 1;
  }
  @media screen and (max-width: 529px) {
    min-height: calc(100vh - 167px);
  }
}

.rightContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 400px;
  flex: 1 1;
  @media screen and (max-width: 767px) {
    max-width: unset;
  }
}

.floatHeader {
  display: flex;
  flex-direction: column;
  background-color: $sidebarPrimaryBackgroundColor;
  border-radius: 15px;
  max-width: 400px;
  height: 100%;
  flex-grow: 1;
  i svg path {
    fill: $sidebarFontColor1;
  }
  .titleContainer {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    h2 {
      display: flex;
      align-items: center;
      gap: 8px;
      color: $sidebarFontColor1;
    }
    p {
      margin: 0;
      color: $sidebarFontColor1;
      font-size: 12px;
    }
  }
  & > div {
    padding: 15px 30px;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.3);
    &:last-child {
      border-right: none;
    }
  }
  .offerInfoContainer {
    display: flex;
    flex-direction: column;
    gap: 14px;
    flex-grow: 1;
    .collapseBtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $sidebarFontColor2;
      font-size: 14px;
      font-family: $circularBold;
      cursor: pointer;
      margin-top: 5px;
      p {
        margin: 0;
      }
      svg {
        path {
          fill: $sidebarFontColor2;
        }
      }
      .up {
        transform: rotateZ(180deg);
      }
    }
  }
  .offerOverview {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .advanceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: $sidebarFontColor1;
      font-size: 14px;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    h3 {
      @include font-acumin-b;
      display: flex;
      margin: 0;
      font-size: 50px;
      line-height: 50px;
      margin-bottom: -5px;
      color: $sidebarFontColor2;
      text-transform: lowercase;
      white-space: nowrap;
      :global(.odometer-auto-theme) {
        @include font-acumin-b;
        line-height: 50px;
        margin-bottom: -5px;
        flex-shrink: 0;
        @media screen and (max-width: 767px) {
          font-size: 32px;
          line-height: 32px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 32px;
        line-height: 32px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .otherOfferContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      color: $sidebarFontColor1;
      font-size: 14px;
      margin: 0;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    h3 {
      @include font-acumin-b;
      display: flex;
      margin: 0;
      font-size: 27px;
      line-height: 30px;
      margin-bottom: -5px;
      color: $sidebarFontColor2;
      text-transform: lowercase;
      white-space: nowrap;
      :global(.odometer-auto-theme) {
        @include font-acumin-b;
        line-height: 30px;
        margin-bottom: -5px;
        flex-shrink: 0;
        @media screen and (max-width: 767px) {
          font-size: 20px;
          line-height: 22px;
        }
      }
      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 22px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .royalty {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    color: $sidebarFontColor1;
    p {
      display: flex;
      align-items: center;
      gap: 5px;
      margin: 0;
      font-size: 14px;
      svg {
        width: 14px;
        height: 14px;
        margin: auto;
        path {
          fill: $sidebarFontColor2;
        }
      }
      &:last-child {
        @include font-acumin-b;
        font-size: 27px;
        line-height: 30px;
        text-align: right;
      }
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
    @media screen and (max-width: 1024px) {
      gap: 10px;
    }
  }
  .submitContainer {
    display: flex;
    align-items: center;
    button {
      background-color: $sidebarFontColor1;
      color: $primaryColor;
      padding: 14px 20px;
      border-radius: 90px;
      text-transform: uppercase;
      font-size: 14px;
      font-family: $circularBold;
      border: none;
      flex-grow: 1;
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-color: $sidebarPrimaryBackgroundColor;
      border-bottom: none;
      border-radius: 12px 12px 0 0;
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
    max-width: unset;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid rgba($color: $grey, $alpha: 0.6);
  padding: 20px 30px;

  h2 {
    @include font-acumin-sb;
    display: flex;
    font-size: 27px;
    letter-spacing: 0;
    margin: 0;
    margin-bottom: -5px;
    color: $primaryColor;
    text-transform: uppercase;
  }
}

.detailsPopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 760px;
  width: 90%;
  outline: none;
  background-color: #fff;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  @media screen and (max-width: 767px) {
    max-width: 400px;
    height: 380px;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}

.selectorContainer {
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  gap: 30px;
  flex-grow: 1;
  .fieldContainer {
    display: flex;
    flex-direction: column;
    & > div {
      max-width: unset;
      :global {
        .offers__control {
          border: 1px solid $primaryColor;
          padding: 0;
          height: 40px;
          font-size: 14px;
          color: $primaryColor;
          &.offers__control--menu-is-open {
            border-bottom: 1px solid transparent;
            border-radius: 8px 8px 0 0;
          }
          .offers__single-value img {
            display: none;
          }
        }
        .offers__menu {
          border-color: $primaryColor;
          font-size: 14px;
          color: $primaryColor;
          .offers__option img {
            height: 20px;
            margin-right: 15px;
          }
        }
        .offers__input {
          color: $primaryColor;
        }
        .offers__placeholder {
          color: rgba($color: $primaryColorRgb, $alpha: 0.6);
          font-family: $circularStd;
        }
        .offers__single-value {
          color: $primaryColor;
        }
      }
    }
    .toggleButton {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      button {
        border: 1px solid $primaryColor;
        color: $primaryColor;
        flex-grow: 1;
        border-radius: 8px;
        padding: 9px 12px;
        font-size: 14px;
        font-family: $circularBold;
        min-width: 80px;
        &:global(.Mui-disabled) {
          border: 1px solid rgba($color: $primaryColorRgb, $alpha: 0.3);
          color: rgba($color: $primaryColorRgb, $alpha: 0.3);
          cursor: not-allowed;
          pointer-events: all;
          &::after {
            content: "This deal term is unavailable with the other terms you’ve selected";
            position: absolute;
            text-transform: none;
            font-family: $circularStd;
            top: 50px;
            border: 1px solid rgba($color: $primaryColorRgb, $alpha: 0.3);
            left: 50%;
            width: 300px;
            transform: translateX(-50%);
            background-color: $white;
            color: $primaryColor;
            font-size: 12px;
            padding: 5px 8px;
            border-radius: 8px;
            opacity: 0;
            z-index: 1;
            visibility: hidden;
            transition: opacity 0.2s ease-in-out;
            @media screen and (max-width: 767px) {
              width: 210px;
            }
          }
          &:hover::after {
            opacity: 1;
            visibility: visible;
          }
        }
        &:global(.Mui-selected) {
          background-color: $primaryColor;
          color: $white;
        }
        :global(.MuiToggleButton-label) {
          display: flex;
          flex-direction: column;
          text-transform: uppercase;
          p {
            margin: 0;
            font-size: 12px;
            font-family: $circularStd;
            text-transform: none;
            &:first-child {
              font-size: 14px;
              font-family: $circularBold;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    label {
      display: flex;
      align-items: center;
      font-family: $circularBold;
      font-size: 14px;
      gap: 5px;
      text-align: left;
    }
    span {
      margin-left: 5px;
      svg {
        cursor: pointer;
        transform: translate(0px, -1px);
        path {
          fill: $primaryColor;
        }
        height: 15px;
        width: 15px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    gap: 20px;
  }
}
.advanceTitle {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
  cursor: pointer;
  h3 {
    font-size: 14px;
    font-family: $circularBold;
    text-align: left;
    margin: 0;
    span {
      font-size: 10px;
      font-family: $circularStd;
    }
  }
  svg {
    height: 22px;
    width: 22px;
    &.opened {
      transform: rotateZ(180deg);
    }
    path {
      fill: $primaryColor;
    }
  }
}
.advanceContainer :global(.MuiCollapse-wrapperInner) {
  display: flex;
  flex-direction: column;
  padding: 0 30px 20px;
  gap: 30px;
  .fieldContainer {
    display: flex;
    flex-direction: column;
    & > div {
      max-width: unset;
      :global {
        .offers__control {
          border: 1px solid $primaryColor;
          padding: 0;
          height: 40px;
          font-size: 14px;
          color: $primaryColor;
          &.offers__control--menu-is-open {
            border-bottom: 1px solid transparent;
            border-radius: 8px 8px 0 0;
          }
        }
        .offers__menu {
          border-color: $primaryColor;
          font-size: 14px;
          color: $primaryColor;
        }
        .offers__input {
          color: $primaryColor;
        }
        .offers__placeholder {
          color: rgba($color: $primaryColorRgb, $alpha: 0.6);
          font-family: $circularStd;
        }
        .offers__single-value {
          color: $primaryColor;
        }
      }
    }
    .toggleButton {
      display: flex;
      gap: 10px;
      button {
        border: 1px solid $primaryColor;
        color: $primaryColor;
        flex-grow: 1;
        border-radius: 8px;
        padding: 9px 12px;
        font-size: 14px;
        font-family: $circularBold;
        min-width: 98px;
        &:global(.Mui-selected) {
          background-color: $primaryColor;
          color: $white;
        }
        :global(.MuiToggleButton-label) {
          display: flex;
          flex-direction: column;
          text-transform: uppercase;
          p {
            margin: 0;
            font-size: 12px;
            font-family: $circularStd;
            text-transform: none;
            &:first-child {
              font-size: 14px;
              font-family: $circularBold;
              text-transform: uppercase;
            }
          }
        }
      }
    }
    label {
      display: flex;
      align-items: center;
      font-family: $circularStd;
      font-size: 14px;
      gap: 5px;
      text-align: left;
    }
  }
}
.desktopFinePrint {
  display: block;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.mobileFinePrint {
  display: none;
  margin-bottom: 70px;
  @media screen and (max-width: 767px) {
    display: block;
  }
}

.linkBtn {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $primaryColor;
  border: none;
  column-gap: 8px;
  padding: 4px 20px;
  text-decoration: underline;
  font-size: 12px;
  margin: 0 auto;
  span {
    white-space: nowrap;
    font-family: $circularBold;
    letter-spacing: normal;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 0;
  }
}

.videoContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  align-items: flex-start;
  position: relative;
  gap: 10px;
  h2 {
    @include font-acumin-sb;
    font-size: 32px;
    align-items: center;
    color: $primaryColor;
    text-transform: uppercase;
    letter-spacing: 0;
  }
  p {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    color: $sidebarPrimaryBackgroundColor;
    margin: 0;
    align-items: center;
  }
  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    color: $primaryColor;
    cursor: pointer;
    font-size: 30px;
    line-height: 20px;
    padding: 20px;
  }
  .video {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 394px;
    iframe {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      @media screen and (max-width: 767px) {
        iframe {
          height: 400px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      height: unset;
    }
  }
}

.headerOfferRange {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  p {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    margin: 0;
    color: $sidebarPrimaryBackgroundColor;
  }
  .rangeNumbers {
    @include font-acumin-sb;
    display: flex;
    align-items: center;
    font-size: 32px;
    color: $sidebarPrimaryBackgroundColor;
    text-transform: lowercase;
    margin-bottom: -10px;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
