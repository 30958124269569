@import "../../../styles/variables.scss";

.main {
  height: 100%;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px;
  background: $veryLight;

  @media screen and (max-width: 767px) {
    max-height: calc(100vh - 133px);
    padding: 50px 20px;
  }

  .container {
    width: 95%;

    .summaryContainer {
      .detailsContainer {
        display: flex;
        text-align: left;
        width: 100%;
        form {
          & > div {
            margin-bottom: 0 !important;
          }
          .borderBottom {
            border-bottom: 1px solid $light;
          }
        }
        .offerContainer {
          display: flex;
          flex-direction: column;
          width: 100%;
          flex-grow: 2;
          background: $white;
          border-radius: 12px;
          .totalContent {
            display: flex;
            align-items: center;
            padding: 30px 35px;
            width: 100%;
            font-size: 14px;
            font-family: $circularStd;
            letter-spacing: 0;
            border-bottom: 2px solid $veryLight;

            .label {
              font-size: 18px;
              font-family: $circularBold;
              width: 50%;
              margin-right: 10px;
              text-align: left;

              @media screen and (max-width: 767px) {
                text-align: center;
                width: 100%;
                margin-right: 0px;
              }
            }
            .expireDate {
              text-align: right;
              width: 100%;
            }
            @media screen and (max-width: 767px) {
              flex-direction: column;
              padding-bottom: 10px;
              border-bottom: none;
              .expireDate {
                text-align: center;
              }
            }
          }
          .estimateContainer {
            display: flex;
            padding: 0px 20px;
            border-bottom: 2px solid $veryLight;
            font-size: 14px;
            width: 100%;
            p {
              margin-bottom: 0;
            }
            label {
              margin-bottom: 0;
            }
            .totalEstimate {
              width: 100%;
              font-family: $circularBold;
              color: $primaryColor;
              text-align: center;
              border-right: 2px solid $veryLight;
              padding: 25px 15px;

              @media screen and (max-width: 767px) {
                font-size: 16px;
              }

              .totalNumber {
                font-size: 32px;

                .more {
                  font-family: $circularStd;
                  font-size: 14px;
                }

                @media screen and (max-width: 1366px) {
                  font-size: 26px;
                }
                @media screen and (max-width: 767px) {
                  font-size: 26px;
                }
              }
              @media screen and (max-width: 767px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 5px 15px;
                border-right: none;
                font-size: 16px;
              }
            }
            .offerDetails {
              font-family: $circularBold;
              width: 100%;
              color: $primaryColor;
              border-right: 2px solid $veryLight;
              padding: 25px 15px;
              text-align: center;
              font-size: 15px;
              .importantText {
                color: $primaryColor;
              }
              .recoupmentRate {
                font-size: 14px;
                color: $black;
                margin-top: 25px;
                font-family: $circularStd;
                @media screen and (max-width: 1366px) {
                  font-size: 14px;
                  margin-top: 20px;
                }
                @media screen and (max-width: 767px) {
                  display: flex;
                  justify-content: space-between;
                  padding: 0px 40px;
                  font-size: 14px;
                  margin-top: 10px;
                }

                .recoupmentValue {
                  @media screen and (max-width: 767px) {
                    font-size: 16px;
                    font-family: $circularBold;
                    padding-left: 10px;
                  }
                }
              }
              @media screen and (max-width: 767px) {
                display: flex;
                flex-direction: column;
                padding: 5px 15px;
                margin-bottom: 10px;
                border-right: none;
                color: $primaryColor;
                font-family: $circularBold;
              }
            }
            .termContainer {
              font-family: $circularStd;
              width: 100%;
              padding: 25px 15px;
              text-align: left;
              .importantText {
                color: $primaryColor;
                @media screen and (max-width: 767px) {
                  font-family: $circularBold;
                }
              }
              @media screen and (max-width: 767px) {
                padding: 20px 0px;
                text-align: center;
                font-size: 14px;
                background-color: $greyColor;
              }
            }
            @media screen and (max-width: 767px) {
              flex-direction: column;
              padding: 0px;
              font-size: 12px;
            }
          }
          .contentContainer {
            display: flex;
            padding: 25px 35px;
            ul {
              width: 100%;
              padding-inline-start: 20px;
              margin-bottom: 0;
              @media screen and (max-width: 767px) {
                margin-bottom: 0;
                list-style: none;
              }
            }
            li {
              cursor: text;
              align-items: center;
              width: 100%;
              margin-bottom: 15px;
              span {
                font-size: 14px;
                font-family: $circularStd;
              }
              .value {
                font-size: 16px;
                font-family: $circularBold;
                margin-left: auto;

                @media screen and (max-width: 767px) {
                  padding-left: 10px;
                }
              }
            }
            @media screen and (max-width: 767px) {
              padding: 30px 35px 10px;
            }
          }
          .adjustTermsContainer {
            display: flex;
            padding: 25px 35px;
            align-items: center;
            .termsContent {
              font-size: 12px;
              padding: 0 10px;
            }
            .importantText {
              color: $primaryColor;
              text-decoration: underline;
              cursor: pointer;
            }
            @media screen and (max-width: 767px) {
              padding: 10px 35px;
            }
          }
          @media screen and (max-width: 767px) {
            order: 2;
            margin-top: 30px;
          }
        }
        .actionContainer {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          justify-content: center;
          margin-left: 40px;
          padding: 20px 0;
          background: $white;
          border-radius: 12px;

          @media screen and (max-width: 1366px) {
            margin-left: 20px;
          }

          .actionHeader {
            padding: 0 35px;
            font-size: 18px;
            text-align: center;
            font-family: $circularBold;
            @media screen and (max-width: 767px) {
              order: 0;
            }
          }

          .actionHeaderPost {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 35px 0px;
            font-size: 14px;
            font-family: $circularStd;
            @media screen and (max-width: 767px) {
              order: 0;
              padding: 10px 50px;
            }

            svg path {
              fill: $primaryColor;
            }

            .postText {
              color: $primaryColor;
              padding-left: 20px;
              text-align: left;
            }
          }

          .acceptOfferContainer {
            display: flex;
            flex-direction: column;
            padding: 35px 35px;
            align-items: center;

            p {
              text-align: center;
            }

            @media screen and (max-width: 767px) {
              border-top: none;
              margin-left: 0;
              position: fixed;
              bottom: 0;
              right: 0;
              left: 0;
              padding: 20px;
              border-radius: 12px 12px 0 0;
              box-shadow: 0px 10px 51px rgba($color: $black, $alpha: 0.3);
              background-color: $white;
              z-index: 1;
            }
          }
          .contactUsContainer {
            padding: 35px 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 14px;
            border-bottom: 2px solid $veryLight;

            @media screen and (max-width: 767px) {
              text-align: center;
              order: 0;
              padding: 10px 50px;
              border-bottom: none;
            }
          }

          .contactUsContainerPost {
            padding: 35px 35px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 14px;

            @media screen and (max-width: 767px) {
              text-align: center;
              order: 0;
              padding: 10px 50px;
              border-bottom: none;
            }
          }

          button {
            font-weight: bold;
            width: 100%;
            max-width: 280px;
            border-radius: 25px;
            height: 50px;
            background-color: $ctaColor;
            text-align: center;
            text-transform: uppercase;
            color: $ctaColor;
            letter-spacing: 2.6px;
            font-size: 13px;
            &.secondary {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $ctaColor;
              color: $white;
              font-family: $circularBold;
            }
            &.primary {
              border: 1px solid $ctaColor;
              background-color: $ctaColor;
              color: $fundingCtaFontColor;
            }
            &.primaryPost {
              max-width: 200px;
              border: 1px solid $ctaColor;
              background-color: $ctaColor;
              color: $fundingCtaFontColor;
            }
          }
          @media screen and (max-width: 1024px) {
            margin-left: 0;
            margin-top: 40px;
          }
          @media screen and (max-width: 767px) {
            margin-top: 0px;
          }
        }
        .digitContainer {
          display: flex;
          margin-bottom: 15px;
          align-items: flex-start;
          .digitContent {
            background-color: $sidebarPrimaryBackgroundColor;
            padding: 10px;
            border-radius: 5px;
            font-family: $circularBold;
            color: $sidebarFontColor1;
            font-size: 22px;
            margin-right: 30px;
            flex-shrink: 0;
            margin-bottom: 10px;
            td {
              padding: 8px 12px;
              padding-left: 20px;
              & > div {
                font-family: $circularStd;
                font-weight: 500;
                display: flex;
                font-size: 18px;
                justify-content: space-between;
                @media screen and (max-width: 1440px) {
                  font-size: 17px;
                }
                @media screen and (max-width: 767px) {
                  font-size: 14px;
                }
              }
            }
            @media screen and (max-width: 1440px) {
              font-size: 20px;
            }
            @media screen and (max-width: 767px) {
              font-size: 18px;
              width: 100%;
            }
          }
          .htmlContent {
            flex-grow: 1;
            position: relative;
            font-size: 14px;
            * {
              box-sizing: initial;
              &:after,
              &:before {
                font-size: initial;
                box-sizing: initial;
              }
            }
            a {
              text-decoration: underline;
              color: -webkit-link;
            }
          }
          @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
          }
        }
        .customizedContent {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          .recoupedContainer {
            display: flex;
            flex-direction: column;
            margin-right: 30px;
            p {
              color: $primaryColor;
              font-size: 14px;
              font-family: $circularBold;
              margin: 0;
            }
            ul {
              padding-inline-start: 18px;
            }
            @media screen and (max-width: 767px) {
              width: 100%;
              margin-right: 0;
            }
          }
          .artistMsg {
            position: relative;
            display: flex;
            padding: 30px 40px;
            flex-grow: 1;
            background-image: linear-gradient(
              to bottom right,
              $primaryColor calc((0.15 - 1) * 500%),
              transparent calc(0.15 * 650%)
            );
            i {
              font-family: monospace;
              font-style: normal;
              position: absolute;
              top: -18px;
              left: -18px;
              height: 45px;
              width: 45px;
              display: flex;
              justify-content: center;
              border-radius: 50%;
              background: white;
              box-shadow: 0 0 6px $darkGreyColor;
              color: $primaryColor;
              align-items: center;
              font-weight: 600;
              font-size: 15px;
              @media screen and (max-width: 767px) {
                height: 35px;
                width: 35px;
                font-size: 14px;
                top: -12px;
                left: -12px;
              }
            }
            p {
              margin: 0;
            }
            @media screen and (max-width: 767px) {
              padding: 20px;
            }
          }
        }
        @media screen and (max-width: 1024px) {
          flex-direction: column;
          padding-bottom: 0;
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
      }
    }
  }

  .last {
    margin-top: 30px;
    width: 95%;
    display: flex;
    flex-direction: row;
    background-color: $white;
    border-radius: 12px;

    @media screen and (max-width: 1240px) {
      flex-direction: column;
      padding-bottom: 20px;
    }

    &.posted {
      @media screen and (max-width: 767px) {
        margin-bottom: 120px;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 0px;
    }

    .next {
      width: 40%;
      padding: 30px 35px;

      @media screen and (max-width: 1240px) {
        width: 100%;
        text-align: center;
      }

      @media screen and (max-width: 767px) {
        padding: 20px 50px;
      }

      h3 {
        font-size: 18px;
        font-family: $circularBold;
      }

      .maximize {
        font-size: 14px;
        font-family: $circularStd;
        padding: 10px 0px;
        line-height: 1.6;
      }
    }

    .reviewMain {
      width: 100%;

      .clickEach {
        margin-top: 20px;
        font-size: 12px;
        font-family: $circularStd;
        text-align: center;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    .review {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      width: 100%;

      .reviewSign {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;

        .reviewSignImg {
          display: flex;
          margin-bottom: 20px;
          width: 100%;
          justify-content: center;

          .reviewSignArrow {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .iconText {
          text-align: left;

          @media screen and (max-width: 1240px) {
            width: 40%;
            text-align: center;
          }
        }
      }

      .parent {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        margin: 0px -10px;

        .line {
          width: 100%;
          padding: 3px;
          background-color: $primaryColor;
          position: relative;
        }

        .arrow {
          border: solid $primaryColor;
          border-width: 0px 5px 5px 0px;
          display: inline-block;
          padding: 7px;
          position: absolute;
          right: 0%;
          transform: rotate(-45deg);
        }
      }

      .lineArrow {
        width: 80%;
        height: 50px;
        border-bottom: 5px solid $primaryColor;
      }

      .assets {
        border-radius: 50%;
        cursor: pointer;
        background-color: white;

        svg {
          :global(.outerCircle) {
            fill: $progressAccentColor;
          }
        }

        &.big {
          svg {
            width: 90px;
            height: 90px;
            margin-bottom: -13px;
            margin-top: -10px;
            :global(.outerCircle) {
              fill: $marketplaceGreen;
            }
          }
          margin-bottom: 0px;
        }
      }

      @media screen and (max-width: 1240px) {
        margin-top: 20px;
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .reviewMob {
      display: none;

      @media screen and (max-width: 767px) {
        display: inline-block;
        margin-left: 30px;

        .reviewMobAsset {
          display: flex;
          flex-direction: row;
          align-items: center;

          .reviewMobText {
            margin-left: 30px;
            font-size: 16px;

            @media screen and (max-width: 430px) {
              margin-left: 10px;
            }
          }

          .reviewMobLogo {
            cursor: pointer;
            border-radius: 50%;
            svg {
              :global(.outerCircle) {
                fill: $progressAccentColor;
              }
            }
          }

          .big {
            svg {
              :global(.outerCircle) {
                fill: $marketplaceGreen;
              }
            }
          }
        }

        .mobParent {
          display: flex;

          .mobLine {
            padding: 3px;
            background-color: $primaryColor;
            height: 60px;
            margin-left: 30px;
            transform: translate(0px, -30px);
            margin-bottom: -36px;

            @media screen and (max-width: 540px) {
              height: 80px;
            }

            @media screen and (max-width: 345px) {
              height: 95px;
            }
          }

          .mobLine1 {
            padding: 3px;
            background-color: $primaryColor;
            height: 35px;
            margin: -12px 0px -12px 30px;
          }

          .mobLine2 {
            padding: 3px;
            background-color: $primaryColor;
            height: 60px;
            margin: -12px 0px -12px 30px;

            @media screen and (max-width: 540px) {
              height: 80px;
            }

            @media screen and (max-width: 345px) {
              height: 95px;
            }
          }

          .mobLineArrow {
            border: solid $primaryColor;
            border-width: 0px 5px 5px 0px;
            display: inline-block;
            padding: 7px;
            position: relative;
            left: 14px;
            top: -16px;
            margin-left: 10px;
            transform: rotate(45deg);
          }

          .mobLineText {
            margin-left: 60px;
            display: flex;
            align-items: center;
            margin-top: -10px;
            padding-right: 10px;
            width: 80%;
            font-size: 14px;

            @media screen and (max-width: 510px) {
              width: 85%;
              font-size: 13px;
            }

            @media screen and (max-width: 430px) {
              margin-left: 40px;
              font-size: 12px;
            }
          }

          .mobLineTextLast {
            margin-left: 100px;
            display: flex;
            align-items: center;
            margin-top: -10px;
            width: 80%;
            padding-right: 10px;
            font-size: 14px;

            @media screen and (max-width: 510px) {
              width: 85%;
              font-size: 13px;
            }

            @media screen and (max-width: 430px) {
              margin-left: 80px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
