@import "../../styles/variables.scss";

@mixin menu-item {
    .menuItem {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      width: 100%;
      position: relative;
      opacity: 0.5;
      background-color: transparent;
      color: $sidebarTextIconColor;
      border-radius: 6px;
      margin-bottom: 10px;
      &.active {
        opacity: 1;
        background: $sidebarCardButtonColor !important;
        border-radius: 0 !important;
      }
      .iconContainer {
        display: flex;
        align-items: center;
        width: 31px;  
        svg {
          flex-shrink: 0;
          width: 100%;
          margin-left: -5px;
          path {
            fill: $sidebarTextIconColor;
            stroke: none;
          }
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .linkTitle {
        font-size: 12px;
        margin: 3px 0;
        color: $sidebarTextIconColor;
      }
      .sidebar-card-button-color {
        display: flex;
        align-items: center;
        font-size: 11px;
        font-family: $circularBold;
        color: $progressAccentColor;
        span {
          display: block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $progressAccentColor;
          margin-right: 6px;
        }
        &.initialIndicator {
          color: $white;
          span {
            background-color: $white;
          }
        }
        &.successIndicator {
          color: $success;
          span {
            background-color: $success;
          }
        }
        &.errorIndicator {
          color: $error;
          span {
            background-color: $error;
          }
        }
      }
      @media screen and (max-width: 1440px) {
        width: 100%;
      }
      @media screen and (max-width: 1366px) {
        width: 100%;
      }
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 5px 10px;
        opacity: 1;
      }
    }
  }

.container {
    flex-shrink: 0;
    z-index: 4;
    width: 240px;
    background-color: $sidebarPrimaryBackgroundColor;
    color: $sidebarFontColor1;
    max-height: 100vh;
    overflow-y: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .logoContainer {
      display: flex;
      width: 100%;
      padding: 30px 30px 0px;
      cursor: pointer;
      img {
        width: 100%;
        max-height: 60px;
        object-fit: contain;
        flex-shrink: 0;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .menuContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 1000px;
      margin: auto;
      overflow-x: auto;
      margin-top: 30px;
      @include menu-item;
      p {
        margin: 0;
        white-space: break-spaces;
      }
      .options {
        display: flex;
        align-items: center;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        width: 100%;
        display: none;
      }
    }
    .mobileMenuContainer {
      display: none;
      flex-direction: column;
      @include menu-item;
      p {
        margin: 0;
        white-space: nowrap;
      }
      :global(.navigation__option--is-focused) {
        background-color: $sidebarPrimaryBackgroundColor;
      }
      :global(.navigation__option--is-selected) {
        background-color: $sidebarHeaderFooterColor;
        color: $sidebarFontColor1;
      }
      :global(.navigation__dropdown-indicator) {
        color: $sidebarFontColor1;
        svg path {
          fill: $sidebarFontColor1;
        }
      }
      :global(.navigation__menu) {
        background-color: $sidebarPrimaryBackgroundColor;
        .estimateContainerText {
          .fundingEstimatedValue {
            font-size: 24px;
            font-family: $circularBold;
          }
          span {
            margin-left: 5px;
            svg {
              cursor: pointer;
              transform: translate(0px, -1px);
              path {
                fill: $sidebarTooltipIconColor;
              }
            }
          }
        }
        .modalBtnContainer {
          cursor: pointer;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 15px;
          border-radius: 8px;
          background-color: lighten($grey, 31%);
          font-family: $circularSemiBold;
          font-size: 14px;
          color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.8);
          svg {
            fill: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.8);
            margin-right: 15px;
          }
        }
      }
      :global(.navigation__control) {
        padding: 8px 0;
        border-bottom: 1px solid $sidebarHeaderFooterColor;
        background-color: var(--sidebar-card-button-color);
      }
      @media screen and (max-width: 767px) {
        display: flex;
      }
    }
    @media screen and (max-width: 1440px) {
      font-size: 16px;
    }
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1024px) {
      font-size: 12px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0;
      overflow-y: visible;
      margin-top: 80px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($color: $white, $alpha: 0.3);
      border-radius: 5px;
      max-height: 100px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba($color: $white, $alpha: 0.5);
    }
  }