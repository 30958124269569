@import "../../../../styles/variables.scss";

.getfundedContent {
  padding: 0;
  text-align: center;
  margin: 0 auto;
  max-width: 692px;
  margin-bottom: 90px;
  margin-top: 40px;
  .header {
    background-color: $appHeaderBackgroundColor;
    padding: 30px;
    color: $appHeaderFontColor;
    h2 {
      font-size: 30px;
      letter-spacing: 1.2px;
      font-family: $circularBold;
    }
    p {
      font-size: 18px;
      letter-spacing: 0.72px;
      font-family: $circularBold;
      margin-bottom: 0;
    }
  }
  .formBody {
    background-color: $white;
    padding: 40px;
    @media screen and (max-width: 767px) {
      padding: 20px;
      margin-bottom: 70px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
    max-width: unset;
    margin-bottom: 0;
  }
}

.hideSubtitle {
  display: none;
}

.socialMediaLink {
  display: flex;
  align-items: center;
  padding: 23px 18px;
  text-align: left;
  color: $black;
  background-color: $greyColor;
  border: 1px solid $darkGreyColor;
  border-radius: 2px;
  margin: 8px 0;
  float: left;
  width: 100%;
  vertical-align: middle;
}
.socialMediaLink a {
  color: $black;
  font-size: 16px;
  letter-spacing: 0.4px;
  vertical-align: top;
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
}
.socialMediaLink img {
  margin-right: 18px;
  width: 19px;
}
.socialMediaLink a span {
  text-decoration: underline;
  width: 80%;
}

.socialMediaLinkActive {
  display: flex;
  align-items: center;
  padding: 20px 18px;
  text-align: left;
  color: $white;
  background-color: $primaryColor;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-top: 10px;
  float: left;
  width: 100%;
}
.socialMediaLinkActive img {
  margin-right: 15px;
  width: 19px;
  float: left;
}
.socialMediaLinkActive span {
  color: $white;
  font-size: 16px;
  letter-spacing: -0.2px;
  display: block;
  float: left;
  width: 76%;
}
.socialMediaLinkActive .linkbutton {
  padding: 8px 20px;
  width: auto;
  color: #ffffff;
  flex: 0 0 auto;
  border-radius: 2px;
  font-weight: bold;
  background-color: $dark;
  margin-top: 0px;
  margin-left: -15px;
}
.socialMediaLinkActive .linkbutton:hover {
  color: $white;
}

.getfundedContent a.startOver {
  font-size: 22px;
  font-weight: bold;
  color: $primary;
  letter-spacing: 1px;
  text-align: left;
}
.getfundedContent a.startOver i {
  font-size: 30px;
  margin-left: -18px;
  margin-top: -5px;
  line-height: 30px;
  vertical-align: middle;
}
.stratoverDiv {
  text-align: left;
}
.socialMedia {
  padding: 20px;
  display: none;
}
.p1,
.P_subtitle {
  @media (max-width: 639px) {
    text-align: left;
    padding: 0 20px;
  }
}

.getFundedAuthContent_heading {
  white-space: normal !important;
  padding-top: 18px;
}

.getFundedAuthContent_profileImg {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  margin-bottom: 15px;
  @media screen and (max-width: 767px) {
    height: 70px;
    width: 70px;
    margin-top: 20px;
  }
}

.preHeading {
  margin-bottom: 25px;
  p:first-child {
    font-size: 24px;
    letter-spacing: 0.96px;
    margin-bottom: 5px;
  }
  p:last-child {
    font-size: 18px;
    letter-spacing: 0.72px;
    font-family: $circularBold;
    color: $appAccentFontColor;
  }
}

.getFundedAuthContent_profileImgIcon {
  height: 120px !important;
  width: 120px !important;
  @media screen and (max-width: 767px) {
    height: 70px !important;
    width: 70px !important;
    margin-top: 20px;
  }
}

.profileDetail {
  margin: auto;
  width: 80%;
  min-width: 511px;
  position: relative;
  &::after {
    content: " ";
    position: absolute;
    width: 15%;
    transform: translateX(-50%);
    border-bottom: 1px solid $darkGreyColor;
  }
  @media screen and (max-width: 639px) {
    min-width: unset;
    width: 100%;
  }
}

.formContainer {
  max-width: 572px;
  padding-bottom: 0;
  h1 {
    font-size: 30px;
    letter-spacing: 1.2px;
    font-family: $circularBold;
    max-width: 530px;
    padding: 20px 0;
    margin: auto;
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
      line-height: 26px;
    }
  }
}
