@import '../../../styles/variables.scss';

@mixin btn {
  background-color: $ctaColor;
  width: 100%;
  max-width: 200px;
  padding: 10px 0px;
  color: $fundingCtaFontColor;
  border: 0px;
  border-radius: 28px;
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 10px 50px;
    display: block;
    margin: 0px auto;
    max-width: unset;
  }
  @media screen and (max-width: 474px) {
    padding: 5px 30px;
    font-size: 14px;
  }
  @media screen and (max-width: 350px) {
    margin-top: 40px;
    width: 100%;
    max-width: 220px;
    padding: 5px 10px;
  }
  &.forgotResetPasswordBtn{
    max-width: unset;
  }
}

.footerBtn{
  display:flex;
  flex-direction: column;
  justify-content: center;
}

.mainContainer {
  background-color: $loginBgColor;
  height: 100vh;
}

.layoutContainer {
  display: flex;
  min-height: 100vh;
  .funnelContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.loginMainContent{
   width: 100%;
    display: flex;
    padding: 30px 40px;
    background-color: #f1f1f1;
    flex-grow: 1;
    justify-content: center;
    max-height: calc(100vh - 100px);
    overflow: auto;
    @media (max-width: 639px) {
      padding: 30px 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 20px;
      max-height: unset;
    }
}


.loginContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 70px 30px 30px 30px;
  background-color: $white;
  margin: auto;
  max-width: 550px;
  border-radius: 14px;
  @media (max-width: 639px) {
    padding: 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 10px;
  }
}


.mainTitleContainer
{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  .iconContainer {  
    svg {
      flex-shrink: 0;
      width: 60px;
      height: 60px;
      margin-left: -5px;
      path {
        fill: $primaryColor;
        stroke: none;
      }
    }
  }
}
.titleContainer{
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  .title{
    font-family: $circularBold;
    font-size: 24px;
  }
}


.formContainer {
  text-align: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding: 50px 0px 0px;
  align-items: center;
  &.formWithoutLogo{
    padding: unset;
    @media (max-width: 767px) {
      padding: 20px;
    }
  }
  .btn {
    @include btn();
  }

  .submitBtn{
    display:flex;
    justify-content: space-between;
    gap:20px;
    align-items:center;
    padding: 30px 0px;
    height: 50px;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      padding: unset;
      align-items: flex-start;
      height: unset;
    }
  }

  .withoutLogoForm{
    padding: 0px;
  }

  .maintenanceTitle {
    @media (max-width: 639px) {
      font-size: 24px;
    }
    @media (max-width: 479px) {
      font-size: 18px;
      white-space: nowrap;
    }
  }
  .maintenanceDetail {
    @media (max-width: 639px) {
      text-align: left;
      padding: 0 20px;
    }
    @media screen and (max-width: 479px) {
      padding: 0px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 29px 10px 0px;
  }
}

.loginContent h1 {
  color: $appPrimaryBackgroundColor;
  font-weight: 600;
  font-family: $circular;
  font-size: 28px;
  line-height: 20px;
  letter-spacing: 0px;
  white-space: nowrap;
  margin-bottom: 25px;
  @media screen and (max-width: 767px) {
    white-space: unset;
    font-size: 24px;
  }
  @media (max-width: 639px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 479px) {
    white-space: unset;
    font-size: 20px;
    padding: 0px 5px;
  }
}

.loginContent .subTitle {
  color: $primaryColor;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  @media (max-width: 639px) {
    text-align: left;
    padding: 0 20px;
  }
}

.loginContent .formLink {
  margin: 15px 0;
  display: block;
  text-align: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
  @media screen and (max-width: 474px) {
    font-size: 12px;
  }
}

.loginContent .formMargin0 > div {
  margin: 0 !important;
}

.loginContent .emailOrPhone {
  margin: 0;
  cursor: pointer;
  text-align: right;
  float: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
}

.loginContent .resendContainer {
  display: flex;
  align-items: center;
  color: $black;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  span {
    margin-right: 10px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
}

.loginContent .resendContainer button {
  margin-right: 10px;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
  background-color: transparent;
  border: none;
  padding: 0;
}

.loginContent .forgotPassword {
  margin: 0px 0px 15px 0px;
  text-align: right;
  float: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    float: unset;
    display: block;
    text-align: center;
    margin: 10px 0 0;
  }
  @media screen and (max-width: 474px) {
    font-size: 12px;
  }
}

.loginContent .infoContainer {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
  }
}

.loginContent .infoIcon {
  position: absolute;
  transform: translate(150%, 160%);
  color: $tooltipFontColor;
  background-color: $tooltipBackgroundColor;
  right: 0;
  margin-left: 10px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    transform: translate(0, 0);
  }
}

.loginContent .infoIcon::after {
  content: '?';
}

.loginContent .signUpDiv {
  display: flex;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  padding:0px;
  white-space:nowrap;
  margin-top:10px;
  span {
    color: $primaryColor;
    text-decoration: underline;
    cursor: pointer;
  }
}
.loginContent .policyDiv {
  font-size: 10px;
  line-height: 15px;
  color: $black;
  text-align: center;
  a {
    color: $primaryColor;
    text-decoration: underline;
  }
}
.loginContent .emailorphone {
  margin: 0;
  text-align: right;
  float: right;
  font-size: 16px;
  text-decoration: underline;
  color: $primaryColor;
  font-weight: 600;
  cursor: pointer;
}
.nameContainer {
  display: flex;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.fnameContainer {
  margin-right: 10px;
  p {
    padding: 5px 0px;
    border-bottom: 1px solid $darkGreyColor;
    color: $primaryColor;
  }
}

.formgroup {
  &.marginTop {
    margin-top: 35px;
  }
  label {
    width: 100%;
    padding: 19px 0 14px;
    margin: 0;
    font-weight: 600;
  }

  .infoIcon {
    position: absolute;
    transform: translate(120%, 135%);
    right: 0;
    margin-left: 10px;
    z-index: 1;
    height: 20px;
    width: 20px;
    color: $tooltipFontColor;
    cursor: pointer;
    background-color: $tooltipBackgroundColor;
    font-style: normal;

    @media screen and (max-width: 479px) {
      height: 17px;
      width: 17px;
      transform: translate(120%, 180%);
    }
  }
}

.poweredBy {
  img {
    height: 60px;
    width: auto;
  }
  p {
    margin-top: 10px;
  }
}

.formHeader {
  display: flex;
  flex-direction: column;
  .mainHeader {
    display: flex;
    align-items: left;
    .headerText {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .artistImage {
      width: 43px;
      height: 43px;
      z-index: 1;
      border-radius: 25px;
    }
    @media screen and (max-width: 767px) {
      justify-content: center;
    }
  }
  .createAccountText {
    display: flex;
    align-items: left;
    .partnerText {
      display: flex;
      text-align: start;
    }
    p {
      margin-top: 0px;
      font-family: $circularStd;
      font-size: 14px;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  p {
    margin-top: 5px;
    margin-left: 10px;
    font-family: $circularBold;
    font-size: 24px;
  }
  span {
    color: $primaryColor;
  }
}

.headerImages {
  width: 43px;
  height: 43px;
  padding: 10px;
  background-color: $primaryColor;
  border-radius: 25px;
  margin-left: -18px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: $white;
    }
  }
}

.registerContent {
  @media screen and (max-width: 767px) {
    background-color: $white;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    border-radius: 12px 12px 0px 0px;
    z-index: 2;
    box-shadow: 0px 5px 10px 0px $black;
  }
}

.registerContent .btnregister {
  padding: 18px;
  font-size: 14px;
  background-color: $ctaColor;
  color: $fundingCtaFontColor;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  border-radius: 30px;
  width: fit-content;
  display: flex;
  margin-left: auto;
  border: none;
  &.padding {
    padding: 18px 25px;
  }

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-bottom: 10px;
    padding: 18px 50px;
  }
}

.registerContent .btnregister:disabled {
  color: $white;
  border: none;
}

.registerContent .infoContainer {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    align-items: center;
  }
}

.registerContent .infoIcon {
  position: absolute;
  transform: translate(150%, 160%);
  color: $white;
  right: 0;
  margin-left: 10px;
  z-index: 1;
  @media screen and (max-width: 767px) {
    transform: translate(0, 0);
  }
}

.optionalSpan {
  margin-left: auto;
  color: $grey;
}

.greyborder {
  border-top: 1px solid $grey;
  margin: 0;
}
a {
  color: $primaryColor;
}

.and {
  font-family: $circularBold;
}

.specialChar {
  font-family: $circularStd;
}
.checkboxAlign {
  text-align: left;
  padding: 15px 0px;
  margin-bottom: -30px;
  @media screen and (max-width: 767px) {
    margin-top: 24px;
    padding: 15px 0px;
    font-size: 14px;
  }
}
.checkboxAlign a {
  color: $black;
  font-family: $circularBold;
}
.checkboxAlign a:hover {
  color: $black;
  border-bottom: 1px solid $black;
}
.checkboxinput {
  height: 15px;
  width: 15px;
  label {
    vertical-align: middle;
    padding-left: 10px;
    display: inline;
  }
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 90%;
  }
  a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}
.socialMediaSectionEnd {
  margin: 20px 20px 16px 20px;
}

.verifyContent {
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: 45px;
  max-width: 1076px;
  @media (max-width: 1025px) {
    max-width: 765px;
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media (max-width: 992px) {
    max-width: 740px;
    margin-bottom: 50px;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    margin-top: 0;
    max-width: 573px;
    margin-bottom: 40px;
  }
  @media (max-width: 639px) {
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 192px);
    margin-bottom: 0;
  }
}
.errorContainer {
  padding: 40px;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 18px;
    font-family: $circularBold;
    color: $blueColor;
    line-height: 27px;
    margin-bottom: 20px;
    padding-top: 20px;
  }
  p {
    font-size: 14px;
    font-family: $circularStd;
    color: $darkBlueColor;
  }
  .btnContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 70px;
    padding-bottom: 20px;
    button {
      width: 100%;
      max-width: 190px;
      display: flex;
      box-shadow: 0px 7px 15px rgba($black, 0.2);
      padding: 20px;
      color: $fundingCtaFontColor;
      justify-content: center;
      border: none;
      border-radius: 35px;
      font-family: $circularBold;
      font-size: 14px;
      margin-top: 0;
      letter-spacing: 2.8px;
      background-color: $ctaColor;
    }
  }
}

.spotifySearch {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  label:global(.floating) {
    z-index: 1;
  }
  @media screen and (max-width: 470px) {
    font-size: 13px;
  }
}

.spotifySearch > * {
  flex-shrink: 0;
}

.spotifySearch > div {
  width: 100%;
}

.spotifySearch > i {
  color: $spotify;
  position: absolute;
  background: radial-gradient(
    $white,
    $white,
    $white,
    $white,
    $white,
    transparent,
    transparent,
    transparent,
    transparent
  );
  border-radius: 50px;
  font-size: 25px;
  transform: translateX(-15px);
}

.line {
  border-bottom: 1px solid $veryLight;
  width: 100%;
  margin: 5px 0px;
}

.policyMainDiv {
  label {
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    a {
      text-decoration: underline;
    }
  }
  .label1 {
    color: $appPrimaryBackgroundColor;
    font-family: $circularStd;
    font-size: 14px;
  }
  .checkbox {
    color: $appPrimaryBackgroundColor !important;
    padding: 0px 10px !important;
  }
}
