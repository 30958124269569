@import "../../styles/variables.scss";

//animations
@keyframes twistBack {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0);
  }
}
@keyframes twistForward {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}


@mixin menu-item {
  .menuItem {
    display: flex;
    flex-direction: column;
    padding: 5px 15px 14px;
    width: 100%;
    position: relative;
    opacity: 0.5;
    background-color: transparent;
    color: $sidebarFontColor2;
    margin-bottom: 10px;
    &.active {
      opacity: 1;
      background: $sidebarCardButtonColor !important;
      color: $sidebarFontColor1 !important;
      svg, path, rect, g {
        fill: $sidebarFontColor1 !important;
      }
    } 
    .linkTitle {
      font-size: 12px;
      margin: 3px 0;
      color: $sidebarTextIconColor;
    }
    .statusText {
      display: flex;
      align-items: center;
      font-size: 11px;
      font-family: $circularBold;
      color: $progressAccentColor;
      span {
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $progressAccentColor;
        margin-right: 6px;
      }
      &.initialIndicator {
        color: $white;
        span {
          background-color: $white;
        }
      }
      &.successIndicator {
        color: $success;
        span {
          background-color: $success;
        }
      }
      &.errorIndicator {
        color: $error;
        span {
          background-color: $error;
        }
      }
    }
    @media screen and (max-width: 1440px) {
      width: 100%;
    }
    @media screen and (max-width: 1366px) {
      width: 100%;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 5px 10px;
      opacity: 1;
    }
  }
}

.container {
  flex-shrink: 0;
  z-index: 4;
  width: 240px;
  background-color: $sidebarPrimaryBackgroundColor;
  color: $sidebarFontColor2;
  max-height: 100vh;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .logoContainer {
    display: flex;
    width: 100%;
    padding: 30px 30px 0px;
    cursor: pointer;
    img {
      width: 100%;
      max-height: 60px;
      object-fit: contain;
      flex-shrink: 0;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .menuTypeHeader {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .typeTitle {
      font-size: 12px;
      margin-bottom: 2px;
    }
    .typeSubTitle {
      font-size: 10px;
      opacity: 0.6;
      margin-bottom: 10px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
    padding-left: 20px;
  }
  .skeletonStyle {
    background-color: $sidebarHeaderFooterColor;
    height: 90px;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 10px;
    &.txt {
      height: unset;
      width: 70%;
      margin-bottom: 5px;
    }
  }
  .menuContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1000px;
    margin: auto;
    overflow-x: auto;
    @include menu-item;
    p {
      margin: 0;
      white-space: break-spaces;
    }
    &.approvedMenu {
      justify-content: flex-start;
      margin-top: 30px;
      .menuItem {
        background-color: transparent;
        padding: 0px;
        margin: 0px;
        font-size: 12px;
        .options {
          display: flex;
          align-items: center;
          gap: 6px;
          padding: 10px 20px;
          svg {
            width: 16px;
            height: 16px;
            flex-shrink: 0;
            svg, path, rect, g {
              fill: $sidebarFontColor2;
              stroke: none;
            }
            @media screen and (max-width: 767px) {
              display: none;
            }
          }
          p {
            width: 100%;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      display: none;
    }
  }
  .mobileMenuContainer {
    display: none;
    flex-direction: column;
    @include menu-item;
    p {
      margin: 0;
      white-space: nowrap;
    }
    :global(.navigation__option--is-focused) {
      background-color: transparent;
    }
    :global(.navigation__option--is-selected) {
      background-color: $sidebarCardButtonColor;
      color: $sidebarFontColor1;
    }
    :global(.navigation__menu) {
      background-color: $sidebarPrimaryBackgroundColor;
      border-radius: 0 0 15px 15px;
      padding: 0px;
      :global(.navigation__menu-list) {
        padding: 0px;
      }
      :global(.navigation__group) {
        padding: 0px;
        :global(.navigation__group-heading) {
          display: none;
        }
      }
      .estimateContainerText {
        color: $sidebarFontColor2;
        .fundingEstimatedValue {
          font-size: 24px;
          font-family: $circularBold;
        }
        span {
          margin-left: 5px;
          svg {
            cursor: pointer;
            transform: translate(0px, -1px);
            svg, path, rect, g {
              fill: $sidebarTooltipIconColor;
            }
          }
        }
      }
      .modalBtnContainer {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 15px;
        border-radius: 8px;
        background-color: lighten($grey, 31%);
        font-family: $circularSemiBold;
        font-size: 14px;
        color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.8);
        svg {
          fill: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.8);
          margin-right: 15px;
        }
      }
    }
    :global(.navigation__control) {
      padding: 8px 0;
      border-bottom: 1px solid $sidebarHeaderFooterColor;
      box-sizing: border-box;
      background-color: $sidebarCardButtonColor;
      color: $sidebarFontColor1;
      
      :global(.navigation__dropdown-indicator) {
        color: $sidebarFontColor1;
        animation: twistBack 0.5s ease 0s 1 normal forwards;
        svg, path, rect, g  {
          fill: $sidebarFontColor1;
        }
      }
    }
    @media screen and (max-width: 767px) {
      display: flex;
    }
    :global(.navigation__control--menu-is-open) {
      background-color: $sidebarPrimaryBackgroundColorRgb;
      color: $sidebarFontColor2;
      :global(.navigation__dropdown-indicator) {
        color: $sidebarFontColor2;
        animation: twistForward 0.5s ease 0s 1 normal forwards;
        svg, path, rect, g  {
          fill: $sidebarFontColor2;
        }
      }
    }
  }
  .skeletonContainer {
    justify-content: center;
    align-items: center;
  }
  .rightNav {
    width: 100%;
    :global(.select__single-value) .optional {
      display: none;
    }
    .rightContainer {
      display: flex;
      flex-direction: column;
      font: 500 normal 12px/150% $circularStd;
      letter-spacing: 0px;
      .text {
        display: flex;
        padding: 10px 20px;
        .leftSideMenu {
          display: flex;
          cursor: pointer;
        }
        p {
          margin: 0;
        }
        .svgLeft {
          margin-right: 10px;
          svg, path, rect, g {
            fill: $sidebarFontColor2;
          }
        }
      }
      .settingIconContainer {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 10px 20px;
        cursor: pointer;
        background-color: unset;
        color: unset;
        &.activeSettings {
          background-color: $sidebarCardButtonColor;
          color: $sidebarFontColor1;
          svg, path, rect, g {
            fill: $sidebarFontColor1 !important;
          }
        }
        p {
          margin: 0;
        }
        .svgLeft {
          margin-right: 10px;
          svg, path, rect, g {
            fill: $sidebarFontColor2;
          }
        }
        @media screen and (max-width: 767px) {
          svg, path, rect, g {
            fill: $white;
          }
        }
      }
    }
    .artistDropDown {
      padding: 0px 20px;
      font-size: 12px;
      background: color-mix(in srgb, $sidebarPrimaryBackgroundColor 40%, black 60%);
      height: 80px;
      display: flex;
      align-items: center;
      > div {
        width: 100%;
      }
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
    :global(.artist-select__menu) {
      background-color: color-mix(in srgb, $sidebarPrimaryBackgroundColor 40%, black 60%) !important;
      width: 240px !important;
      left: -20px !important;
      bottom: 50px !important;
      box-shadow: 0px -1px 4px 0px #000 inset !important;
      border-radius: 15px 15px 0px 0px !important;
      margin: 0px !important;
      .profile {
        height: 20px;
        width: 20px;
      }
    }
    :global(.artist-select__option) {
      background-color: unset;
      .dropLabel {
        font: 500 normal 12px/150% $circularStd;
        letter-spacing: 0px;
      }
      .profile {
        height: 20px;
        width: 20px;
      }
    }
    :global(.artist-select__option--is-selected) {
      background-color: rgba(255, 255, 255, 0.10);
      .dropLabel {
        font-family: $circularBold;
      }
      .profile {
        border: 2px solid $white;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    font-size: 16px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 14px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 12px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
    overflow-y: visible;
    margin-top: 80px;
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($color: $white, $alpha: 0.3);
    border-radius: 5px;
    max-height: 100px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($color: $white, $alpha: 0.5);
  }
}

.checkIcon {
  display: inline-block;
  transform: rotate(45deg);
  height: 10px;
  width: 6px;
  border-bottom: 2px solid $progressAccentColor;
  border-right: 2px solid $progressAccentColor;
  margin: 0 10px;
  @media screen and (max-width: 1024px) {
    margin: 0 6px;
  }
}



.selectOption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: $white;
  .profile {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
    box-sizing:content-box;
    img {
      width: 100%;
      height: 100%;
    }
    svg {
      height: auto;
      width: auto;
    }
  }
  p {
    letter-spacing: 0px;
    font: 500 normal 14px/150% $circularStd;
    margin: 0;
    color: $white;
  }
}



.selectArtistDrop {
  width: 100%;
  position: relative;
  :global(.artist-select__control) {
    padding-top: 0px;
    background: none;
    border: none;
    .labelContainer {
      width: 150px;
    }
    :global(.artist-select__indicators) {
      color: $white;
      animation: twistBack 0.5s ease 0s 1 normal forwards;
      svg, path, rect, g  {
        fill: $white;
      }
    }
  }
  :global(.artist-select__control--menu-is-open){
    :global(.artist-select__indicators) {
      color: $white;
      animation: twistForward 0.5s ease 0s 1 normal forwards;
      svg, path, rect, g  {
        fill: $white;
      }
    }
  }
  :global(.artist-select__value-container) {
    padding: 0px;
    overflow: unset;
    div {
      color: $white;
    }
  }
  :global(.artist-select__indicator) {
    padding: 0px;
  }
  .dropLabel {
    color: $white;
    font-family: $circularStd;
    letter-spacing: 0px;
    text-align: left;
    text-overflow: ellipsis;
    font-size: 14px;
    overflow: hidden;
  }
  .dropBtnContainer {
    display: flex;
    flex-direction: column;
    .goToArtistBtn {
      display: flex;
      gap: 6px;
      align-items: center;
      padding: 10px 20px;
      font-size: 12px;
    }
    .artistSvg {
      width: 20px;
      height: 20px;
    }
    .artistSvg path {
      fill: $white;
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.dropSetting {
  display: flex;
  justify-content: space-between;
  padding-left: 6px;
  width: 100%;
  margin-bottom: -15px;
  font-family: $circularStd;
  letter-spacing: 0px;
  :active {
    background-color: unset;
  }
  :hover {
    background-color: unset;
  }
  p {
    font-size: 14px;
    padding-left: 10px;
    opacity: 0.7;

    @media screen and (max-width: 1366px) {
      font-size: 12px;
      padding-left: 20px;
    }
  }
}

.settingMenu {
  .mobileMenuItem {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
  :global(.MuiListItem-button:hover) {
    background-color: $sidebarPrimaryBackgroundColor;
    color: $sidebarFontColor2;
  }
  :global(.MuiPaper-root) {
    background-color: $sidebarCardButtonColor;
    color: $sidebarFontColor1;
    border-radius: 0px;
  }
  :global(.MuiMenuItem-root) {
    background-color: unset;
    min-height: auto;
    padding: 10px 20px;
  }
}
.menuText {
  display: flex;
  font: 500 normal 14px/142% $circularStd;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: capitalize;
}
.rightNav .menuText {
  padding: 15px 5px;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  font-family: $circularStd;
  color: $black;
  letter-spacing: 0px;
  line-height: 0px;
  white-space: nowrap;
  &:last-child {
    border-bottom: none;
  }
  @media screen and (max-width: 1440px) {
    font-size: 13px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 12px;
    padding: 12px 5px;
  }
}

.dashboard {
  div:global(.MuiPaper-root) {
    max-width: 636px;
    background-color: $veryLight;
  }
}



.eventPopup {
  display: flex;
  flex-direction: column;
  .mainText {
    color: $black;
    span {
      color: $primaryColor;
    }
  }
  img {
    height: 83px;
  }
  button {
    border: none;
    padding: 12px 20px;
    background-color: transparent;
    border-radius: 25px;
    min-width: 150px;
    &.primaryBtn {
      color: $fundingCtaFontColor;
      background-color: $ctaColor;
      font-family: $circularBold;
      text-transform: uppercase;
      letter-spacing: 2.8px;
    }
    &.linkBtn {
      margin-top: 10px;
      color: $ctaColor;
      text-decoration: underline;
      padding: 0 20px;
    }
  }
  .promaryBtn {
    border: none;
  }
}