@import "../../../../styles/variables.scss";

.pageContainer {
  display: flex;
  flex: 1;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  padding: 30px 40px;
  max-width: 1145px;
  flex-grow: 1;
  width: 100%;
  background-color: $veryLight;
  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }
}

.mainContainer {
  background: $veryLight;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
      max-height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 221px);
    padding: 0px 15px;
    max-height: unset;
  }
  @media screen and (max-width: 529px) {
    min-height: calc(100vh - 167px);
  }
}

.spotifyProfile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .profileContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    .artistProfile {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .editProfileLink {
        text-decoration: underline;
        color: $primaryColor;
        font-size: 12px;
        margin-bottom: 0;
        display: flex;
        cursor: pointer;
        svg {
          width: 12px;
          height: 12px;
          flex-grow: 0;
          flex-shrink: 0;
          margin: 3px 3px 2px;
          path {
            fill: $primaryColor;
          }
        }
      }
    }
    .bioContainer {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
  }
  label {
    letter-spacing: 0.4px;
    color: $primaryColor;
    text-transform: uppercase;
    @media screen and (max-width: 1440px) {
      font-size: 14px;
      letter-spacing: 0.56px;
    }
  }
  a {
    color: $black;
    letter-spacing: 0.76px;
    text-decoration: underline;
    display: block;
    word-break: break-all;
    &:hover {
      color: $black;
    }
    @media screen and (max-width: 1440px) {
      font-size: 13px;
      letter-spacing: 0.56px;
    }
  }
  .imgContainer {
    width: 122px;
    height: 122px;
    border-radius: 50%;
    margin-right: 20px;
    flex-shrink: 0;
    position: relative;
    input[type="file"] {
      position: absolute;
      opacity: 0;
      width: 100%;
      z-index: 1;
      height: 100%;
      cursor: pointer;
      &::-webkit-file-upload-button {
        cursor: pointer;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .iconContainer {
      position: absolute;
      border-radius: 15px;
      background-color: $primaryColor;
      height: 32px;
      width: 32px;
      bottom: 0px;
      right: 0px;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid $white;
      svg {
        width: 100%;
        path {
          fill: $white;
        }
        circle {
          fill: $white;
        }
      }
    }
    span {
      font-size: 10px;
      white-space: nowrap;
      color: $error;
    }
    @media screen and (max-width: 1440px) {
      width: 100px;
      height: 100px;
    }
    @media screen and (max-width: 1366px) {
      width: 100px;
      height: 100px;
    }
    @media screen and (max-width: 1024px) {
      width: 90px;
      height: 90px;
    }
    @media screen and (max-width: 767px) {
      margin: 15px 0;
    }
  }
  .infoContainer {
    width: 100%;
    margin: 0 15px;
    .artistNameBox {
      font-size: 18px;
      color: $primaryColor;
      font-family: $circularBold;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
    .infoProfile {
      line-height: 1.5em;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
    }
    .readMoreText {
      text-decoration: underline;
      color: $primaryColor;
      letter-spacing: 0px;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.detailsContainer {
  width: 100%;
  padding: 0;
  background-color: $white;
  border-radius: 12px;
  box-shadow: 0px 3px 6px #0000000b;
  form {
    & > div {
      margin-bottom: 0 !important;
    }
    .borderBottom {
      border-bottom: 1px solid $light;
    }
  }
}

.title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  p {
    text-align: left;
  }
}

.title h1 {
  display: flex;
  align-items: center;
  font-family: $circularBold;
  padding: 7px 0 0 0;
  color: $black;
  letter-spacing: 1.44px;
  font-size: 36px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (max-width: 1366px) {
    font-size: 22px;
    letter-spacing: 0.76px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    font-size: 24px;
    padding: 30px 0 0;
  }
  @media screen and (max-width: 1440px) {
    font-size: 27px;
    letter-spacing: 1.08px;
  }
}

.formContainer {
  padding: 40px 40px;
}

.detailsBody {
  display: flex;
  justify-content: space-between;
  & > .formContainer {
    flex: 1 1 auto;
    & > div {
      position: relative;
      & > div {
        margin-bottom: 0 !important;
      }
    }
    .socialUrlForm {
      text-align: left;
      width: 100%;
      .socialTitle {
        position: relative;
        font-size: 11px;
        letter-spacing: 2.2px;
        margin-bottom: 20px;
        text-transform: uppercase;
        font-family: $circularBold;
        @media screen and (max-width: 1440px) {
          font-size: 11px;
          margin-bottom: 5px;
        }
        @media screen and (max-width: 1024px) {
          font-size: 11px;
        }
      }
      .socialUrlInputs {
        display: flex;
        .urlInputs {
          max-width: 500px;
          margin-bottom: 0;
          &:first-child {
            margin-right: 35px;
          }
        }
        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
      }
      .socialProfileInput {
        border-radius: 7px;
        border: 2px solid;
        font-size: 14px;
        font-family: $circularStd;
        height: 50px;
      }
      .btnContainer {
        display: flex;
        justify-content: right;
        @media screen and (max-width: 767px) {
          justify-content: center;
          position: fixed;
          bottom: 0;
          padding: 30px 20px;
          right: 0;
          left: 0;
          background-color: white;
          box-shadow: 0 0 8px #00000066;
          border-radius: 12px 12px 0 0;
        }
        .btn {
          text-transform: uppercase;
          font-family: $circularBold;
          background-color: $primaryColor;
          border-radius: 25px;
          color: $white;
          min-width: 200px;
          height: 50px;
          border: none;
        }
      }
      @media screen and (max-width: 767px) {
        max-width: unset;
      }
    }
    @media screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }

  & > .profileEdit {
    flex: 0.2 1 auto;
  }
  svg {
    color: $grey;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    & > .formContainer {
      margin-right: 0;
      margin-bottom: 80px;
    }
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  background-color: $darkGreyColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $white;
    color: $black;
    margin-right: 15px;
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "i";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

.subtitle {
  font-size: 18px;
  text-align: left;
  width: 100%;
  margin: 15px 0;
  @media screen and (max-width: 1366px) {
    font-size: 16px;
  }
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
}

.bioModal {
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  padding: 50px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  border-radius: 20px;
  &:focus {
    outline: none;
  }
  .header {
    text-align: left;
    font-family: $circularBold;
    color: $primaryColor;
    letter-spacing: 0px;
    font-size: 18px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }
  .bioDetails {
    font-family: $circularStd;
    font-size: 14px;
    margin: 20px 0;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .textarea {
    height: 200px;
    font-family: $circularStd;
    font-size: 14px;
    color: $black;
    padding: 15px;
    border: 1.5px solid;
    border-radius: 7px;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .modalBtnContainer {
    display: flex;
    justify-content: right;
    .modalBtn {
      text-transform: uppercase;
      font-family: $circularBold;
      background-color: $primaryColor;
      border-radius: 25px;
      color: $white;
      min-width: 125px;
      height: 50px;
      border: none;
      &:disabled {
        background-color: $grey;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    a {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      margin-top: 40px;
    }
  }
}

.formMainButtons {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  right: 0;
  padding: 15px 0;
  background: $white;
  border-top: 1px solid $darkGreyColor;

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    button {
      font-weight: bold;
      width: 280px;
      max-width: 280px;
      border-radius: 2px;
      height: 50px;
      background-color: transparent;
      text-align: center;
      margin: 20px 5px;
      color: $ctaColor;
      &.secondary {
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &.primary {
        border: 1px solid $ctaColor;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 1440px) {
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    padding: 10px 0;
  }
}

.imageModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: fit-content;
  padding: 50px;
  background-color: $white;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 20px;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  h3 {
    text-align: center;
    font-family: $circular;
    letter-spacing: 0.76px;
  }
  .modalBtnContainer {
    display: flex;
    justify-content: right;
    margin-top: 50px;
    .modalBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 200px;
      text-transform: uppercase;
      font-family: $circularBold;
      background-color: $primaryColor;
      border-radius: 25px;
      color: $white;
      letter-spacing: 0.6px;
      font-size: 15px;
      height: 50px;
      border: none;
      &.cancelBtn {
        background-color: $grey;
      }
      @media screen and (max-width: 767px) {
        min-width: 90px;
        margin: 15px;
      }
    }
    & > button:first-child {
      margin-right: 25px;
      @media screen and (max-width: 767px) {
        margin: 15px;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    button {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      margin-top: 40px;
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}
