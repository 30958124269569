@import "../../../styles/variables.scss";
.container {
  &.containerStyle {
    background-color: $white;
    overflow: auto;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.emailPreferenceContainer {
  background-color: $white;
  height: 100%;
  overflow: auto;
}

.pageContent {
  text-align: center;
  max-width: 692px;
  padding: 30px;
  height: 100vh;
  &.containerPageContent {
    max-width: unset;
  }
  .header {
    padding-top: 32px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: $primaryColor;
    &.containerHeader {
      align-items: center;
    }
    h2 {
      font-size: 30px;
      letter-spacing: 1.2px;
      font-family: $circularBold;
      @media screen and (max-width: 767px) {
        display: none;
        &.visible {
          display: block;
        }
      }
      span {
        color: $sidebarPrimaryBackgroundColor;
      }
    }
    p {
      font-size: 16px;
      letter-spacing: 0.72px;
      font-family: $circular;
      color: $black;
      margin-bottom: 0;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 0px;
      flex-wrap: wrap;
    }
  }
  .logoContainer {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    img {
      width: auto;
      max-height: 60px;
      object-fit: contain;
      flex-shrink: 0;
      height: 30px;
    }

    .closeIcon {
      justify-content: flex-end;
      display: none;
      span {
        font-size: 16px;
        font-family: $circularBold;
        color: $primaryColor;

        @media screen and (max-width: 767px) {
          padding-right: 10px;
        }
      }
      @media screen and (max-width: 767px) {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  .title {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: $primaryColor;
    h2 {
      margin: 0;
      font-size: 25px;
      letter-spacing: -1px;
      font-weight: 900;
      @media screen and (max-width: 767px) {
        letter-spacing: 0px;
        text-align: left;
      }
      span {
        color: $sidebarPrimaryBackgroundColor;
      }
    }

    @media screen and (max-width: 767px) {
      display: flex;
      padding: 0px;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: unset;
    padding: 30px;
  }
}

.preferenceContainer {
  display: flex;
  flex-direction: column;
  &.emailContainer {
    max-width: 600px;
    margin: auto;
  }
  table {
    text-align: left;
    margin-bottom: 20px;
    .primary {
      display: flex;
      color: $success;
      font-size: 16px;
      font-family: $circularBold;
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }

    td,
    th {
      padding-top: 34px;
      font-family: $circular;

      @media screen and (max-width: 767px) {
        font-size: 14px;
        padding-top: 20px;
      }
    }
    th {
      font-weight: 500;
      text-transform: uppercase;
      color: rgba($color: $black, $alpha: 0.7);
    }
    h4 {
      font-size: 16px;
      margin: 5px 0;
      font-family: $circularBold;
      color: $black;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }
    }
    .subText {
      font-size: 16px;
      font-family: $circular;
      max-width: 328px;

      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }
    }
  }
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 160px;
    &.preferenceButtonContainer {
      padding-top: 70px;
    }

    button {
      background-color: $ctaColor;
      border-radius: 25px;
      color: $fundingCtaFontColor;
      border: none;
      padding: 15px;
      margin-bottom: 15px;
      min-width: 250px;
      font-family: $circularBold;
      &:disabled {
        background-color: rgba($color: $ctaColorRgb, $alpha: 0.4);
        cursor: not-allowed;
      }
      &.primary {
        background-color: transparent;
        color: $ctaColor;
      }
      @media screen and (max-width: 767px) {
        min-width: 200px;
      }
    }
    @media screen and (max-width: 767px) {
      padding-top: 50px;
      align-items: center;
    }
  }
  .subtext {
    display: flex;
    align-items: center;
    justify-content: space-between;
    :global(.react-switch-label) {
      margin: 0px;
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    justify-content: unset;
    align-items: unset;
  }
  @media screen and (max-height: 700px) {
    align-items: unset;
  }
}

.imageModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: fit-content;
  padding: 50px;
  border-radius: 20px;
  background-color: $white;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  button {
    padding: 10px 30px;
    min-width: 200px;
    font-family: $circularBold;
    color: $fundingCtaFontColor;
    background-color: $ctaColor;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    font-size: 15px;
    &.primary {
      border: none;
      border-radius: 25px;
      &:disabled {
        border: 1px solid $grey;
        color: $grey;
        cursor: not-allowed;
      }
    }
    &.secondary {
      border: none;
    }
  }
  h3 {
    text-align: center;
    letter-spacing: 0.76px;
    font-family: $circularBold;
    font-size: 32px;
    color: $sidebarPrimaryBackgroundColor;
    .primaryName {
      text-transform: capitalize;
      color: $primaryColor;
      white-space: nowrap;
    }
    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }
  p {
    margin: 30px 0;
    text-align: center;
  }
  .modalBtnContainer {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    button {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      margin-top: 40px;
    }
  }
}
