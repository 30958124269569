@import "../../styles/variables.scss";

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.95
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
}

.detailsPopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 516px;
  width: 90%;
  outline: none;
  padding: 20px;
  background-color: $white;
  margin: auto;
  position: relative;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.2);
  border-radius: 12px;
  gap: 20px;
  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    line-height: 18px;
    padding: 10px;
    cursor: pointer;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      svg {
        width: 70px;
        height: 70px;
        path {
          stroke: $darkYellow;
          fill: transparent;
        }
      }
    }
    p {
      font-size: 22px;
      font-family: $circularSemiBold;
      color: $primaryColor;
      margin-bottom: 0px;
      text-align: center;
    }
    @media screen and (max-width: 479px) {
      flex-direction: column;
    }
  }
  span {
    font-size: 16px;
    font-family: $circular;
    color: $primaryColor;
    text-align: center;
  }
  p {
    font-size: 28px;
    font-family: $circular;
    color: $black;
    text-align: center;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    button {
      padding: 15px 25px;
      color: $white;
      background-color: $primaryColor;
      border-radius: 25px;
      font-size: 12px;
      letter-spacing: 2.1px;
      text-transform: uppercase;
      font-family: $circularBold;
      border: none;
      white-space: nowrap;
      width: 100%;
      max-width: 200px;
      @media screen and (max-width: 479px) {
        padding: 10px;
        white-space: nowrap;
      }
    }
    .secondary {
      background-color: transparent;
      color: $primaryColor;
      border: 1px solid $primaryColor;
    }
    @media screen and (max-width: 479px) {
      flex-direction: column;
      gap: 10px;
    }
  }
}
