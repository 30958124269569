@import "../../../styles//variables.scss";

.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 111px);
  overflow-y: auto;
  text-align: center;
  width: 100%;
  background-color: $light;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  max-width: 100%;

  .subContainer {
    text-align: center;
    width: 100%;
    padding: 50px 40px;
    align-items: flex-start;
    height: calc(100vh - 111px);
    overflow-y: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      h1 {
        color: $primaryColor;
        font-weight: 600;
        font-size: 54px;
        line-height: 81px;
        letter-spacing: -3.5px;
        font-family: $circularStd;
        white-space: nowrap;
        @media screen and (max-width: 767px) {
          white-space: unset;
          font-size: 48px;
        }
        @media screen and (max-width: 479px) {
          white-space: unset;
          font-size: 40px;
        }
      }
    }
    .mainContainer {
      background-color: $white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0px 0px 3px $darkGreyColor;
    }

    .typeContainer {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 25px;
      > div {
        display: flex;
        flex-direction: column;
        span {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
        }
      }
      h1 {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        font-family: $circularStd;
        letter-spacing: unset;
      }
      .radioField {
        > div {
          display: flex;
          gap: 20px;
        }
      }
      .categoryField {
        width: 100%;
        max-width: 400px;
      }
      .error {
        color: $red;
      }
    }
    .kcContainer {
      display: flex;
      flex-direction: column;
      .titleDiv {
        display: flex;
        justify-content: space-between;
        h1 {
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          font-family: $circularStd;
          letter-spacing: unset;
        }
        .toggleDiv {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
          .leftToggle {
            display: flex;
            flex-direction: column;
            gap: 5px;
            p {
              margin: 0;
              font-weight: 700;
              font-size: 14px;
              line-height: 20px;
              font-family: $circularStd;
            }
            span {
              color: #999999;
              font-size: 12px;
            }
          }
          label {
            margin: 0;
          }
        }
        @media screen and (max-width: 676px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
    .articleContainer {
      display: flex;
      gap: 20px;
      .leftContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 2 1;
        h1 {
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          font-family: $circularStd;
          letter-spacing: unset;
        }
        .dateContainer {
          width: 100%;
          max-width: 300px;
          margin-top: 15px;
        }
      }
      .rightContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1;
      }
      @media screen and (max-width: 767px) {
        flex-direction: column;
        .rightContainer {
          align-items: center;
        }
      }
    }

    .customFileUpload {
      > p {
        font-weight: normal !important;
        font-family: $circular !important;
        font-size: 20px !important;
        text-align: center;
      }
      position: relative;
      cursor: pointer;
      margin-right: 16px;
      min-width: 200px;
      width: 200px;
      max-width: 200px;
      overflow: hidden;
      input[type="file"] {
        position: absolute;
        opacity: 0;
        width: fit-content;
        max-width: 100%;
        z-index: 1;
        height: 100%;
        cursor: pointer;
        &::-webkit-file-upload-button {
          cursor: pointer;
        }
      }
      button {
        position: relative;
        width: 100%;
        background-color: $white;
        border: 1px dotted $grey;
        color: $grey;
        font-size: 20px;
        font-weight: 500;
        div {
          height: 100%;
          overflow: hidden;
        }
        p {
          span {
            color: $grey;
          }
          &:first-child {
            font-size: 20px !important;
            text-align: center;
          }
          font-weight: normal !important;
          font-family: $circular !important;
          font-size: 12px !important;
          text-align: left;
          @media screen and (max-width: 1126px) {
            &:first-child {
              font-size: 15px !important;
              text-align: center;
              margin-bottom: 1rem;
            }
            font-size: 12px !important;
          }
          @media screen and (max-width: 345px) {
            &:first-child {
              font-size: 12px !important;
              text-align: center;
              margin-bottom: 1rem;
            }
            font-size: 10px !important;
          }
          @media screen and (min-width: 1300px) {
            &:first-child {
              font-size: 15px !important;
              text-align: center;
              margin-bottom: 1rem;
            }
            font-size: 12px !important;
          }
        }
      }
      img {
        position: relative;
        height: auto;
        width: 100%;
        background-color: #e6e6e6;
        border: 1px solid $grey;
        max-width: 250px;
      }

      @media screen and (max-width: 1126px) {
        margin-top: 20px;
        position: relative;
        margin-right: 0;
        padding: 0;
        transform: unset;
        width: 100%;
        max-width: 100%;
        button {
          height: 180px;
          width: 100%;
        }
      }
      @media screen and (max-width: 767px) {
        max-width: 200px;
        margin: auto;
      }
    }

    @media screen and (max-width: 1366px) {
      height: calc(100vh - 66px);
    }
    @media screen and (max-width: 767px) {
      height: unset;
      padding: 10px;
      min-width: 100%;
    }
    @media screen and (max-width: 479px) {
      padding: 10px;
      margin: 0px;
      min-width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    height: 100vh;
  }
  .fieldContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    & > div {
      margin-right: 20px;
    }
    .maxWidth {
      max-width: 450px;
    }
  }
}
