@import "../../../../styles/variables.scss";

.whiteLabelProfile {
  width: 50px;
  height: 50px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
  }
}

.whiteLabelTitle {
  display: flex;
  justify-content: space-between !important;
  align-items: flex-end;
  margin-bottom: 20px;
  @media (max-width: 1101px) {
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
  }
}
.searchContainer {
  width: 80% !important;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  form {
    width: 60%;
  }
  .formMargin0 {
    padding: 0 !important;
    & > div {
      padding: 0;
    }
  }
  button {
    padding: 8px;
    color: $grey;
    width: 20%;
    border-radius: 2px;
    border: 1px solid $black;
    font-weight: 600;
    font-size: 18px;
    background-color: transparent;
    height: 50px;
  }
  input {
    padding: 10px;
    border: 1px solid $black;
    color: $black;
    border-radius: 2px;
    outline: none;
    width: 100%;
    height: 50px;
    font-weight: 300;
    &::placeholder {
      color: $grey;
      font-weight: 600;
    }
  }
  @media (max-width: 1133px) {
    flex-direction: column;
    justify-content: center !important;
    form {
      width: 100%;
    }
    button {
      width: 100%;
    }
    input::placeholder {
      text-align: center;
    }
  }
}
.whiteLabelPagination {
  margin-bottom: 30px !important;
}

.whiteLabelTable {
  width: 80% !important;
  align-items: center;
  th {
    font-size: 20px;
    color: $grey;
    font-family: $circularBold;
  }
  td {
    font-size: 20px;
    font-family: $circular;
    vertical-align: middle !important;
  }
}

.dialogSlugName {
  color: $primaryColor;
}
.slugName {
  cursor: pointer;
}
.disabledSlugName {
  color: $grey;
  cursor: default;
}

// create white label account

.contentContainer {
  form > div {
    margin-bottom: 20px;
    p {
      text-align: left;
    }
  }
  label {
    span {
      color: $red;
    }
  }
  .nameAndLogoContainer {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
    p {
      font-size: 20px;
      font-family: $circularBold;
    }
    .subContainer {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0px 45px 0;
        @media screen and (max-width: 1126px) {
          padding: 20px;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: inherit;
        }
      }
      .customFileUpload {
        > p {
          font-weight: normal !important;
          font-family: $circular !important;
          font-size: 20px !important;
          text-align: center;
        }
        position: relative;
        cursor: pointer;
        margin-right: 16px;
        min-width: 200px;
        width: 200px;
        max-width: 200px;
        overflow: hidden;
        input[type="file"] {
          position: absolute;
          opacity: 0;
          width: fit-content;
          max-width: 100%;
          z-index: 1;
          height: 100%;
          cursor: pointer;
          &::-webkit-file-upload-button {
            cursor: pointer;
          }
        }
        button {
          position: relative;
          width: 100%;
          background-color: $white;
          border: 1px dotted $grey;
          color: $grey;
          font-size: 20px;
          font-weight: 500;
          div {
            height: 100%;
            overflow: hidden;
          }
          p {
            span {
              color: $grey;
            }
            &:first-child {
              font-size: 20px !important;
              text-align: center;
            }
            font-weight: normal !important;
            font-family: $circular !important;
            font-size: 12px !important;
            text-align: left;
            @media screen and (max-width: 1126px) {
              &:first-child {
                font-size: 15px !important;
                text-align: center;
                margin-bottom: 1rem;
              }
              font-size: 12px !important;
            }
            @media screen and (max-width: 345px) {
              &:first-child {
                font-size: 12px !important;
                text-align: center;
                margin-bottom: 1rem;
              }
              font-size: 10px !important;
            }
            @media screen and (min-width: 1300px) {
              &:first-child {
                font-size: 15px !important;
                text-align: center;
                margin-bottom: 1rem;
              }
              font-size: 12px !important;
            }
          }
        }
        img {
          position: relative;
          height: auto;
          width: 100%;
          background-color: #e6e6e6;
          border: 1px solid $grey;
          max-width: 250px;
        }
        @media screen and (max-width: 1126px) {
          margin-top: 20px;
          position: relative;
          margin-right: 0;
          padding: 0;
          transform: unset;
          width: 100%;
          max-width: 100%;
          button {
            height: 180px;
            width: 100%;
          }
        }
      }
      @media screen and (max-width: 1126px) {
        flex-direction: column;
      }
    }
    @media screen and (max-width: 767px) {
      margin: 0 20px 20px 20px;
    }
  }
  .thankYouCTAContainer {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
    p {
      font-family: $circularBold;
      font-size: 20px;
    }
    .subContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @media screen and (max-width: 767px) {
      margin: 0 20px 20px 20px;
    }
  }

  .wpDomainURL {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
    p {
      font-family: $circularBold;
      font-size: 20px;
    }
    .subContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .customInstructionUpload {
      position: relative;
      cursor: pointer;
      margin-right: 16px;
      min-width: 150px;
      width: 200px;
      max-width: 200px;
      overflow: hidden;
      input[type="file"] {
        position: absolute;
        opacity: 0;
        width: 100%;
        z-index: 1;
        height: 100%;
        cursor: pointer;
        &::-webkit-file-upload-button {
          cursor: pointer;
        }
      }
      button {
        border: 1px solid $primaryColor;
        color: $primaryColor;
        padding: 15px 10px;
        margin-top: 0;
        @media screen and (max-width: 767px) {
          padding: 10px 20px;
          font-size: 16px;
        }
        @media screen and (max-width: 767px) {
          width: auto;
        }
      }
    }
    @media screen and (max-width: 767px) {
      margin: 0 20px 20px 20px;
    }
  }

  .serviceContainer {
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
    .title {
      font-family: $circularBold;
      font-size: 20px;
    }
    .serviceDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
      max-width: 350px;
      p {
        font-family: $circularSemiBold;
        font-size: 14px;
        margin: 0;
      }
      :global(.react-switch-label) {
        margin: 20px;
        scale: 0.8;
      }
    }
    @media screen and (max-width: 767px) {
      margin: 0 20px 20px 20px;
    }
  }

  .mainExclusivityContainer{
    display: flex;
    flex-direction: column;
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
  }

  // distributor switch
  .isDistributor {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
    .isDistributorContainer {
      display: flex;
      align-items: center !important;
      flex-wrap: wrap;
      p {
        font-family: $circularBold;
        font-size: 20px;
        margin-bottom: 0;
        &:nth-child(2) {
          font-size: 12px;
          color: $grey;
        }
      }
      .subContainer {
        margin-left: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      label {
        margin: 5px 0px 0px 0px !important;
      }
    }
    @media screen and (max-width: 767px) {
      margin: 0 20px 20px 20px;
    }
  }

  // report additional fields
  .reportAdditionalFields {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
    p {
      font-family: $circularBold;
      font-size: 20px;
      margin-bottom: 0;
      &:nth-child(2) {
        font-size: 12px;
        color: $grey;
        margin-bottom: 5px;
      }
    }
    .subContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @media screen and (max-width: 767px) {
      margin: 0 20px 20px 20px;
    }
  }

  .finePrintText {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
    p {
      font-family: $circularBold;
      font-size: 20px;
    }
    .subContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @media screen and (max-width: 767px) {
      margin: 0 20px 20px 20px;
    }
  }

  .sidebarText {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
    p {
      font-family: $circularBold;
      font-size: 20px;
    }
    .subContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    @media screen and (max-width: 767px) {
      margin: 0 20px 20px 20px;
    }
  }
  .colorsContainer {
    background-color: $white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 3px $darkGreyColor;
    font-family: $circular;
    > p {
      font-family: $circularBold !important;
      font-size: 20px;
    }
    .subContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > section {
        border-radius: 8px;
        margin: 10px;
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        box-shadow: 0px 0px 3px $darkGreyColor;
        flex-direction: column;
        > p {
          color: $grey;
          font-family: $circularBold;
          padding-bottom: 5px;
          font-size: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid $grey;
          &.colorSection {
            display: flex;
            justify-content: space-between;
            button {
              border: 0;
              background-color: transparent;
              color: $primaryColor;
              display: flex;
              align-items: center;
              gap: 10px;
              span {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
                width: 20px;
                color: $primaryColor;
                border: 2px solid $primaryColor;
                border-radius: 50%;
              }
            }
          }
        }
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }
      }
      .colorField {
        > p {
          color: $primaryColor;
        }
        > div {
          width: 100%;
          max-width: 300px;
        }
        display: flex;
        justify-content: space-between;
      }
      .colorFieldsContainer {
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 0px 3px $darkGreyColor;
        margin-top: 30px;
        > p {
          color: $grey;
          font-family: $circularBold;
          padding-bottom: 5px;
          font-size: 20px;
          border-bottom: 1px solid $black;
          margin-bottom: 20px;
        }
        .colorFields {
          > p {
            color: $primaryColor;
          }
          > div {
            width: 100%;
            max-width: 300px;
          }
          display: flex;
          justify-content: space-between;
        }
      }
    }
    @media screen and (max-width: 767px) {
      margin: 0 20px 20px 20px;
    }
  }
}
.saveButton {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  flex-wrap: wrap;
  button {
    padding: 20px;
    margin-bottom: 30px;
    color: $black;
    border-radius: 2px;
    border: none;
    font-weight: bold;
    font-size: 18px;
    background-color: transparent;
    &:not(:nth-child(1)) {
      border: 1px solid $black;
    }
  }
}

.formGroup {
  position: relative;
  margin-bottom: 0;
  &.padding {
    :global(.mce-content-body) {
      p {
        background-color: red;
      }
    }
  }
  .pdfLink {
    color: $primaryColor;
    text-decoration: underline;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 10px;
    &:hover {
      color: $primaryColor;
    }
  }
  &.switchFormGroup {
    margin-bottom: 20px;
    display: flex;
    flex: 0;
    align-items: center;
    color: $black;
    font-size: 14px;
    & > * {
      flex-shrink: 0;
    }
    input {
      display: none;
    }
    .requiredSwitch {
      margin: 0 0 0 15px;
    }
  }
}
.disabledSubmitButton {
  border: 1px solid $grey !important;
  color: $grey !important;
  cursor: not-allowed;
}

.errorText {
  font-weight: normal !important;
  font-family: $circular !important;
  font-size: 14px !important;
  color: $red;
}

.slugInstruction {
  p {
    font-weight: normal !important;
    font-family: $circular !important;
    font-size: 14px !important;
    color: $grey;
    margin-bottom: 0 !important;
  }
}

.urlPlaceholder {
  @media screen and (max-width: 1126px) {
    input::placeholder {
      font-size: 10px;
    }
  }
}

// whitelisted URLS
.arrayContainer {
  // array fields
  .arraySubFieldsContainer {
    display: flex;
  }

  // array plus minus button
  button {
    border: none !important;
    width: 2px;
    padding: 0px;
    display: flex;
    align-items: center;
    svg {
      color: $primaryColor;
    }
  }

  .addURLButton {
    // array plus button
    display: flex;
    margin-top: 20px;
    width: 160px;
    justify-content: flex-start;
    align-items: center;
    span {
      font-size: 20px !important;
      font-family: $circularBold !important;
      margin-left: 2px;
    }
  }
}

.whiteListedURLHeader {
  margin-top: 10px;
}

.removeMargin {
  margin-top: -1.5rem !important;
}

.additionalFieldErrorMessage {
  margin-top: 40px;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: left;
}

.dropDownStyle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  overflow: hidden;
  .colorContainer {
    height: 24px;
    width: 24px;
    border: 1px solid $grey;
  }
  .main {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: $grey;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.newColorSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .colorDiv {
    width: 38px;
    height: 38px;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    padding: unset;
    margin: unset;
    color: $primaryColor;
    border: 2px solid $primaryColor;
    border-radius: 50%;
  }
}
