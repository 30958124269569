@import "../../../styles/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 111px);
  overflow-y: auto;
  text-align: center;
  width: 100%;
  background-color: $light;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  max-width: 100%;

  a,
  .artistDetails {
    color: $primaryColor;
    &:hover {
      color: rgba($color: $primaryColor, $alpha: 0.6);
      .subText {
        color: rgba($color: $primaryColor, $alpha: 0.6);
      }
    }
  }
  .subContainer {
    text-align: center;
    width: 100%;
    padding: 50px 40px;
    align-items: flex-start;
    height: calc(100vh - 111px);
    overflow-y: auto;
    @media screen and (max-width: 1440px) {
      height: calc(100vh - 84px);
    }
    @media screen and (max-width: 1366px) {
      height: calc(100vh - 66px);
    }
    @media screen and (max-width: 767px) {
      height: unset;
      padding: 10px;
      min-width: 100%;
    }
    @media screen and (max-width: 479px) {
      padding: 10px;
      margin: 0px;
      min-width: 100%;
    }
  }
  @media screen and (max-width: 1440px) {
    height: calc(100vh - 84px);
  }
  @media screen and (max-width: 1366px) {
    height: calc(100vh - 66px);
  }
  @media (max-width: 767px) {
    max-width: 100%;
    height: unset;
    min-height: calc(100vh - 66px);
    margin-top: 0;
  }
  @media screen and (max-width: 479px) {
    height: 100%;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  flex-wrap: wrap;
  h1 {
    color: $primaryColor;
    font-weight: 600;
    font-size: 54px;
    line-height: 69px;
    letter-spacing: -3.5px;
    font-family: $circular;
    white-space: nowrap;
    margin-right: 30px;
    @media screen and (max-width: 767px) {
      white-space: unset;
      font-size: 48px;
    }
    @media screen and (max-width: 479px) {
      white-space: unset;
      font-size: 40px;
    }
  }
  h2 {
    display: none;
    color: $white;
    padding: 18px;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: -1.1px;
  }
  h4 {
    position: absolute;
    left: 0;
    cursor: pointer;
    padding-right: 10px;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    @media screen and (max-width: 767px) {
      top: 10px;
    }
  }
  .liveStatus {
    display: flex;
    align-items: center;
    margin-right: 30px;
    font-weight: 700;
    font-size: 28px;
    text-transform: uppercase;
    color: $successColor;
    &::before {
      content: " ";
      display: block;
      margin-right: 10px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: $successColor;
      @media screen and (max-width: 767px) {
        height: 15px;
        width: 15px;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
  .liveBtn {
    padding: 10px 30px;
    background-color: transparent;
    border: 1px solid $black;
  }

  @media screen and (max-width: 767px) {
    background-color: $dark;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    h1 {
      display: none;
    }
    h2 {
      display: block;
      flex-shrink: 0;
      margin-bottom: 0;
    }
    &.innerTitle {
      background-color: $light;
      justify-content: center;
      h1 {
        display: block;
        font-size: 36px;
      }
    }
  }
}

.formContainer {
  margin-top: 20px;
  .card {
    padding: 20px;
  }
  .cardTitle {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    label {
      margin: 0;
    }
  }
  .btnContainer {
    margin: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      padding: 15px 30px;
      background-color: transparent;
      border: 1px solid $black;
      color: $black;
      font-weight: 700;
      &:first-child {
        margin-right: 20px;
        border: none;
        &:hover {
          text-decoration: underline;
        }
        @media screen and (max-width: 411px) {
          margin-right: 0;
        }
      }
    }
    @media screen and (max-width: 411px) {
      flex-direction: column;
    }
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: $white, $alpha: 0.7);
  overflow: auto;
  z-index: 1;
  backdrop-filter: blur(4px);
}

.deleteModal {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: fit-content;
  padding: 50px;
  background-color: $white;
  margin: auto;
  align-items: center;
  border: none;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  button {
    padding: 18px 30px;
    min-width: 200px;
    font-family: $circularBold;
    color: $ctaColor;
    background-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-size: 15px;
    &.primary {
      border: 1px solid $ctaColor;
    }
    &.secondary {
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  h3 {
    text-align: center;
    font-family: $circular;
    letter-spacing: 0.76px;
  }
  .modalBtnContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    & > button:first-child {
      margin-right: 25px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    width: 90%;
    button {
      padding: 18px 20px;
      min-width: 90px;
    }
    .modalBtnContainer {
      margin-top: 40px;
    }
  }
}
