@import "../../../styles/variables.scss";

.pageContainer {
  display: flex;
  flex: 1;
  width: 100%;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: none;
  }
}

.container {
  max-width: 1366px;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 0px;
  display: flex;
  flex-direction: column;

  &.fastFlowContainer {
    display: flex;
    flex-direction: row;
    gap: 25px;
    @media screen and (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.subtitle {
  font-size: 16px;
  text-align: left;
  width: 100%;
  margin: 15px 0;
  max-width: 600px;
  &.fastSubTitle {
    max-width: unset;
  }
  @media screen and (max-width: 1440px) {
    font-size: 14px;
    letter-spacing: 0.56px;
  }
}

.mainContainer {
  background: $veryLight;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  margin-top: 0px;
  position: relative;
  .scrollContainer {
    height: calc(100vh - 100px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    .pageHeadContainer {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      max-width: 1336px;
      .titleContainer {
        margin-right: 10px;
        @media screen and (max-width: 767px) {
          margin-right: 0;
        }
      }
      .rightInfoText {
        display: flex;
        align-items: center;
        padding: 10px;
        font-size: 14px;
        border-radius: 13px;
        background-color: rgba($color: $helpTextColorRgb, $alpha: 0.1);
        color: $helpTextColor;
        min-width: 350px;
        max-width: 450px;
        svg {
          width: 15px;
          flex-shrink: 0;
          margin-right: 10px;
          path {
            fill: $helpTextColor;
          }
        }
        @media screen and (max-width: 1024px) {
          max-width: 250px;
          min-width: 250px;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      @media screen and (max-width: 767px) {
        background-color: $white;
        border-radius: 13px;
        padding: 20px;
        box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.1);
        margin-bottom: 20px;
      }
    }
    .explainerContainer {
      max-width: 1366px;
      display: flex;
      flex-direction: column;
      width: 100%;
      color: $black;
      font-size: 14px;
      margin: 0 auto;
      p {
        margin-bottom: 0;
      }
      .header {
        font-family: $circularBold;
        color: $primaryColor;
      }
      .content {
        font-family: $circularStd;
        font-size: 18px;
        @media screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
    .soundExchangeDiv {
      max-width: 1366px;
      width: 100%;
      color: $black;
      margin: 20px 0 auto;
      padding: 10px 15px;
      background-color: rgba($color: $grey, $alpha: 0.1);
      border-radius: 10px;
      font-size: 12px;
    }
    .mogulReportDlGuideLink {
      cursor: pointer;
      text-decoration: underline;
      color: $helpTextColor;
    }
    @media screen and (max-width: 1440px) {
      height: calc(100vh - 100px);
    }
    @media screen and (max-width: 1366px) {
      height: calc(100vh - 100px);
    }
    @media screen and (max-width: 767px) {
      padding: 20px;
      height: unset;
    }
  }
  @media screen and (max-width: 767px) {
    min-height: calc(100vh - 133px);
    padding: 0px 10px 10px 10px;
    max-height: unset;
  }
  @media screen and (max-width: 529px) {
    padding: 0;
    min-height: calc(100vh - 133px);
  }
}

.title {
  display: flex;
  align-items: center;
  text-align: center;
  @media screen and (max-width: 767px) {
    margin-bottom: 4px;
  }
}

.title h1 {
  display: flex;
  align-items: center;
  font-family: $circularBold;
  padding: 7px 0 0 0;
  color: $black;
  letter-spacing: 0;
  font-size: 36px;
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  @media screen and (max-width: 1440px) {
    font-size: 32px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    font-size: 18px;
    font-family: $circularSemiBold;
    padding: 0;
  }
}

.infoIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  font-style: normal;
  height: 20px;
  width: 20px;
  border: none;
  color: $tooltipFontColor;
  border-radius: 50%;
  cursor: pointer;
  background-color: $tooltipBackgroundColor;
  margin-left: 15px;
  &.infoWhite {
    background-color: $white;
    color: $black;
    margin-right: 15px;
  }
  @media screen and (max-width: 1366px) {
    height: 17px;
    width: 17px;
  }
}

.infoIcon::after {
  font-size: 12px;
  text-transform: lowercase;
  content: "?";
  letter-spacing: 0px;
  line-height: 12px;
  position: relative;
  top: 1px;
  font-family: $circularBold;
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
}

.tooltip {
  width: 30%;
  pointer-events: auto !important;
  @media screen and (max-width: 767px) {
    width: 50%;
  }
  a {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
}

.sendReportsContainer {
  .fastRightInfoText {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
    border-radius: 15px;
    background-color: rgba($color: $grey, $alpha: 0.1);
    color: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.6);
    &.deskInfoText {
      @media screen and (max-width: 1280px) {
        display: none;
      }
    }
    &.mobInfoText {
      width: 100%;
      @media screen and (min-width: 1281px) {
        display: none;
      }
    }
    svg {
      width: 15px;
      flex-shrink: 0;
      margin-right: 15px;
      path {
        fill: rgba($color: $sidebarPrimaryBackgroundColorRgb, $alpha: 0.6);
      }
    }
    span {
      text-align: left;
    }
    @media only screen and (max-width: 1280px) {
      padding: 20px;
    }
  }
  .dataReviewSubHeading {
    width: 80%;
    max-width: 600px;
    p {
      font-weight: bold;
      letter-spacing: 1px;
      text-align: center;
      padding: 10px 0px 20px 0px;
      font-size: 18px;
    }
  }
  .quickLinksContainer {
    width: 100%;
    max-width: 700px;

    p {
      margin-top: 20px;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
      font-family: $circularBold;
      @media screen and (max-width: 1366px) {
        font-size: 16px;
      }
    }
    .selectContainer {
      margin-top: 10px;
      justify-content: center;
      display: flex;
      & > div {
        width: 100%;
      }
      button {
        color: $ctaColor;
        background-color: transparent;
        border: 1px solid $ctaColor;
        margin-left: 10px;
        padding: 0 20px;
        border-radius: 2px;
      }
      :global {
        div::-webkit-scrollbar {
          width: 6px;
          height: 0px;
        }
        div::-webkit-scrollbar-track {
          background: $white;
        }
        div::-webkit-scrollbar-thumb {
          background: rgba($color: $dark, $alpha: 0.2);
        }
        div::-webkit-scrollbar-thumb:hover {
          background: rgba($color: $dark, $alpha: 0.4);
        }
      }
    }
  }
  .sendReportsSubContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 13px;
    padding: 0;
    margin-top: 20px;
    flex-grow: 1;
    &.fastFlowRightContainer {
      width: 60%;
      @media screen and (max-width: 1280px) {
        margin-bottom: 0;
        width: 100%;
        margin-top: 0;
      }
    }
    .selectDistributorContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 25px 40px;
      background-color: $white;
      border-radius: 0 0 12px 12px;
      margin-bottom: auto;
      &.initial {
        align-items: center;
        min-height: calc(100vh - 600px);
        flex-grow: 1;
        border-radius: 12px;
        .subTitle {
          margin: 30px 0 15px;
          text-align: center;
          max-width: 300px;
        }
      }
      .subTitle {
        margin: 0;
        font-size: 18px;
        text-align: left;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      .reportInitialLogo {
        height: 65px;
        width: 68px;
        svg {
          height: 100%;
          :global(.reports-progress-fill) {
            fill: $progressAccentColor;
          }
          :global(.reports-primary-fill) {
            fill: $helpTextColor;
          }
        }
        @media screen and (max-width: 767px) {
          height: 50px;
        }
      }
      @media screen and (max-width: 767px) {
        background-color: $white;
        border-radius: 0 0 13px 13px;
        box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.1);
        padding: 20px;
      }
    }
    .reportListContainer {
      margin-top: 20px;
      width: 100%;
      max-width: 700px;
      margin-bottom: 200px;
      p {
        margin-top: 20px;
        font-size: 18px;
        text-align: left;
        font-family: $circularBold;
        @media screen and (max-width: 1366px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 14px;
          letter-spacing: 0.56px;
        }
      }
      .noFiles {
        color: $grey;
        text-align: center;
      }
      table {
        color: $primaryColor;
        width: 100%;
        th {
          text-decoration: underline;
          @media screen and (max-width: 1440px) {
            font-size: 15px;
            letter-spacing: 0.56px;
          }
        }
        td,
        th {
          padding: 5px;
        }
        td:first-child {
          text-align: left;
        }
        td:first-child,
        th:first-child {
          padding-left: 0;
        }
        td:last-child,
        th:last-child {
          text-align: right;
          font-weight: bold;
          padding-right: 0;
          span {
            cursor: pointer;
          }
        }
        td {
          @media screen and (max-width: 1440px) {
            font-size: 14px;
            letter-spacing: 0.56px;
          }
        }
        tr.deleted {
          color: $grey;
        }
      }
      @media screen and (max-width: 1024px) {
        margin-bottom: 160px;
      }
    }
    .uploadReportListing {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 25px 40px;
      border-bottom: 1px solid $light;
      background-color: $white;
      border-radius: 12px 12px 0 0;
      &.fastUploadReportListing {
        max-height: calc(100vh - 460px);
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 8px;
          height: 0px;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba($color: $grey, $alpha: 0.7);
          max-height: 100px;
          border-radius: 6px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: rgba($color: $grey, $alpha: 0.9);
        }
        @media screen and (max-width: 767px) {
          max-height: unset;
          margin-bottom: 0;
          border-radius: 13px 13px 0 0;
        }
      }
      .reportListTitle {
        text-align: left;
        font-size: 18px;
        &.fastReportListTitle {
          display: none;
        }
      }
      .distList {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }
      .reportContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        position: relative;
        .reportDistributor {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;
          margin-bottom: 10px;
          .imgContainer {
            margin-right: 30px;
            img {
              margin-right: 0;
            }
          }
          &.fastReportDistributor {
            width: 100%;
            & > * {
              width: 30%;
            }
            .actionContainer {
              justify-content: space-between;
              @media screen and (max-width: 767px) {
                position: absolute;
                width: 100%;
                transform: translateY(40px);
              }
              @media screen and (max-width: 480px) {
                transform: translateY(50px);
              }
              .addMore {
                margin-left: unset;
                @media screen and (max-width: 767px) {
                  transform: translateY(0);
                  position: relative;
                }
              }
            }
          }
          img {
            height: 40px;
            object-fit: contain;
            margin-right: 30px;
            @media screen and (max-width: 1366px) {
              height: 35px;
            }
            @media screen and (max-width: 767px) {
              height: 26px;
              margin-right: 10px;
            }
          }
          h2 {
            font-size: 22px;
            font-family: $circularBold;
            letter-spacing: -0.45px;
            margin: 0;
            margin-right: 30px;
            @media screen and (max-width: 1440px) {
              font-size: 20px;
            }
            @media screen and (max-width: 1366px) {
              font-size: 18px;
            }
            @media screen and (max-width: 767px) {
              margin-right: 10px;
            }
            @media screen and (max-width: 480px) {
              font-size: 16px;
              word-break: break-word;
            }
            @media screen and (max-width: 400px) {
              font-size: 14px;
            }
          }
          .reportListCount {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 14px;
            margin-bottom: 0;
            margin-right: 10px;
            color: $helpTextColor;
            &.fastReportList {
              margin-right: 10px;
              @media screen and (max-width: 767px) {
                margin-right: 0;
              }
              @media screen and (max-width: 480px) {
                max-width: 150px;
              }
              @media screen and (max-width: 400px) {
                max-width: 120px;
              }
            }
            span:nth-child(2) {
              margin-top: 2px;
            }
            @media screen and (max-width: 767px) {
              font-size: 12px;
              flex-shrink: 0;
              margin-right: unset;
              align-items: flex-end;
            }
          }
          .actionContainer {
            display: flex;
            align-items: center;
          }
          .addMore {
            display: flex;
            align-items: center;
            margin-left: auto;
            color: $primaryColor;
            font-size: 14px;
            cursor: pointer;
            .linkText {
              text-decoration: underline;
            }
            span.plus {
              display: flex;
              flex-shrink: 0;
              justify-content: center;
              align-items: center;
              width: 14px;
              height: 14px;
              font-size: 14px;
              line-height: 14px;
              background-color: $primaryColor;
              color: $white;
              border-radius: 7px;
              margin-right: 5px;
            }
            @media screen and (max-width: 1440px) {
              font-size: 13px;
            }
            @media screen and (max-width: 767px) {
              position: absolute;
              width: 100%;
              left: 0;
              bottom: 0;
              transform: translateY(20px);
              font-size: 12px;
            }
          }
          svg {
            display: flex;
            font-size: 14px;
            padding: 2px;
            width: 20px;
            height: 20px;
            margin-left: 15px;
            cursor: pointer;
            path {
              fill: $error;
            }
            @media screen and (max-width: 1440px) {
              font-size: 14px;
            }
          }
        }
        .reportList {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          font-size: 16px;
          .fileName {
            display: flex;
            align-items: center;
            background-color: $veryLight;
            padding: 2px 6px;
            border-radius: 2px;
            margin-right: 6px;
            margin-bottom: 4px;
            text-align: left;
          }
          .delete {
            font-size: 24px;
            line-height: 16px;
            margin-left: 10px;
            cursor: pointer;
            @media screen and (max-width: 767px) {
              font-size: 20px;
              line-height: 14px;
            }
          }

          @media screen and (max-width: 1440px) {
            font-size: 14px;
          }
          @media screen and (max-width: 767px) {
            font-size: 12px;
          }
        }
        @media screen and (max-width: 767px) {
          margin-bottom: 50px;
        }
      }
      @media screen and (max-width: 1280px) {
        padding: 25px;
      }
      @media screen and (max-width: 767px) {
        padding: 20px;
        box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.1);
        border-radius: 13px;
        background-color: $white;
        margin-bottom: 20px;
        border-bottom: none;
      }
    }
    .dropDownContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      max-width: 400px;
      margin-top: 20px;

      &.fastDropDownContainer {
        flex-direction: row;
      }
      .option {
        display: flex;
        align-items: center;
        column-gap: 10px;
        img {
          height: 20px;
          max-width: 35px;
          object-fit: contain;
          object-position: left;
        }
      }
      :global(.distributor__control--menu-is-open) {
        border-radius: 7px 7px 0 0;
        border-bottom: none;
      }
      :global(.distributor__menu) {
        position: relative;
        top: 0;
        animation: fadeInDistributorMenu 0.2s ease-in-out;
      }
      :global(.distributor__option) {
        font-size: 14px;
        padding: 6px 12px;
      }
      :global(.distributor__menu-list) {
        &::-webkit-scrollbar {
          width: 6px;
          height: 0px;
        }
        &::-webkit-scrollbar-track {
          background: $veryLight;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba($color: $grey, $alpha: 0.4);
          border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: rgba($color: $grey, $alpha: 0.3);
        }
      }
      @keyframes fadeInDistributorMenu {
        0% {
          max-height: 0;
          opacity: 0;
        }
        50% {
          opacity: 0.2;
        }
        100% {
          max-height: 200px;
          opacity: 1;
        }
      }
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    @media screen and (max-width: 767px) {
      background-color: transparent;
      box-shadow: none;
    }
    @media screen and (max-width: 411px) {
      margin-bottom: 130px;
    }
  }
}

.sentReportsBtns {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  z-index: 4;
  left: 0;
  padding: 10px 0;
  background: $white;
  border-top: 1px solid $darkGreyColor;
  @media screen and (max-width: 767px) {
    position: fixed;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    button {
      font-weight: bold;
      width: 280px;
      max-width: 280px;
      border-radius: 2%;
      border: 1px solid $ctaColor;
      height: 50px;
      text-align: center;
      background-color: transparent;
      margin: 10px 5px;
      color: $ctaColor;
      &.secondary {
        background-color: transparent;
        color: $ctaColor;
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.radioContainer {
  border-radius: 13px;
  padding: 25px 40px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px rgba($color: $black, $alpha: 0.1);
  p {
    text-align: left;
    font-family: $circularStd;
    font-size: 18px;
    font-weight: 500;
    @media screen and (max-width: 1440px) {
      font-size: 14px;
      letter-spacing: 0.56px;
      margin-bottom: 10px;
    }
  }
  label {
    &:global(.MuiFormControlLabel-root) {
      width: fit-content;
    }
    span {
      font-family: $circularStd !important;
      font-size: 18px;
      font-weight: 500;
      text-align: left;
      @media screen and (max-width: 1440px) {
        font-size: 14px;
      }
    }
  }
  .checkbox {
    color: $primaryColor !important;
    &:hover {
      background-color: transparent !important;
    }
  }
  .form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .error {
      width: 100%;
    }
    .containerClass {
      width: 100%;
      margin-top: -20px;
      text-align: left;
      padding: 0;
    }
    .formInfoText {
      width: 100%;
      text-align: left;
      padding: 0;
      color: $grey;
      font-size: 14px;
      margin-bottom: -5px;
      @media screen and (max-width: 1366px) {
        font-size: 13px;
      }
    }
    & > :global(.form-group) {
      margin-right: 60px;
      width: auto;
      margin-bottom: 0;
      @media screen and (max-width: 1024px) {
        margin-right: 30px;
      }
      @media screen and (max-width: 767px) {
        &.spacingBottom {
          margin-bottom: 10px;
        }
      }
    }
    & > :global(.form-group input) {
      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
      @media screen and (max-width: 365px) {
        font-size: 12px;
      }
    }

    & > :global(.form-group > div) {
      overflow: hidden;
      & > div {
        position: unset !important;
      }
    }
    & > :global(.form-group label.floating) {
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
      @media screen and (max-width: 430px) {
        font-size: 13px;
      }
      @media screen and (max-width: 370px) {
        font-size: 12px;
      }
      @media screen and (max-width: 350px) {
        font-size: 11px;
      }
    }
    @media screen and (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}
.checked {
  span {
    font-family: $circularBold !important;
  }
}
.unchecked {
  span {
    font-family: $circular !important;
  }
}

.mobileModalOverlay {
  display: none;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.9
  );
  overflow: auto;
  z-index: 6;
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.modalOverlay {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(
    $color: $sidebarPrimaryBackgroundColorRgb,
    $alpha: 0.9
  );
  overflow: auto;
  z-index: 4;
  backdrop-filter: blur(4px);
  @media screen and (max-width: 767px) {
    justify-content: unset;
    align-items: unset;
  }
}

.fileTypePopupContainer {
  display: flex;
  flex-direction: column;
  max-width: 516px;
  width: 90%;
  outline: none;
  padding: 20px;
  background-color: #fff;
  margin: auto;
  position: relative;
  border-radius: 12px;
  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
    line-height: 18px;
    padding: 10px;
    color: $black;
    cursor: pointer;
  }
  .fileFormatInfo {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    & > div {
      padding: 10px 10px 20px;
      flex-grow: 1;
    }
    &.publishingFileInfo {
      flex-direction: column;
      padding: 0;
      flex-grow: 1;
      & > div {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .acceptableInfo {
        border-right: unset;
        border-bottom: 1px solid $light;
      }
    }
    .acceptableInfo {
      border-bottom: 1px solid $light;
      h4 {
        color: $success;
      }
      ul {
        margin-bottom: 0;
        padding-left: 18px;
      }
      li::before {
        color: $success;
      }
      li::marker {
        color: $success;
      }
      @media screen and (max-width: 767px) {
        border-right: none;
        padding: 0 0 20px;
      }
    }
    .notAcceptableInfo {
      padding: 20px 10px 10px;
      h4 {
        color: $error;
      }
      ul {
        margin-bottom: 0;
        padding-left: 18px;
      }
      li::before {
        color: $error;
      }
      li::marker {
        color: $error;
      }
      @media screen and (max-width: 767px) {
        border-right: none;
        padding: 20px 0 0;
      }
    }
    h4 {
      font-size: 14px;
      font-family: $circularBold;
    }
  }
}

.mobileModal {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1366px;
  text-align: center;
  padding: 40px;
  background-color: $white;
  margin: 20px;
  border: none;
  border-radius: 20px;
  height: fit-content;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  h2 {
    font-size: 32px;
    font-family: $circularBold;
    letter-spacing: 0;
    margin-bottom: 10px;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    button {
      border: 1px solid $ctaColor;
      background-color: transparent;
      color: $ctaColor;
      padding: 12px 20px;
      border-radius: 25px;
      font-family: $circularBold;
      letter-spacing: 2.8px;
      text-transform: uppercase;
      margin-top: 20px;
      min-width: 190px;
      &.primary {
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
      }
    }
  }
}

.uploadModal {
  display: flex;
  flex-direction: column;
  max-width: 1366px;
  width: 90%;
  background-color: $white;
  margin: auto;
  border: none;
  height: fit-content;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  padding: 0;
  border-radius: 20px;
  @media screen and (max-width: 767px) {
    margin: 20px auto 60px;
    padding: 0;
    overflow: hidden;
    ::-webkit-scrollbar {
      width: 6px;
      height: 0px;
    }
    ::-webkit-scrollbar-track {
      background: $veryLight;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba($color: $black, $alpha: 0.1);
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba($color: $black, $alpha: 0.25);
    }
  }
}

.backBtn {
  font-size: 12px;
  color: $primaryColor;
  text-decoration: underline;
  text-align: center;
  margin: 4px 0 0;
  width: fit-content;
  cursor: pointer;
  font-weight: 500;
}

.uploadModalContent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  min-height: 70vh;
  input {
    &::placeholder {
      color: $darkGreyColor;
    }
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
  .otherForm {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .uploadModelParentDiv {
    display: flex;
    gap: 10px;
    align-items: center;
    border-bottom: 1px solid $light;
    padding: 20px 30px;
    .logoDetails {
      flex-grow: 1;
      flex-basis: 0;
      border-bottom: unset;
      padding: unset;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      border-bottom: unset;
    }
  }

  .logoDetails {
    display: flex;
    order: 1;
    flex-direction: column;
    justify-content: center;
    padding: 20px 30px;
    row-gap: 3px;
    border-bottom: 1px solid $light;
    width: 100%;

    label {
      font-size: 14px;
      font-weight: 500;
    }

    img.logo {
      height: 50px;
      @media screen and (max-width: 767px) {
        height: 35px;
      }
    }
    h2 {
      text-transform: capitalize;
      font-size: 24px;
      margin-right: 20px;
      font-family: $circularBold;
      letter-spacing: 0;
      margin-bottom: 0;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    .otherForm {
      div {
        margin: 0 !important;
        padding: 0;
      }
      input {
        &::placeholder {
          color: $darkGreyColor;
        }
        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
      .renameLink {
        display: flex;
        align-items: center;
        color: $primaryColor;
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
        margin-left: 10px;
        @media screen and (max-width: 1024px) {
          margin-left: 0;
        }
      }
    }
    .option {
      display: flex;
      align-items: center;
      column-gap: 10px;

      img {
        height: 20px;
        max-width: 35px;
        object-fit: contain;
        object-position: left;
      }
    }
    :global(.distributor__control--menu-is-open) {
      border-radius: 7px 7px 0 0;
      border-bottom: none;
    }

    :global(.distributor__control--is-disabled) {
      opacity: 0.6;
    }

    :global(.distributor__menu) {
      animation: fadeInDistributorMenu 0.2s ease-in-out;
    }

    :global(.distributor__option) {
      font-size: 14px;
      padding: 6px 12px;
    }

    :global(.distributor__menu-list) {
      &::-webkit-scrollbar {
        width: 6px;
        height: 0px;
      }

      &::-webkit-scrollbar-track {
        background: $veryLight;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba($color: $grey, $alpha: 0.4);
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgba($color: $grey, $alpha: 0.3);
      }
    }

    :global(.distributor__indicator) {
      svg {
        fill: rgba($color: $grey, $alpha: 0.9);
      }
    }

    @keyframes fadeInDistributorMenu {
      0% {
        max-height: 0;
        opacity: 0;
      }

      50% {
        opacity: 0.2;
      }

      100% {
        max-height: 200px;
        opacity: 1;
      }
    }
    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    :global(.form-group) {
      margin-bottom: 0px;
      > div {
        margin-bottom: 0px !important;
        padding-top: 0px !important;
      }
    }
  }

  .infoAndUploadContainer {
    display: flex;
    flex-grow: 1;
    .distributorInfo {
      display: flex;
      flex-direction: column;
      flex: 1 0;
      overflow-y: auto;
      h4.infoTitle {
        font-size: 14px;
        font-family: $circularBold;
        @media screen and (max-width: 767px) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &::after {
            content: " ";
            display: block;
            width: 10px;
            height: 10px;
            border-bottom: 2px solid $primaryColor;
            border-right: 2px solid $primaryColor;
            flex-shrink: 0;
            transition: transform 0.3s ease-in-out;
            transform: rotate(45deg);
          }
        }
      }
      .formatList {
        font-size: 12px;
        margin-bottom: 0;
        list-style: none;
        padding-inline-start: 1em;
        li {
          cursor: default;
          &::before {
            content: "\2022";
            font-family: $circularBold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
      .highlightBox {
        background-image: linear-gradient(
          to bottom,
          $primaryColor calc((0.09 - 1) * 10000%),
          transparent calc(0.09 * 10000%)
        );
        padding: 15px;
        margin-left: -15px;
        border-radius: 5px;
        color: $primaryColor;
        margin-bottom: 20px;
        p {
          margin: 0;
          font-size: 15px;
        }
      }
      .instructionInfoContainer {
        padding: 20px 40px;
        order: 2;
        .btnLink {
          margin-top: 20px;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          border: 1px solid $helpTextColor;
          border-radius: 30px;
          color: $helpTextColor;
          background-color: transparent;
          width: fit-content;
          font-size: 12px;
          svg {
            width: 15px;
            margin-right: 10px;
            path {
              fill: $helpTextColor;
            }
          }
        }
        .otherContent {
          font-size: 16px;
          ul {
            list-style: none;
            padding-inline-start: 20px;
          }
          li::before {
            content: "\2022";
            line-height: 16px;
            color: $primaryColor;
            font-weight: 600;
            display: inline-block;
            margin-left: -20px;
            margin-right: 10px;
          }
        }
        @media screen and (max-width: 767px) {
          &.showInstruction {
            .instructionsContent {
              max-height: 1000px;
            }
            .infoTitle::after {
              transform: translateY(5px) rotate(-135deg);
            }
          }
        }
      }
      .helpTextContainer {
        display: flex;
        margin-top: auto;
        padding: 20px 40px;
        order: 3;
        .helpTextLink {
          color: $helpTextColor;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .backCtn {
        display: flex;
        padding: 20px 40px;
        order: 4;
        border-top: 1px solid $light;
        &.publish {
          margin-top: auto;
        }
        .backBtn {
          font-size: 18px;
          text-decoration: underline;
          color: $helpTextColor;
          cursor: pointer;
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      @media screen and (max-width: 767px) {
        overflow-y: visible;
      }
    }
    .uploadContainer {
      display: flex;
      flex-direction: column;
      padding: 30px;
      overflow-y: auto;
      border-left: 1px solid $light;
      flex-grow: 0;
      flex-basis: 357px;

      .uploadBox {
        border: 2px dashed $primaryColor;
        border-radius: 7px;
        width: 100%;
        height: 125px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }
      .uploadBtn {
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
        border: none;
        border-radius: 25px;
        font-size: 14px;
        font-family: $circularBold;
        margin-top: 10px;
        padding: 10px;
        text-transform: uppercase;
        letter-spacing: 2.8px;
        &:disabled {
          background-color: rgba($color: $ctaColorRgb, $alpha: 0.4);
          cursor: not-allowed;
        }
      }
      .modalLink {
        margin: 20px 0 0;
        background-color: $white;
        padding: 5px 0;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
          margin: 0;
          text-decoration: underline;
          cursor: pointer;
          font-size: 14px;
          color: $helpTextColor;
          @media screen and (max-width: 767px) {
            text-align: center;
          }
        }
        span {
          margin-left: 5px;
        }
        svg {
          transform: translate(0px, -1px);
          width: 15px;
          height: 15px;
          path {
            fill: $ctaColor;
          }
        }
      }
      .infoText {
        margin: 0;
        background-color: $white;
        padding: 5px 0;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
          margin: 0;
          font-size: 12px;
          @media screen and (max-width: 767px) {
            text-align: center;
          }
        }
      }

      &.initialBox {
        .uploadBox {
          flex-grow: 1;
          @media screen and (max-width: 767px) {
            min-height: unset;
          }
        }
      }
      .backMobileCtn {
        display: none;
        .backBtn {
          font-size: 16px;
          text-decoration: underline;
          color: $helpTextColor;
          cursor: pointer;
        }
        @media screen and (max-width: 767px) {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
        }
      }
      @media screen and (max-width: 767px) {
        padding-top: 0;
        flex-basis: unset;
        overflow-y: unset;
        border-bottom: 1px solid $veryLight;
      }
    }
    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }
}

.overlayClass {
  background-color: $white;
  border-radius: 12px;
  max-width: 1366px;
  width: 100%;
  outline: none;
  padding: 0px;
  box-sizing: border-box;
  margin-top: 20px;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);

  * {
    box-sizing: border-box;
  }

  .icon {
    width: 14px;
    position: absolute;
    top: 0;
    right: 6px;
    transform: rotate(45deg);
    cursor: pointer;

    g {
      path {
        stroke: $black;
      }
    }
  }
}

.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: transparent;
  font-size: 30px;
  cursor: pointer;
  line-height: 18px;
  overflow: hidden;
  padding: 4px;
  @media screen and (max-width: 767px) {
    color: $white;
    position: fixed;
    right: 6px;
    top: 6px;
  }
}

.instructionsContent {
  position: relative;
  font-size: 14px;
  * {
    font-size: initial;
    box-sizing: initial;
    &:after,
    &:before {
      font-size: initial;
      box-sizing: initial;
    }
  }
  a {
    text-decoration: underline;
    color: -webkit-link;
  }
  @media screen and (max-width: 767px) {
    overflow-y: hidden;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
  }
}

.deleteModal {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 90%;
  background-color: $white;
  margin: auto;
  border: none;
  height: fit-content;
  outline: none;
  box-shadow: 0 0 8px -2px rgba($color: $black, $alpha: 0.3);
  padding: 0;
  border-radius: 20px;
}

.publishingModalContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  position: relative;
  padding: 40px;
  svg {
    width: 64px;
    path {
      fill: $primaryColor;
    }
  }
  h2 {
    text-align: center;
    font-family: $circularBold;
    font-size: 30px;
    margin: 0;
    letter-spacing: 0;
    span {
      text-transform: capitalize;
    }
    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }
  .textCenter {
    text-align: center;
    margin: 0;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    column-gap: 10px;
    row-gap: 20px;
    width: 100%;
    button {
      padding: 10px 20px;
      border-radius: 30px;
      font-family: $circularBold;
      color: $ctaColor;
      border: 1px solid $ctaColor;
      background-color: transparent;
      text-transform: uppercase;
      font-size: 14px;
      width: 100%;
      &.btnPrimary {
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 30px;
  }
}

.deleteModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 50px;
  .closeBtn {
    @media screen and (max-width: 767px) {
      position: absolute;
    }
  }
  svg {
    width: 65px;
    margin-bottom: 10px;
  }
  h2 {
    text-align: center;
    font-family: $circularBold;
    letter-spacing: 0.76px;
    font-size: 32px;
    span {
      color: $primaryColor;
    }
    @media screen and (max-width: 767px) {
      font-size: 26px;
    }
  }
  .textCenter {
    text-align: center;
  }
  .ackSubtitle {
    font-weight: 600;
  }
  .buttonContainer {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    button {
      padding: 12px 30px;
      min-width: 200px;
      border-radius: 25px;
      font-family: $circularBold;
      color: $ctaColor;
      border: 1px solid $ctaColor;
      background-color: transparent;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      font-size: 14px;
      &.btnPrimary {
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
      }
      &.btnLink {
        border: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &:first-child {
        margin-right: 20px;
      }
      @media screen and (max-width: 767px) {
        min-width: unset;
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}

.helpText {
  display: block;
  padding: 5px 0;
  text-decoration: underline;
  color: $primaryColor;
  font-size: 16px;
  margin-top: 5px;
  cursor: pointer;
  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
}

.ackIssueModal {
  max-width: 900px;
  h2 {
    font-family: $circularBold;
  }
  .ackSubtitle {
    margin: 15px 0px 10px;
  }

  .missingReport {
    background-color: rgba($color: $veryLight, $alpha: 0.5);
    max-height: 30vh;
    min-height: 100px;
    overflow-y: auto;

    .distLogo {
      height: 40px;
      width: 125px;
      flex-shrink: 0;
      img {
        width: auto;
        height: 100%;
      }
      p {
        margin: 0;
      }
      @media screen and (max-width: 767px) {
        height: 35px;
        width: 105px;
      }
      @media screen and (max-width: 410px) {
        padding: 0px;
        height: 28px;
        width: 90px;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      padding-left: 40px;
      justify-content: center;
      p {
        margin: 0;
        &:first-child {
          font-size: 20px;
          margin-bottom: 0;
          @media screen and (max-width: 767px) {
            font-size: 18px;
          }
          @media screen and (max-width: 410px) {
            font-size: 16px;
          }
        }
        &.errorText {
          color: $grey;
          font-size: 12px;
          margin: 0;
          font-family: $circularBold;
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        &.otherErrorText {
          color: $grey;
          font-size: 12px;
          margin: 0;
          font-family: $circularBold;
          @media screen and (max-width: 767px) {
            letter-spacing: -0.7px;
          }
        }
      }
      @media screen and (max-width: 767px) {
        padding-left: 20px;
      }
    }
    .otherLogo {
      height: 40px;
      width: 125px;
      background-color: $white;
      color: $black;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      p {
        margin: 0 0 0 5px;
        font-family: $circularBold;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
        @media screen and (max-width: 410px) {
          font-size: 12px;
          height: 25px;
          width: 90px;
        }
      }

      img {
        height: 20px;
      }

      @media screen and (max-width: 767px) {
        height: 35px;
        width: 105px;
        padding: 0 10px;
      }
    }
    .toolIcon {
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 767px) {
        display: flex;
        align-items: center;
        line-height: normal;
      }
    }
    .headerCell {
      background-color: $light;
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 600;
      color: $medium;
      @media screen and (max-width: 767px) {
        padding: 4px;
        font-size: 10px;
      }
      @media screen and (max-width: 410px) {
        padding: 4px;
        font-size: 8px;
      }
    }
    .bodyCell {
      @media screen and (max-width: 410px) {
        padding: 4px;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 10px 5px;
    }
    @media screen and (max-width: 410px) {
      padding: 0px;
    }
  }
  .buttonContainer {
    button {
      background-color: $ctaColor;
      color: $fundingCtaFontColor;
      text-transform: unset;
      @media screen and (max-width: 767px) {
        padding: 10px 15px;
      }
    }
  }
}

.ackTrackIssueModal {
  max-width: 1000px;
  h2 {
    font-family: $circularBold;
  }
  .ackSubtitle {
    margin: 15px 0px 10px;
  }
  .trackTable {
    max-height: 440px;
    background-color: rgba($color: $veryLight, $alpha: 0.5);
    .headerCell {
      background-color: $light;
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 600;
      color: $medium;
      &:first-child {
        max-width: 150px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 10px;
      }
      @media screen and (max-width: 767px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .trackDetail {
      display: flex;
      align-items: center;
      img {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        @media screen and (max-width: 1440px) {
          height: 40px;
          width: 40px;
        }
        @media screen and (max-width: 410px) {
          height: 30px;
          width: 30px;
        }
      }
      svg {
        width: 60px;
        height: auto;
        @media screen and (max-width: 1440px) {
          width: 40px;
        }
        @media screen and (max-width: 410px) {
          width: 30px;
        }
      }
      p {
        font-size: 18px;
        font-weight: 600;
        margin: 0 0 0 20px;
        @media screen and (max-width: 1440px) {
          font-size: 16px;
          margin: 0 0 0 15px;
        }
        @media screen and (max-width: 767px) {
          font-size: 14px;
          margin: 0 0 0 8px;
        }
      }
    }
    .chartData {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 1440px) {
        font-size: 12px;
        font-weight: 600;
      }
    }
    .trackTableCell {
      border-bottom: none;
      &:first-child {
        max-width: 150px;
      }
      @media screen and (max-width: 1440px) {
        padding: 5px 10px;
      }
      @media screen and (max-width: 767px) {
        &:nth-child(2) {
          display: none;
        }
      }
    }
    .issueCell {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .missingError {
        color: $error;
        display: flex;
        align-items: center;
        margin: 0;
        span {
          content: " ";
          background-color: $error;
          height: 20px;
          width: 20px;
          color: $white;
          border-radius: 50%;
          padding-left: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $circularBold;
          margin-left: 8px;
          flex-shrink: 0;
          @media screen and (max-width: 1440px) {
            height: 16px;
            width: 16px;
          }
        }
        @media screen and (max-width: 1440px) {
          font-size: 12px;
        }
      }
      @media screen and (max-width: 410px) {
        :global {
          .action__menu {
            font-size: 11px;
          }
          .action__value-container {
            justify-content: flex-start;
          }
          .action__placeholder {
            white-space: nowrap;
          }
        }
      }
    }
    input {
      background-color: transparent;
      &:focus::placeholder {
        color: $grey;
      }
    }
  }
  .buttonContainer {
    button {
      background-color: transparent;
      color: $ctaColor;
      border: 1px solid $ctaColor;
      font-size: 14px;
      text-transform: unset;
      &:first-child {
        margin-right: 10px;
        @media screen and (max-width: 767px) {
          margin: 0 0 15px 0;
        }
      }
      &.btnPrimary {
        background-color: $ctaColor;
        color: $fundingCtaFontColor;
      }
      @media screen and (max-width: 1440px) {
        padding: 15px 15px;
      }
      @media screen and (max-width: 767px) {
        padding: 10px 15px;
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
}
